import styled from '@emotion/styled';
import React from 'react';
import { Nav } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { TestableComponentProps } from '~/model/TestableComponentProps.interface';

const StyledIconContainer = styled.span`
  margin-right: 10px;
  width: 24px;
  height: 24px;
`;

const StyledIconAndTextContainer = styled.span`
  display: flex;
  align-items: center;
`;

const StyledText = styled.span`
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
`;

interface Props extends TestableComponentProps {
  title: string;
  icon?: React.ReactNode;
  path?: string;
  index: number;
}

export const TabTitle = ({ title, path, icon, index, testId }: Props) => {
  const link = (
    <Nav.Link eventKey={index}>
      <StyledIconAndTextContainer data-testid={testId}>
        {icon && <StyledIconContainer>{icon}</StyledIconContainer>}
        <StyledText>{title}</StyledText>
      </StyledIconAndTextContainer>
    </Nav.Link>
  );

  return (
    <Nav.Item as="li">
      {path && <LinkContainer to={path}>{link}</LinkContainer>}
      {!path && link}
    </Nav.Item>
  );
};
