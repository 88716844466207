import React from 'react';
import { useFoodLog } from '~/model/Client/FoodLog/useFoodLog';
import { Loader } from '~/components/common/Loader';
import { useClient } from '~/components/pages/client/context';
import { Card, CardBody } from '~/components/common/Card';
import { FoodLog as Component } from './FoodLog';

const Error = () => <div>Could not retrieve data</div>;

export const FoodLog = () => {
  const { clientId } = useClient();
  const { isError, isLoading, data } = useFoodLog(clientId);
  if (isLoading) {
    return <Loader size="medium" center />;
  }

  if (isError || !data) {
    return <Error />;
  }

  return (
    <Card>
      <CardBody>
        <Component {...data} />
      </CardBody>
    </Card>
  );
};
