import styled from '@emotion/styled';
import { Field } from 'formik';
import { colors } from '~/components/utils/colors';

export const DisplayText = styled.div`
  height: 1.5rem;
  overflow: hidden;
  word-break: break-word;

  &.is-large {
    height: 1.7rem;
  }
`;

export const StyledButtonDropdowWrapper = styled.div`
  position: relative;
`;

export const StyledDropdownButton = styled.button`
  background-image: url('/media/svg/misc/dropdown-down.svg');
  background-repeat: no-repeat;
  background-position: right 12px center;
  padding: 11px 30px 11px 16px;
  margin-bottom: 2px;
  height: 40px;

  &.open {
    background-image: url('/media/svg/misc/dropdown-up.svg');
  }

  &:disabled {
    background-color: ${colors.colorGsOne};
    color: ${colors.colorGsThree};
  }
  
  &.is-large {
    padding: 16px 25px 16px 25px;
    height: 57px;
    font-size: 14px;
    line-height: 21px;
  }
`;

export const StyledDropdownList = styled.div`
  position: absolute;
  height: auto;
  background-color: ${colors.colorWhite};
  right: 0;
  width: 100%;
  box-shadow: 0px 4px 8px 4px rgba(0, 0, 0, 0.1);
  max-height: 300px;
  overflow: auto;
  border-radius: 6px;
  z-index: 2;
  display: flex;
  flex-direction: column;
`;

export const StyledDropdownItem = styled.label`
  display: flex;
  justify-content: space-between;
  padding: 10px 12px 10px 16px;
  margin: 0;
  color: ${colors.colorGsThree};

  &:first-of-type {
    border-radius: 6px 6px 0 0;
  }

  &:last-of-type {
    border-radius: 0 0 6px 6px;
  }

  &:hover {
    background-color: ${colors.colorDisabledTwo};
  }
`;

export const StyledDropdownItemCheck = styled(Field)`
  appearance: none;
  width: 16px;
  height: 16px;

  &:checked {
    background-image: url('/media/svg/misc/checkmark.svg');
  }
`;
