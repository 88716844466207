import { Color } from '~/components/common/Label';
import { Header } from '~/components/pages/client/Sections/ExpandableTable/model';
import { style } from './Supplements.style';

export interface SupplementCategory {
  name: string;
  supplements: Supplement[];
}

export enum SupplementStatusValue {
  OUT_OF_STOCK = 'Out of stock',
  IN_STOCK = 'In stock',
}

export interface SupplementStatus {
  color: Color;
  value: SupplementStatusValue;
}

export interface Supplement {
  id: number;
  name: string;
  brand: string;
  category: string;
  servings: number;
  price: number;
  currency: string;
  formattedPrice: string;
  status: SupplementStatus;
}

export const headerColumns: Header[] = [
  {
    title: 'Supplement',
    width: '2.35fr',
  },
  {
    title: 'Brand',
    width: '1.3fr',
  },
  {
    title: 'Category',
    width: '1.3fr',
  },
  {
    title: 'Servings',
    width: '1fr',
  },
  {
    title: 'Price',
    width: '1fr',
    tooltip:
      'All prices shown include VAT, except for Norway, Swizerland, UAE. In these countries taxes are paid by the client during the customs clearance process.',
  },
  {
    title: 'Status',
    width: '1.25fr',
  },
  {
    title: 'In basket',
    width: '1fr',
  },
  {
    title: 'Action',
    className: style.headers.actions,
    width: '1.25fr',
  },
];
