import React, { PropsWithChildren } from 'react';
import { TestableComponentProps } from '~/model/TestableComponentProps.interface';
import { Remarks as Component } from './Remarks.style';

export interface Props extends PropsWithChildren<any>, TestableComponentProps {
  lines: string[];
}

export const Remarks = ({ lines }: Props) => (
  <Component>
    <div className="asterisks">
      {lines.map((line, index) => (
        <span key={line}>{'*'.repeat(index + 1)}</span>
      ))}
    </div>
    <div>
      {lines.map((line) => (
        <span key={line}>{line}</span>
      ))}
    </div>
  </Component>
);
