import * as React from 'react';
import { SVGProps } from 'react';
import { Size } from '../types';
interface Props extends SVGProps<SVGSVGElement> {
  size?: Size;
}
const SvgTrash = (props: Props) => {
  const getSize = () => {
    if (props.size) {
      switch (props.size) {
        case 'small':
          return 20;

        case 'medium':
          return 24;

        case 'large':
          return 32;

        default:
          return null;
      }
    }
  };

  return (
    <svg
      width={getSize() || 20}
      height={getSize() || 20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.047 6.667v10.166a1.5 1.5 0 001.5 1.5h7.042a1.5 1.5 0 001.5-1.5V6.667H5.047z"
        fill="currentColor"
      />
      <path
        opacity={0.3}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.742 3.75V3.5a1 1 0 00-1-1H9.394a1 1 0 00-1 1v.25H4.71a.5.5 0 00-.5.5v.25a.5.5 0 00.5.5h10.716a.5.5 0 00.5-.5v-.25a.5.5 0 00-.5-.5h-3.684z"
        fill="currentColor"
      />
    </svg>
  );
};

export default SvgTrash;
