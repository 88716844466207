import { capital } from 'case';
import { PostEditQuestionAnswerRequest } from '~/model/Client/Questionnaire';
import { AnswerValue, QuestionAnswer } from './model';

export interface FormFields {
  dimension: string;
  category: string;
  [questionId: string]: AnswerValue;
}

export const initialValues = ({
  dimension,
  category,
  question: { id: questionId, value },
  followUpQuestions,
}: QuestionAnswer): FormFields => {
  const followUpQuestionValues = followUpQuestions.reduce(
    (acc, followUpQuestion) => ({
      ...acc,
      [followUpQuestion.id]: followUpQuestion.value,
    }),
    {},
  );

  return {
    dimension: dimension ? capital(dimension) : 'N/A',
    category,
    [questionId]: value || '',
    ...followUpQuestionValues,
  };
};

export const toApiRequest = ({
  dimension: _dimension,
  category: _category,
  ...answers
}: FormFields): PostEditQuestionAnswerRequest => ({
  ...answers,
});
