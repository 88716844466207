import React from 'react';
import { Provider } from 'react-redux';
import { Store } from '@reduxjs/toolkit';
import { BrowserRouter } from 'react-router-dom';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import { I18nProvider } from '~/vendor/_metronic/i18n';
import {
  MaterialThemeProvider,
  LayoutSplashScreen,
} from '~/vendor/_metronic/layout';
import { queryClient } from '~/services/api';
import * as Sentry from "@sentry/react";
import { ErrorPageGeneric } from '~/components/pages/error';
import { ToastContainer } from 'react-toastify';
import { Router } from '~/components/router/Router';
import { SettingsProvider } from '~/components/common/SettingsProvider';

interface Props {
  store: Store<any>;
}

export function App({ store }: Props) {
  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <React.Suspense fallback={<LayoutSplashScreen />}>
          <SettingsProvider>
            <BrowserRouter>
              <MaterialThemeProvider>
                <I18nProvider>
                  <Sentry.ErrorBoundary fallback={ErrorPageGeneric} showDialog>
                    <Router />
                    <ToastContainer />
                    <ReactQueryDevtools initialIsOpen={false} />
                  </Sentry.ErrorBoundary>
                </I18nProvider>
              </MaterialThemeProvider>
            </BrowserRouter>
          </SettingsProvider>
        </React.Suspense>
      </QueryClientProvider>
    </Provider>
  );
}
