import React, { } from 'react';
import {
  FormikInput,
  FormRowInputWrapper,
  FormRow,
} from '~/components/common/Form';
import { FormRowLabel } from './Label';

export const BankDetailsIndividual = () => (
  <>
    <FormRow width="half">
      <>
        <FormRowLabel>Bank name</FormRowLabel>
        <FormRowInputWrapper>
          <FormikInput name="bankName" placeholder="DBS" />
        </FormRowInputWrapper>
      </>
      <>
        <FormRowLabel>Bank address</FormRowLabel>
        <FormRowInputWrapper>
          <FormikInput name="bankAddress" placeholder="12 Marina Boulevard, Singapore 018982" />
        </FormRowInputWrapper>
      </>
    </FormRow>
    <FormRow width="half">
      <>
        <FormRowLabel>Bank account name</FormRowLabel>
        <FormRowInputWrapper>
          <FormikInput name="bankAccountName" placeholder="Your Name" />
        </FormRowInputWrapper>
      </>
      <>
        <FormRowLabel>Paynow ID</FormRowLabel>
        <FormRowInputWrapper>
          <FormikInput name="paynowId" placeholder="89123456" />
        </FormRowInputWrapper>
      </>
    </FormRow>
    <FormRow width="half">
      <>
        <FormRowLabel>Bank account number</FormRowLabel>
        <FormRowInputWrapper>
          <FormikInput name="iban" placeholder="1234567890" />
        </FormRowInputWrapper>
      </>
    </FormRow>
  </>
);
