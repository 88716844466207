import styled from '@emotion/styled';
import { colors } from '~/components/utils/colors';

export const StyledDropdown = styled.select`
  padding: 2px;
  height: 27px;
  border-style: none;
  border-radius: 6px;
  background-color: ${colors.colorDisabledTwo};
  color: ${colors.colorMenuDefault};
  font-weight: 500;
`;
