import { AddGoalRequest, EditGoalRequest } from '~/model/Client/Goals';
import { Goal } from './model';

export interface FormFields {
  dimension: number;
  topic: string;
  goal: string;
  description: string;
  actions?: number;
  week: number;
  recommendation?: number;
  habits?: number[];
}

// generate an an array of [ {title : 1, value: 1}, { title : 2, value: 2}, ... ]
export const getWeekOptions = () => {
  const journeyWeeks = 12;
  return [...Array.from(Array(journeyWeeks).keys())].map((i) => ({ title: i + 1, value: i + 1 }));
};

// generate an array of [ {title : 1, value: 1}, { title : 2, value: 2}, ... ]
export const getActionsOptions = () => {
  const actions = 7;

  return [...Array.from(Array(actions).keys())].map((i) => ({ title: i + 1, value: i + 1 }));
};

export const initialValues = ({
  dimension,
  topic,
  description,
  goal,
  week,
  actions,
  recommendation,
}: Goal): FormFields => ({
  dimension,
  topic,
  description,
  // FYI: formik + <select> tag allows only undefined or empty string for empty select value,
  // so we need to cast null to undefined here ¯\_(ツ)_/¯
  actions: actions || undefined,

  goal,
  week,
  recommendation,
});

export const toApiRequest = ({
  goal,
  description,
  actions,
  week,
}: FormFields): EditGoalRequest => ({
  title: goal,
  text: description,
  // FYI: React does not allow null as a value for <option> (but does allow <option value=""> )
  // so we need to cast empty string to null ¯\_(ツ)_/¯ ¯\_(ツ)_/¯ ¯\_(ツ)_/¯
  actions_total_override: actions || null,
  week,
});

export const toApiAddRequest = ({
  dimension,
  recommendation,
  goal,
  description,
  actions,
  week,
  habits,
}: FormFields): AddGoalRequest => ({
  dimension,
  recommendation,
  title: goal,
  text: description,
  // FYI: React does not allow null as a value for <option> (but does allow <option value=""> )
  // so we need to cast empty string to null ¯\_(ツ)_/¯ ¯\_(ツ)_/¯ ¯\_(ツ)_/¯
  actionsTotalOverride: actions || null,
  week,
  habits,
});
