/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import clsx from 'clsx';
import { TestableComponentProps } from '~/model/TestableComponentProps.interface';

export type Variant = 'primary' | 'success' | 'warning' | 'danger';

export interface Props extends TestableComponentProps {
  variant?: Variant;
  completed?: number;
  className?: string;
}

export const Progress = ({
  variant = 'primary',
  completed = 0,
  testId = 'progress-bar',
  className = '',
}) => (
  <div className={clsx('progress progress-xs w-100', `bg-light-${variant}`, className)}>
    <div
      data-testid={testId}
      className={`progress-bar bg-${variant}`}
      role="progressbar"
      aria-valuenow={completed}
      aria-valuemin={0}
      aria-valuemax={100}
      style={{ width: `${completed}%` }}
    />
  </div>
);
