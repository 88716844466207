import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { IconButton } from '~/components/common/Button';
import { FormikDropdown, FormikInput, FormRowInputWrapper } from '~/components/common/Form';
import { Close2, Search } from '~/components/common/Icons/dist';
import { SearchFilterActions, SearchFilterWrapper } from '~/components/common/SearchAndFilter';
import {
  useCategoryChoises,
  useResultChoises,
  useTestkitChoises,
} from '~/model/Client/TestResults/hooks';
import {
  TestKitFilterParams,
  TestKitSearchAndFilters,
} from '~/model/Client/TestResults/testResults';
import { useClient } from '../../../context';

export interface Props {
  initialValues: TestKitSearchAndFilters;
  term: string;
  setTerm: (value) => void;
  filters: TestKitFilterParams;
  setFilters: (values) => void;
  isLoading: boolean;
}

export const SearchAndFilters = ({
  initialValues,
  term,
  setTerm,
  filters,
  setFilters,
  isLoading,
}: Props) => {
  const [localTerm, setLocalTerm] = useState(term);
  const { clientId } = useClient();
  const { data: categories } = useCategoryChoises(clientId);
  const { data: testKits } = useTestkitChoises(clientId);
  const { data: results } = useResultChoises(clientId);

  const onSubmit = () => {
    setTerm(localTerm);
  };

  const handleTermChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLocalTerm(event.target.value);
  };

  const handleCategoryChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setFilters({
      ...filters,
      category: event.target.value,
    });
  };

  const handleTestkitChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setFilters({
      ...filters,
      testKit: event.target.value,
    });
  };

  const handleResultChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setFilters({
      ...filters,
      result: event.target.value,
    });
  };

  const handleClear = (resetForm) => {
    setLocalTerm(initialValues.term);
    setTerm(initialValues.term);
    setFilters(initialValues.filters);
    resetForm(initialValues);
  };

  const handleFocus = (event: React.FocusEvent<HTMLButtonElement>) => {
    event.target.blur();
  };

  return (
    <SearchFilterWrapper style={{ paddingLeft: '10px', paddingRight: '10px' }}>
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        {({ resetForm, submitForm }) => (
          <Form className="row align-items-center">
            <div className="row col">
              <div className="col-12 col-sm-3">
                <FormRowInputWrapper>
                  <FormikInput
                    name="term"
                    icon={<Search />}
                    value={localTerm}
                    onChange={handleTermChange}
                    placeholder="Search for a biomarker"
                  />
                </FormRowInputWrapper>
              </div>
              <div className="col-12 col-sm-3">
                <FormRowInputWrapper>
                  <FormikDropdown
                    name="filters.category"
                    value={filters.category}
                    onChange={handleCategoryChange}
                    placeholder="Filter category"
                  >
                    <option value="" disabled>
                      Filter category
                    </option>
                    <option value="all">All Categories</option>
                    {categories?.map(({ id, name }) => (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    ))}
                  </FormikDropdown>
                </FormRowInputWrapper>
              </div>
              <div className="col-12 col-sm-3">
                <FormRowInputWrapper>
                  <FormikDropdown
                    name="filters.testKit"
                    value={filters.testKit}
                    onChange={handleTestkitChange}
                    placeholder="Filter test kit"
                  >
                    <option value="" disabled>
                      Filter test kit
                    </option>
                    <option value="all">All Test kits</option>
                    {testKits?.map(({ id, name }) => (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    ))}
                  </FormikDropdown>
                </FormRowInputWrapper>
              </div>
              <div className="col-12 col-sm-3">
                <FormRowInputWrapper>
                  <FormikDropdown
                    name="filters.result"
                    value={filters.result}
                    onChange={handleResultChange}
                    placeholder="Filter result"
                  >
                    <option value="" disabled>
                      Filter result
                    </option>
                    <option value="all">All Results</option>
                    {results?.map(({ id, name }) => (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    ))}
                  </FormikDropdown>
                </FormRowInputWrapper>
              </div>
            </div>
            <div className="col-auto">
              <SearchFilterActions>
                <IconButton
                  icon={<Search />}
                  variant="neutral"
                  type="submit"
                  aria-label="Search"
                  onClick={submitForm}
                  disabled={isLoading}
                  isLoading={isLoading}
                  onFocus={handleFocus}
                />
                <IconButton
                  icon={<Close2 />}
                  variant="neutral"
                  type="reset"
                  aria-label="Clear"
                  onClick={() => {
                    handleClear(resetForm);
                  }}
                  disabled={isLoading}
                  isLoading={isLoading}
                  onFocus={handleFocus}
                />
              </SearchFilterActions>
            </div>
          </Form>
        )}
      </Formik>
    </SearchFilterWrapper>
  );
};
