import React from 'react';
import { Button } from '~/components/common/Button';
import { Bullets20 } from '~/components/common/Icons/dist';

export const ScheduleButton = ({ disabled, onClick: handleClick }) => (
  <Button
    disabled={disabled}
    onClick={() => handleClick()}
    shade="light"
    icon={<Bullets20 />}
    iconPosition="right"
    size="large"
  >
    Schedule
  </Button>
);
