import React from 'react';
import { Card, CardHeader } from '~/components/common/Card';
import { useFeatureToggle } from '~/services/feature-toggle/hooks';
import { Feature } from '~/services/feature-toggle/model';

export const FeatureToggles = () => {
  const disabledFeatureId = 'some-random-disabled-feature' as Feature;

  const { isFeatureEnabled: isDemoFeatureEnabled } = useFeatureToggle('demo-feature');
  const { isFeatureEnabled: isNonExistingFeatureEnabled } = useFeatureToggle(disabledFeatureId);

  return (
    <>
      <div>
        <h1>*note*: demo-feature is enabled in dev/disabled in prod via backend</h1>
      </div>
      {isDemoFeatureEnabled() && (
        <Card>
          <CardHeader title="demo-feature: enabled feature via useFeatureToggle context" />
        </Card>
      )}
      {isNonExistingFeatureEnabled() && (
        <Card>
          <CardHeader title="This should never render" />
        </Card>
      )}
    </>
  );
};
