import React from 'react';

import {
  AlternativeHeading,
  Column,
  ListItemPrefix,
  Row,
  ScrollableContent,
} from '~/components/common/Modal/Modal.style';
import { NutritionPerServing as NutritionPerServingModel } from '~/model/NutritionPerServing';
import { TestableComponentProps } from '~/model/TestableComponentProps.interface';

interface NutritionPerServingProps extends TestableComponentProps {
  nutritionPerServing: NutritionPerServingModel;
  prefix?: null | undefined | '-';
}

const NutritionPerServing = ({ nutritionPerServing, prefix, testId }: NutritionPerServingProps) => (
  <>
    <Row
      data-testid={`${testId}-nutrition-per-serving-item-${nutritionPerServing.name}`}
      key={`${nutritionPerServing.name}-${nutritionPerServing.amount}-${nutritionPerServing.unit}`}
      $gap="0px"
      $marginBottom="0px"
    >
      <Column $direction="row" $width="65%">
        {prefix && <ListItemPrefix>{prefix}</ListItemPrefix>}
        {nutritionPerServing.name}
      </Column>
      <Column $width="35%">
        {nutritionPerServing.amount?.toFixed(2).replace(/[.,]00$/, '')} {nutritionPerServing.unit}
      </Column>
    </Row>
    {nutritionPerServing.children?.map((child) => (
      <NutritionPerServing
        key={`${child.name}-${child.amount}`}
        prefix="-"
        nutritionPerServing={child}
        testId={testId}
      />
    ))}
  </>
);

export interface NutritionsPerServingProps extends TestableComponentProps {
  nutritionsPerServing: NutritionPerServingModel[];
}

export const NutritionsPerServing = ({
  nutritionsPerServing,
  testId,
}: NutritionsPerServingProps) => {
  if (!nutritionsPerServing || nutritionsPerServing.length === 0) {
    return null;
  }

  return (
    <Column data-testid={`${testId}-nutrition-per-serving`}>
      <AlternativeHeading>Nutrition per serving</AlternativeHeading>
      <ScrollableContent $height="190px">
        {nutritionsPerServing
          .filter(
            (nutritionPerServing) =>
              nutritionPerServing.amount !== null && nutritionPerServing.amount !== undefined,
          )
          .map((nutritionPerServing) => (
            <NutritionPerServing
              testId={testId}
              key={`${nutritionPerServing.name}-${nutritionPerServing.amount}`}
              nutritionPerServing={nutritionPerServing}
            />
          ))}
      </ScrollableContent>
    </Column>
  );
};
