import { generatePath } from 'react-router-dom';
import { TestKitCategory } from '~/components/pages/client/Sections/TestKits/model';
import { api } from '~/services/api';

const URL = {
  LIST: '/api/client/:clientId/test-kit',
};

export const model = {
  getList: (clientId) => api.get<TestKitCategory[]>(generatePath(URL.LIST, { clientId })),
};
