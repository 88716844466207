import React from 'react';

import { Error, Loader, Props as ModalProps } from '~/components/common/Modal';
import { useGetPhenotype } from '~/model/Client/TestResults/Phenotype/useGetPhenotype';
import { TestableComponentProps } from '~/model/TestableComponentProps.interface';
// eslint-disable-next-line import/no-cycle
import { PhenotypeModal as Component } from './PhenotypeModal';

export interface Props extends TestableComponentProps, ModalProps {
  phenotypeId: number;
  width: string;
}

export const PhenotypeModal = ({ phenotypeId, ...otherProps }: Props) => {
  const { data, isLoading, isError } = useGetPhenotype(phenotypeId);

  if (isLoading) {
    return <Loader {...otherProps} />;
  }

  if (isError || !data) {
    return <Error {...otherProps} />;
  }

  return <Component phenotype={data} {...otherProps} />;
};
