import { useState } from 'react';

export interface Props {
  term: string;
  filters: {
    subscription: string;
    journey: string;
  };
}

export const useSearchFilters = ({ term: passedTerm, filters: passedFilters }: Props) => {
  const [term, setTerm] = useState(passedTerm);
  const [filters, setFilters] = useState(passedFilters);

  return {
    changeTerm: setTerm,
    changeFilters: setFilters,
    term,
    filters,
  };
};
