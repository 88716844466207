import { PaginationData } from '../paginationData';

const defaultPaginationData = {
  current: 2,
  currentItemCount: 5,
  first: 1,
  firstItemNumber: 6,
  last: 3,
  lastItemNumber: 10,
  next: 3,
  numItemsPerPage: 5,
  pagesInRange: [1, 2, 3],
  previous: 1,
  totalCount: 15,
};

export const mockPaginationData = (override?: Partial<PaginationData>): PaginationData => ({
  ...defaultPaginationData,
  ...override,
});

export const mockPaginationDataByNextPage = (nextPage: number) => ({
  ...defaultPaginationData,
  current: nextPage,
  currentItemCount: defaultPaginationData.numItemsPerPage * nextPage,
  firstItemNumber: defaultPaginationData.numItemsPerPage * nextPage,
  lastItemNumber:
    defaultPaginationData.numItemsPerPage * nextPage + defaultPaginationData.numItemsPerPage,
  next: defaultPaginationData.last <= nextPage ? defaultPaginationData.last : nextPage + 1,
  previous: defaultPaginationData.first >= nextPage ? defaultPaginationData.first : nextPage - 1,
});
