import { api } from '~/services/api';

export interface Payment {
  id: string;
  name: string;
}

export interface CreatePayment {
  products: string[];
  client: string;
}

export interface OfferServiceResource {
  id: string;
  quantity: number;
  price: number;
  float_price: number;
  float_vat: number;
  currency: string;
  name: string;
  product_id: string;
  formatted_price: string;
}

export interface PaymentPreviewResponse {
  products: OfferServiceResource[];
  currency: string;
  total_price: number;
  products_price: number;
  delivery_price: number;
  client_secret: number;
  formatted_total_price: string;
  formatted_products_price: string;
  formatted_delivery_price: string;
  formatted_coach_commissions: string;
  source: string;
  client: string;
  client_name: string;
  is_paid: boolean;
  is_expired: boolean;
  formatted_vat: string;
}

const URL = {
  CREATE_PAYMENT: '/api/payments',
  PREVIEW_PAYMENT: '/api/payments-preview',
};

export const model = {
  createPayment: <T>(data: T) => api.post<T>(URL.CREATE_PAYMENT, data),
  previewPayment: <T>(data: T) => api.post<T, PaymentPreviewResponse>(URL.PREVIEW_PAYMENT, data),
};
