import React from 'react';
import { useClient } from '~/components/pages/client/context';
import { useWidgetHistory } from '~/model/Client/Progress/hooks/useWidgetHistory';
import { ExpandableTable, TableRow, TableRowContent } from '~/components/pages/client/Sections/ExpandableTable';
import clsx from 'clsx';
import { css } from '@emotion/css';
import { colors } from '~/components/utils/colors';
import { DateTime } from 'luxon';
import { Color, getVariantFromColor } from '~/components/common/Label';
import { Loader } from '~/components/common/Loader';
import { WidgetHistoryDayData, WidgetId } from '~/model/Client/Progress/progress';
import { Header } from '~/components/pages/client/Sections/ExpandableTable/model';
import { DateIntervalLabel } from '~/components/common/DateIntervalLabel';
import { DayDataRow, LoaderContainer } from '../Modal.style';
import { formatTimeHMin, formatTimeMin } from './Shared';

export const headerClassName = clsx(
  css({
    textTransform: 'initial !important' as 'initial',
  }),
);

const headerColumns: Header[] = [
  {
    title: 'Date',
    width: '8fr',
  },
  {
    title: 'HR min',
    width: '6fr',
    className: headerClassName,
  },
  {
    title: 'HR max',
    width: '6fr',
    className: headerClassName,
  },
  {
    title: 'HR avg.',
    width: '6fr',
    className: headerClassName,
  },
  {
    title: 'STEPS',
    width: '6fr',
  },
  {
    title: 'kcal',
    width: '6fr',
    className: headerClassName,
  },
  {
    title: 'DURATION',
    width: '8fr',
  },
  {
    title: '',
    width: '0',
  },
];

export const ExerciseHistory = () => {
  const { clientId } = useClient();
  const { data, isLoading } = useWidgetHistory(clientId, WidgetId.Exercise);

  if (isLoading) return <LoaderContainer><Loader center /></LoaderContainer>;

  const tableRow = (dayData: WidgetHistoryDayData[]) => (
    <TableRow key={WidgetId.BloodPressue}>
      {dayData.map(({ time, hrMin, hrMax, hrAvg, steps, calories, duration }) => {
        const date = DateTime.fromJSDate(new Date(time));

        return (
          <DayDataRow
            key={time}
            $widths={headerColumns.map(({ width }) => width || '1fr')}
          >
            <div>{date.toFormat('dd/MM/yyyy')}</div>
            <div>{hrMin ?? 'N/A'}</div>
            <div>{hrMax ?? 'N/A'}</div>
            <div>{hrAvg ?? 'N/A'}</div>
            <div>{steps ?? 'N/A'}</div>
            <div>{calories ?? 'N/A'}</div>
            <div>{formatTimeMin({ seconds: duration })}</div>
          </DayDataRow>
        )
      })}
    </TableRow>
  );

  return (
    <ExpandableTable
      headers={headerColumns}
      withHeader={false}
      activeOnExpand
      rows={(data ?? []).map(({ days, steps, calories, duration, colour, weekFrom, weekTo }, weekIndex) => {
        const hasAnyData = days.length > 0;

        return ({
          title: `week-${weekIndex}`,
          showTitle: false,
          titleRowContent: [
            <span className={clsx(css({ gridColumnStart: 1, gridColumnEnd: 5 }))}>
              <DateIntervalLabel from={new Date(weekFrom)} to={new Date(weekTo)} />
            </span>,
            <span className={clsx(css({ gridColumnStart: 5, color: colors.colorDisabledOne }))}>
              {steps}
            </span>,
            <span className={clsx(css({ gridColumnStart: 6, color: colors.colorDisabledOne }))}>
              {calories}
            </span>,
            <span className={clsx(
              { [`text-${getVariantFromColor(colour ?? Color.GREY)}`]: colour },
              css({
                gridColumnStart: 7,
                color: colour ? undefined : colors.colorDisabledOne
              }))}>
              {formatTimeHMin({ seconds: duration })}
            </span>,
          ],
          content: (
            <TableRowContent className={clsx(css({ color: colors.colorGsThree, fontWeight: 500 }))}>
              {hasAnyData ? tableRow(days) : (
                <TableRow>
                  <DayDataRow $widths={['1fr']}>
                    <div>Client has not logged any data for this week</div>
                  </DayDataRow>
                </TableRow>
              )}
            </TableRowContent>
          )
        });
      })}
    />
  );
};
