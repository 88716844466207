import React, { useEffect, useReducer } from 'react';
import clsx from 'clsx';

import { css } from '@emotion/css';
import { DoubleChevron } from '~/components/common/Icons';

import {
  ActionCollapse,
  ActionExpand,
  reducer,
  State,
  ActionReplaceRows,
} from '~/stateManagement/expandable';
import { findRow } from '~/stateManagement/expandable/selectors';

import { Info } from '~/components/common/Icons/dist';
import { Tooltip } from '~/components/common/Tooltip';

import { Layout } from '../Layout';
import {
  ChevronWrapper,
  TableGridSpacing,
  TableHeaderTitle,
  TableRow,
  TableRowHeader,
  TableRows,
} from '../../../ExpandableTable/ExpandableTable.style';
import { Header, Row } from '../../../ExpandableTable/model';

export interface Props {
  headers: Header[];
  rows: Row[];
  emptyState?: JSX.Element[] | JSX.Element;
  footer?: JSX.Element[] | JSX.Element;
}

const updateRows = (rows: Row[], state: State) =>
  rows.map((row) => ({
    ...(findRow(row.title, state) || { id: row.title, expanded: row.active }),
  }));

export const body = ({
  rows,
  emptyState,
  headers,
  state,
  handleCollapseRow,
  handleExpandRow,
  footer,
}) => {
  if (emptyState && (!rows || rows.length === 0)) {
    return emptyState;
  }

  return (
    <>
      <TableGridSpacing $widths={headers.map((header) => header.width || '1fr')}>
        {headers.map((header) => (
          <TableHeaderTitle className={header.className} key={header.title}>
            {header.title}

            {header.tooltip && (
              <Tooltip content={header.tooltip}>
                <Info className="mt-1" />
              </Tooltip>
            )}
          </TableHeaderTitle>
        ))}
      </TableGridSpacing>
      <TableRows>
        {rows.map((row) => {
          const rowState = findRow(row.title, state);
          if (!rowState) return null;
          return (
            <TableRow key={row.title}>
              <TableRowHeader
                $widths={headers.map((header) => header.width || '1fr')}
                className={clsx({
                  active: row.active,
                  expanded: rowState.expanded,
                })}
                onClick={() => {
                  if (!rowState.expanded) {
                    handleExpandRow(rowState.id);
                  }

                  if (rowState.expanded) {
                    handleCollapseRow(rowState.id);
                  }
                }}
              >
                <span className={css({
                  gridColumnStart: 1,
                  gridColumnEnd: 3,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                })}>
                  {row.title}
                </span>
                {React.Children.map(row.titleRowContent, (element) => element)}
                <ChevronWrapper
                  className={clsx({ active: row.active }, css({ gridColumnStart: headers.length }))}
                >
                  <DoubleChevron direction={rowState.expanded ? 'down' : 'right'} />
                </ChevronWrapper>
              </TableRowHeader>
              {rowState.expanded && row.content}
            </TableRow>
          );
        })}
      </TableRows>
      {footer}
    </>
  );
};

const makeInitialState = (rows: Row[]) => ({
  rows: rows.map((row) => ({ expanded: row.expanded || false, id: row.title })),
});

export const ExpandableTable = ({ headers, rows, emptyState, footer }: Props) => {
  const [state, dispatch] = useReducer(reducer, makeInitialState(rows));

  useEffect(() => {
    dispatch(ActionReplaceRows(updateRows(rows, state)));
  }, [rows]);

  const handleExpandRow = (rowTitle) => dispatch(ActionExpand(rowTitle));
  const handleCollapseRow = (rowTitle) => dispatch(ActionCollapse(rowTitle));

  return (
    <Layout>
      {body({ rows, emptyState, headers, state, handleCollapseRow, handleExpandRow, footer })}
    </Layout>
  );
};
