import React from 'react';
import clsx from 'clsx';
import { capitalize } from '@material-ui/core';
import { getVariantFromColor, Label } from '~/components/common/Label';
import {
  Question as QuestionModel,
  QuestionEvaluation,
} from '~/components/pages/client/Sections/Questionnaire/model';
import { Row, Table, Column, StrongColumn } from '~/components/common/Table';
import { headerColumns } from './model';
import { Section, SectionHeading, SectionContent } from '../Topic.style';

const label = (evaluation?: QuestionEvaluation) => {
  if (!evaluation) {
    return (
      <Label light variant="neutral">
        N/A
      </Label>
    );
  }

  return (
    <Label light variant={getVariantFromColor(evaluation.color)}>
      {capitalize(evaluation.value)}
    </Label>
  );
};
interface AssociatedQuestionnaireAnswerProps {
  associatedQuestionnaireAnswer: QuestionModel;
  followUpQuestion?: boolean;
}

const AssociatedQuestionnaireAnswer = ({
  associatedQuestionnaireAnswer,
  followUpQuestion = false,
}: AssociatedQuestionnaireAnswerProps) => {
  if (!associatedQuestionnaireAnswer) {
    return null;
  }

  const questionColumn = (question) => {
    if (followUpQuestion) {
      return <Column>{question}</Column>;
    }

    return <StrongColumn>{question}</StrongColumn>;
  };

  return (
    <>
      <tr className={clsx([{ 'no-border': followUpQuestion }])}>
        {questionColumn(associatedQuestionnaireAnswer.question)}
        <Column>{associatedQuestionnaireAnswer.answers}</Column>
        <Column>{label(associatedQuestionnaireAnswer.evaluation)}</Column>
      </tr>
      {associatedQuestionnaireAnswer.followup_questions.map((question) => (
        <AssociatedQuestionnaireAnswer
          key={associatedQuestionnaireAnswer.id}
          followUpQuestion
          associatedQuestionnaireAnswer={question}
        />
      ))}
    </>
  );
};

const rows = (associatedQuestionnaireAnswers): Row[] =>
  associatedQuestionnaireAnswers.map((item) => ({
    id: item.id,
    expanded: true,
    content: <AssociatedQuestionnaireAnswer key={item.id} associatedQuestionnaireAnswer={item} />,
  }));

export const AssociatedQuestionnaireAnswers = ({ associatedQuestionnaireAnswers }) => {
  if (!associatedQuestionnaireAnswers || associatedQuestionnaireAnswers.length === 0) {
    return null;
  }

  return (
    <Section data-testid="goal-topic-modal-associated-questionnaire-answers">
      <SectionHeading>Associated questionnaire answers</SectionHeading>
      <SectionContent contentType="table">
        <Table rows={rows(associatedQuestionnaireAnswers)} headers={headerColumns} />
      </SectionContent>
    </Section>
  );
};
