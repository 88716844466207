/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/css';
import { Field, FieldArray, Form, Formik, FormikErrors, FormikProps } from 'formik';
import * as Yup from 'yup';

import { Card, CardBody, CardHeader } from '~/components/common/Card';
import {
  FormRow,
  FormRowLabel,
  FormRowInputWrapper,
  FormikInput,
  FormikDropdown,
  FormikPhoto,
  FormikRichText,
  FormikCheckbox,
  FormikDropdownMultiple,
  FormikToggle,
} from '~/components/common/Form';
import { Button } from '~/components/common/Button';
import { colors } from '~/components/utils/colors';

const SubmitButtonWrapper = styled.div`
  text-align: right;
`;

const Separator = styled.div`
  height: 1px;
  margin: 60px 0 30px;
  background: ${colors.colorDisabledTwo};
`;

interface FormFields {
  name?: string;
  attachment?: File;
  email?: string;
  phone?: string;
  bank_details?: string;
  i_dont_know: boolean;
  checkboxes: string[];
  country?: string;
  bio?: string;
  group: {
    field1: string;
    field2: boolean;
  };
  countries: string[];
}

const initialValues: FormFields = {
  name: 'Default name',
  attachment: undefined,
  email: '',
  phone: '',
  bank_details: '',
  i_dont_know: false,
  checkboxes: ['1', '3', '5'],
  country: '',
  bio: 'Test bio',
  group: {
    field1: 'test',
    field2: false,
  },
  countries: ['DE', 'PL'],
};

const initialErrors: FormikErrors<FormFields> = {
  bio: 'Please write something here',
  group: {
    field1: 'input error',
    field2: 'checkbox error',
  },
};

const countries = [
  { title: 'Lithuania', value: 'LT' },
  { title: 'Germany', value: 'DE' },
  { title: 'United Kingdom', value: 'UK' },
  { title: 'Poland', value: 'PL' },
];

export const FormDemo = () => {
  const [formData, setFormData] = useState<Partial<FormFields>>(initialValues);

  return (
    <Card>
      <CardHeader title="Form Elements - Form" />
      <CardBody>
        <p>form data: {JSON.stringify(formData)}</p>
        <Formik
          initialValues={initialValues}
          initialErrors={initialErrors}
          validationSchema={Yup.object({
            name: Yup.string().max(15, 'Must be 15 characters or less').required('Required'),
            // attachment: Yup.mixed().max(15, 'Must be 15 characters or less').required('Required'),
            email: Yup.string().email('Invalid email address').required('Required'),
            country: Yup.string().oneOf(['UK', 'LT']).required('Please select a country'),
            bio: Yup.string().required('Please do your bio'),
          })}
          onSubmit={(values: FormFields) => {
            setFormData(values);
          }}
        >
          {({ setFieldValue }: FormikProps<FormFields>) => (
            <Form>
              <div className={css({ padding: '30px 30px 0 30px' })}>
                <FormRow>
                  <>
                    <FormRowLabel>Picture</FormRowLabel>

                    <FormRowInputWrapper>
                      <FormikPhoto
                        name="profile_image_url"
                        onChange={(event) => {
                          if (event.currentTarget.files && event.currentTarget.files[0]) {
                            setFieldValue('profile_image_url', event.currentTarget.files[0]);
                          }
                        }}
                      />
                      <input type="file" name="file-test" />
                    </FormRowInputWrapper>
                  </>
                </FormRow>
                <FormRow>
                  <>
                    <FormRowLabel>First name</FormRowLabel>
                    <FormRowInputWrapper>
                      <FormikInput id="name" name="name" />
                    </FormRowInputWrapper>
                  </>
                </FormRow>
                <FormRow>
                  <>
                    <FormRowLabel>Email</FormRowLabel>
                    <FormRowInputWrapper>
                      <FormikInput name="email" />
                    </FormRowInputWrapper>
                  </>
                </FormRow>
                <FormRow>
                  <>
                    <FormRowLabel>Phone</FormRowLabel>
                    <FormRowInputWrapper>
                      <FormikInput name="phone" />
                    </FormRowInputWrapper>
                  </>
                  <>
                    <FormRowLabel>Bank Details</FormRowLabel>
                    <FormRowInputWrapper>
                      <FormikInput name="bank_details" />
                    </FormRowInputWrapper>
                  </>
                </FormRow>
                <Separator />
                <h3>Dropdown</h3>
                <FormRow>
                  <>
                    <FormRowLabel>Country</FormRowLabel>
                    <FormRowInputWrapper>
                      <FormikDropdown name="country">
                        <option>Select a country</option>
                        <option value="LT">Lithuania</option>
                        <option value="UK">United Kingdom</option>
                      </FormikDropdown>
                    </FormRowInputWrapper>
                  </>
                </FormRow>
                <FormRow>
                  <>
                    <FormRowLabel>Country</FormRowLabel>
                    <FormRowInputWrapper>
                      <FormikDropdownMultiple name="countries" options={countries} />
                    </FormRowInputWrapper>
                  </>
                </FormRow>
                <Separator />
                <h3>Checkboxes, toggles (single and group)</h3>
                <FormRow>
                  <>
                    <FormRowLabel>I don&apos;t know</FormRowLabel>
                    <FormRowInputWrapper>
                      <FormikCheckbox name="i_dont_know" type="checkbox" />
                    </FormRowInputWrapper>
                  </>
                </FormRow>
                <FormRow>
                  <>
                    <FormRowLabel />
                    <FormRowInputWrapper>
                      <div
                        className={css({
                          display: 'grid',
                          gridTemplateColumns: 'auto auto auto auto',
                        })}
                      >
                        {['1', '2', '3', '4'].map((i) => (
                          <FormikCheckbox
                            label={`checkbox ${i}`}
                            name="checkboxes"
                            value={i}
                            type="checkbox"
                          />
                        ))}
                        {['1', '2', '3', '4'].map((i) => (
                          <FormikToggle
                            label={`toggle ${i}`}
                            name="checkboxes"
                            value={i}
                            type="checkbox"
                          />
                        ))}
                      </div>
                    </FormRowInputWrapper>
                  </>
                </FormRow>
                <Separator />
                <h3>Rich Text editor</h3>
                <FormRow>
                  <>
                    <FormRowLabel>Bio</FormRowLabel>
                    <FormRowInputWrapper>
                      <FormikRichText onChange={(data) => setFieldValue('bio', data)} name="bio" />
                    </FormRowInputWrapper>
                  </>
                </FormRow>
                <Separator />
                <h3>Array fields</h3>
                <FieldArray
                  name="group"
                  render={() => (
                    <FormRow width="half">
                      <>
                        <FormRowLabel htmlFor="group.field1">Field1:</FormRowLabel>
                        <FormRowInputWrapper>
                          <FormikInput name="group.field1" />
                        </FormRowInputWrapper>
                      </>
                      <>
                        <FormRowLabel htmlFor="group.field2">Field 2:</FormRowLabel>
                        <FormRowInputWrapper>
                          <FormikCheckbox name="group.field2" />
                          <Field name="group" type="checkbox" value="field2" />
                        </FormRowInputWrapper>
                      </>
                    </FormRow>
                  )}
                />
                <Separator />
                <SubmitButtonWrapper>
                  <Button variant="success" type="submit">
                    Save
                  </Button>
                </SubmitButtonWrapper>
              </div>
            </Form>
          )}
        </Formik>
      </CardBody>
    </Card>
  );
};
