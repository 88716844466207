import { generatePath } from 'react-router-dom';
import { Week } from '~/components/pages/client/Sections/FoodLog/model';
import { api } from '~/services/api';

const URL = {
  LIST: '/api/client/:clientId/food-log',
};

export interface FoodLogResponse {
  startDate?: string;
  weeks: Week[];
}

export const model = {
  getList: (clientId) => api.get<FoodLogResponse>(generatePath(URL.LIST, { clientId })),
};
