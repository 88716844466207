import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useHtmlClassService } from '~/vendor/_metronic/layout';
import SVG from "react-inlinesvg";
import { ROUTES } from '~/components/router';
import { TestableComponentProps } from '~/model/TestableComponentProps.interface';
import { getPartnerColor } from '~/components/utils/helpers/partner';
import { homeUrl } from '~/components/pages/error/config';
import { PartnerAuthWrapper, Description, IntroContent, IntroWrapper, LogoLink, OutletWrapper, PartnerContent, Title } from './AuthLayout.style';
import { getToken } from '../../TokenProvider/utils';
import { config } from './config';

export const AuthLayout = ({ testId }: TestableComponentProps) => {
  const uiService = useHtmlClassService();
  const token = getToken();
  const partnerColor = getPartnerColor();

  if (token) {
    return <Navigate to={ROUTES.DEFAULT} replace />;
  }

  return (
    <PartnerAuthWrapper data-testid={testId} partnerColor={partnerColor}>
      <PartnerContent partnerColor={partnerColor}>
        <IntroWrapper>
          <LogoLink href={homeUrl} target="_blank">
            <SVG src={uiService.getLogo()} className="logo" />
          </LogoLink>
          <IntroContent>
            <Title>{config.title}</Title>
            <Description>{config.description}</Description>
          </IntroContent>
          <SVG src={config.loginVisual} />
        </IntroWrapper>
        <div>
          <OutletWrapper>
            <Outlet />
          </OutletWrapper>
        </div>
      </PartnerContent>
    </PartnerAuthWrapper>
  );
}