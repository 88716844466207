import { Action } from './actions';

export interface BasketItem {
  id: number;
  quantity: number;
}

export interface BasketState {
  items: BasketItem[];
}

export const INITIAL_STATE: BasketState = { items: [] };

export const addIfMissing = (items, id) => {
  const found = items.find((item) => item.id === id);
  if (!found) {
    items.push({ id, quantity: 0 });
  }

  return [...items];
};

export const incrementQuantity = (state: BasketState, itemId: number): BasketState => ({
  ...state,
  items: addIfMissing(state.items, itemId).map((item) => ({
    ...item,
    quantity: item.id === itemId ? item.quantity + 1 : item.quantity,
  })),
});

export const decrementQuantity = (state: BasketState, itemId: number): BasketState => ({
  ...state,
  items: addIfMissing(state.items, itemId).map((item) => ({
    ...item,
    quantity: item.id === itemId && item.quantity > 0 ? item.quantity - 1 : item.quantity,
  })),
});

export const reducer = (state, action: Action): BasketState => {
  switch (action.type) {
    case 'INCREASE':
      return incrementQuantity(state, action.payload!.id);
    case 'DECREASE':
      return decrementQuantity(state, action.payload!.id);
    case 'RESET':
      return INITIAL_STATE;
    default:
      throw Error(`Unknown action: ${action}`);
  }
};
