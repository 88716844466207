import { useQuery, useMutation, UseMutationOptions } from 'react-query';
import { GetNotesListResponse, model as noteModel } from '~/model/Notes/notes';

export const notesQueryKey = 'notes';

export const useNotes = (coachId, currentPage, notesPerPage, term, filters) =>
  useQuery<GetNotesListResponse>([notesQueryKey, currentPage, notesPerPage, term, filters], () =>
    noteModel(coachId).getList(currentPage, notesPerPage, term, filters),
  );

export const useDeleteCoachNote = <T>(
  coachId: string,
  noteId: number,
  mutationOptions?: UseMutationOptions<any, unknown, T>,
) =>
  useMutation(() => noteModel(coachId).delete(noteId), {
    ...mutationOptions,
  });
