import React, { useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Card } from '~/components/common/Card';
import { EmptyState } from '~/components/common/EmptyState';
import { Dropdown, FormRow, FormRowInputWrapper, FormRowLabel } from '~/components/common/Form';
import { ROUTES } from '~/components/router';
import { useClientsChoices } from '~/model/Client';
import { FormRowContainer } from './ClientSelectCard.style';

export type Props = {
  onClientSelected: (clientId: number) => void;
  clientId?: number;
}

export const ClientSelectCard = ({ onClientSelected, clientId }: Props) => {
  const { isLoading, isError, data: clients } = useClientsChoices();

  useEffect(() => {
    if (clients && clients.length > 0 && !clientId) {
      onClientSelected(clients[0].id);
    }
  }, [clients, clientId]);

  const renderCardContent = useMemo(() => {
    if (isLoading) {
      return <EmptyState title="Loading..." />
    }

    if (!clients?.length) {
      return (
        <EmptyState title="There currently have no clients!">
          When you <Link to={ROUTES.CLIENTS}>invite new</Link> clients you will be able to offer them products and services from this tab.
        </EmptyState>
      );
    }

    if (isError || !clients) {
      return (
        <EmptyState
          title="Something went wrong!"
          description="Please try again later."
        />
      );
    }

    return (
      <FormRowContainer>
        <FormRow>
          <>
            <FormRowLabel>Select your client</FormRowLabel>
            <FormRowInputWrapper>
              <Dropdown
                name="client"
                value={clientId}
              >
                {isLoading && <option disabled>Loading...</option>}
                {clients.map((client) => (
                  <option
                    key={client.id}
                    value={client.id}
                    onClick={() => onClientSelected(client.id)}
                  >
                    {client.fullName}, {client.email}
                  </option>
                ))}
              </Dropdown>
            </FormRowInputWrapper>
          </>
        </FormRow>
      </FormRowContainer>
    );
  }, [clients, clientId, isLoading, isError]);

  return (
    <Card className="py-3">
      {renderCardContent}
    </Card>
  );
}
