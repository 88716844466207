import * as React from 'react';
import { SVGProps } from 'react';
import { Size } from '../types';
interface Props extends SVGProps<SVGSVGElement> {
  size?: Size;
}
const SvgArrowLeft = (props: Props) => {
  const getSize = () => {
    if (props.size) {
      switch (props.size) {
        case 'small':
          return 20;

        case 'medium':
          return 24;

        case 'large':
          return 32;

        default:
          return null;
      }
    }
  };

  return (
    <svg
      width={getSize() || 26}
      height={getSize() || 26}
      viewBox="0 0 26 26"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M15.184 9.338a.667.667 0 00-.943-.943l-4 4a.667.667 0 00-.02.922l4.021 4.011a.667.667 0 10.983-.9l-3.59-3.541 3.549-3.549z" />
    </svg>
  );
};

export default SvgArrowLeft;
