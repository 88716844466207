import { useMutation, UseMutationOptions } from 'react-query';
import { MealStatusChangeMutationContext, model } from '../meal';

export const useUnskipMeal = <T>(
  clientId: string,
  mealId: number,
  mutationOptions?: UseMutationOptions<any, unknown, T, MealStatusChangeMutationContext>,
) =>
  useMutation(() => model.unskip(clientId, mealId), {
    ...mutationOptions,
  });
