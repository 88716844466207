import React, { useEffect, useState } from 'react';
import { useClientDevices } from '~/model/Client/Devices/useClientDevices';
import { Loader } from '~/components/common/Loader';
import { useClient } from '~/components/pages/client/context';
import { Card, CardBody } from '~/components/common/Card';
import { useCoachContext } from '~/components/pages/profile';
import { Partner, getPartner } from '~/components/utils/helpers/partner';
import { Devices as Component } from './Devices';
import { VerificationWarningModal } from '../TestKits/VerificationWarningModal';

const Error = () => <div>Could not retrieve data</div>;

export const Devices = () => {
  const [showWarningModal, setShowWarningModal] = useState(false);

  const { clientId } = useClient();
  const { coach } = useCoachContext();
  const partner = getPartner();
  const isHei = partner === Partner.HEI;

  const { isError, isLoading, data } = useClientDevices(clientId);

  useEffect(() => {
    if (localStorage.getItem('hasVisitedDevicesTab') !== 'true') {
      setShowWarningModal(true);
      localStorage.setItem('hasVisitedDevicesTab', 'true');
    }
  }, []);

  if (isLoading) {
    return <Loader size="medium" center />;
  }

  if (isError || !data) {
    return <Error />;
  }

  return (
    <Card>
      <CardBody>
        <Component devicesCategories={data} />
        {coach.onboarding_status !== 'COMPLETED' && showWarningModal && !isHei && (
          <VerificationWarningModal onClose={() => setShowWarningModal(false)} />
        )}
      </CardBody>
    </Card>
  );
};
