import React, { useState } from 'react';
import { ApiFile } from '~/model/File/apiFile';
import { TestableComponentProps } from '~/model/TestableComponentProps.interface';
import clsx from 'clsx';
import { FeaturedImageWrapper, ImageWrapper, ImagesGrid } from './Gallery.style';

export interface Props extends TestableComponentProps {
  images: ApiFile[];
}

export const Gallery = ({ images, testId }: Props) => {
  const [featuredImage, setFeaturedImage] = useState<ApiFile>(images[0]);

  const onClick = (image: ApiFile) => setFeaturedImage(image);

  return (
    <div data-testid={testId} className="d-flex justify-content-between gap-2">
      <FeaturedImageWrapper>
        <img
          src={featuredImage.url}
          alt={featuredImage.name}
          data-testid={`${testId}-featured-image`}
        />
      </FeaturedImageWrapper>

      <ImagesGrid>
        {images.map((image, index) => (
          <ImageWrapper
            key={image.url}
            onClick={() => onClick(image)}
            className={clsx({ featured: image.url === featuredImage.url })}
          >
            <img
              src={image.url}
              alt={image.name}
              data-testid={`${testId}-image-${index}`}
            />
          </ImageWrapper>
        ))}
      </ImagesGrid>
    </div>
  );
}
