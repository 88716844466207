import styled from '@emotion/styled';
import { colors } from '~/components/utils/colors';

export const OnboardingHeader = styled.h1`
  font-size: 18px;
  color: ${colors.colorPriOne};
  font-weight: 600;
  line-height: 27px;

  margin-top: 35px;
  margin-bottom: 30px;
`;

export const OnboardingText = styled.p`
  font-size: 16px;
  color: ${colors.colorGsThree};
  font-weight: 500;
  line-height: 24px;

  margin-bottom: 30px;
`;

export const LabelText = styled.p`
  font-size: 16px;
  color: ${colors.colorPriOne};
  font-weight: 500;
  line-height: 24px;
  margin-top: 60px;
`;

export const Separator = styled.div`
  height: 0;
  margin: 0 0 30px;
  padding-top: 30px;
  border-bottom: 2px solid ${colors.colorDisabledTwo};
`;

export const ButtonWrapper = styled.div`
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  padding: 0 30px;
`;
