import React, { useLayoutEffect } from 'react';
import { useSubheader } from '~/vendor/_metronic/layout';

import { Layout } from '~/components/common/Layout';

import { Summary } from './Summary';
import { Route as Details } from './Details';

export const CoachProfile = () => {
  const subheader = useSubheader();
  useLayoutEffect(() => {
    subheader.setTitle('Profile');
  });

  return (
    <Layout>
      <Summary />
      <Details />
    </Layout>
  );
};
