import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { DateTime } from 'luxon';
import { EmptyState } from '~/components/common/EmptyState';
import { Button } from '~/components/common/Button';
import { Plus1 } from '~/components/common/Icons/dist';
import { Row, Table } from '~/components/common/Table';
import { useTableContext } from '~/components/common/Table/TableProvider';
import { allExpanded, findRow, State } from '~/stateManagement/expandable';
import { DoubleChevron } from '~/components/common/Icons';
import { CardBody, CardHeader } from '~/components/common/Card';
import { GetNotesListResponse, Note as model, NoteFormFilter } from '~/model/Notes';
import { usePaginationContext } from '~/components/common/Pagination/PaginationProvider';
import { Pagination } from '~/components/common/Pagination';
import { Note } from './Note';
import { headerColumns } from './model';
import { AddModal } from './Modal';
import { SearchAndFilters } from './SearchAndFilters';
import { LayoutHeaderButtons } from './Layout/Layout.style';

interface FormFields {
  term: string;
  filter: NoteFormFilter;
}

export interface Props {
  data: GetNotesListResponse;
  initialValues: FormFields;
  term: string;
  filter: NoteFormFilter;
  onTermChange: Dispatch<SetStateAction<string>>;
  onFilterChange: Dispatch<SetStateAction<NoteFormFilter>>;
}

const updateNotes = (notes: model[], state: State) =>
  notes.map((note) => ({
    ...(findRow(note.id, state) || { id: note.id, expanded: false }),
  }));

const rows = (notes, state): Row[] =>
  notes
    .map((item, index) => {
      const stateRow = findRow(item.id, state!);
      if (!stateRow) {
        return null;
      }

      return {
        content: <Note note={item} index={index + 1} />,
        expanded: false,
        ...stateRow,
      };
    })
    .filter((item) => !!item);

export const Notes = ({
  data,
  initialValues,
  term,
  filter,
  onTermChange,
  onFilterChange,
}: Props) => {
  const { state, expandAll, collapseAll, replaceRows } = useTableContext();
  const [showModal, setShowModal] = useState(false);
  const { items: notes, updatedAt, paginationData: notesPaginationData } = data;
  const { currentPage, changeCurrentPage, changeItemsPerPage } = usePaginationContext();

  useEffect(() => {
    replaceRows(updateNotes(notes, state!));
    if (currentPage > notesPaginationData.last && currentPage > 1) {
      changeCurrentPage(notesPaginationData.last);
    }
  }, [notes]);

  const hasNotes = useMemo(() => notes && notes.length > 0, [notes, notes.length]);
  const filteredOrSearched = useMemo(() => term !== '' || filter.client !== '', [
    term,
    filter.client,
  ]);

  const subtitle = () => {
    if (hasNotes) {
      return `Updated ${DateTime.fromISO(updatedAt).toFormat('dd/MM/yyyy')}`;
    }
    return null;
  };

  const handleTermChange = (changedTerm: string) => {
    changeCurrentPage(1);
    onTermChange(changedTerm);
  };

  const handleFilterChange = (changedFilters) => {
    changeCurrentPage(1);
    onFilterChange(changedFilters);
  };

  return (
    <>
      <CardHeader
        headerClassName="border-0 py-10"
        className="flex-column align-items-start"
        title={
          <div>
            <h3 className="card-label">Notes</h3>
            <div className="text-muted mt-2 font-size-lg font-weight-normal">{subtitle()}</div>
          </div>
        }
        toolbar={
          <LayoutHeaderButtons>
            {hasNotes && !allExpanded(state!) && (
              <Button
                iconPosition="right"
                shade="light"
                size="large"
                aria-label="Expand all notes"
                icon={<DoubleChevron direction="down" />}
                onClick={expandAll}
              >
                Expand all
              </Button>
            )}
            {hasNotes && allExpanded(state!) && (
              <Button
                iconPosition="right"
                shade="light"
                size="large"
                aria-label="Collapse all notes"
                icon={<DoubleChevron direction="up" />}
                onClick={collapseAll}
              >
                Collapse all
              </Button>
            )}
            <Button
              icon={<Plus1 />}
              iconPosition="right"
              size="large"
              aria-label="Add new note"
              onClick={() => setShowModal(true)}
            >
              Add new
            </Button>
          </LayoutHeaderButtons>
        }
      />
      <SearchAndFilters
        initialValues={initialValues}
        term={term}
        filter={filter}
        isLoading={false}
        onTermChange={handleTermChange}
        onFilterChange={handleFilterChange}
      />
      <CardBody>
        {filteredOrSearched && !hasNotes && (
          <EmptyState title="No notes found" description="Try searching for something else..." />
        )}
        {!hasNotes && !filteredOrSearched && (
          <EmptyState
            title="You have no notes!"
            description="All of your notes will be visible here"
          />
        )}
        {hasNotes && notesPaginationData && (
          <>
            <Table rows={rows(notes, state)} headers={headerColumns} />
            <Pagination
              paginationData={notesPaginationData}
              onPageChange={changeCurrentPage}
              onItemsPerPageChange={changeItemsPerPage}
            />
          </>
        )}
        {showModal && <AddModal onClose={() => setShowModal(false)} />}
      </CardBody>
    </>
  );
};
