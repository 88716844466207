const ROUTES = {
  BASENAME: '/portal',
  /* Route that we redirect to on unknown route */
  DEFAULT: '/clients',

  /* Chat paths */
  CHAT_HOME: '/chat',
  CHAT_WITH_USER: '/chat/:id',

  /* Profile */
  PROFILE_TABBED: '/profile/:tab',
  PROFILE: '/profile',

  /* Client list */
  CLIENTS: '/clients',

  /* Client profile */
  CLIENT_TABBED: '/client/:id/:tab',
  CLIENT: '/client/:id',

  /* Notes */
  NOTES: '/notes',

  /* Marketplace */
  MARKETPLACE: '/marketplace',
  MARKETPLACE_TABBED: '/marketplace/:tab',

  /* Errors */
  ERROR404: '/error/404',

  /* Auth */
  LOGIN: '/sign-in',
  REGISTER_STEPPED: '/sign-up/:step',
  REGISTER: '/sign-up',
  PASSWORD_RESET: '/password-reset',
  PASSWORD_RESET_CONFIRM: '/password-reset/:token',
};

export { ROUTES };
