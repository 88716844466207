import * as Yup from 'yup';

const ERROR_REQUIRED = 'This field is required';
const ERROR_MATCH = 'Passwords must match';
export const PASSWORD_STRENGTH_REQUIREMENTS = 'The password must contain at least 8 symbols, one capital letter, one lower case letter, one number and one special character.';

export interface FormFields {
  password: string;
  password_confirmation: string;
  generic?: string;
}

export const initialValues: FormFields = {
  password: '',
  password_confirmation: '',
}

export const validationSchema = Yup.object({
  password: Yup.string()
    .required(ERROR_REQUIRED)
    .min(8, 'The password must contain at least 8 symbols')
    .max(255, 'The password must contain at most 255 symbols')
    .matches(/^(?=.*[A-Z])/, 'The password must contain at least one capital letter')
    .matches(/^(?=.*[a-z])/, 'The password must contain at least one lower case letter')
    .matches(/^(?=.*\d)/, 'The password must contain at least one number')
    .matches(/^(?=.*[!@#$%^&*,.?(){}[\]])/, 'The password must contain at least one special character'),
  password_confirmation: Yup.string().required(ERROR_REQUIRED).oneOf([Yup.ref('password'), null], ERROR_MATCH),
});
