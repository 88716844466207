import * as React from 'react';
import { SVGProps } from 'react';
import { Size } from '../types';
interface Props extends SVGProps<SVGSVGElement> {
  size?: Size;
}
const SvgAtmCard = (props: Props) => {
  const getSize = () => {
    if (props.size) {
      switch (props.size) {
        case 'small':
          return 20;

        case 'medium':
          return 24;

        case 'large':
          return 32;

        default:
          return null;
      }
    }
  };

  return (
    <svg
      width={getSize() || 24}
      height={getSize() || 24}
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      {...props}
    >
      <rect opacity={0.3} x={2.806} y={4} width={20} height={5} rx={1} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.806 7v12a2 2 0 002 2h1V7h-3zm6 0v14h6a2 2 0 002-2V7h-8z"
      />
    </svg>
  );
};

export default SvgAtmCard;
