import React from 'react';
import { DateTime } from 'luxon';
import { Card, CardBody } from '~/components/common/Card';
import { Button } from '~/components/common/Button';
import { TitledLabel } from '~/components/common/Label';
import { useCoachContext } from '~/components/pages/profile/CoachProvider';

import { ProfileImage } from '~/components/common/ProfileImage';
import { useFeatureToggle } from '~/services/feature-toggle';
import { InfoIcons } from './InfoIcons';
import { BioWrapper } from './Summary.style';

const fullName = (first_name: string, last_name: string) => (
  <span className="text-priOne font-size-h5 font-weight-bold mr-3">
    {`${first_name} ${last_name}`}
  </span>
);

const payoutButton = () => <Button disabled>Payout</Button>;

const contactDetails = (email: string, phone_number: string, city: string) => (
  <>
    <span className="text-disabledOne font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2">
      <i className="far fa-envelope mr-2 font-size-lg" />
      {email}
    </span>
    <span className="text-disabledOne font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2">
      <i className="fas fa-phone-alt mr-2 font-size-lg" />
      {phone_number}
    </span>
    <span className="text-disabledOne font-weight-bold">
      <i className="far fa-dot-circle mr-2 font-size-lg" />
      {city}
    </span>
  </>
);

const loginBadges = (last_login_at: string, signed_up_at: string) => (
  <>
    <TitledLabel variant="success" title="Last login" light className="mr-2">
      {DateTime.fromISO(last_login_at).toFormat('dd/MM/yyyy')}
    </TitledLabel>
    <TitledLabel variant="primary" light title="Signed up">
      {DateTime.fromISO(signed_up_at).toFormat('dd/MM/yyyy')}
    </TitledLabel>
  </>
);

const bio = (coachBio?: string) => {
  if (!coachBio) {
    return 'No bio available';
  }

  if (coachBio.length > 400) {
    return `${coachBio?.substring(0, 400)}...`;
  }

  return coachBio;
};

export const Summary = () => {
  const { coach } = useCoachContext();
  const { isFeatureEnabled: isShowPayoutsFeatureEnabled } = useFeatureToggle('OMH-1316-show-payout');

  const {
    first_name,
    last_name,
    phone_number,
    email,
    city,
    signed_up_at,
    last_login_at,
    profile_image_url,
    empty_profile_image_url,
  } = coach;

  return (
    <Card>
      <CardBody>
        <div className="d-flex mb-9">
          <ProfileImage imageUrl={profile_image_url || empty_profile_image_url} />

          <div className="flex-grow-1">
            <div className="d-flex justify-content-between flex-wrap mt-1">
              <div className="d-flex mr-3">{fullName(first_name, last_name)}</div>
              {isShowPayoutsFeatureEnabled() && <div className="my-lg-0 my-3">{payoutButton()}</div>}
            </div>

            <div className="mt-1">
              <div className="row w-100 m-0">
                <div className="col-12 col-md-9 pl-md-0">
                  <div className="d-flex flex-column flex-grow-1 pr-8">
                    <div className="d-flex flex-wrap mb-4">
                      {contactDetails(email, phone_number, city)}
                    </div>
                    <span className="text-gsThree">
                      <BioWrapper>
                        {bio(coach.bio)}
                      </BioWrapper>
                    </span>
                  </div>
                </div>
                <div className="col-12 col-md-3 pr-md-0 mt-4">
                  <div className="d-flex align-items-center justify-content-md-end h-100">
                    {loginBadges(last_login_at, signed_up_at)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="separator separator-solid" />
        <InfoIcons coach={coach} />
      </CardBody>
    </Card>
  );
};
