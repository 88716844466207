import React from 'react';
import { OverlayTrigger } from 'react-bootstrap';
import { TestableComponentProps } from '~/model/TestableComponentProps.interface';
import { TooltipWrapper, StyledTooltip, TooltipSize } from './Tooltip.style';

export interface Props extends TestableComponentProps {
  content: string | JSX.Element;
  placement?: 'top' | 'right' | 'bottom' | 'left';
  children: JSX.Element;
  size?: TooltipSize;
}

export const Tooltip = ({ content, placement, testId, children, size = 'default' }: Props) => (
  <OverlayTrigger
    placement={placement}
    overlay={
      <StyledTooltip id="tooltip" data-testid={testId} size={size}>
        <TooltipWrapper>{content}</TooltipWrapper>
      </StyledTooltip>
    }
  >
    {children}
  </OverlayTrigger>
);
