import React from 'react';
import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { ConfirmationModal } from '~/components/common/ConfirmationModal';
import { useClient } from '~/components/pages/client/context';
import {
  testReportsQueryKey,
  useDeleteClientTestReport,
} from '~/model/Client/TestResults/TestReports';

export interface Props {
  id: number;
  reportName: string;
  onClose: () => void;
}

export const DeleteConfirmationModal = ({ id, reportName, onClose }: Props) => {
  const { clientId } = useClient();
  const queryClient = useQueryClient();
  const deleteReportMutation = useDeleteClientTestReport(clientId, id);
  const { isLoading: isDeleteLoading } = deleteReportMutation;

  return (
    <ConfirmationModal
      testId="delete-report-modal"
      iconVariant="danger"
      title="Are you sure?"
      closeButtonText="No, cancel"
      submitButtonText="Yes, delete!"
      submitButtonVariant="danger"
      description={
        <p className="text-center">
          This will permanently delete the report <strong>{reportName}</strong> from your client’s
          test results.
        </p>
      }
      isLoading={isDeleteLoading}
      onClose={onClose}
      onSubmit={() => {
        deleteReportMutation.mutate(
          {},
          {
            onSuccess: async () => {
              await queryClient.invalidateQueries([testReportsQueryKey(clientId)]);
              onClose();
            },
            onError: () => {
              toast.error('Something went wrong... Please try again.');
            },
          },
        );
      }}
    />
  );
};
