import { useMutation, useQuery } from 'react-query';

import { model as coachProfileModel, CoachProfile } from './coach';
import { model as userModel } from './user';

export const coachProfileQueryKey = (id?: string) => `coachProfile${id ? `--${id}` : ''}`;

export const useUpdateUserProfilePicture = () =>
  useMutation((attachment: File) => userModel.setProfileImage(attachment));

export const useCoachProfile = (id: string) =>
  useQuery<CoachProfile>(coachProfileQueryKey(id), () => coachProfileModel.getCoachProfile(id), {
    enabled: !!id,
  });

export const useCoach = () =>
  useQuery<CoachProfile>(coachProfileQueryKey(), () => coachProfileModel.getProfile());
