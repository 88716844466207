import React from 'react';
import { Loader, Error as ErrorModal } from '~/components/common/Modal';
import { InviteClientResponse, model, useInviteClient } from '~/model/InviteClient';
import { FormFields, toApiRequest } from './config';
import { InviteClientModal as Component, Props as ComponentProps } from './InviteClientModal';

export type Props = Omit<ComponentProps, 'onSubmit' | 'initialValues'>;

export const InviteClientModal = ({ onClose, ...otherProps }: Props) => {
  const { isLoading, isError, data } = useInviteClient();

  const props: Props = {
    onClose,
    withHeaderSeparator: true,
    width: '772px',
    hasCloseButton: false,
    closeOnOuterClick: false,
    ...otherProps,
  };

  if (isLoading) {
    return <Loader {...props} />;
  }

  if (isError || !data) {
    return <ErrorModal {...props} hasCloseButton closeOnOuterClick />;
  }

  const initialValues = ({ from, to, subject, content }: InviteClientResponse): FormFields => ({
    from,
    to: to ?? '',
    subject,
    content,
  });

  return (
    <>
      <Component
        {...props}
        initialValues={initialValues(data)}
        onSubmit={async (values: FormFields) => {
          const request = toApiRequest(values);
          await model.send(request);
        }}
      />
    </>
  );
};
