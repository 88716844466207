import * as React from 'react';
import { css } from '@emotion/css';
import clsx from 'clsx';
import { style } from './Loader.style';
import { getAlignmentClassName, getWidthClassName, Props } from './LoaderTypes';

export const HeiLoader = ({
  size = 'medium',
  testId,
  wrapperHeight = 'auto',
  fullScreen = false,
  center,
  ...otherProps
}: Props) => (
  <div
    className={clsx(style.wrapper, css({ height: wrapperHeight }), {
      [style.fullScreen]: fullScreen,
    })}
  >
    <svg
      data-testid={testId}
      id="ed4C2zWBfFN1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 57 57"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      className={clsx(
        css({
          shapeRendering: 'auto',
          display: 'block',
        }),
        getWidthClassName(size),
        getAlignmentClassName(center),
      )}
      preserveAspectRatio="xMidYMid"
      {...otherProps}
    >
      <style>
        {
          '@keyframes ed4C2zWBfFN2_c_o{0%,33.333333%,66.666667%,to{opacity:1}16.666667%,50%,83.333333%{opacity:.1}}'
        }
      </style>
      <g
        id="a256fcd9-177b-4edb-a166-4a5c6ddd427f"
        data-name="Layer 2"
        style={{
          animation: 'ed4C2zWBfFN2_c_o 3000ms linear infinite normal forwards',
        }}
      >
        <g id="b46aa2bd-69ba-489c-910d-28ab7d2773be" data-name="Layer 1">
          <path d="M47.3769 57H9.62308C4.30846 57 0 52.6915 0 47.3769V9.62308C0 4.30846 4.30846 0 9.62308 0H47.3769C52.6915 0 57 4.30846 57 9.62308V47.3769C57 52.6915 52.6915 57 47.3769 57Z" fill="#D54A2E" />
          <path d="M23.6469 30.6969C23.6469 26.0192 20.5707 22.2369 16.1492 22.2369C14.6746 22.2369 12.7523 22.6869 11.8869 23.7438V14.9008H7.33844V38.7715H11.8892V30.6969C11.8892 28.4215 13.7469 26.7877 15.7661 26.7877C17.6238 26.7877 19.1307 28.1008 19.1307 30.6969V38.7715H23.6492V30.6969H23.6469Z" fill="white" />
          <path d="M38.2246 32.9723C37.4862 34.1262 36.1108 34.6062 34.7308 34.6062C34.2508 34.6062 33.8031 34.5739 33.3854 34.4146L42.42 27.9416C41.4254 24.93 38.3515 22.2369 34.3454 22.2369C29.7 22.2369 25.95 26.0169 25.95 30.6969C25.95 35.31 29.8592 39.1246 34.7285 39.1246C37.6131 39.1246 40.4654 37.8116 41.8408 35.76L38.2246 32.9723ZM30.5677 30.6969C30.5677 28.4216 32.2662 26.7877 34.3477 26.7877C35.2131 26.7877 35.9492 27.0762 36.5908 27.5562L30.6946 31.7539C30.6 31.4354 30.5677 31.0823 30.5677 30.6969Z" fill="white" />
          <path d="M46.9408 19.74C48.4777 19.74 49.6639 18.5538 49.6639 17.1438C49.6639 15.8307 48.4777 14.6446 46.9408 14.6446C45.5308 14.6446 44.3123 15.8631 44.3123 17.1438C44.3123 18.5538 45.5308 19.74 46.9408 19.74ZM49.2462 22.5923H44.6977V38.7738H49.2462V22.5923Z" fill="white" />
        </g>
      </g>
    </svg>
  </div>
);
