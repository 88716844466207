import React, { useEffect, useMemo, useState } from 'react';
import {
  ConfirmationModal,
  IconVariant,
  ButtonVariant,
} from '~/components/common/ConfirmationModal';
import { Loader } from '~/components/common/Loader';
import { useClient } from '~/components/pages/client/context';
import { TestKitOrderRequest, TestKitOrderResponse } from '~/model/Client/TestKits/TestKitOrder/testKitOrder';
import { usePreviewOrder } from '~/model/Client/TestKits/TestKitOrder/hooks';
import { BasketState } from '~/stateManagement/basket';
import { useConfirmOrder } from '~/model/Client/TestKits/TestKitOrder/hooks/useConfirmOrder';
import { generatePath, Link } from 'react-router-dom';
import { ROUTES } from '~/components/router/Routes';
import { useCoachContext } from '~/components/pages/profile';
import { OnboardingStatus } from '~/model/Profile/coach';
import { Mark } from '~/components/common/Mark';
import { getPartner, Partner } from '~/components/utils/helpers/partner';
import { List, ListItem, ListItemText, WarningTextLine } from '~/components/common/ConfirmationModal/ConfirmationModal.style';

export interface Props {
  basket: BasketState;
  onClose: () => void;
  onSuccess: () => void;
  onError: (error: any) => void;
}

export const toApiRequest = (basket: BasketState) => ({
  items: basket.items
    .map((item) => ({
      testKit: item.id,
      quantity: item.quantity,
    }))
    .filter((item) => item.quantity > 0),
});

const Error = () => <div>Could not retrieve data</div>;

const description = (
  customerName: string,
  previewOrder: TestKitOrderResponse,
  onboarding_status: OnboardingStatus,
  basketIndex: number,
  basketsCount: number,
) => {
  const partner = getPartner();
  const isHei = partner === Partner.HEI;

  return (
    <>
      {basketsCount > 1 && (
        <ListItem data-testid="basket-index" $marginBottom="20px">
          <ListItemText $width="auto">
            <strong>Basket {basketIndex + 1} of {basketsCount}</strong>
          </ListItemText>
        </ListItem>
      )}
      <p>This will recommend {customerName} to purchase the following test kits:</p>
      <div>
        <List>
          {previewOrder?.products.map((product) => (
            <ListItem key={product.id}>
              {product.name.split(', ').map((part, index) => (
                <ListItemText key={part} $width={index === 0 ? '50%' : '21%'}>
                  {part}
                </ListItemText>
              ))}
            </ListItem>
          ))}
          <ListItem data-testid="shipping-price" $marginTop="20px">
            <ListItemText $width="auto">Express shipping </ListItemText>
            <ListItemText>{previewOrder?.formatted_delivery_price}</ListItemText>
          </ListItem>
          <ListItem data-testid="vat">
            <ListItemText $width="auto">VAT/GST </ListItemText>
            <ListItemText>{previewOrder?.formatted_vat}</ListItemText>
          </ListItem>
          <ListItem data-testid="total-price" $marginTop="20px" $marginBottom="5px">
            <ListItemText $width="100%">
              <strong>Total price: {previewOrder?.formatted_total_price}</strong>
            </ListItemText>
          </ListItem>
          <ListItem data-testid="coach-commissions">
            <ListItemText $width="100%">
              <Mark color="green">
                <strong>Your commission: {previewOrder?.formatted_coach_commissions}</strong>
              </Mark>
            </ListItemText>
          </ListItem>
          {onboarding_status !== 'COMPLETED' && !isHei && (
            <ListItem $marginBottom="0px">
              <WarningTextLine $width="100%">
                Set up{' '}
                <Link to={generatePath(ROUTES.PROFILE_TABBED, { tab: 'payout' })}>Payout details</Link> to
                earn this commission!
              </WarningTextLine>
            </ListItem>
          )}
        </List>
      </div>
    </>
  );
}

export const TestKitConfirmationModal = ({ basket, onSuccess, onClose, onError }: Props) => {
  const { clientId, client } = useClient();
  const { coach } = useCoachContext();
  const {
    isLoading: isPreviewOrderLoading,
    isError: isPreviewOrderError,
    data: previewOrders,
    mutate: mutatePreviewOrder,
  } = usePreviewOrder<TestKitOrderRequest>(clientId);
  const confirmOrderMutation = useConfirmOrder<TestKitOrderRequest>(clientId);
  const [basketIndex, setbasketIndex] = useState(0);

  useEffect(() => {
    mutatePreviewOrder(toApiRequest(basket), {
      onError,
    });
  }, []);

  const { isLoading: isConfirmOrderLoading, isError: isConfirmOrderError } = confirmOrderMutation;

  const onSuccessModal = () => {
    // if basketIndex < previewOrders.length - 1, then we need to show the next modal,
    // otherwise we can call onSuccess
    const basketsCount = previewOrders?.length || 1;

    if (basketIndex < basketsCount - 1) {
      setbasketIndex(basketIndex + 1);
    } else {
      onSuccess();
    }
  };

  const basketItems: BasketState = useMemo(() => ({
    items: previewOrders?.[basketIndex].products.map((product) => ({
      id: product.id,
      quantity: product.quantity
    })) ?? [],
  }), [previewOrders, basketIndex]);

  const defaultProps = {
    iconVariant: 'primary' as IconVariant,
    title: 'Are you sure?',
    closeButtonText: 'No, cancel',
    submitButtonText: 'Yes, send!',
    submitButtonVariant: 'primary' as ButtonVariant,
    isLoading: isPreviewOrderLoading || isConfirmOrderLoading,
    onClose,
    onSubmit: () => {
      confirmOrderMutation.mutate(toApiRequest(basketItems), {
        onSuccess: onSuccessModal,
        onError,
      });
    },
  };

  if (isPreviewOrderLoading || isConfirmOrderLoading) {
    return <ConfirmationModal {...defaultProps} description={<Loader size="small" center />} />;
  }

  if (isPreviewOrderError || isConfirmOrderError || !previewOrders) {
    return <ConfirmationModal {...defaultProps} description={<Error />} />;
  }

  return (
    <>
      {previewOrders.map((previewOrder, index) => (basketIndex === index && (
        <ConfirmationModal
          key={previewOrder.formatted_total_price}
          {...defaultProps}
          description={description(client.customer_name, previewOrder, coach?.onboarding_status, basketIndex, previewOrders.length)}
        />
      )))}
    </>
  );
};
