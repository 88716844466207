import * as React from 'react';
import { SVGProps } from 'react';
import { Size } from '../types';
interface Props extends SVGProps<SVGSVGElement> {
  size?: Size;
}
const SvgExport = (props: Props) => {
  const getSize = () => {
    if (props.size) {
      switch (props.size) {
        case 'small':
          return 20;

        case 'medium':
          return 24;

        case 'large':
          return 32;

        default:
          return null;
      }
    }
  };

  return (
    <svg
      width={getSize() || 20}
      height={getSize() || 20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        opacity={0.3}
        d="M14.167 6.667a.833.833 0 010-1.667H15a3.333 3.333 0 013.334 3.333V15A3.333 3.333 0 0115 18.333H5A3.333 3.333 0 011.667 15V8.327A3.333 3.333 0 015 4.994h.834a.833.833 0 110 1.667H5c-.92 0-1.666.746-1.666 1.666V15c0 .92.746 1.667 1.666 1.667h10c.92 0 1.667-.747 1.667-1.667V8.333c0-.92-.746-1.666-1.667-1.666h-.833z"
        fill="currentColor"
      />
      <rect
        opacity={0.3}
        width={1.667}
        height={10}
        rx={0.833}
        transform="matrix(-1 0 0 1 10.833 1.667)"
        fill="currentColor"
      />
      <path
        d="M10 2.012l1.911 1.91a.833.833 0 101.179-1.178l-2.5-2.5a.833.833 0 00-1.179 0l-2.5 2.5A.833.833 0 108.09 3.923L10 2.012z"
        fill="currentColor"
      />
    </svg>
  );
};

export default SvgExport;
