import { UseMutationOptions } from 'react-query/types/react/types';
import { useMutation } from 'react-query';
import { model as coachProfileModel } from '~/model/Profile/coach';
import { model as userModel } from '~/model/Profile/user';

export const useUpdateCoachProfile = <T>(
  id: string,
  mutationOptions?: UseMutationOptions<any, unknown, T>,
) =>
  useMutation(
    (data: T) => {
      const { profile_image_url, profile_image_url_remove, ...otherData } = data as any;

      const promises = [coachProfileModel.updateCoachProfile<T>(id, otherData)];

      if (profile_image_url instanceof File) {
        promises.push(userModel.setProfileImage(profile_image_url));
      } else if (profile_image_url_remove === 1) {
        promises.push(userModel.removeProfileImage());
      } else {
        promises.push(Promise.resolve());
      }

      return Promise.all(promises);
    },
    {
      ...mutationOptions,
    },
  );
