import { Header } from '../ExpandableTable/model';
import { style } from './FoodLog.style';

export interface LogEntry {
  id: string;
  meal: string;
  amount?: string;
  kcal?: string;
  fiber?: string;
  saturated_fat?: string;
  sugar?: string;
  salt?: string;
}

export interface Log {
  date: string;
  foodLogItems: LogEntry[];
}

export interface Averages {
  kcal?: number;
  fiber?: number;
  saturatedFat?: number;
  sugar?: number;
  salt?: number;
}

export interface Week {
  number: number;
  active: boolean;
  foodLogs?: Log[];
  averages: Averages;
}

export const headerColumns: Header[] = [
  {
    title: 'Day',
    width: '0.75fr',
  },
  {
    title: 'Time',
    width: '0.75fr',
  },
  {
    title: 'Meal',
    width: '5fr',
  },
  {
    title: 'Amount',
    width: '3fr',
  },
  {
    title: 'Kcal',
    width: '1.5fr',
  },
  {
    title: 'Fiber',
    width: '1fr',
  },
  {
    title: 'SF',
    width: '1fr',
  },
  {
    title: 'Sugar',
    width: '1fr',
  },
  {
    title: 'Salt',
    width: '1fr',
  },
  {
    title: 'Action',
    className: style.headers.actions,
    width: '1.5fr',
  },
];
