import { useState } from 'react';
import { TestKitSearchAndFilters } from '~/model/Client/TestResults/testResults';

export const useSearchAndFilters = ({
  term: passedTerm,
  filters: passedFilters,
}: TestKitSearchAndFilters) => {
  const [term, setTerm] = useState(passedTerm);
  const [filters, setFilters] = useState(passedFilters);

  return {
    changeTerm: setTerm,
    changeFilters: setFilters,
    term,
    filters,
  };
};
