import * as Yup from 'yup';

export const ERROR_REQUIRED = 'This field is required';

export const GOAL_TITLE_MAX_LENGTH = 110;

export const validationSchema = Yup.object({
  goal: Yup.string().required(ERROR_REQUIRED).max(GOAL_TITLE_MAX_LENGTH),
  description: Yup.string().required(ERROR_REQUIRED),
});

export const validationAddSchema = Yup.object({
  goal: Yup.string().required(ERROR_REQUIRED).max(GOAL_TITLE_MAX_LENGTH),
  description: Yup.string().required(ERROR_REQUIRED),
  dimension: Yup.number().required(ERROR_REQUIRED),
  recommendation: Yup.number().required(ERROR_REQUIRED),
});
