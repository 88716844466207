import * as React from 'react';
import { SVGProps } from 'react';
import { Size } from '../types';
interface Props extends SVGProps<SVGSVGElement> {
  size?: Size;
}
const SvgClose = (props: Props) => {
  const getSize = () => {
    if (props.size) {
      switch (props.size) {
        case 'small':
          return 20;

        case 'medium':
          return 24;

        case 'large':
          return 32;

        default:
          return null;
      }
    }
  };

  return (
    <svg
      width={getSize() || 24}
      height={getSize() || 24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x={6.343}
        y={7.757}
        width={2}
        height={14}
        rx={1}
        transform="rotate(-45 6.343 7.757)"
        fill="currentColor"
      />
      <rect
        x={7.757}
        y={17.657}
        width={2}
        height={14}
        rx={1}
        transform="rotate(-135 7.757 17.657)"
        fill="currentColor"
      />
      <circle cx={12} cy={12} r={11.5} stroke="currentColor" />
    </svg>
  );
};

export default SvgClose;
