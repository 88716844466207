import React from 'react';
import { generatePath } from 'react-router-dom';
import { User, Briefcase, Cogwheel, AtmCard } from '~/components/common/Icons/dist';

import { Tabs, Tab, TabConfig } from '~/components/common/Tabs';
import { ROUTES } from '~/components/router';
import { ProfessionalDetails } from './ProfessionalDetails';
import { Payout } from './Payout';
import { PersonalDetails } from './PersonalDetails';
import { Settings } from './Settings';

export type ExistingTab = 'personal-details' | 'professional-details' | 'payout' | 'settings';

export interface Props {
  activeTab?: ExistingTab;
}

export const Details = ({ activeTab }: Props) => {
  const TABS: TabConfig[] = [
    {
      id: 'personal-details',
      title: 'Personal details',
      icon: <User />,
      content: <PersonalDetails />,
      enabled: true,
    },
    {
      id: 'professional-details',
      title: 'Professional details',
      icon: <Briefcase />,
      content: <ProfessionalDetails />,
      enabled: true,
    },
    {
      id: 'payout',
      title: 'Payout details',
      icon: <AtmCard />,
      content: <Payout />,
      enabled: true,
    },
    {
      id: 'settings',
      title: 'Settings',
      icon: <Cogwheel />,
      content: <Settings />,
      enabled: true,
    },
  ];

  return (
    <Tabs variant="card" activeTab={activeTab}>
      {TABS.filter((t) => t.enabled).map((tab) => (
        <Tab
          key={tab.id}
          id={tab.id}
          testId={tab.id}
          path={generatePath(ROUTES.PROFILE_TABBED, { tab: tab.id })}
          icon={tab.icon}
          title={tab.title}
        >
          {tab.content}
        </Tab>
      ))}
    </Tabs>
  );
};
