import styled from '@emotion/styled';
import { colors } from '~/components/utils/colors';

export const Row = styled.tr`
  td:first-of-type {
    padding-left: 20px;
  }

  &:last-of-type {
    border-bottom: 2px solid ${colors.colorGsFour};
  }
`;

export const Column = styled.td`
  font-weight: 500;
  color: ${colors.colorDisabledOne};
  font-size: 13px;
  line-height: 19px;
`;
