import React from 'react';
import { useQueryClient } from 'react-query';
import { useClient } from '~/components/pages/client/context';

import { model, clientNotesQueryKey } from '~/model/Client/Notes';
import { toApiRequest } from '~/components/common/Notes/Modal/config';
import { notesQueryKey } from '~/model/Notes/hooks';
import { Modal as Component, Props as ComponentProps } from '../Modal';

export const AddModal = ({ ...otherProps }: Omit<ComponentProps, 'onSubmit' | 'initialValues'>) => {
  const queryClient = useQueryClient();
  const { clientId } = useClient();

  return (
    <Component
      onSubmit={async (values) => {
        const data = toApiRequest(values);
        await model(clientId).add(data);
        await queryClient.invalidateQueries([clientNotesQueryKey(clientId)]);
        await queryClient.removeQueries([clientNotesQueryKey(clientId)], { inactive: true });
        await queryClient.invalidateQueries(notesQueryKey);
      }}
      testId="add-note-modal"
      initialValues={{ name: '', description: '' }}
      {...otherProps}
    />
  );
};
