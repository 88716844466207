import { generatePath } from 'react-router-dom';
import { api } from '~/services/api';

export interface TestReport {
  id: number;
  date: string;
}

export interface TestReportResponse {
  name: string;
  testReports: TestReport[];
}

const URL = {
  LIST: '/api/client/:clientId/test-report',
  GET_TEST_REPORT: '/api/client/:clientId/test-report/:testReportId',
  DELETE: '/api/client/:clientId/test-report/:testReportId',
  SEND_TO_CLIENT: '/api/client/:clientId/test-report/:testReportId/send-to-client',
};

export const model = (clientId) => ({
  getList: () => api.get<TestReportResponse[]>(generatePath(URL.LIST, { clientId })),
  getTestReport: (testReportId) =>
    api.download(generatePath(URL.GET_TEST_REPORT, { clientId, testReportId })),
  delete: (testReportId) => api.delete(generatePath(URL.DELETE, { clientId, testReportId })),
  sendToClient: (testReportId) => api.post(generatePath(URL.SEND_TO_CLIENT, { clientId, testReportId })),
});
