export enum QuestionVariant {
  PrimaryHealthGoal = 1,
  DietaryPlan = 2,
  Allergies = 3,
  HighBp = 4,
  HighCholesterol = 5,
  Diabetes = 6, // 70
  CoronaryHeartDisease = 7,
  MetricSystem = 9, // 138
  Gender = 10, // 134
  DateOfBirth = 11, // 139
  Weight = 12, // 136
  Height = 13, // 137
  BedTime = 14, // 97
  WakeTime = 15, // 98
  WaistCircumference = 16, // 114
  HipCircumference = 17, // 115

  Bp = 18, // 68
  Ancestry = 19, // 1
  EyeColor = 20, // 89
  HairColor = 21, // 90
  SkinColor = 22, // 91
  Freckles = 23, // 92
  SkinBurn = 24, // 93
  SkinTan = 25, // 94
  TanDepth = 26, // 95
  FaceSensitivity = 27, // 96
  Occupation = 28, // 83
  Exercise = 29, // 128
  SleepQuality = 30, // 132
  StressPerception = 31, // 105
  Satisfaction = 32, // 104
  StressEating = 33, // 19
  FastFoodFrequency = 34, // 5
  ReadyMeals = 35, // 4
  EnergyLevel = 36, // 153
  DietQuality = 37, // 154
  Supplements = 38, // 144
  VitaminsWaterSoluble = 39, // 145
  VitaminsFatSoluble = 40, // 146
  Macronutrient = 41, // 147
}
