import React, { useState } from 'react';

import { Edit, Edit2, Trash } from '~/components/common/Icons/dist';
import { Button, IconButton } from '~/components/common/Button';
import { Habit } from './Habit';
import { EditModal } from './Modal/EditModal';
import { Topic } from './Topic/container';

import { GoalContent, GoalHeader, HabitsWrapper, style } from './Goal.style';
import { Goal as Model, headerColumns } from '../model';
import { TableRowItem } from '../../ExpandableTable/ExpandableTable.style';
import { RowHeaderButton } from '../../Sections.style';
import { DeleteModal } from './Modal/DeleteModal';

export interface Props {
  goal: Model;
  expanded?: boolean;
}

export const Goal = ({
  expanded = false,
  goal: { id, goal, topic, topic_id: topicId, dimension, completed, total, text, habits },
}: Props) => {
  const [goalTextExpanded, setGoalTextExpanded] = useState(expanded);
  const [habitModalId, setHabitModalId] = useState<string>();
  const [editGoalModalId, setEditGoalModalId] = useState<string>();
  const [topicModalId, setTopicModalId] = useState<number>();
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleExpandClick = () => {
    setGoalTextExpanded(true);
  };

  const handleCollapseClick = () => {
    setGoalTextExpanded(false);
  };

  const handleHabitClick = (itemId: string) => {
    setHabitModalId(itemId);
  };

  const handleEditGoalClick = (itemId: string) => {
    setEditGoalModalId(itemId);
  };

  const handleTopicClick = () => {
    setTopicModalId(topicId);
  };

  const gridColumnWidths = headerColumns.map(({ width }) => width || '1fr');

  return (
    <TableRowItem>
      <GoalHeader $widths={gridColumnWidths}>
        <strong className={style.goal}>
          <RowHeaderButton onClick={goalTextExpanded ? handleCollapseClick : handleExpandClick}>
            {goal}
          </RowHeaderButton>
        </strong>
        <div
          className={style.topic}
          onClick={handleTopicClick}
          onKeyDown={handleTopicClick}
          role="button"
          tabIndex={-1}
          aria-label={topic}
          data-testid={topic}
        >
          {topic}
        </div>
        <div className={style.dimension}>{dimension.toLowerCase()}</div>
        <div>
          {completed}/{total}
        </div>
        <div className={style.actions}>
          {!goalTextExpanded && (
            <IconButton
              shade="light"
              aria-label="Expand goal"
              icon={<Edit2 />}
              onClick={handleExpandClick}
            />
          )}
          {goalTextExpanded && (
            <IconButton aria-label="Collapse goal" icon={<Edit2 />} onClick={handleCollapseClick} />
          )}
          <IconButton
            shade="light"
            aria-label="Edit goal"
            icon={<Edit />}
            onClick={() => handleEditGoalClick(id)}
          />

          <IconButton
            shade="light"
            icon={<Trash />}
            aria-label="Delete goal"
            onClick={() => setShowDeleteModal(true)}
          />
        </div>
      </GoalHeader>
      {showDeleteModal && <DeleteModal goalId={id} onClose={() => setShowDeleteModal(false)} />}
      {goalTextExpanded && (
        <GoalContent $widths={gridColumnWidths}>
          <div>
            {/* eslint-disable-next-line react/no-danger */}
            <div dangerouslySetInnerHTML={{ __html: text }} />
            {habits && habits.length > 0 && (
              <HabitsWrapper>
                {habits.map(({ id: habitId, title }) => (
                  <Button
                    size="small"
                    testId={title}
                    className="habit"
                    key={habitId}
                    shade="light"
                    variant="success"
                    onClick={() => handleHabitClick(habitId)}
                  >
                    {title}
                  </Button>
                ))}
              </HabitsWrapper>
            )}
          </div>
        </GoalContent>
      )}
      {habitModalId && (
        <Habit
          id={String(habitModalId)}
          testId="habit-modal"
          onClose={() => setHabitModalId(undefined)}
        />
      )}
      {editGoalModalId && (
        <EditModal
          goalId={editGoalModalId}
          testId="edit-goal-modal"
          width="772px"
          onClose={() => setEditGoalModalId(undefined)}
        />
      )}
      {topicModalId && (
        <Topic
          testId="goal-topic-modal"
          topicId={topicModalId}
          onClose={() => setTopicModalId(undefined)}
        />
      )}
    </TableRowItem>
  );
};
