import { css } from '@emotion/css';
import React, { useState } from 'react';
import { Button } from '~/components/common/Button';
import { Card, CardBody, CardHeader } from '~/components/common/Card';
import { Loader } from '~/components/common/Loader';

export const LoaderDemo = () => {
  const [fullScreenLoaderVisible, setFullScreenLoaderVisible] = useState(false);
  const handleShowFullScreenLoaderClick = () => {
    setFullScreenLoaderVisible(true);
    setTimeout(() => setFullScreenLoaderVisible(false), 4000);
  };

  return (
    <>
      <Card>
        <CardHeader title="FullScreen loader" />
        <CardBody>
          <Button onClick={() => handleShowFullScreenLoaderClick()}>
            Show full screen loader for 4 seconds
          </Button>
          {fullScreenLoaderVisible && <Loader fullScreen />}
        </CardBody>
      </Card>
      <Card>
        <CardHeader title="Small inside the auto-height card" />
        <CardBody>
          <Loader size="small" />
        </CardBody>
      </Card>
      <Card>
        <CardHeader title="Medium, centered, 400px wrapper height" />
        <CardBody>
          <Loader wrapperHeight="400px" center />
        </CardBody>
      </Card>
      <div>
        <h1>Large, centered, 750px x 400px container</h1>
        <div
          className={css({
            width: '750px',
            border: '1px solid gray',
          })}
        >
          <Loader wrapperHeight="400px" size="large" center />
        </div>
      </div>
    </>
  );
};
