export interface OnboardingLinkParams extends Record<string, string> {
  returnUrl: string;
}

export interface LoginLinkParams extends Record<string, string> {
  redirectUrl: string;
}

export const URL = {
  STRIPE_ONBOARDING_FLOW: '/stripe-connect/onboarding-link/create',
  STRIPE_LOGIN_LINK: '/stripe-connect/login-link/create',
};
