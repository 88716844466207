import { generatePath } from 'react-router-dom';
import { Meal, MealStatus } from '~/components/pages/client/Sections/Meals/model';
import { api, queryClient } from '~/services/api';
import { ClientMealsListResponse } from '../meals';
import { clientsQueryKey } from '../useClientMeals';

const URL = {
  SKIP: '/api/client/:clientId/meal/skip/:mealId',
  UNSKIP: '/api/client/:clientId/meal/unskip/:mealId',
  GET_RECIPE: '/api/client/:clientId/meal/:mealId/recipe',
};

export const model = {
  skip: <T>(clientId, mealId) => api.post<T>(generatePath(URL.SKIP, { clientId, mealId })),
  unskip: <T>(clientId, mealId) => api.post<T>(generatePath(URL.UNSKIP, { clientId, mealId })),
  getMealRecipe: <Recipe>(clientId, mealId) =>
    api.get<Recipe>(generatePath(URL.GET_RECIPE, { clientId, mealId })),
};

export const updateMealOfMealsList = (
  data: ClientMealsListResponse | undefined,
  updatedMeal: Meal,
) => ({
  ...data,
  weeks: data?.weeks.map((week) => ({
    ...week,
    meals: week?.meals?.map((meal) => {
      if (meal.id === updatedMeal.id) {
        return { ...updatedMeal };
      }

      return { ...meal };
    }),
  })),
});

export const updateMealOfMealsListQueryData = async (clientId, updatedMeal) => {
  const queryKey = clientsQueryKey(clientId);
  const data: ClientMealsListResponse | undefined = queryClient.getQueryData(queryKey);
  queryClient.setQueryData(queryKey, () => updateMealOfMealsList(data, updatedMeal));
};

export interface MealStatusChangeMutationContext {
  id: number;
  status: MealStatus;
  oldStatus: MealStatus;
}
