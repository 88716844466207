import React from 'react';

import { Client } from '~/model/Client/clients';
import { ClientRow } from '~/components/pages/clients/ClientRow';
import { Header, Row, Table as MainTable } from '~/components/common/Table';

interface Props {
  clients: Client[];
}

export const headerColumns: Header[] = [
  {
    title: 'Client',
    colSpan: 2,
  },
  {
    title: 'Key Biomarker',
    style: {
      minWidth: '150px',
    },
    tooltip: 'This shows the change of the marker most representative of your client’s health journey',
  },
  {
    title: 'Activity',
    style: {
      minWidth: '140px',
    },
    tooltip:
      'This shows the last time the Client was active in the app and the number of completed goals',
  },
  {
    title: 'Progress',
    style: {
      minWidth: '150px',
    },
  },
  {
    title: 'Subscription',
    style: {
      minWidth: '100px',
    },
  },
  {
    title: 'Action',
    style: {
      textAlign: 'right' as const,
      minWidth: '100px',
    },
  },
];

const rows = (clients: Client[]): Row[] =>
  clients.map((item) => ({
    content: <ClientRow client={item} key={item.customer_id} />,
    id: item.customer_id,
  }));

export const Table = ({ clients }: Props) => (
  <MainTable rows={rows(clients)} headers={headerColumns} />
);
