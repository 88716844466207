import React, { PropsWithChildren } from 'react';
import { DateTime } from 'luxon';
import { generatePath, Link } from 'react-router-dom';

import { getVariantFromColor, Label } from '~/components/common/Label';
import { Progress } from '~/components/common/Progress';
import {
  Client,
  getJourneyTitleFromCode,
  JourneyCode,
} from '~/model/Client';
import { ROUTES } from '~/components/router/Routes';
import { IconButton } from '~/components/common/Button';
import { Chat20, Edit2 } from '~/components/common/Icons/dist';
import { ActionColumn, ButtonWrapper } from './ClientRow.style';

export interface ClientRowProps {
  client: Client;
}

const progress = (journey_title, journey_progress, journey_total, is_journey_started) => {
  let percentage = 0;
  let variant = 'muted';

  if (is_journey_started) {
    percentage = (100 * journey_progress) / journey_total;

    switch (journey_title) {
      case JourneyCode.WEIGHT_LOSS:
        variant = 'success';
        break;
      case JourneyCode.BE_MORE_ENERGETIC:
        variant = 'warning';
        break;
      case JourneyCode.EAT_HEALTHY_TO_GET_FIT:
        variant = 'danger';
        break;
      case JourneyCode.IMPROVE_OVERALL_HEALTH:
      default:
        variant = 'primary';
        break;
    }
  }

  return <Progress completed={percentage} variant={variant} />;
};

const actions = (client: Client) => (
  <ButtonWrapper>
    {client.has_comet_chat_account && (
      <Link
        to={generatePath(ROUTES.CHAT_WITH_USER, { id: client.customer_id })}
        data-testid="client-chat-button"
      >
        <IconButton shade="light" icon={<Chat20 />} />
      </Link>
    )}
    <Link
      to={generatePath(ROUTES.CLIENT, { id: client.customer_id })}
      data-testid="edit-client-button"
    >
      <IconButton shade="light" icon={<Edit2 />} />
    </Link>
  </ButtonWrapper>
);

interface ClientProfileLinkProps {
  clientId: string;
}

const ClientProfileLink = ({ clientId, children }: PropsWithChildren<ClientProfileLinkProps>) => (
  <Link
    to={generatePath(ROUTES.CLIENT, { id: clientId })}
    className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
  >
    {' '}
    {children}
  </Link>
);

const keyBiomarkers = ({
  key_biomarker_value,
  key_biomarker_value1_diff,
  key_biomarker_value2_diff,
}: Client) => {
  if (key_biomarker_value) {
    return (
      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
        {key_biomarker_value}&nbsp;
        <small>
          {key_biomarker_value1_diff && (
            <>
              (
              <span className={`text-${getVariantFromColor(key_biomarker_value1_diff.color)}`}>
                {key_biomarker_value1_diff.value}
              </span>
              {key_biomarker_value2_diff && (
                <>
                  /
                  <span className={`text-${getVariantFromColor(key_biomarker_value2_diff.color)}`}>
                    {key_biomarker_value2_diff.value}
                  </span>
                </>
              )}
              )
            </>
          )}
        </small>
      </span>
    );
  }

  return <span className="text-dark-75 font-weight-bolder d-block font-size-lg">-</span>;
};

export const ClientRow = ({ client }: ClientRowProps) => {
  const lastActivity = client.last_activity
    ? DateTime.fromISO(client.last_activity).toFormat('dd/MM/yyyy')
    : '-';

  return (
    <tr>
      <td className="pr-0">
        <div className="symbol symbol-50 symbol-light mt-1 overflow-hidden">
          <span className="symbol-label">
            {!client.has_uploaded_image && (
              <img
                src={`${client.profile_image_name}`}
                className="h-75 align-self-end"
                alt={client.customer_name}
              />
            )}
            {client.has_uploaded_image && (
              <img src={client.profile_image_name} className="rounded w-100" alt="" />
            )}
          </span>
        </div>
      </td>
      <td className="pl-4 pr-8">
        <ClientProfileLink clientId={client.customer_id}>{client.customer_name}</ClientProfileLink>
        <span className="text-muted font-weight-bold text-muted d-block">
          {client.welcome_pack_name}
        </span>
      </td>
      <td>
        {keyBiomarkers(client)}
        <span className="text-muted font-weight-bold">{client.key_biomarker_name}</span>
      </td>
      <td>
        <span className="text-dark-75 font-weight-bolder d-block font-size-lg" title={lastActivity}>
          {lastActivity}
        </span>
        <span className="text-muted font-weight-bold">
          {client.goals_completed}/{client.goals_total} Goals
        </span>
      </td>
      <td>
        <div className="d-flex flex-column w-100 mr-2">
          <div className="d-flex align-items-center justify-content-between mb-2">
            <span className="font-weight-bold">{client.is_journey_started && client.journey_code !== null ? getJourneyTitleFromCode(client.journey_code) : 'Not started'}</span>
            <span className="mr-2 font-weight-bold">
              {client.journey_progress}/{client.journey_total}
            </span>
          </div>
          {progress(client.journey_code, client.journey_progress, client.journey_total, client.is_journey_started)}
        </div>
      </td>
      <td>
        <Label inline light testId="subscription-status-label" variant={client.subscription_status ? getVariantFromColor(client.subscription_status.color) : 'neutral'}>
          {client.subscription_status?.value ?? 'N/A'}
        </Label>
      </td>
      <ActionColumn>{actions(client)}</ActionColumn>
    </tr>
  );
};
