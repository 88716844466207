import { generatePath } from 'react-router-dom';
import { Color } from '~/components/common/Label';
import { TestKit } from '~/components/pages/client/Sections/TestKits/model';
import { api } from '~/services/api';

export interface Phenotype {
  id: number;
  name: string;
  measuringUnits: string;
  description: string;
  category: string;
  sample: string;
  references: Reference[];
  resultRangeSets: ResultRangeSet[];
  testKits: TestKit[];
}

export interface Reference {
  name: string;
  url: string;
}

export interface ResultRangeSet {
  id: number;
  name: string;
  resultRanges: ResultRange[];
}

export interface ResultRange {
  id: number;
  name: ResultRangeName;
  valueFrom?: number;
  valueTo?: number;
  description: string;
}

export interface ResultRangeName {
  value: string;
  color: Color;
}

const URL = {
  LIST: '/api/phenotype',
  GET_PHENOTYPE: '/api/phenotype/:phenotypeId',
};

export const model = () => ({
  getList: () => api.get<Phenotype[]>(generatePath(URL.LIST)),
  getPhenotype: (phenotypeId) =>
    api.get<Phenotype>(generatePath(URL.GET_PHENOTYPE, { phenotypeId })),
});
