import * as React from 'react';
import { SVGProps } from 'react';
import { Size } from '../types';
interface Props extends SVGProps<SVGSVGElement> {
  size?: Size;
}
const SvgBriefcase = (props: Props) => {
  const getSize = () => {
    if (props.size) {
      switch (props.size) {
        case 'small':
          return 20;

        case 'medium':
          return 24;

        case 'large':
          return 32;

        default:
          return null;
      }
    }
  };

  return (
    <svg
      width={getSize() || 24}
      height={getSize() || 24}
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.174 8a2 2 0 00-2 2v8a2 2 0 002 2h13.652a2 2 0 002-2v-8a2 2 0 00-2-2H5.175zm5.326 2a.5.5 0 00-.5.5v1a.5.5 0 00.5.5h3a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5h-3z"
      />
      <path
        opacity={0.3}
        d="M10 8H8V7a3 3 0 013-3h2a3 3 0 013 3v1h-2V7a1 1 0 00-1-1h-2a1 1 0 00-1 1v1z"
      />
    </svg>
  );
};

export default SvgBriefcase;
