/**
 * Create React App entry point. This and `public/index.html` files can not be
 * changed or moved.
 */
import React from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import { App } from '~/app/App';
import * as _redux from '~/redux';
import store from '~/redux/store';
import './index.scss'; // Standard version

import '~/vendor/_metronic/_assets/plugins/flaticon/flaticon.css';
import '~/vendor/_metronic/_assets/plugins/flaticon2/flaticon.css';
import '@fortawesome/fontawesome-free/css/all.min.css';

import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';

import {
  MetronicLayoutProvider,
  MetronicSplashScreenProvider,
  MetronicSubheaderProvider,
} from '~/vendor/_metronic/layout';
import { MetronicI18nProvider } from '~/vendor/_metronic/i18n';
import { FirebaseProvider } from './components/common/FirebaseProvider';
import { IntercomProvider } from './components/common/IntercomProvider';

_redux.mockAxios(axios);
_redux.setupAxios(axios, store);

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_FRONTEND_DSN as string,
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: `${process.env.REACT_APP_FIREBASE_PROJECT_ID}.firebaseapp.com`,
  databaseURL: `https://${process.env.REACT_APP_FIREBASE_PROJECT_ID}-default-rtdb.firebaseio.com`,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: `${process.env.REACT_APP_FIREBASE_PROJECT_ID}.appspot.com`,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
getAnalytics(app);

ReactDOM.render(
  <MetronicI18nProvider>
    <MetronicLayoutProvider>
      <MetronicSubheaderProvider>
        <MetronicSplashScreenProvider>
          <FirebaseProvider app={app}>
            <IntercomProvider>
              <App store={store} />
            </IntercomProvider>
          </FirebaseProvider>
        </MetronicSplashScreenProvider>
      </MetronicSubheaderProvider>
    </MetronicLayoutProvider>
  </MetronicI18nProvider>,
  document.getElementById('root'),
);
