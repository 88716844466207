import * as React from 'react';
import { SVGProps } from 'react';
import { Size } from '../types';
interface Props extends SVGProps<SVGSVGElement> {
  size?: Size;
}
const SvgPlus2 = (props: Props) => {
  const getSize = () => {
    if (props.size) {
      switch (props.size) {
        case 'small':
          return 20;

        case 'medium':
          return 24;

        case 'large':
          return 32;

        default:
          return null;
      }
    }
  };

  return (
    <svg
      width={getSize() || 20}
      height={getSize() || 20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        opacity={0.3}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.666 10a8.333 8.333 0 1016.667 0 8.333 8.333 0 00-16.667 0z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.167 10.833a.833.833 0 000-1.666h-3.334V5.833a.833.833 0 00-1.666 0v3.334H5.833a.833.833 0 000 1.666h3.334v3.334a.833.833 0 001.666 0v-3.334h3.334z"
        fill="currentColor"
      />
    </svg>
  );
};

export default SvgPlus2;
