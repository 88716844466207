import { useQuery } from 'react-query';
import { GetScheduleResponse, model } from './schedule';

export const clientsQueryKey = (clientId: string) => ['client', clientId, 'schedule'];

export const useSchedule = (clientId: string) =>
  useQuery<GetScheduleResponse>(clientsQueryKey(clientId), () => model.get(clientId), {
    retry: 2,
    useErrorBoundary: false,
  });
