import React, { PropsWithChildren } from 'react';
import clsx from 'clsx';
import { TestableComponentProps } from '~/model/TestableComponentProps.interface';
import { style, Wrapper } from './Label.style';

export enum Color {
  GREY = 'grey',
  RED = 'red',
  GREEN = 'green',
  YELLOW = 'yellow',
}

export type Variant = 'primary' | 'info' | 'success' | 'danger' | 'warning' | 'neutral' | 'muted';

export const getVariantFromColor = (color: Color): Variant => {
  switch (color) {
    default:
    case Color.GREY:
      return 'muted';
    case Color.RED:
      return 'danger';
    case Color.GREEN:
      return 'success';
    case Color.YELLOW:
      return 'warning';
  }
};

export interface Props extends PropsWithChildren<any>, TestableComponentProps {
  variant?: Variant;
  light?: boolean;
  inline?: boolean;
  className?: string;
}

const backgroundColorClassName = (light: boolean, variant: Variant) => {
  const prefix = `bg${light ? '-light' : ''}`;
  return `${prefix}-${variant}`;
};

const textColor = (light: boolean, variant: Variant) => {
  // inverse the text - light text if bg is dark, and vice versa
  const prefix = `text${!light ? '-light' : ''}`;
  return `${prefix}-${variant}`;
};
export const Label = ({
  children,
  variant = 'primary',
  light = false,
  inline = false,
  testId,
  className,
}: Props) => (
  <Wrapper>
    <span
      data-testid={testId}
      className={clsx(
        { 'd-flex': !inline, 'd-inline-flex': inline },
        backgroundColorClassName(light, variant),
        style.wrapper,
        className,
      )}
    >
      <span className={clsx(textColor(light, variant), style.text)}>{children}</span>
    </span>
  </Wrapper>
);
