import * as Yup from 'yup';

const ERROR_REQUIRED = 'This field is required';
const ERROR_EMAIL = 'Invalid email address';

export interface FormFields {
  username: string;
  generic?: string;
}

export const initialValues: FormFields = {
  username: '',
}

export const validationSchema = Yup.object({
  username: Yup.string().email(ERROR_EMAIL).required(ERROR_REQUIRED),
});
