import React, { useEffect, useReducer } from 'react';
import clsx from 'clsx';

import { css } from '@emotion/css';
import { Button } from '~/components/common/Button';
import { DoubleChevron } from '~/components/common/Icons';

import {
  ActionCollapse,
  ActionCollapseAll,
  ActionExpandAll,
  ActionExpand,
  reducer,
  State,
  ActionReplaceRows,
} from '~/stateManagement/expandable';
import { findRow, allExpanded } from '~/stateManagement/expandable/selectors';

import { Info } from '~/components/common/Icons/dist';
import { Tooltip } from '~/components/common/Tooltip';
import {
  TableHeaderTitle,
  TableGridSpacing,
  TableRow,
  TableRowHeader,
  TableRows,
  ChevronWrapper,
} from './ExpandableTable.style';

import { Row, Header } from './model';
import { Layout } from '../Layout';

export interface Props {
  title?: string;
  subtitle?: string;
  appendHeaderButtons?: JSX.Element[] | JSX.Element;
  prependHeaderButtons?: JSX.Element[] | JSX.Element;
  searchAndFilters?: JSX.Element[] | JSX.Element;
  headers: Header[];
  rows: Row[];
  emptyState?: JSX.Element[] | JSX.Element;
  footer?: JSX.Element[] | JSX.Element;
  withHeader?: boolean;
  activeOnExpand?: boolean;
}

const updateRows = (rows: Row[], state: State) =>
  rows.map((row) => ({
    ...(findRow(row.title, state) || { id: row.title, expanded: row.active }),
  }));

export const body = ({
  rows,
  emptyState,
  headers,
  state,
  handleCollapseRow,
  handleExpandRow,
  footer,
  activeOnExpand,
}) => {
  if (emptyState && (!rows || rows.length === 0)) {
    return emptyState;
  }

  return (
    <>
      <TableGridSpacing $widths={headers.map((header) => header.width || '1fr')}>
        {headers.map((header) => (
          <TableHeaderTitle className={header.className} key={header.title}>
            {header.title}

            {header.tooltip && (
              <Tooltip content={header.tooltip}>
                <Info className="mt-1" />
              </Tooltip>
            )}
          </TableHeaderTitle>
        ))}
      </TableGridSpacing>
      <TableRows>
        {rows.map((row) => {
          const rowState = findRow(row.title, state);
          if (!rowState) return null;
          return (
            <TableRow key={row.title}>
              <TableRowHeader
                $widths={headers.map((header) => header.width || '1fr')}
                className={clsx({
                  active: row.active || (activeOnExpand && rowState.expanded),
                  expanded: rowState.expanded,
                })}
                onClick={() => {
                  if (!rowState.expanded) {
                    handleExpandRow(rowState.id);
                  }

                  if (rowState.expanded) {
                    handleCollapseRow(rowState.id);
                  }
                }}
              >
                {(row.showTitle ?? true) && <span className={css({ gridColumnStart: 1, gridColumnEnd: 3 })}>{row.title}</span>}
                {React.Children.map(row.titleRowContent, (element) => element)}
                <ChevronWrapper
                  className={clsx({ active: row.active || (activeOnExpand && rowState.expanded) }, css({ gridColumnStart: headers.length }))}
                >
                  <DoubleChevron direction={rowState.expanded ? 'down' : 'right'} />
                </ChevronWrapper>
              </TableRowHeader>
              {rowState.expanded && row.content}
            </TableRow>
          );
        })}
      </TableRows>
      {footer}
    </>
  );
};

const makeInitialState = (rows: Row[]) => ({
  rows: rows.map((row) => ({ expanded: row.expanded || false, id: row.title })),
});

export const ExpandableTable = ({
  title,
  subtitle,
  appendHeaderButtons,
  prependHeaderButtons,
  searchAndFilters,
  headers,
  rows,
  emptyState,
  footer,
  withHeader = true,
  activeOnExpand = false,
}: Props) => {
  const [state, dispatch] = useReducer(reducer, makeInitialState(rows));

  useEffect(() => {
    dispatch(ActionReplaceRows(updateRows(rows, state)));
  }, [rows]);

  const handleExpandAll = () => dispatch(ActionExpandAll());
  const handleCollapseAll = () => dispatch(ActionCollapseAll());
  const handleExpandRow = (rowTitle) => dispatch(ActionExpand(rowTitle));
  const handleCollapseRow = (rowTitle) => dispatch(ActionCollapse(rowTitle));

  return (
    <Layout
      title={title}
      subtitle={subtitle}
      headerButtons={
        <>
          {prependHeaderButtons}
          {rows.length > 0 && (
            <>
              {!allExpanded(state) && (
                <Button
                  iconPosition="right"
                  size="large"
                  shade="light"
                  icon={<DoubleChevron direction="down" />}
                  onClick={handleExpandAll}
                >
                  Expand all
                </Button>
              )}
              {allExpanded(state) && (
                <Button
                  iconPosition="right"
                  shade="light"
                  size="large"
                  icon={<DoubleChevron direction="up" />}
                  onClick={handleCollapseAll}
                >
                  Collapse all
                </Button>
              )}
            </>
          )}
          {appendHeaderButtons}
        </>
      }
      searchAndFilters={searchAndFilters}
      withHeader={withHeader}
    >
      {body({ rows, emptyState, headers, state, handleCollapseRow, handleExpandRow, footer, activeOnExpand })}
    </Layout>
  );
};
