import React from 'react';
import { useNavigate } from 'react-router-dom';
import { StyledButton } from '~/components/common/Layout/AuthLayout/AuthLayout.style';
import { ROUTES } from '~/components/router/Routes';
import { getPartnerButtonVairant } from '~/components/utils/helpers/partner';
import { helpUrl } from '../../error/config';
import { ActionButtonWrapper, ContentWrapper, FormWrapper, GetHelpWrapper, Heading, LinkWrapper, NoteParagraph } from '../auth.style';

export const PasswordResetSent = () => {
  const navigate = useNavigate();
  const partnerButtonVariant = getPartnerButtonVairant();

  const onGoBack = () => navigate(ROUTES.LOGIN);

  return (
    <div data-testid="password-reset">
      <GetHelpWrapper>
        Having issues? <a href={helpUrl} target="_blank" rel="noreferrer">Get help</a>
      </GetHelpWrapper>
      <ContentWrapper>
        <Heading>Reset request</Heading>
        <LinkWrapper>Your password reset email is on its way</LinkWrapper>
        <FormWrapper>
          <p>An email with a password reset link has been sent to you. Please click the link in the email and follow the instructions to change your password.</p>
          <p>If you don&apos;t get an email in the next few minutes please check your spam folder or try resetting your password again.</p>
          <NoteParagraph>Note: You can only request a new password once within 2 hours.</NoteParagraph>
          <p />
        </FormWrapper>
        <ActionButtonWrapper>
          <StyledButton
            onClick={onGoBack}
            type="button"
            size="extraLarge"
            isBlock
            variant={partnerButtonVariant}
          >
            Back
          </StyledButton>
        </ActionButtonWrapper>
      </ContentWrapper>
    </div>
  );
}

