import { GoalResponse } from '~/model/Client/Goals';

export interface Goal {
  id?: string;
  dimension: number;
  topic: string;
  goal: string;
  description: string;
  actions?: number;
  week: number;
  recommendation?: number;
}

export const fromApiResponse = ({
  title,
  id,
  text,
  recommendation_title,
  dimension,
  actions_total_override,
  week,
}: GoalResponse): Goal => ({
  id,
  goal: title,
  description: text,
  topic: recommendation_title,
  dimension,
  actions: actions_total_override,
  week,
});
