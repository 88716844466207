import React, { useState } from 'react';
import { DateTime } from 'luxon';
import { capitalize } from '@material-ui/core';

import clsx from 'clsx';

import { TestableComponentProps } from '~/model/TestableComponentProps.interface';
import { getVariantFromColor, Label } from '~/components/common/Label';
import { IconButton } from '~/components/common/Button';
import { Edit, Edit2, Trash } from '~/components/common/Icons/dist';

import { TestResultListItem, style } from './TestResult.style';
import { headerColumns, TestResult as Model } from '../model';
import { EditTestResultsModal } from '../Modal/EditTestResultsModal';
import { DeleteTestResultsModal } from '../Modal/DeleteTestResultsModal';
import { AddTestResultsModal } from '../Modal/AddTestResultsModal';

export interface Props extends TestableComponentProps {
  testResult?: Model;
  phenotypeId: number;
  measuringUnits: string;
  setShowPhenotypeModal: (value: boolean) => void;
}

export const formatNumber = (number?: number) => (!number ? null : parseFloat(number.toFixed(3)));

const diff = (valueDiff) => {
  if (!valueDiff) {
    return null;
  }

  const { color, value } = valueDiff;

  return (
    <>
      (<span className={`text-${getVariantFromColor(color)}`}>{value}</span>)
    </>
  );
};

const resultRange = ({ from, to, measuringUnits }) => {
  if (!from && !to) {
    return 'N/A';
  }

  if (!from || !to) {
    return (
      <>
        {[from ? `≥ ${from}` : null, to ? `≤ ${to}` : null].filter((item) => !!item).join(' - ')}{' '}
        {measuringUnits}
      </>
    );
  }

  return `${from} - ${to} ${measuringUnits}`;
};

const buildLabel = (result?: any) => {
  if (!result) {
    return (
      <Label inline variant="neutral" light>
        N/A
      </Label>
    );
  }

  const { color, value } = result;

  return (
    <Label inline light variant={getVariantFromColor(color)}>
      {capitalize(value)}
    </Label>
  );
};

const testResultModal = (onClose: () => void, phenotypeId: number, testResult?: Model) => {
  if (testResult) {
    return (
      <EditTestResultsModal onClose={onClose} testResult={testResult} phenotypeId={phenotypeId} />
    );
  }

  return <AddTestResultsModal phenotypeId={phenotypeId} onClose={onClose} />;
};

export const TestResult = ({
  measuringUnits,
  testResult,
  phenotypeId,
  setShowPhenotypeModal,
}: Props) => {
  const [showTestResultsModal, setShowTestResultsModal] = useState(false);
  const [showDeleteTestResultsModal, setShowDeleteTestResultsModal] = useState(false);

  const date = testResult?.date ? DateTime.fromISO(testResult.date).toFormat('dd/MM/yyyy') : 'N/A';
  const value =
    testResult?.value !== undefined ? (
      <>
        <span>
          {testResult.value === 0 ? testResult.value : formatNumber(testResult.value)}{' '}
          {measuringUnits}
        </span>{' '}
        {diff(testResult.valueDiff)}
      </>
    ) : (
      'N/A'
    );

  const range = resultRange({
    from: formatNumber(testResult?.resultRangeValueFrom),
    to: formatNumber(testResult?.resultRangeValueTo),
    measuringUnits,
  });

  const columnClass = clsx(style.column, { [style.disabled]: !testResult });

  return (
    <>
      <TestResultListItem
        className={style.innerRow}
        $widths={headerColumns.slice(2).map(({ width }) => width || '1fr')}
      >
        <div className={columnClass}>{date}</div>
        <div className={columnClass}>
          <span>{value}</span>
        </div>
        <div className={columnClass}>
          <span>{range}</span>
        </div>
        <div className={style.column}>{buildLabel(testResult?.result)}</div>
        <div className={style.actions}>
          <IconButton
            aria-label="Show test result"
            shade="light"
            icon={<Edit2 />}
            onClick={() => setShowPhenotypeModal(true)}
          />
          <IconButton
            aria-label="Add/edit test result"
            shade="light"
            icon={<Edit />}
            onClick={() => setShowTestResultsModal(true)}
          />
          <IconButton
            aria-label="Delete test result"
            shade="light"
            icon={<Trash />}
            disabled={!testResult}
            onClick={() => setShowDeleteTestResultsModal(true)}
          />
        </div>
      </TestResultListItem>
      {showTestResultsModal &&
        testResultModal(() => setShowTestResultsModal(false), phenotypeId, testResult)}
      {showDeleteTestResultsModal && testResult && (
        <DeleteTestResultsModal
          onClose={() => setShowDeleteTestResultsModal(false)}
          testResultsId={testResult.id}
        />
      )}
    </>
  );
};
