import React from 'react';
import { css } from '@emotion/css';
import { Form, Formik, FormikProps } from 'formik';
import { useQueryClient } from 'react-query';
import {
  FormikTextArea,
  FormRowInputWrapper,
  FormRow,
  FormRowLabel,
  FormikDropdownMultiple,
  FormikInput,
} from '~/components/common/Form';
import { Button } from '~/components/common/Button';
import { useCoachContext } from '~/components/pages/profile/CoachProvider';
import { CoachProfile, coachProfileQueryKey } from '~/model/Profile';

import { useLanguages } from '~/model/Language';
import { Loader } from '~/components/common/Loader';
import { CoachRequest } from '~/model/Profile/coach';
import { FormFields, initialValues, toApiRequest } from './config';

import { Separator, SubmitButtonWrapper } from '../Layout/Form.style';

import { useUpdateCoachProfessionalDetails } from './hooks';
import { validationSchema } from './validations';

export const ProfessionalDetails = () => {
  const { coach } = useCoachContext();
  const queryClient = useQueryClient();
  const { data: languagesList, isLoading, isError } = useLanguages();
  const mutation = useUpdateCoachProfessionalDetails<CoachRequest>(coach.id, {
    onSuccess: () => {
      queryClient.invalidateQueries(coachProfileQueryKey());
    },
  });

  if (isLoading) {
    return <Loader size="small" center />;
  }

  if (isError || !languagesList) {
    return <>Error has occured</>;
  }

  return (
    <Formik
      initialValues={initialValues(coach)}
      validationSchema={validationSchema}
      onSubmit={(values: FormFields, actions) => {
        const data = toApiRequest(values);
        mutation.mutate(data, {
          onSettled: async () => {
            queryClient.setQueryData(coachProfileQueryKey(), (oldData) => ({
              ...(oldData as CoachProfile),
              ...data,
            }));
            actions.setSubmitting(false);
          },
        });
      }}
    >
      {({ isSubmitting }: FormikProps<FormFields>) => (
        <Form>
          <div className={css({ padding: '30px 30px 0 0' })}>
            <FormRow>
              <>
                <FormRowLabel htmlFor="bio">Introduction</FormRowLabel>
                <FormRowInputWrapper>
                  <FormikTextArea
                    name="bio"
                    maxLength={280}
                    note="Introduce yourself briefly to your clients (280 characters maximum)"
                    placeholder="I love food and I believe that..."
                    autoHeight
                    minHeight="40px"
                    maxHeight="60px"
                    rows={1}
                    data-testid="bio"
                  />
                </FormRowInputWrapper>
              </>
            </FormRow>
            <FormRow>
              <>
                <FormRowLabel htmlFor="focus_area">Focus area</FormRowLabel>
                <FormRowInputWrapper>
                  <FormikTextArea
                    name="focus_area"
                    maxLength={280}
                    note="Are there any health and wellness areas that you are particularly strong in?"
                    placeholder="Weightloss, sleep disorders, stress management, gut health, etc."
                    autoHeight
                    minHeight="40px"
                    maxHeight="60px"
                    rows={1}
                    data-testid="focus-area"
                  />
                </FormRowInputWrapper>
              </>
            </FormRow>
            <FormRow width="half">
              <>
                <FormRowLabel htmlFor="languages">Languages</FormRowLabel>
                <FormRowInputWrapper>
                  <FormikDropdownMultiple
                    name="languages"
                    note="Which languages can you provide your services in?"
                    options={languagesList.map((language) => ({
                      title: language.name,
                      value: language.name,
                    }))}
                    testId="languages"
                  />
                </FormRowInputWrapper>
              </>
            </FormRow>
            <FormRow>
              <>
                <FormRowLabel htmlFor="linkedin">LinkedIn</FormRowLabel>
                <FormRowInputWrapper>
                  <FormikInput
                    name="linkedin"
                    maxLength={280}
                    placeholder="https://www.linkedin.com/in/your-profile/"
                    data-testid="linkedin"
                  />
                </FormRowInputWrapper>
              </>
            </FormRow>
            <FormRow>
              <>
                <FormRowLabel htmlFor="education">Education</FormRowLabel>
                <FormRowInputWrapper>
                  <FormikTextArea
                    name="education"
                    maxLength={280}
                    note="Please list your official degrees that are relevant for your practice"
                    placeholder="BSc Nutrion, Oxford university; MSc Biochemistry Cambridge university; etc."
                    autoHeight
                    minHeight="40px"
                    maxHeight="60px"
                    rows={1}
                    data-testid="education"
                  />
                </FormRowInputWrapper>
              </>
            </FormRow>
            <FormRow>
              <>
                <FormRowLabel htmlFor="qualifications">Qualifications</FormRowLabel>
                <FormRowInputWrapper>
                  <FormikTextArea
                    name="qualifications"
                    maxLength={280}
                    note="Please list any certification or accreditations that are relevant for your practice"
                    placeholder="MD, RD, ANutr, Certified Health Coach, IFMCP, CFMP, ISSA Fitness Coach, CPT, etc."
                    autoHeight
                    minHeight="40px"
                    maxHeight="60px"
                    rows={1}
                    data-testid="qualifications"
                  />
                </FormRowInputWrapper>
              </>
            </FormRow>
            <Separator />
            <SubmitButtonWrapper>
              <Button
                variant="success"
                type="submit"
                disabled={isSubmitting}
                isLoading={isSubmitting}
              >
                Save
              </Button>
            </SubmitButtonWrapper>
          </div>
        </Form>
      )}
    </Formik>
  );
};
