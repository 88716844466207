import React from 'react';

import { model } from '~/model/Client/Goals';
import { useQueryClient } from 'react-query';
import { useClient } from '~/components/pages/client/context';
import { clientGoalsQueryKey } from '~/model/Client/Goals/hooks';
import { toast } from 'react-toastify';
import { Modal as Component, Props as ComponentProps } from '../Modal';
import { FormFields, initialValues, toApiAddRequest } from '../config';

interface Props extends Omit<ComponentProps, 'onSubmit' | 'initialValues' | 'clientId'> {}

export const AddModal = ({ ...otherProps }: Props) => {
  const queryClient = useQueryClient();
  const { clientId, client } = useClient();
  const modelValues: FormFields = {
    goal: '',
    description: '',
    topic: '',
    dimension: undefined!,
    actions: 0,
    week: client.journey_progress,
    habits: [],
  };

  return (
    <Component
      onSubmit={async (values) => {
        try {
          const data = toApiAddRequest(values);
          await model(clientId).add(data);
          await queryClient.invalidateQueries(clientGoalsQueryKey(clientId));
          toast.success('New Goal has been created');
        } catch (err: unknown) {
          toast.error('Something went wrong while adding the goal, please try again');
        }
      }}
      initialValues={initialValues(modelValues)}
      title="Add goal"
      clientId={clientId}
      {...otherProps}
    />
  );
};
