import * as React from 'react';
import { SVGProps } from 'react';
import { Size } from '../types';
interface Props extends SVGProps<SVGSVGElement> {
  size?: Size;
}
const SvgCoinStack = (props: Props) => {
  const getSize = () => {
    if (props.size) {
      switch (props.size) {
        case 'small':
          return 20;

        case 'medium':
          return 24;

        case 'large':
          return 32;

        default:
          return null;
      }
    }
  };

  return (
    <svg
      width={getSize() || 32}
      height={getSize() || 32}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      {...props}
    >
      <path
        d="M10.6 13.733c-5.067 0-9.067 4.134-9.067 9.067 0 5.067 4.134 9.067 9.067 9.067 4 0 7.333-2.534 8.533-6.134h1.6c2.8 0 9.334-.266 9.334-3.066V2.4c-.134-.267-.267-.533-.534-.667-1.866-1.6-8-1.6-8.8-1.6-.8 0-6.8 0-8.8 1.6-.8.534-.533.934-.533.934v11.066h-.8zM29 22.8c0 .133 0 .133 0 0 0 1.067-3.733 2-8.267 2H19.4c.133-.533.133-1.2.133-1.867V22.4h1.2c2.134 0 6.267-.133 8.267-1.467V22.8zm0-3.467s0 .134 0 0c0 1.067-3.733 2-8.267 2H19.4c-.133-.933-.533-1.866-.933-2.666h2.266c2.134 0 6.267-.134 8.267-1.467v2.133zm0-8.533c0 1.067-3.733 2-8.267 2-4.533 0-8.133-.8-8.266-1.867V8.8c2 1.2 6.133 1.467 8.266 1.467 2.134 0 6.267-.134 8.267-1.467v2zm-8.133-9.2c4.666 0 7.6.933 8 1.467-.534.533-3.334 1.466-8 1.466-4.667 0-7.6-.933-8-1.466.4-.534 3.333-1.467 8-1.467zm0 4.133c.8 0 5.866 0 8.266-1.333v2.4c0 1.067-3.733 1.867-8.266 1.867-4.534 0-8.134-.8-8.267-1.867V4.4c2.267 1.333 7.467 1.333 8.267 1.333zm0 8.667c.8 0 5.866 0 8.266-1.333v2.4c0 1.066-3.733 1.866-8.266 1.866-1.067 0-2.134 0-3.067-.133-1.333-1.6-3.067-2.8-5.2-3.333v-.934c2.267 1.334 7.467 1.467 8.267 1.467zm-2.4 8.533c0 4.4-3.6 7.867-7.867 7.867-4.4 0-7.867-3.6-7.867-7.867 0-4.4 3.6-7.866 7.867-7.866 4.4-.134 7.867 3.466 7.867 7.866z"
        fill="#6D7784"
      />
      <path
        d="M4.467 22.933c0 3.334 2.8 6.134 6.133 6.134s6.133-2.8 6.133-6.134c0-3.333-2.8-6.133-6.133-6.133s-6.133 2.667-6.133 6.133zm11.466 0c0 2.934-2.4 5.334-5.333 5.334-2.933 0-5.333-2.4-5.333-5.334 0-2.933 2.4-5.333 5.333-5.333 2.933-.133 5.333 2.4 5.333 5.333z"
        fill="#6D7784"
      />
      <path
        d="M10.775 22.856v-1.733c.534 0 .8.267 1.067.267.267 0 .4-.267.4-.534 0-.4-.8-.533-1.333-.666v-.267c0-.133-.134-.133-.267-.133-.267 0-.267.133-.267.133v.267c-.8.133-1.6.533-1.6 1.6 0 1.066.667 1.371 1.467 1.638v2c-.8-.134-.933-.705-1.2-.705-.267 0-.4.267-.4.533 0 .4.667.933 1.733.933v.267c0 .134.134.134.267.134.267 0 .267-.134.267-.134v-.267c.666 0 1.333-.533 1.333-1.6 0-1.2-.8-1.466-1.467-1.733zm-.533-.267c-.4-.133-.8-.4-.8-.8s.4-.666.8-.666v1.466zm.505 2.934V23.79c.4.133.695.4.695.933 0 .4-.161.667-.695.8z"
        fill="#6D7784"
        stroke="#6D7784"
        strokeWidth={0.25}
        strokeMiterlimit={10}
      />
    </svg>
  );
};

export default SvgCoinStack;
