import { generatePath } from 'react-router-dom';
import { Device } from '~/components/pages/client/Sections/Devices/Device/model';
import { api } from '~/services/api';

const URL = {
  GET_DEVICE: '/api/client/:clientId/device/:deviceId',
};

export const model = {
  getDevice: (clientId: string, deviceId: number) =>
    api.get<Device>(generatePath(URL.GET_DEVICE, { clientId, deviceId })),
};
