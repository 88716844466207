import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { IconButton } from '~/components/common/Button';
import { FormikDropdown, FormikInput, FormRowInputWrapper } from '~/components/common/Form';
import { Close2, Search } from '~/components/common/Icons/dist';
import { SearchFilterActions, SearchFilterWrapper } from '~/components/common/SearchAndFilter';
import { getJourneyOptions, getSubscriptionOptions } from './config';

interface FormFilterFields {
  journey: string;
  subscription: string;
}

interface FormFields {
  term: string;
  filters: FormFilterFields;
}

export interface Props {
  initialValues: FormFields;
  term: string;
  setTerm: (value) => void;
  filters: FormFilterFields;
  setFilters: (values) => void;
  isLoading: boolean;
}

export const SearchAndFilters = ({
  initialValues,
  term,
  setTerm,
  filters,
  setFilters,
  isLoading,
}: Props) => {
  const [localTerm, setLocalTerm] = useState(term);

  const onSubmit = () => {
    setTerm(localTerm);
  };

  const handleTermChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLocalTerm(event.target.value);
  };

  const handleJourneyChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setFilters({
      ...filters,
      journey: event.target.value,
    });
  };

  const handleSubscriptionChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setFilters({
      ...filters,
      subscription: event.target.value,
    });
  };

  const handleClear = (resetForm) => {
    setLocalTerm(initialValues.term);
    setTerm(initialValues.term);
    setFilters(initialValues.filters);
    resetForm(initialValues);
  };

  const handleFocus = (event: React.FocusEvent<HTMLButtonElement>) => {
    event.target.blur();
  };

  return (
    <SearchFilterWrapper>
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        {({ resetForm, submitForm }) => (
          <Form className="row align-items-center">
            <div className="row col">
              <div className="col-12 col-sm-3">
                <FormRowInputWrapper>
                  <FormikInput
                    name="term"
                    icon={<Search />}
                    value={localTerm}
                    onChange={handleTermChange}
                    placeholder="Search for a client"
                  />
                </FormRowInputWrapper>
              </div>
              <div className="col-12 col-sm-3">
                <FormRowInputWrapper>
                  <FormikDropdown
                    name="filters.journey"
                    value={filters.journey}
                    onChange={handleJourneyChange}
                    placeholder="Filter Journey"
                  >
                    <option value="">All Journeys</option>
                    {getJourneyOptions().map(({ value, title }) => (
                      <option key={value} value={value}>
                        {title}
                      </option>
                    ))}
                  </FormikDropdown>
                </FormRowInputWrapper>
              </div>
              <div className="col-12 col-sm-3">
                <FormRowInputWrapper>
                  <FormikDropdown
                    name="filters.subscription"
                    value={filters.subscription}
                    onChange={handleSubscriptionChange}
                    placeholder="Filter Subscription"
                  >
                    <option value="">All subscriptions</option>
                    {getSubscriptionOptions().map(({ value, title }) => (
                      <option key={value} value={value}>
                        {title}
                      </option>
                    ))}
                  </FormikDropdown>
                </FormRowInputWrapper>
              </div>
            </div>
            <div className="col-auto">
              <SearchFilterActions>
                <IconButton
                  icon={<Search />}
                  variant="neutral"
                  type="submit"
                  aria-label="Search"
                  onClick={submitForm}
                  disabled={isLoading}
                  isLoading={isLoading}
                  onFocus={handleFocus}
                />
                <IconButton
                  icon={<Close2 />}
                  variant="neutral"
                  type="reset"
                  aria-label="Clear"
                  onClick={() => {
                    handleClear(resetForm);
                  }}
                  disabled={isLoading}
                  isLoading={isLoading}
                  onFocus={handleFocus}
                />
              </SearchFilterActions>
            </div>
          </Form>
        )}
      </Formik>
    </SearchFilterWrapper>
  );
};
