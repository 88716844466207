import { generatePath } from 'react-router-dom';
import { api } from '~/services/api';

const URL = {
  LIST: '/api/language',
};

export interface Language {
  name: string;
}

export const model = {
  getList: () => api.get<Language[]>(generatePath(URL.LIST)),
};
