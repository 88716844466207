import { api } from '~/services/api';

const URL = {
  LIST: '/api/phenotype-dimension',
};

export interface Dimension {
  id: number;
  name: string;
}

export const model = () => ({
  getList: () => api.get<Array<Dimension>>(URL.LIST),
});
