import React from 'react';
import { Dropdown } from './Dropdown';
import { StyledDropdownContainer, Content } from './DropdownContainer.style';

interface Props {
  firstPageNumber;
  lastPageNumber;
  totalPagesCount;
  onChange: (value) => void;
  itemsPerPage: number;
}

export const DropdownContainer = ({
  onChange,
  itemsPerPage,
  firstPageNumber,
  lastPageNumber,
  totalPagesCount,
}: Props) => (
  <StyledDropdownContainer data-testid="pagination-items-per-page-selector">
    <Dropdown onChange={onChange} itemsPerPage={itemsPerPage} />
    <Content>
      Showing {firstPageNumber} - {lastPageNumber} of {totalPagesCount}
    </Content>
  </StyledDropdownContainer>
);
