import React, { PropsWithChildren } from 'react';
import { DateTime } from 'luxon';

import { Error, Loader, Modal, Props as ModalProps } from '~/components/common/Modal';
import { TestableComponentProps } from '~/model/TestableComponentProps.interface';
import { useGetSupplement } from '~/model/Client/Supplements/Supplement';
import {
  AlternativeHeading,
  Column,
  Image,
  Row,
  ScrollableContent,
} from '~/components/common/Modal/Modal.style';
import { Label } from '~/components/common/Label';
import { Supplement } from '../model';

export interface Props extends TestableComponentProps, ModalProps {
  supplementId: number;
  clientId: string;
}

const image = (supplementImage?: string) =>
  supplementImage && (
    <Column>
      <Image 
        data-testid="supplement-modal-image" 
        $url={supplementImage} 
        $backgroundSize="contain" 
        $backgroundRepeat="no-repeat" 
      />
    </Column>
  );

const description = (supplementDescription?: string) =>
  supplementDescription && (
    <Column data-testid="supplement-modal-description">
      <AlternativeHeading>Description</AlternativeHeading>
      <ScrollableContent
        $fixedHeight="190px"
        dangerouslySetInnerHTML={{ __html: supplementDescription }}
      />
    </Column>
  );

const labels = ({ servings, quantity, format }: Partial<Supplement>) => {
  const allLabels = [servings, quantity];

  return (
    allLabels.some((label) => !!label) && (
      <Row>
        {quantity && format && (
          <Column $width="auto">
            <Label light variant="success">
              {quantity} {format}
            </Label>
          </Column>
        )}
        {servings && (
          <Column $width="auto">
            <Label light variant="warning">
              {servings} Servings
            </Label>
          </Column>
        )}
      </Row>
    )
  );
};

const row = (heading: string, testId: string, content?: string) =>
  content && (
    <Row>
      <Column $width="100%" data-testid={`supplement-modal-${testId}`}>
        <AlternativeHeading>{heading}</AlternativeHeading>
        <Column $width="100%" dangerouslySetInnerHTML={{ __html: content }} />
      </Column>
    </Row>
  );

export const SupplementModal = ({
  clientId,
  supplementId,
  onClose,
  testId,
}: PropsWithChildren<Props>) => {
  const { data, isLoading, isError } = useGetSupplement(clientId, supplementId);

  const defaultProps = {
    onClose,
    testId,
  };

  if (isLoading) {
    return <Loader {...defaultProps} />;
  }

  if (isError || !data) {
    return <Error {...defaultProps} />;
  }

  const { name, createdAt, brand } = data;

  return (
    <Modal
      title={`${name}, ${brand}`}
      subtitle={createdAt && `Added ${DateTime.fromISO(createdAt).toFormat('dd/MM/yyyy')}`}
      {...defaultProps}
    >
      <Row $maxHeight="230px" $marginBottom="20px">
        {image(data.image)}
        {description(data.description)}
      </Row>
      {labels(data)}
      {row('Nutrition per serving', 'nutrients', data.nutrients)}
      {row('Indications (MeSH)', 'indications', data.indications)}
      {row('Instructions', 'instructions', data.instructions)}
      {row('Warnings', 'warnings', data.warnings)}
      {row('Allergens', 'allergens', data.allergens)}
      {row('Ingredients', 'ingredients', data.ingredients)}
    </Modal>
  );
};
