import styled from '@emotion/styled';
import clsx from 'clsx';
import React from 'react';

export const Image = styled.div<{ $imageUrl: string; $size: number }>`
  width: ${({ $size }) => $size}px;
  height: ${({ $size }) => $size}px;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(${({ $imageUrl }) => $imageUrl});
  background-position: center;
  border-radius: 6px;
`;

export interface Props {
  imageUrl: string;
  size?: number;
  testId?: string;
}

export const ProfileImage = ({ imageUrl, size = 114, testId }: Props) => (
  <div className="flex-shrink-0 mr-7 mt-lg-0 mt-3">
    <div className={clsx('symbol symbol-50 symbol-lg-114')} data-testid={testId}>
      <Image $imageUrl={imageUrl} $size={size} />
    </div>
  </div>
);
