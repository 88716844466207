import React, { ButtonHTMLAttributes } from 'react';
import clsx from 'clsx';
import { TestableComponentProps } from '~/model/TestableComponentProps.interface';
import { ButtonIcon, ButtonSpinner, StyledButton, StyledLink } from './Button.style';

export type Variant = 'primary' | 'success' | 'danger' | 'neutral' | 'empty' | 'ori';
export type Size = 'extraSmall' | 'large' | 'medium' | 'small' | 'extraLarge';

export interface Props
  extends ButtonHTMLAttributes<HTMLButtonElement | HTMLAnchorElement>,
  TestableComponentProps {
  variant?: Variant;
  size?: Size;
  isLoading?: boolean;
  shade?: 'dark' | 'light';
  icon?: React.ReactNode;
  iconPosition?: 'left' | 'right';
  href?: string;
  target?: '_blank';
  isBlock?: boolean;
}

const renderIcon = (
  isLoading: boolean,
  icon?: React.ReactNode,
  iconPosition: 'left' | 'right' = 'left',
) => {
  if (isLoading) {
    return (
      <ButtonSpinner
        data-testid="spinner"
        as="span"
        animation="border"
        size="sm"
        role="status"
        aria-hidden="true"
      />
    );
  }

  if (!icon) {
    return null;
  }

  return <ButtonIcon $iconPosition={iconPosition}>{icon}</ButtonIcon>;
};

export const Button = ({
  children,
  isLoading = false,
  variant = 'primary',
  size = 'medium',
  className,
  icon,
  iconPosition = 'left',
  testId,
  href,
  shade,
  isBlock,
  ...otherProps
}: Props) => {
  const props = {
    'data-testid': testId,
    $size: size,
    $iconLeft: Boolean(icon && iconPosition === 'left'),
    $iconRight: Boolean(icon && iconPosition === 'right'),
    $isBlock: isBlock,
    className: clsx(`btn btn-${variant}`, shade ? `btn-${shade}-${variant}` : '', className),
    ...otherProps,
  };

  const componentChildren = (
    <>
      {iconPosition === 'left' && renderIcon(isLoading, icon, iconPosition)}
      {children}
      {iconPosition === 'right' && renderIcon(isLoading, icon, iconPosition)}
    </>
  );

  return (
    <>
      {href && (
        <StyledLink href={href} {...props}>
          {componentChildren}
        </StyledLink>
      )}
      {!href && <StyledButton {...props}>{componentChildren}</StyledButton>}
    </>
  );
};
