import React, { PropsWithChildren, useState } from 'react';

import { Error, Loader, Modal, Props as ModalProps } from '~/components/common/Modal';
import { TestableComponentProps } from '~/model/TestableComponentProps.interface';
import { AlternativeHeading, Column, Row } from '~/components/common/Modal/Modal.style';
import { useGetTestKit } from '~/model/Client/TestKits/TestKit';
import { useClient } from '~/components/pages/client/context';
// eslint-disable-next-line import/no-cycle
import { PhenotypeModal } from '~/components/pages/client/Sections/TestResults/PhenotypeModal/container';
import { LinkButton } from '~/components/common/Button/Button.style';
import { ApiFile } from '~/model/File/apiFile';

export interface Props extends TestableComponentProps, ModalProps {
  testKitId: number;
}

const row = (heading: string, testId: string, content?: string) =>
  content && (
    <Row>
      <Column $width="100%" data-testid={`test-kit-modal-${testId}`}>
        <AlternativeHeading>{heading}</AlternativeHeading>
        <Column $width="100%" dangerouslySetInnerHTML={{ __html: content }} />
      </Column>
    </Row>
  );

const files = (filesList: ApiFile[]) =>
  filesList &&
  filesList.length > 0 && (
    <Row>
      <Column $width="100%" data-testid="test-kit-modal-files">
        <AlternativeHeading>Files</AlternativeHeading>
        <Column $width="100%">
          {filesList.map((file) => (
            <Row key={file.name} $marginBottom="0">
              <a href={file.url} target="_blank" rel="noreferrer">
                {file.name}
              </a>
            </Row>
          ))}
        </Column>
      </Column>
    </Row>
  );

const phenotypes = (phenotypesList: any[], openPhenotypeModal: (phenotypeId: number) => void) =>
  phenotypesList &&
  phenotypesList.length > 0 && (
    <Row>
      <Column $width="100%" data-testid="test-kit-modal-biomarkers">
        <AlternativeHeading>Biomarkers included</AlternativeHeading>
        <Column $width="100%">
          <div>
            {phenotypesList.map((phenotype, index) => (
              <span key={phenotype.id}>
                {index > 0 && ', '}
                <LinkButton onClick={() => openPhenotypeModal(phenotype.id)}>
                  {phenotype.name}
                </LinkButton>
              </span>
            ))}
          </div>
        </Column>
      </Column>
    </Row>
  );

export const TestKitModal = ({ testKitId, onClose, testId }: PropsWithChildren<Props>) => {
  const [showPhenotypeModal, setShowPhenotypeModal] = useState(false);
  const [phenotypeId, setPhenotypeId] = useState<number | null>(null);

  const { clientId } = useClient();
  const { data, isLoading, isError } = useGetTestKit(clientId, testKitId);

  const openPhenotypeModal = (id) => {
    setPhenotypeId(id);
    setShowPhenotypeModal(true);
  };

  const defaultProps = {
    onClose,
    testId,
    width: '772px',
  };

  if (isLoading) {
    return <Loader {...defaultProps} />;
  }

  if (isError || !data) {
    return <Error {...defaultProps} />;
  }

  const { name } = data;

  if (showPhenotypeModal && phenotypeId) {
    return (
      <PhenotypeModal
        phenotypeId={phenotypeId}
        width="772px"
        onClose={() => setShowPhenotypeModal(false)}
        testId="phenotype-modal"
      />
    );
  }

  return (
    <Modal title={name} withHeaderSeparator {...defaultProps}>
      {row('Description', 'description', data.description)}
      {row('Key indications', 'indications', data.indications)}
      <Row $marginBottom="0">
        {row('Sample', 'sample', data.samples.join(', '))}
        {row('Price', 'price', data.formattedPrice)}
        {row('Estimated wait time', 'wait-time', data.estimatedWaitTime)}
      </Row>
      {row('Sample collection overview', 'instructions', data.instructions)}
      {files(data.files)}
      {phenotypes(data.biomarkers, openPhenotypeModal)}
    </Modal>
  );
};
