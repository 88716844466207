import React, { useLayoutEffect, useMemo, useState } from 'react';
import { Card, CardBody, CardHeader } from '~/components/common/Card';
import { EmptyState } from '~/components/common/EmptyState';
import { Layout } from '~/components/common/Layout';
import { Loader } from '~/components/common/Loader';
import { useClientList } from '~/model/Client';
import { useSubheader } from '~/vendor/_metronic/layout';
import { usePagination } from '~/components/common/Pagination/hooks/usePagination';
import { ClientsEmptyState } from './ClientsEmptyState';
import { Pagination } from '../../common/Pagination/Pagination';
import { InviteClientButton } from './InviteClientButton';
import { InviteClientModal } from './InviteClientModal';
import { SearchAndFilters } from './SearchAndFilters';
import { initialValues } from './SearchAndFilters/config';
import { Table } from './Table';
import { useSearchFilters } from './hooks';
import { CoachProvider } from '../profile/CoachProvider';

export const Clients = () => {
  const subheader = useSubheader();
  const values = initialValues();

  const [showInviteClientModal, setShowInviteClientModal] = useState(false);
  const { currentPage, itemsPerPage, changeCurrentPage, changeItemsPerPage } = usePagination({
    currentPage: 1,
    itemsPerPage: 10,
  });
  const { term, filters, changeTerm, changeFilters } = useSearchFilters({
    term: values.term,
    filters: values.filters,
  });

  const handleTermChange = (changedTerm: string) => {
    changeCurrentPage(1);
    changeTerm(changedTerm);
  };

  const handleFiltersChange = (changedFilters) => {
    changeCurrentPage(1);
    changeFilters(changedFilters);
  };

  const { isLoading, isError, data: clientList } = useClientList(
    currentPage,
    itemsPerPage,
    term,
    filters,
  );

  useLayoutEffect(() => {
    subheader.setTitle('Clients');
  });

  const handleClick = () => setShowInviteClientModal(true);
  const isLoadingOrError = useMemo(() => isLoading || isError, [isLoading, isError]);
  const filteredOrSearched = useMemo(
    () => term !== '' || filters.journey !== '' || filters.subscription !== '',
    [term, filters],
  );

  return (
    <Layout testId="clients-wrapper">
      <Card>
        <CardHeader
          headerClassName="border-0 py-10"
          className="flex-column align-items-start"
          title={
            <div>
              <h3 className="card-label">My Clients</h3>
              <div className="text-muted mt-2 font-size-lg font-weight-normal">
                {clientList?.total_clients !== undefined
                  ? `${clientList.total_clients} in total`
                  : 'Loading...'}
              </div>
            </div>
          }
          toolbar={
            <div>
              <InviteClientButton onClick={handleClick} />
            </div>
          }
        />
        {clientList?.clients && clientList?.total_clients > 0 && (
          <SearchAndFilters
            initialValues={values}
            term={term}
            setTerm={handleTermChange}
            filters={filters}
            setFilters={handleFiltersChange}
            isLoading={isLoading}
          />
        )}
        <CardBody>
          {isLoading && <Loader wrapperHeight="240px" center />}
          {!isLoading && (isError || !clientList) && <div>Could not load the client list</div>}
          {!isLoadingOrError && clientList?.total_clients === 0 && (
            <CoachProvider>
              <ClientsEmptyState />
            </CoachProvider>
          )}
          {!isLoadingOrError &&
            filteredOrSearched &&
            clientList?.clients &&
            clientList.clients.length === 0 && (
              <EmptyState
                title="No clients found"
                description="Try searching for something else..."
              />
            )}
          {clientList?.clients && clientList.clients.length > 0 && (
            <Table clients={clientList.clients} />
          )}
          {!isLoading && clientList?.paginationData && (
            <Pagination
              paginationData={clientList?.paginationData}
              onPageChange={changeCurrentPage}
              onItemsPerPageChange={changeItemsPerPage}
            />
          )}
        </CardBody>
      </Card>
      {showInviteClientModal && (
        <InviteClientModal onClose={() => setShowInviteClientModal(false)} />
      )}
    </Layout>
  );
};
