import * as React from 'react';
import { SVGProps } from 'react';
import { Size } from '../types';
interface Props extends SVGProps<SVGSVGElement> {
  size?: Size;
}
const SvgBucket = (props: Props) => {
  const getSize = () => {
    if (props.size) {
      switch (props.size) {
        case 'small':
          return 20;

        case 'medium':
          return 24;

        case 'large':
          return 32;

        default:
          return null;
      }
    }
  };

  return (
    <svg
      width={getSize() || 24}
      height={getSize() || 25}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect opacity={0.3} x={7} y={1.5} width={10} height={3} rx={1} fill="currentColor" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.363 6.5a5 5 0 00-5 5v9a2 2 0 002 2h11.274a2 2 0 002-2v-9a5 5 0 00-5-5H9.363zM9 9.5a2 2 0 00-2 2v1a2 2 0 002 2h6a2 2 0 002-2v-1a2 2 0 00-2-2H9z"
        fill="currentColor"
      />
    </svg>
  );
};

export default SvgBucket;
