import React from 'react';
import { useClient } from '~/components/pages/client/context';
import { useCoachContext } from '~/components/pages/profile';
import { useCoachProducts } from '~/model/Product';
import { Loader } from '../Loader';
import { ClientSummary as Summary } from './ClientSummary';

interface Props {
  showProfileButton?: boolean;
  showChatButton?: boolean;
  showScheduleButton?: boolean;
  showProductsDropdown?: boolean;
}

export const ClientSummary = ({ showChatButton = true, showProfileButton = false, showScheduleButton = true, showProductsDropdown = true }: Props) => {
  const { coach } = useCoachContext();
  const { client } = useClient();
  const {
    isLoading: isCoachProductsLoading,
    isError: isCoachProductsError,
    data: coachProductSections,
  } = useCoachProducts(client.customer_id);

  if (isCoachProductsLoading) {
    return <Loader wrapperHeight="305px" center />;
  }

  if (isCoachProductsError || !coachProductSections) {
    return <div>Error has occured</div>;
  }

  return (
    <Summary
      client={client}
      user={coach}
      coachProductSections={coachProductSections}
      showChatButton={showChatButton}
      showProfileButton={showProfileButton}
      showScheduleButton={showScheduleButton}
      showProductsDropdown={showProductsDropdown}
    />
  );
};
