import * as React from 'react';
import { SVGProps } from 'react';
import { Size } from '../types';
interface Props extends SVGProps<SVGSVGElement> {
  size?: Size;
}
const SvgUsers = (props: Props) => {
  const getSize = () => {
    if (props.size) {
      switch (props.size) {
        case 'small':
          return 20;

        case 'medium':
          return 24;

        case 'large':
          return 32;

        default:
          return null;
      }
    }
  };

  return (
    <svg
      width={getSize() || 32}
      height={getSize() || 32}
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      {...props}
    >
      <path d="M31.867 23.6c0-.134.133-4.667-2.667-7.2-.133-.133-.533-.133-.667 0 0 0-3.333 2.533-6.266 0-.267-.133-.534-.133-.667 0 0 0-.933.8-1.733 2.4-.134-.134-.4 0-.534.133-.133.134-3.333 2.4-6.266 0-.267-.267-.534-.267-.667-.267-.4-.8-.933-1.6-1.733-2.266-.134-.133-.534-.133-.667 0 0 0-3.333 2.533-6.267 0-.266-.133-.533-.133-.666 0-.134.133-2.8 2.533-2.934 7.2 0 .133 0 .266.134.4.133.133.266.133.4.133h8.8c-.134.534-.134 1.2-.134 1.867 0 .133 0 .267.134.4.133.133.266.133.4.133H22c.267 0 .533-.267.533-.533 0-.134 0-.8-.133-1.867h8.933c.267 0 .534-.267.534-.533zm-22.134-.667H1.2c.133-2.933 1.6-4.8 2.133-5.466 2.934 2 5.867.666 6.934 0 .533.666.933 1.333 1.2 2-.534.666-1.2 1.866-1.734 3.466zm.667 2.534c.267-2.934 1.6-4.8 2.133-5.6 2.934 2 5.867.666 6.934 0 1.6 1.733 2 4.4 2 5.6H10.4zm11.867-2.534c-.267-1.067-.8-2.4-1.6-3.466.4-.934.933-1.734 1.333-2 2.933 2 5.867.666 6.933 0 1.6 1.733 2 4.4 2 5.6l-8.666-.134z" />
      <path d="M25.2 5.467c-1.333 0-2.667.533-3.733 1.466A5.12 5.12 0 0020 10c-.933-1.2-2.4-2.133-4.133-2.133-1.334 0-2.667.533-3.734 1.466 0 .267-.133.4-.266.534-.4-2.534-2.534-4.4-5.067-4.4-1.333 0-2.667.533-3.733 1.466-.934 1.067-1.6 2.4-1.6 3.734 0 2.933 2.4 5.2 5.2 5.2 1.333 0 2.666-.534 3.733-1.467l.4-.4c.4 2.533 2.533 4.4 5.2 4.4 1.333 0 2.667-.533 3.733-1.467.8-.8 1.334-1.866 1.467-2.933.933 1.2 2.4 2.133 4.133 2.133 1.334 0 2.667-.533 3.734-1.466.8-1.334 1.466-2.667 1.466-4 0-2.934-2.4-5.2-5.333-5.2zM9.6 13.6c-.8.8-1.867 1.2-2.933 1.2-2.134 0-4-1.867-4-4.133 0-1.067.4-2.134 1.2-2.934.8-.8 1.866-1.2 2.933-1.2 2.267 0 4.133 1.867 4.133 4.134C10.8 11.733 10.4 12.8 9.6 13.6zm9.2 2.4c-.8.8-1.867 1.2-2.933 1.2-2.267 0-4.134-1.867-4.134-4.133 0-1.067.4-2.134 1.2-2.934.8-.8 1.867-1.2 2.934-1.2C18.133 8.933 20 10.8 20 13.067c0 1.2-.4 2.133-1.2 2.933zm9.333-2.4c-.8.8-1.866 1.2-2.933 1.2-2.267 0-4.133-1.867-4.133-4.133 0-1.067.4-2.134 1.2-2.934.8-.8 1.866-1.2 2.933-1.2 2.267 0 4.133 1.867 4.133 4.134 0 1.066-.4 2.133-1.2 2.933z" />
    </svg>
  );
};

export default SvgUsers;
