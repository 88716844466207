import * as Yup from 'yup';

const ERROR_REQUIRED = 'This field is required';
const ERROR_PHONE_FORMAT = 'Phone number must start with a + and contain only numbers';

const FILE_SIZE = 10485760;

const ERROR_FILE_TOO_LARGE = `Maximum allowed file size is ${Math.round(
  FILE_SIZE / 1024 / 1024,
)}MB`;

const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png'];
const ERROR_FILE_UNSUPPORTED_TYPE = `This file format is not supported`;

export const validationSchema = (countries) =>
  Yup.object({
    first_name: Yup.string().required(ERROR_REQUIRED),
    last_name: Yup.string().required(ERROR_REQUIRED),
    country: Yup.string()
      .oneOf(
        countries.map(({ iso }) => iso),
        ERROR_REQUIRED,
      )
      .required(ERROR_REQUIRED),
    // https://ome-health.atlassian.net/browse/OMH-503
    // email: Yup.string().email().required(ERROR_REQUIRED),
    phone_number: Yup.string()
      .matches(/^\+[0-9 ]+$/i, ERROR_PHONE_FORMAT)
      .required(ERROR_REQUIRED)
      .nullable(),
    profile_image_url: Yup.mixed()
      .test('fileSize', ERROR_FILE_TOO_LARGE, (value) => {
        if (!value) {
          return true;
        }
        if (!(value instanceof File)) {
          return true;
        }

        return value.size <= FILE_SIZE;
      })
      .test('fileFormat', ERROR_FILE_UNSUPPORTED_TYPE, (value) => {
        if (!value) {
          return true;
        }
        if (!(value instanceof File)) {
          return true;
        }
        return SUPPORTED_FORMATS.includes(value.type);
      }),
    address1: Yup.string().required(ERROR_REQUIRED),
    city: Yup.string().required(ERROR_REQUIRED),
    post_code: Yup.string().required(ERROR_REQUIRED).nullable(),
  });
