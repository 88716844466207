import { TestKitSearchAndFilters } from '~/model/Client/TestResults/testResults';

export const initialValues = (): TestKitSearchAndFilters => ({
  term: '',
  filters: {
    category: '',
    testKit: '',
    result: '',
  },
});
