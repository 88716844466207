import React from 'react';
import { Activity, useClientActivities } from '~/model/Client/Activities';
import { useClient } from '~/components/pages/client/context';
import { Loader } from '~/components/common/Loader';
import { TableProvider } from '~/components/common/Table/TableProvider';
import { Card, CardBody } from '~/components/common/Card';
import { Activities as Component } from './Activities';

const makeInitialState = (activities: Activity[]) => ({
  rows: activities.map((activity) => ({ id: activity.title, expanded: false })),
});

export const Activities = () => {
  const { clientId } = useClient();
  const { isError, isLoading, data } = useClientActivities(clientId);

  if (isLoading) {
    return <div className="col-6"><Loader size="medium" center /></div>;
  }

  if (isError || !data) {
    return <>Error has occured</>;
  }

  return (
    <Card>
      <CardBody>
        <TableProvider initialState={makeInitialState(data.items)}>
          <Component activities={data.items} updatedAt={data.updatedAt} />
        </TableProvider>
      </CardBody>
    </Card>
  );
};
