import React from 'react';
import {
  DoubleArrowLeft,
  DoubleArrowRight,
  ArrowLeft,
  ArrowRight,
} from '~/components/common/Icons/dist';
import { IconButton } from '~/components/common/Button';
import { PaginationData } from '~/model/PaginationData/paginationData';
import { DropdownContainer } from './Dropdown/DropdownContainer';
import { Wrapper, Button, PaginationWrapper } from './Pagination.style';

export interface Props {
  paginationData: PaginationData;
  onPageChange: (value: number) => void;
  onItemsPerPageChange?: (value: number) => void;
}

export const Pagination = ({
  paginationData: {
    last,
    first,
    next,
    current,
    previous,
    pagesInRange,
    firstItemNumber,
    lastItemNumber,
    totalCount,
    numItemsPerPage,
  },
  onPageChange,
  onItemsPerPageChange,
}: Props) => {
  if (totalCount === 0) {
    return null;
  }

  const handlePageChange = (page: number) => onPageChange(page);

  const handleNextClick = () => handlePageChange(current < last ? next : current);

  const handlePrevClick = () => handlePageChange(current > 1 ? previous : current);

  const handleItemsPerPageChange = (itemsPerPage: number) => {
    onPageChange(first);
    onItemsPerPageChange!(itemsPerPage);
  };

  return (
    <Wrapper data-testid="pagination-content">
      <PaginationWrapper>
        <IconButton
          variant="neutral"
          shade="light"
          icon={<DoubleArrowLeft />}
          onClick={() => handlePageChange(first)}
          className="p-0"
          disabled={first === current}
          aria-label="Go to first page"
        />
        <IconButton
          variant="neutral"
          shade="light"
          icon={<ArrowLeft />}
          onClick={handlePrevClick}
          className="p-0"
          disabled={first === current}
          aria-label="Go to previous page"
        />
        {pagesInRange.map((number) => (
          <Button
            $active={current === number}
            onClick={() => handlePageChange(number)}
            key={number}
            variant="empty"
            size="extraSmall"
            aria-label={`Go to page ${number}`}
          >
            <span>{number}</span>
          </Button>
        ))}
        <IconButton
          variant="neutral"
          shade="light"
          icon={<ArrowRight />}
          onClick={handleNextClick}
          className="p-0"
          disabled={last === current}
          aria-label="Go to next page"
        />
        <IconButton
          variant="neutral"
          shade="light"
          icon={<DoubleArrowRight />}
          onClick={() => handlePageChange(last)}
          className="p-0"
          disabled={last === current}
          aria-label="Go to last page"
        />
      </PaginationWrapper>
      {onItemsPerPageChange && (
        <DropdownContainer
          onChange={handleItemsPerPageChange}
          firstPageNumber={firstItemNumber}
          lastPageNumber={lastItemNumber}
          totalPagesCount={totalCount}
          itemsPerPage={numItemsPerPage}
        />
      )}
    </Wrapper>
  );
};
