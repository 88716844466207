import { generatePath } from 'react-router-dom';
import { api } from '~/services/api';

const URL = {
  GET_TEST_KIT: '/api/client/:clientId/test-kit/:testKitId',
};

export const model = {
  getTestKit: <TestKit>(clientId, testKitId) =>
    api.get<TestKit>(generatePath(URL.GET_TEST_KIT, { clientId, testKitId })),
};
