import { useQuery } from 'react-query';
import {
  GetClientQuestionAnswerResponse,
  model as clientQuestionnaireModel,
} from '../questionnaire';

export const queryKey = (clientId: string, questionId: string) => [
  'client',
  `${clientId}`,
  'questionnaire',
  'question',
  `${questionId}`,
];

export const useClientQuestion = (clientId: string, questionId: string) =>
  useQuery<GetClientQuestionAnswerResponse>(queryKey(clientId, questionId), () =>
    clientQuestionnaireModel(clientId).getQuestion(questionId),
  );
