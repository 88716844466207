interface CometChatSettingsProvider {
  getAuthKey: () => string;
  getRegion: () => string;
  getAppId: () => string;
}

export const cometChatSettingsProvider: CometChatSettingsProvider = {
  getAuthKey: () => process.env.REACT_APP_COMETCHAT_AUTH_KEY as string,
  getAppId: () => process.env.REACT_APP_COMETCHAT_APP_ID as string,
  getRegion: () => process.env.REACT_APP_COMETCHAT_REGION as string,
};
