import { generatePath } from 'react-router-dom';
import { api } from '~/services/api';

export interface CoachProduct {
  id: string;
  name: string;
}

export interface CoachProductSection {
  name: string;
  coachProducts: CoachProduct[];
}

const URL = {
  GET_COACH_PRODUCTS_WITH_SECTIONS: '/api/coach/:id/products?withSections=1',
};

export const model = {
  getCoachProducts: (id?: string) =>
    api.get<CoachProductSection[]>(generatePath(URL.GET_COACH_PRODUCTS_WITH_SECTIONS, { id })),
};
