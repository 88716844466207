import React, { useState } from 'react';
import { KeyHabit as model } from '~/model/Client/Habit';
import { StrongColumn } from '~/components/common/Table';
import { IconButton } from '~/components/common/Button';
import { Edit2 } from '~/components/common/Icons/dist';
import { Row, Column } from './KeyHabit.style';
import { Habit } from '../../../Goals/Goal/Habit';
import { ActionButtonWrapper } from '../../../Sections.style';

export interface Props {
  keyHabit: model;
  index: number;
}

export const KeyHabit = ({ keyHabit }: Props) => {
  const [habitId, setHabitId] = useState<number | undefined>();

  return (
    <>
      <Row data-testid="nutrition-main-row">
        <StrongColumn>{keyHabit.title}</StrongColumn>
        <Column>{keyHabit.goalCount}</Column>
        <Column>{keyHabit.priority}</Column>
        <td>
          <ActionButtonWrapper>
            <IconButton
              aria-label="Show"
              shade="light"
              icon={<Edit2 />}
              onClick={() => setHabitId(keyHabit.id)}
            />
          </ActionButtonWrapper>
        </td>
      </Row>
      {habitId && (
        <Habit id={`${habitId}`} testId="habit-modal" onClose={() => setHabitId(undefined)} />
      )}
    </>
  );
};
