import styled from '@emotion/styled';
import React from 'react';
import { colors } from '~/components/utils/colors';
import { Partner, getPartner } from '~/components/utils/helpers/partner';
import { TestableComponentProps } from '~/model/TestableComponentProps.interface';
import { toAbsoluteUrl } from '~/vendor/_metronic/_helpers';

const Container = styled('div')`
  display: flex;
  align-items: center;
  justify-content: end;
  color: ${colors.colorBrandGrey};
  gap: 6px;
  font-size: 10px;
`

export const PoweredByOme = ({ testId }: TestableComponentProps) => {
  const partner = getPartner();

  if (partner === Partner.OME) return null;

  return (
    <Container data-testid={testId}>
      <span>Powered by:</span>
      <img
        width={60}
        height={20}
        src={toAbsoluteUrl("/media/logos/logo_colour.png")}
        alt="Ome"
      />
    </Container>
  );
};
