import React, { LabelHTMLAttributes } from 'react';
import clsx from 'clsx';
import styled from '@emotion/styled';

import { style } from './Label.style';

interface Props extends LabelHTMLAttributes<HTMLLabelElement> {
  takesSpace?: 'required' | 'default' | 'large' | 'larger';
}

export const Label = ({
  children,
  htmlFor,
  className,
  takesSpace: _takesSpace,
  ...otherProps
}: Props) => (
  <label htmlFor={htmlFor} className={clsx(style.label, className)} {...otherProps}>
    {children}
  </label>
);

export const FormRowLabel = styled(Label) <Props>`
  flex-basis: ${(props) => {
    switch (props.takesSpace) {
      case 'default':
      default:
        return '140px';
      case 'large':
        return '200px';
      case 'larger':
        return '240px';
      case 'required':
        return 0;
    }
  }};
`;
