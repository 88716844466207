import React from 'react';
import clsx from 'clsx';
import { Label, Props as LabelProps } from './Label';

interface Props extends LabelProps {
  className?: string;
  title: string;
}

export const TitledLabel = ({ className, title, ...labelProps }: Props) => (
  <div className={clsx('d-flex flex-column', className)}>
    <span className="mb-1 text-dark">{title}</span>
    <Label {...labelProps} />
  </div>
);
