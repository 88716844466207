import { generatePath } from 'react-router-dom';
import { api } from '~/services/api';

const URL = {
  LIST: '/api/country',
};

export interface Country {
  iso: string;
  name: string;
}

export const model = {
  getList: () => api.get<Country[]>(generatePath(URL.LIST)),
};
