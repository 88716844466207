import React, { PropsWithChildren, useEffect, useState } from 'react';

import { FirebaseApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { FirebaseContext } from './FirebaseContext';

export type FirebaseProviderProps = {
  app: FirebaseApp;
};

export const FirebaseProvider = ({ app, children }: PropsWithChildren<FirebaseProviderProps>) => {
  const [token, setToken] = useState<string | undefined>();

  useEffect(() => {
    if (!('serviceWorker' in navigator)) {
      return;
    }

    const messaging = getMessaging(app);

    getToken(messaging)
      .then((currentToken) => {
        if (currentToken) {
          setToken(currentToken);
        } else {
          // Show permission request UI
          // eslint-disable-next-line no-console
          console.warn('No registration token available. Request permission to generate one.');
        }
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.warn('An error occurred while retrieving token. ', err);
      });

    // on message
    onMessage(messaging, (payload) => {
      if (!payload?.data) return;

      const notificationTitle = payload.data.title;
      const notificationOptions = { body: payload.data.alert };

      const notification = new Notification(notificationTitle, notificationOptions);

      notification.onclick = (event) => {
        event.preventDefault(); // prevent the browser from focusing the Notification's tab
        window.open('/chat');
      };
    });
  }, [app]);

  return (
    <FirebaseContext.Provider value={{ token }}>
      {children}
    </FirebaseContext.Provider>
  );
};
