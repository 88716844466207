import React from 'react';
import { Link } from 'react-router-dom';
import { helpUrl, homeUrl, loginUrl } from './config';
import { Heading, ErrorContent, Title, ErrorPageContainer } from './Error.style';

interface Props {
  title: string;
  subtitle?: string;
  bodyText?: string;
  linksText?: string;
}

export const ErrorPage = ({
  title,
  subtitle,
  bodyText,
  linksText,
}: Props) => (
  <div className="d-flex flex-column flex-root">
    <div className="container">
      <ErrorPageContainer>
        <Heading>Oops!</Heading>
        <Title dangerouslySetInnerHTML={{ __html: title }} />
        <ErrorContent>
          {subtitle && <p>{subtitle}</p>}
          <br />
          {bodyText && <p>{bodyText}</p>}
          {linksText && <p>{linksText}</p>}
          <p>
            <a href={homeUrl}>Home</a>
            <Link to={loginUrl}>Login</Link>
            <a href={helpUrl}>Help</a>
          </p>
        </ErrorContent>
      </ErrorPageContainer>
    </div>
  </div>
);

export const ErrorPageGeneric = () => (
  <ErrorPage
    title="It seems that something went wrong..."
    bodyText="Please try to refresh this page or contact us if the problem persists."
    linksText="Here are some other helpgull links:"
  />
);

export const ErrorPage404 = () => (
  <ErrorPage
    title="We can’t seem to find the<br/>page you’re looking for..."
    subtitle="Error code: 404"
    linksText="Here are some helpfull links instead:"
  />
);
