import { Form, Formik, FormikErrors, FormikHelpers } from 'formik';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FormikInput } from '~/components/common/Form';
import { StyledButton } from '~/components/common/Layout/AuthLayout/AuthLayout.style';
import { setToken } from '~/components/common/TokenProvider/utils';
import { ROUTES } from '~/components/router/Routes';
import { getPartnerButtonVairant } from '~/components/utils/helpers/partner';
import { useLogin } from '~/model/Auth/hooks';
import { isBackendValidationError, transformErrors } from '~/model/Error/transformApiErrors';
import { helpUrl } from '../../error/config';
import { ActionButtonWrapper, ContentWrapper, FormInputWrapper, FormWrapper, GetHelpWrapper, Heading, Label, LinkWrapper, RightLinkWrapper } from '../auth.style';
import { FormFields, initialValues, validationSchema } from './config';

export const Login = () => {
  const { isLoading, mutateAsync } = useLogin();
  const navigate = useNavigate();
  const partnerButtonVariant = getPartnerButtonVairant();

  const handleSubmit = async (values: FormFields, { setErrors }: FormikHelpers<FormFields>) => {
    try {
      const response = await mutateAsync(values);

      if (response && response.token) {
        setToken(response.token);
        navigate(ROUTES.DEFAULT, { replace: true });
      }
    } catch (err) {
      if (isBackendValidationError<FormFields>(err)) {
        const errors: FormikErrors<FormFields> = transformErrors<FormFields>(err);

        setErrors(errors);

        if (errors.generic) {
          toast.error(errors.generic);
        }
      }
    }
  };

  return (
    <div data-testid="login">
      <GetHelpWrapper>
        Having issues? <a href={helpUrl} target="_blank" rel="noreferrer">Get help</a>
      </GetHelpWrapper>
      <ContentWrapper>
        <Heading>Sign in</Heading>
        <LinkWrapper>
          No account? <Link to={ROUTES.REGISTER}>Sign up</Link>
        </LinkWrapper>
        <FormWrapper>
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
          >
            {({ submitForm }) => (
              <Form>
                <FormInputWrapper>
                  <Label>Enter your email address</Label>
                  <FormikInput
                    name="username"
                    placeholder="Email address"
                    visualSize="large"
                    type="email"
                  />
                </FormInputWrapper>
                <FormInputWrapper>
                  <Label>Enter your Password</Label>
                  <FormikInput
                    name="password"
                    placeholder="Password"
                    visualSize="large"
                    type="password"
                  />
                </FormInputWrapper>
                <RightLinkWrapper>
                  <Link to={ROUTES.PASSWORD_RESET}>Forgot password?</Link>
                </RightLinkWrapper>
                <ActionButtonWrapper>
                  <StyledButton
                    onClick={isLoading ? undefined : submitForm}
                    type="button"
                    size="extraLarge"
                    isLoading={isLoading}
                    isBlock
                    variant={partnerButtonVariant}
                  >
                    Sign in
                  </StyledButton>
                </ActionButtonWrapper>
              </Form>
            )}
          </Formik>
        </FormWrapper>
      </ContentWrapper>
    </div>
  );
}
