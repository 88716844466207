import styled from '@emotion/styled';
import React from 'react';
import { colors } from '~/components/utils/colors';
import { EmptyState } from '~/components/common/EmptyState';
import { generatePath, Link } from 'react-router-dom';
import { ROUTES } from '~/components/router/Routes';
import { getPartnerName, getPartnerLongName } from '~/components/utils/helpers/partner';
import { Mark } from '~/components/common/Mark';
import { useCoachContext } from '../profile/CoachProvider';

const Spacer = styled.div`
  height: 35px;
`;

const Line = styled.p`
  line-height: 27px;
  margin-bottom: 30px;
  margin-left: 30px;
  margin-right: 30px;
  color: ${colors.colorGsThree};
  text-align: left;
`;

export const ClientsEmptyState = () => {
  const { coach } = useCoachContext();
  const partnerName = getPartnerName();
  const partnerLongName = getPartnerLongName();

  return (
    <EmptyState title={`Welcome to ${partnerLongName}, ${coach.first_name}!`}>
      <div className="d-flex flex-column">
        <Spacer />
        <Line>
          All new clients must use your personal Referral code <Mark color="primary">{coach.code}</Mark> when
          signing up to the {partnerName} app downloaded from the App Store or Google Play. You can
          change this code in the{' '}
          <Link to={generatePath(ROUTES.PROFILE_TABBED, { tab: 'settings' })}>Settings</Link> tab of your
          Profile.
        </Line>
        <Line>
          <Mark color="red">Hint!</Mark> To get a sense of your client’s experience, you can{' '}
          <Mark color="primary">invite yourself to the platform as your first client:</Mark>
        </Line>
        <Line>
          1. Just hit the <Mark color="primary">Invite new</Mark> button above,
          <br />
          2. Enter your personal email address as a recipient and click <Mark color="primary">Send</Mark>,
          <br />
          3. Open the email on your mobile device and follow the instructions.
          <br />
        </Line>
        <Line>
          If you need any help or have some feedback click the <Mark color="primary">Intercom bubble</Mark> below
          and let us know!
        </Line>
        <Line>
          Yours sincerely,
          <br />
          <Mark color="red">{partnerName} team</Mark>
        </Line>
      </div>
    </EmptyState>
  );
};
