import React from 'react';
import { useQueryClient } from 'react-query';
import { useClient } from '~/components/pages/client/context';
import { Error, Loader } from '~/components/common/Modal';

import { clientGoalsQueryKey, useClientGoal } from '~/model/Client/Goals/hooks';
import { model } from '~/model/Client/Goals';

import { Modal as Component, Props as ComponentProps } from '../Modal';
import { fromApiResponse } from '../model';
import { initialValues, toApiRequest } from '../config';

interface Props extends Omit<ComponentProps, 'onSubmit' | 'initialValues' | 'clientId'> {
  goalId: string;
}

export const EditModal = ({ goalId, ...otherProps }: Props) => {
  const queryClient = useQueryClient();
  const { clientId } = useClient();
  const { isLoading, isError, data: goalResponse } = useClientGoal(clientId, goalId);
  if (isLoading) {
    return <Loader {...otherProps} />;
  }
  if (isError || !goalResponse) {
    return <Error {...otherProps} />;
  }
  const modelValues = fromApiResponse(goalResponse);

  return (
    <Component
      onSubmit={async (values) => {
        const data = toApiRequest(values);
        await model(clientId).edit(goalId, data);
        await queryClient.invalidateQueries(clientGoalsQueryKey(clientId));
      }}
      initialValues={initialValues(modelValues)}
      title="Edit goal"
      clientId={clientId}
      {...otherProps}
    />
  );
};
