import { css } from '@emotion/css';
import styled from '@emotion/styled';
import { colors } from '~/components/utils/colors';
import { TableGridSpacing, TableRowItem } from '../../ExpandableTable/ExpandableTable.style';

export const Row = styled(TableGridSpacing)`
  color: ${colors.colorGsThree};
`;

export const style = {
  meal: css({
    fontSize: '14px',
    color: colors.colorPriOne,
    fontWeight: 500,
    lineHeight: '21px',
  }),
  actions: css({
    justifySelf: 'end',
    alignSelf: 'center',
  }),
};

export const FoodLogTableRowItem = styled(TableRowItem)`
  padding-top: 0;
  margin-bottom: 0;

  &.group {
    border-top: 2px solid ${colors.colorDisabledTwo};
    margin-top: 16px;
    padding-top: 20px;
  }

  & + &:not(.group) {
    margin-bottom: 4px;
    border-top: 0;
  }

  &:first-of-type {
    margin-top: 0;
    border-top: 0;
  }

  &:last-child {
    margin-bottom: 20px;
  }
`;
