import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import { Route as CometChat } from '~/components/pages/cometChat';
import { ClientProfileRoute } from '~/components/pages/client';
import { Clients } from '~/components/pages/clients';
import { ROUTES } from './Routes';
import { Profile } from '../pages/profile';
import { Demo } from '../pages/demo/Demo';
import { Notes } from '../pages/notes';
import { ErrorPage404 } from '../pages/error';
import { Login } from '../pages/auth/Login';
import { AuthedWrapper } from '../common/AuthedWrapper';
import { AuthLayout } from '../common/Layout/AuthLayout';
import { PasswordReset } from '../pages/auth/PasswordReset';
import { PasswordResetConfirm } from '../pages/auth/PasswordResetConfirm';
import { Register } from '../pages/auth/Register/Register';
import { Marketplace } from '../pages/marketplace';

export const Router = () => (
  <Routes>
    <Route path="/" index element={<Navigate to={ROUTES.DEFAULT} replace />} />

    <Route element={<AuthedWrapper />}>
      <Route path={ROUTES.CHAT_HOME} element={<CometChat />} />
      <Route path={ROUTES.CHAT_WITH_USER} element={<CometChat />} />
      <Route path={ROUTES.PROFILE_TABBED} element={<Profile />} />
      <Route path={ROUTES.PROFILE} element={<Profile />} />
      <Route path={ROUTES.CLIENTS} element={<Clients />} />
      <Route path={ROUTES.CLIENT_TABBED} element={<ClientProfileRoute />} />
      <Route path={ROUTES.CLIENT} element={<ClientProfileRoute />} />
      <Route path={ROUTES.NOTES} element={<Notes />} />
      <Route path={ROUTES.MARKETPLACE_TABBED} element={<Marketplace />} />
      <Route path={ROUTES.MARKETPLACE} element={<Marketplace />} />
      <Route path="/demo" element={<Demo />} />
    </Route>

    <Route element={<AuthLayout />}>
      <Route path={ROUTES.LOGIN} element={<Login />} />
      <Route path={ROUTES.PASSWORD_RESET} element={<PasswordReset />} />
      <Route path={ROUTES.PASSWORD_RESET_CONFIRM} element={<PasswordResetConfirm />} />
      <Route path={ROUTES.REGISTER_STEPPED} element={<Register />} />
      <Route path={ROUTES.REGISTER} element={<Register />} />
    </Route>

    <Route path={ROUTES.ERROR404} element={<ErrorPage404 />} />
    <Route path="*" element={<Navigate to={ROUTES.ERROR404} replace />} />
  </Routes>
);
