import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { ConfirmationModal } from '~/components/common/ConfirmationModal';
import { useClient } from '~/components/pages/client/context';
import { model } from '~/model/Client/TestResults/TestReports';
import { TestReportContent } from './SendReportConfirmationModal.style';

export interface Props {
  id: number;
  reportName: string;
  onClose: () => void;
}

export const SendReportConfirmationModal = ({ id, reportName, onClose }: Props) => {
  const { clientId, client } = useClient();
  const [isLoading, setIsLoading] = useState(false);

  return (
    <ConfirmationModal
      testId="export-report-modal"
      iconVariant="primary"
      title="Are you sure?"
      closeButtonText="No, cancel"
      submitButtonText="Yes, send!"
      submitButtonVariant="primary"
      description={
        <TestReportContent>
          This will send the report <strong>{reportName}</strong> to your client <strong>{client.customer_name}</strong>.
        </TestReportContent>
      }
      isLoading={isLoading}
      onClose={onClose}
      onSubmit={async () => {
        setIsLoading(true);
        try {
          await model(clientId).sendToClient(id);
          onClose();
        } catch (error) {
          toast.error('Something went wrong... Please try again.');
        }
        setIsLoading(false);
      }}
    />
  );
};
