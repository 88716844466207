import * as React from 'react';
import { SVGProps } from 'react';
import { Size } from '../types';
interface Props extends SVGProps<SVGSVGElement> {
  size?: Size;
}
const SvgScales = (props: Props) => {
  const getSize = () => {
    if (props.size) {
      switch (props.size) {
        case 'small':
          return 20;

        case 'medium':
          return 24;

        case 'large':
          return 32;

        default:
          return null;
      }
    }
  };

  return (
    <svg
      width={getSize() || 33}
      height={getSize() || 32}
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 33 32"
      {...props}
    >
      <g clipPath="url(#scales_svg__a)" stroke="currentColor" strokeWidth={0.1}>
        <path d="M6.6 10.45H2.15V7.1c0-1.57 1.375-2.85 2.95-2.85h17.1c1.573 0 2.95 1.279 2.95 2.95v3.25H20.6c-.957 0-1.592.202-2.052.495-.459.294-.739.676-.989 1.026l-.001.002c-.25.4-.494.79-1.044 1.083-.552.294-1.417.494-2.914.494s-2.362-.2-2.914-.494c-.55-.293-.794-.683-1.044-1.082l-.001-.003a29.889 29.889 0 01-.05-.071c-.234-.329-.484-.68-.901-.954-.448-.295-1.083-.496-2.09-.496zM2.15 24.2V11.55H6.7c.646 0 1.035.1 1.311.268.277.168.447.41.647.71.301.451.657.96 1.38 1.353.723.393 1.807.669 3.562.669s2.839-.276 3.561-.669c.724-.393 1.08-.902 1.38-1.353.201-.3.37-.542.648-.71.276-.169.666-.268 1.311-.268h4.55v12.653c.098 1.668-1.276 2.947-2.85 2.947H5.1c-1.573 0-2.95-1.279-2.95-2.95zM22.2 2.95H5.1C2.772 2.95.95 4.772.95 7.1v17.1c0 2.328 1.822 4.15 4.15 4.15h17.1c2.328 0 4.15-1.822 4.15-4.15V7.1c0-2.227-1.822-4.15-4.15-4.15z" />
        <path d="M9.367 6.462l-.003.004a.559.559 0 00-.176.341.972.972 0 00.065.409h-.001l.004.007 1.9 3.6a.6.6 0 00.544.327h3.9a.6.6 0 00.545-.327l1.9-3.7c.107-.215.109-.54-.11-.758-.106-.107-1.418-1.415-4.335-1.415-1.459 0-2.517.377-3.211.756-.347.19-.603.379-.773.521a3.096 3.096 0 00-.237.222l-.012.013zm1.797.303l.287 1.246.002.005a.652.652 0 00.23.337c.098.07.209.097.317.097h.108l.008-.003c.318-.106.541-.429.432-.76l-.289-1.253c.26-.079.522-.084.791-.084V7.6c0 .166.083.328.202.448.12.12.282.202.448.202a.652.652 0 00.448-.202.652.652 0 00.202-.448V6.35c.187 0 .438.007.691.084l-.29 1.252c-.108.332.115.655.433.761l.008.003h.108c.114 0 .25-.056.355-.135.103-.077.192-.188.195-.309l.286-1.24c.246.084.423.17.596.256L15.17 9.95h-.537l-.487-1.17-.001-.002a.954.954 0 00-.225-.291.506.506 0 00-.32-.137.506.506 0 00-.32.137.954.954 0 00-.225.29l-.002.007-.389 1.166h-.634l-1.56-2.928c.182-.085.434-.17.695-.257zM29.8 25.25h1.75v1.7h-3.4v-23h3.4v1.7h-.9v1h.9v1.7h-1.8v1h1.8v1.7h-.9v1h.9v1.7h-1.8v1h1.8v1.7h-.9v1h.9v1.7h-1.8v1h1.8v1.7h-.9v1h.9v1.4h-1.8v1h.05zM32 2.95h-4.4a.451.451 0 00-.31.14.451.451 0 00-.14.31v24c0 .116.058.228.14.31.082.082.194.14.31.14H32a.451.451 0 00.31-.14.452.452 0 00.14-.31V3.411a.278.278 0 00-.065-.321.544.544 0 00-.385-.14z" />
      </g>
      <defs>
        <clipPath id="scales_svg__a">
          <path transform="translate(.8)" d="M0 0h32v32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SvgScales;
