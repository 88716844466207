import styled from '@emotion/styled';

export const ModalContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`

export const ServicesList = styled.ul`
  list-style: none;
  padding: 0;
`

export const TotalPriceWrapper = styled.p`
  display: flex;
  justify-content: flex-end;
`;