import React from 'react';
import { useClient } from '~/components/pages/client/context';
import { Loader } from '~/components/common/Loader';
import { TableProvider } from '~/components/common/Table/TableProvider';
import { Card, CardBody } from '~/components/common/Card';
import { useKeyHabits } from '~/model/Client/Habit/hooks';
import { KeyHabit } from '~/model/Client/Habit';
import { KeyHabits as Component } from './KeyHabits';

const makeInitialState = (keyHabits: KeyHabit[]) => ({
  rows: keyHabits.map((keyHabit) => ({ id: keyHabit.title, expanded: false })),
});

export const KeyHabits = () => {
  const { clientId } = useClient();
  const { isError, isLoading, data } = useKeyHabits(clientId);

  if (isLoading) {
    return <Loader size="medium" center />;
  }

  if (isError || !data) {
    return <>Error has occured</>;
  }

  const { updatedAt, items } = data;

  return (
    <TableProvider initialState={makeInitialState(items)}>
      <Card>
        <CardBody>
          <Component keyHabits={items} updatedAt={updatedAt} />
        </CardBody>
      </Card>
    </TableProvider>
  );
};
