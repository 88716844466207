import { UseMutationOptions, useMutation, useQuery } from 'react-query';
import { model as coachProfileModel, BillingDetails } from '~/model/Profile/coach';

export const useUpdateCoachBillingDetails = (
  id: string,
  mutationOptions?: UseMutationOptions<any, unknown, BillingDetails>,
) =>
  useMutation(
    (data: BillingDetails) => coachProfileModel.updateCoachBillingDetails<BillingDetails>(id, data),
    {
      ...mutationOptions,
    },
  );

export const coachBillingDetailsQueryKey = (id: string) => `coachBillingDetails--${id}`;

export const useCoachBillingDetails = (id: string) =>
  useQuery<BillingDetails>(coachBillingDetailsQueryKey(id), () =>
    coachProfileModel.getCoachBillingDetails(id),
  );
