import React from 'react';

interface Props {
  text: string;
}

export const LayoutHeader = ({ text }: Props) => {
  if (!text) {
    return null;
  }

  return (
    <div className="card-header">
      <div className="card-title">
        <h3 className="card-label">{text}</h3>
      </div>
    </div>
  );
};
