import React from 'react';
import { DateTime } from 'luxon';

import { EmptyState } from '~/components/common/EmptyState';
import { Row, Table } from '~/components/common/Table';
import { Nutrition as model } from '~/model/Client/Nutritions';
import { Button } from '~/components/common/Button';
import { generatePath, Link } from 'react-router-dom';
import { ROUTES } from '~/components/router/Routes';
import { useClient } from '~/components/pages/client/context';
import { Nutrition } from './Nutrition';
import { headerColumns } from './model';
import { Layout } from '../../Layout';

export interface Props {
  nutritions: model[];
  updatedAt?: string;
}

const rows = (nutritions): Row[] =>
  nutritions
    .map((item: model, index) => ({
      id: item.title,
      content: <Nutrition nutrition={item} index={index + 1} />,
    }))
    .filter((item) => !!item);

export const Nutritions = ({ nutritions, updatedAt }: Props) => {
  const { clientId } = useClient();
  const hasNutritions = () => nutritions && nutritions.length > 0 && updatedAt;

  let layoutProps = {};
  if (hasNutritions() && updatedAt) {
    layoutProps = {
      subtitle: `Last log ${DateTime.fromISO(updatedAt).toFormat('dd/MM/yyyy')}`,
    };
  }

  return (
    <Layout title="Nutrition" {...layoutProps}>
      <>
        {!hasNutritions() && (
          <EmptyState
            title="This Client did not log any food!"
            description="Food log nutrient summary will be visible here"
          />
        )}
        {hasNutritions() && (
          <>
            <Table rows={rows(nutritions)} headers={headerColumns} />
            <div className="mt-3 px-2 d-flex justify-content-end">
              <Link to={generatePath(ROUTES.CLIENT_TABBED, { id: clientId, tab: 'food-log' })}>
                <Button>Details</Button>
              </Link>
            </div>
          </>
        )}
      </>
    </Layout>
  );
};
