import React, { useContext } from 'react';

export interface CometChatContextType {
  unreadMessagesCount: number;
  isLoading?: boolean;
  isError?: boolean;
  updateUnreadMessagesCount: () => void;
}

export const CometChatContext = React.createContext<CometChatContextType>({
  unreadMessagesCount: 0,
  isLoading: true,
  isError: false,
  updateUnreadMessagesCount: () => {},
});

export const useCometChat = () => useContext(CometChatContext);
