import { NoteRequest } from '~/model/Notes';

export interface FormFields {
  name: string;
  description: string;
  type?: number;
  client?: number;
}

export const toApiRequest = ({ name, description, type, client }: FormFields): NoteRequest => ({
  name,
  description,
  type,
  client,
});
