import React from 'react';
import { colors } from '~/components/utils/colors';

export type MarkProps = React.PropsWithChildren<any> & {
  color?: 'default' | 'red' | 'green' | 'primary';
}

export const Mark = ({ color = 'default', children }: MarkProps) => {
  const colorMap = {
    'default': 'inherit',
    'red': colors.colorPriRedOne,
    'green': colors.colorOmeGreen,
    'primary': colors.colorPriOne,
  };

  return (
    <span style={{ color: colorMap[color] }}>
      {children}
    </span>
  );
};
