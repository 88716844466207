import * as React from 'react';
import { SVGProps } from 'react';
import { Size } from '../types';
interface Props extends SVGProps<SVGSVGElement> {
  size?: Size;
}
const SvgBusinessCard = (props: Props) => {
  const getSize = () => {
    if (props.size) {
      switch (props.size) {
        case 'small':
          return 20;

        case 'medium':
          return 24;

        case 'large':
          return 32;

        default:
          return null;
      }
    }
  };

  return (
    <svg
      width={getSize() || 32}
      height={getSize() || 32}
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      {...props}
    >
      <path d="M31 9.3h-9.5v-.6c0-1.7-1.4-3-3-3h-.1V2.4c0-.3-.3-.6-.6-.6h-3.7c-.3 0-.6.3-.6.6v3.3h-.1c-1.7 0-3 1.4-3 3v.6H.9c-.3 0-.6.3-.6.6v19.8c0 .3.3.6.6.6H31c.3 0 .6-.3.6-.6V9.9c.1-.4-.2-.6-.6-.6zM14.8 3h2.4v4.3h-2.4V3zm-3.1 5.7c0-1 .8-1.8 1.8-1.8h.1v1c0 .3.3.6.6.6h3.7c.3 0 .6-.3.6-.6v-1h.1c1 0 1.8.8 1.8 1.8v.6h-8.6v-.6h-.1zM30.4 29H1.5V10.5h28.9V29z" />
      <path d="M27.6 19.1h-8.2c-.3 0-.6.3-.6.6s.3.6.6.6h8.2c.3 0 .6-.3.6-.6s-.3-.6-.6-.6zm0-4.1h-.5c-.3 0-.6.3-.6.6s.3.6.6.6h.5c.3 0 .6-.3.6-.6s-.3-.6-.6-.6zm-2.9 0h-5.4c-.3 0-.6.3-.6.6s.3.6.6.6h5.4c.3 0 .6-.3.6-.6.1-.3-.2-.6-.6-.6zm2.9 8.1h-8.2c-.3 0-.6.3-.6.6s.3.6.6.6h8.2c.3 0 .6-.3.6-.6s-.3-.6-.6-.6zM16 13.5H4.5c-.3 0-.6.3-.6.6v11.5c0 .3.3.6.6.6H16c.3 0 .6-.3.6-.6V14.2c.1-.4-.2-.7-.6-.7zM15.4 25H5.2V14.8h10.3V25h-.1z" />
      <path d="M12.1 19.8c.3-.4.5-.9.5-1.4 0-1.3-1.1-2.3-2.3-2.3-1.2 0-2.4.9-2.4 2.2 0 .5.2 1 .5 1.4C7 20.4 6 21.6 6 23c0 .3.3.6.6.6H14c.3 0 .6-.3.6-.6 0-1.4-1-2.6-2.5-3.2zm-1.8-2.6c.6 0 1.1.5 1.1 1.1 0 .6-.5 1.1-1.1 1.1-.6 0-1.1-.5-1.1-1.1 0-.6.5-1.1 1.1-1.1zm-3 5.2c.4-1 1.6-1.7 3-1.7s2.6.7 3 1.7h-6zM16 3.5c-.3 0-.6.3-.6.6v.1c0 .3.3.6.6.6s.6-.3.6-.6v-.1c0-.3-.3-.6-.6-.6z" />
    </svg>
  );
};

export default SvgBusinessCard;
