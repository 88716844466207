import { RefObject, useEffect, useRef } from 'react';

export function useEscClick(callback): RefObject<HTMLDivElement> {
  const callbackRef = useRef<(e: KeyboardEvent) => void>();
  const innerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    callbackRef.current = callback;
  });

  useEffect(() => {
    function handleClick(this: Document, e: KeyboardEvent): void {
      if (innerRef.current && callbackRef.current && e.key === 'Escape') callbackRef.current(e);
    }

    document.addEventListener('keyup', handleClick);
    return () => document.removeEventListener('keyup', handleClick);
  }, []);

  return innerRef;
}
