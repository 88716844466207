import qs from 'qs';
import { generatePath } from 'react-router-dom';
import { BackendValidationErrorResponse } from '~/model/Error/ErrorTypes';
import { GetNotesListResponse, Note, NoteRequest } from '~/model/Notes/notes';
import { api } from '~/services/api';

const URL = {
  LIST: '/api/client/:clientId/note',
  GET: '/api/client/:clientId/note/:noteId',
  ADD: '/api/client/:clientId/note',
  EDIT: '/api/client/:clientId/note/:noteId',
  DELETE: '/api/client/:clientId/note/:noteId',
};

export const model = (clientId) => ({
  getList: (currentPage, notesPerPage) => {
    const params = qs.stringify({ page: currentPage, limit: notesPerPage }, { skipNulls: true });
    return api.get<GetNotesListResponse>(
      `${generatePath(URL.LIST, { clientId })}${params && `?${params}`}`,
    );
  },
  get: (noteId) => api.get<Note>(generatePath(URL.GET, { clientId, noteId })),
  add: (data: NoteRequest) =>
    api.post<NoteRequest, void | BackendValidationErrorResponse<NoteRequest>>(
      generatePath(URL.ADD, { clientId }),
      data,
    ),
  edit: (noteId: number, data: NoteRequest) =>
    api.put<NoteRequest, void | BackendValidationErrorResponse<NoteRequest>>(
      generatePath(URL.EDIT, { clientId, noteId }),
      data,
    ),
  delete: (noteId: number) => api.delete(generatePath(URL.DELETE, { clientId, noteId })),
});
