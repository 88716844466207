import { Form, Formik, FormikErrors, FormikHelpers } from 'formik';
import React, { useState } from 'react';
import { generatePath } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FormikInput } from '~/components/common/Form';
import { StyledButton } from '~/components/common/Layout/AuthLayout/AuthLayout.style';
import { ROUTES } from '~/components/router/Routes';
import { getPartnerButtonVairant } from '~/components/utils/helpers/partner';
import { useSendResetEmail } from '~/model/Auth/hooks';
import { isBackendValidationError, transformErrors } from '~/model/Error/transformApiErrors';
import { helpUrl } from '../../error/config';
import { ActionButtonWrapper, ContentWrapper, FormInputWrapper, FormWrapper, GetHelpWrapper, Heading, Label, LinkWrapper } from '../auth.style';
import { FormFields, initialValues, validationSchema } from './config';
import { PasswordResetSent } from './PasswordResetSent';

export const PasswordReset = () => {
  const { isLoading, mutateAsync } = useSendResetEmail();
  const [emailSent, setEmailSent] = useState(false);
  const partnerButtonVariant = getPartnerButtonVairant();

  const handleSubmit = async ({ username }: FormFields, { setErrors }: FormikHelpers<FormFields>) => {
    try {
      const url = generatePath(`${window.location.origin}${ROUTES.PASSWORD_RESET_CONFIRM}`, { token: '' });

      await mutateAsync({ username, url });

      setEmailSent(true);
    } catch (err) {
      if (isBackendValidationError<FormFields>(err)) {
        const errors: FormikErrors<FormFields> = transformErrors<FormFields>(err);

        setErrors(errors);

        if (errors.generic) {
          toast.error(errors.generic);
        }
      }
    }
  };

  if (emailSent) {
    return <PasswordResetSent />;
  }

  return (
    <div data-testid="password-reset">
      <GetHelpWrapper>
        Having issues? <a href={helpUrl} target="_blank" rel="noreferrer">Get help</a>
      </GetHelpWrapper>
      <ContentWrapper>
        <Heading style={{ whiteSpace: 'nowrap' }}>Forgot password</Heading>
        <LinkWrapper>Enter your email to reset password</LinkWrapper>
        <FormWrapper>
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
          >
            {({ submitForm }) => (
              <Form>
                <FormInputWrapper>
                  <Label>Enter your email address</Label>
                  <FormikInput
                    name="username"
                    placeholder="Email address"
                    visualSize="large"
                    type="email"
                  />
                </FormInputWrapper>
                <ActionButtonWrapper>
                  <StyledButton
                    onClick={isLoading ? undefined : submitForm}
                    type="button"
                    size="extraLarge"
                    isLoading={isLoading}
                    isBlock
                    variant={partnerButtonVariant}
                  >
                    Submit
                  </StyledButton>
                </ActionButtonWrapper>
              </Form>
            )}
          </Formik>
        </FormWrapper>
      </ContentWrapper>
    </div>
  );
}
