import { Variant } from '~/components/common/Button';
import { colors } from '../colors';

export enum Partner {
  OME = 'ome',
  HEI = 'hei',
}

export const getPartner = (): Partner => {
  const partner = process.env.REACT_APP_PARTNER ?? Partner.OME;

  return partner as Partner;
};

export const getPartnerName = () => {
  const partner = getPartner();

  switch (partner) {
    case Partner.HEI:
      return 'hei';

    default:
      return 'OME Health';
  }
};

export const getPartnerLongName = () => {
  const partner = getPartner();

  switch (partner) {
    case Partner.HEI:
      return 'hei (Health Inside)'

    default:
      return 'OME Health';
  }
};

export const getPartnerColor = () => {
  const partner = getPartner();

  switch (partner) {
    case Partner.HEI:
      return colors.colorHeiRed;

    default:
      return colors.colorWhitelabelBlue;
  }
};

export const getPartnerButtonVairant = (): Variant => {
  const partner = getPartner();

  switch (partner) {
    case Partner.HEI:
      return 'ori';

    default:
      return 'primary';
  }
};

export const getPartnerDefaultCountry = () => {
  const partner = getPartner();

  switch (partner) {
    case Partner.HEI:
      return 'SG';

    default:
      return '';
  }
};

export const getReferralPartner = () => {
  const partner = getPartner();
  let welcomePackSource = '';

  switch (partner) {
    case Partner.HEI:
      welcomePackSource = 'Imagene labs';
      break;
    default:
      welcomePackSource = 'Health Coach';
      break;
  }

  return { welcomePackSource }
};

export const getPartnerAuthSlogan = () => {
  const partner = getPartner();

  switch (partner) {
    case Partner.HEI:
      return 'your Personalised Health Coaching Platform';
    default:
      return 'the Next Generation Client Health Support & Practice Management Platform';
  }
};
