import React, { useMemo } from 'react';
import { ConfirmationModal } from '~/components/common/ConfirmationModal';
import { useClient } from '~/components/pages/client/context';
import { getJourneyTitleFromCode, journeyWeekPeriods } from '~/model/Client';

export interface Props {
  onClose: () => void;
  onSubmit: () => void;
  isLoading: boolean;
  selectedWeeksCount?: number;
}

export const StartJourneyConfirmationModal = ({ onClose, onSubmit, isLoading, selectedWeeksCount = 12 }: Props) => {
  const { client } = useClient();
  const weeksPeriod = useMemo(() => journeyWeekPeriods.find((period) => period.value === selectedWeeksCount), [selectedWeeksCount]);

  return (
    <ConfirmationModal
      testId="start-journey-modal"
      iconVariant="primary"
      title="Are you sure?"
      closeButtonText="No, cancel"
      submitButtonText="Yes, start!"
      submitButtonVariant="primary"
      description={
        <p className="text-center">
          This will start a {weeksPeriod?.title} <strong>{getJourneyTitleFromCode(client.journey_code)}</strong> Journey for your client <strong>{client.customer_name}</strong>.
        </p>
      }
      isLoading={isLoading}
      onClose={onClose}
      onSubmit={onSubmit}
    />
  );
};
