import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { Spinner } from 'react-bootstrap';
import { colors } from '~/components/utils/colors';

export const ButtonSpinner = styled(Spinner)`
  margin-right: 6px;
  place-self: center;
  line-height: 1;
`;

export const ButtonIcon = styled.span(
  {
    lineHeight: 1,
    pointerEvents: 'none',
  },
  (props: { $iconPosition: 'left' | 'right' }) => {
    if (props.$iconPosition === 'left') {
      return {
        marginRight: '6px',
      };
    }

    return {
      marginLeft: '6px',
    };
  },
);

const sizes = {
  extraSmall: css`
    padding: 0;
  `,
  small: css`
    font-size: 11px;
    line-height: 17px;
    padding: 5px 10px;
    font-weight: 500;
  `,
  medium: css`
    font-size: 13px;
    line-height: 20px;
    padding: 7px 16px;
    font-weight: 500;
  `,
  large: css`
    font-size: 14px;
    line-height: 22px;
    padding: 8px 18px;
    font-weight: 600;
  `,
  extraLarge: css`
    font-size: 16px;
    line-height: 24px;
    padding: 15px 24px;
    font-weight: 500;
    min-height: 54px;
    border-radius: 10px;
  `,
};

const iconPaddings = (iconLeft: boolean, iconRight: boolean) => {
  if (!iconLeft && !iconRight) {
    return css``;
  }

  const property = `padding-${iconLeft ? 'left' : 'right'}`;

  return {
    extraSmall: css`
      ${property}: 0
    `,
    small: css`
      ${property}: 5px
    `,
    medium: css`
      ${property}: 8px
    `,
    large: css`
      ${property}: 9px
    `,
    extraLarge: css`
      ${property}: 10px,
    `,
  };
};

interface StyledButtonProps {
  $size: 'extraSmall' | 'small' | 'medium' | 'large' | 'extraLarge';
  $iconLeft: boolean;
  $iconRight: boolean;
  $isBlock?: boolean;
}

const OriButtonStyle = {
  background: colors.colorHeiRed,
  color: colors.colorWhite,

  '&:hover': {
    background: colors.colorHeiRedDark,
  },
};

const ButtonStyle = [
  {
    display: 'inline-flex',
    alignItems: 'center',
    maxHeight: '36px',

    '&.btn-ori': OriButtonStyle,
  },
  (p: StyledButtonProps) => css`
    ${sizes[p.$size]}
    ${iconPaddings(p.$iconLeft, p.$iconRight)[p.$size]}
    ${p.$isBlock && ({
      width: '100%',
      display: 'block',
    })}
  `,
];

export const StyledButton = styled.button(...ButtonStyle);
export const StyledLink = styled.a(...ButtonStyle);

export const StyledIconButton = styled.button`
  display: flex;
  align-items: center;
  padding: 5px;
  line-height: 1;
`;

export const LinkButton = styled.button`
  color: ${colors.colorPriBlueOne};
  padding: unset;
  border: unset;
  background: unset;

  &:hover {
    color: ${colors.colorPriBlueThree};
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease,
      box-shadow 0.15s ease;
  }
`;
