import React, { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import { ModalContext } from './ModalContext';

export const ModalPortal = ({ children }) => {
  const modalRoot = document.querySelector('#modal-portal') as HTMLElement;
  const el = useRef(document.createElement('div'));
  const [openModalCount, setOpenModalCount] = React.useState(1);

  useEffect(() => {
    const { current } = el;

    document.body.classList.add('overflow-hidden');

    modalRoot.appendChild(current);
    setOpenModalCount(modalRoot.childElementCount);

    return () => {
      modalRoot.removeChild(current);
      setOpenModalCount(modalRoot.childElementCount);
      if (modalRoot.childElementCount === 0) {
        document.body.classList.remove('overflow-hidden');
      }
    };
  }, []);

  return (
    <ModalContext.Provider value={{ openModalCount }}>
      <>{createPortal(children, el.current)}</>
    </ModalContext.Provider>
  );
};
