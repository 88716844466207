import { DateTime } from 'luxon';
import React from 'react';
import { ExpandableTable } from '~/components/pages/client/Sections/ExpandableTable';
import { EmptyState } from '~/components/common/EmptyState';
import { TableRowContent } from '../ExpandableTable/ExpandableTable.style';
import { Category, headerColumns } from './model';
import { Question } from './Question/Question';

export interface Props {
  completedAt?: string;
  categories: Category[];
}

export const Questionnaire = ({ completedAt, categories }: Props) => {
  if (categories.length === 0 || !completedAt) {
    return (
      <EmptyState description="Your Client’s answers to their onboarding questionnaire will be visible here." />
    );
  }

  return (
    <>
      <ExpandableTable
        title="Questionnaire"
        subtitle={
          completedAt && `Completed ${DateTime.fromISO(completedAt).toFormat('dd/MM/yyyy')}`
        }
        headers={headerColumns}
        rows={categories.map(({ title, questions }) => ({
          title,
          expanded: false,
          content:
            questions && questions.length > 0 ? (
              <TableRowContent>
                {questions.map((question) => (
                  <Question key={question.id} question={question} />
                ))}
              </TableRowContent>
            ) : undefined,
        }))}
      />
    </>
  );
};
