import React from 'react';
import { useClientNotes } from '~/model/Client/Notes';
import { useClient } from '~/components/pages/client/context';
import { Loader } from '~/components/common/Loader';
import { TableProvider } from '~/components/common/Table/TableProvider';
import { Card, CardBody } from '~/components/common/Card';
import { Note } from '~/model/Notes';
import { usePagination } from '~/components/common/Pagination/hooks/usePagination';
import { PaginationProvider } from '~/components/common/Pagination/PaginationProvider/PaginationProvider';
import { Notes as Component } from './Notes';

const makeInitialState = (notes: Note[]) => ({
  rows: notes.map((note) => ({ id: note.id, expanded: false })),
});

export const Notes = () => {
  const { clientId } = useClient();
  const pagination = usePagination({
    currentPage: 1,
    itemsPerPage: 10,
  });
  const { isError, isLoading, data } = useClientNotes(
    clientId,
    pagination.currentPage,
    pagination.itemsPerPage,
  );

  if (isLoading) {
    return <Loader size="medium" center />;
  }

  if (isError || !data) {
    return <>Error has occured</>;
  }

  return (
    <TableProvider initialState={makeInitialState(data.items)}>
      <Card>
        <CardBody>
          <PaginationProvider pagination={pagination}>
            <Component data={data} />
          </PaginationProvider>
        </CardBody>
      </Card>
    </TableProvider>
  );
};
