import styled from '@emotion/styled';
import React from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { ConfirmationModal } from '~/components/common/ConfirmationModal';
import { ROUTES } from '~/components/router/Routes';
import { colors } from '~/components/utils/colors';

export interface Props {
  onClose: () => void;
}

const Mark = styled.span`
  color: ${colors.colorPriBlueOne};
`;

export const VerificationWarningModal = ({ onClose }: Props) => {
  const navigate = useNavigate();

  const redirectToPayoutDetails = () => {
    navigate(generatePath(ROUTES.PROFILE_TABBED, { tab: 'payout' }));
  };

  return (
    <ConfirmationModal
      testId="verification-warning-modal"
      iconVariant="warning"
      title="Missing payout details!"
      closeButtonText="No, cancel"
      submitButtonText="Yes, set up now!"
      submitButtonVariant="primary"
      description={
        <>
          <p className="text-center">
            To sell your services and receive payments to your bank account you must complete your
            Payout details.
          </p>
          <p className="text-center">
            Do you want to complete your <Mark>Payout details</Mark>?
          </p>
        </>
      }
      onClose={onClose}
      onSubmit={redirectToPayoutDetails}
    />
  );
};
