/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { InputHTMLAttributes } from 'react';
import clsx from 'clsx';
import { useField } from 'formik';

import { Label } from '~/components/common/Form/Label';
import { ErrorText } from '~/components/common/Form/Error';

import { InputField, style, TextAreaField } from './Input.style';

export type VisualSize = 'medium' | 'large';

interface Props extends InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement> {
  name: string;
  label?: string;
  wrapperClassName?: string;
  error?: string;
  note?: string | React.ReactNode;
  icon?: React.ReactNode;
  suffix?: string;
  visualSize?: VisualSize;
}

export const Input = ({
  label,
  wrapperClassName,
  className,
  error,
  note,
  icon,
  suffix,
  type,
  visualSize = 'medium',
  ...otherProps
}: Props) => {
  const renderControl = () => {
    switch (type) {
      case 'input':
      default:
        return (
          <>
            <InputField
              className={clsx(
                className,
                'form-control',
                style.input,
                { 'is-invalid': !!error },
                { 'input-with-icon': !!icon },
                { 'is-large': visualSize === 'large' }
              )}
              type={type}
              {...otherProps}
            />
            {(suffix || icon) && (
              <div className="input-addons">
                {suffix && (
                  <span className={clsx(style.inputSuffix, 'suffix-addon')}>
                    <span className="value">{otherProps.value}&nbsp;</span>
                    <span className="units">{suffix}</span>
                  </span>
                )}
                {icon && <span className={clsx(style.icon, 'icon-addon')}>{icon}</span>}
              </div>
            )}
          </>
        );
      case 'textarea':
        return (
          <>
            <TextAreaField
              className={clsx(
                className,
                'form-control',
                style.input,
                { 'is-invalid': !!error },
                { 'input-with-icon': !!icon },
                { 'is-large': visualSize === 'large' }
              )}
              rows={1}
              {...otherProps}
            />
            {icon && (
              <div className="input-addons">
                <span className={clsx(style.icon, 'icon-addon')}>{icon}</span>
              </div>
            )}
          </>
        );
    }
  };

  return (
    <div className={clsx(wrapperClassName, style.wrapper)}>
      {label && <Label>label</Label>}
      <div className={style.inputWrapper}>
        {renderControl()}
        {error && <ErrorText text={error} visualSize={visualSize} />}
        {note && <small className={clsx('text-gsThree', style.subnote, style.note, visualSize === 'large' ? style.largeNote : '')}>{note}</small>}
      </div>
    </div>
  );
};

type FormikInputProps = Omit<Props, 'error'>;
export const FormikInput = ({ name, ...props }: FormikInputProps) => {
  const [field, meta] = useField({ id: name, name, ...props });

  return (
    <Input
      id={name}
      error={meta.touched && meta.error ? meta.error : undefined}
      {...field}
      {...props}
    />
  );
};
