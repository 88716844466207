import styled from '@emotion/styled';
import { Tooltip as MainTooltip } from 'react-bootstrap';

export type TooltipSize = 'default' | 'large';

export const TooltipWrapper = styled.div`
  text-align: left;
`;

const sizeMaxWidthMap = {
  default: '200px',
  large: '390px',
};

export const StyledTooltip = styled(MainTooltip, { shouldForwardProp: (prop) => prop !== 'size' }) <{ size: TooltipSize }>`
  & .tooltip-inner {
    max-width: ${({ size }) => sizeMaxWidthMap[size]};
  }
`;
