import React from 'react';

import { Layout } from '~/components/common/Layout';
import { Tab, Tabs } from '~/components/common/Tabs';

import { Buttons } from './Buttons';
import { FormInputs } from './FormInputs';
import { FormDemo as Form } from './FormDemo';
import { ProgressBarDemo as ProgressBar } from './ProgressBarDemo';
import { LabelDemo as Labels } from './LabelDemo';
import { TabsDemo } from './Tabs';
import { Toasts } from './Toast';
import { Modals } from './Modals';
import { LoaderDemo as Loader } from './Loader';
import { FeatureToggles } from './FeatureToggles';
import { ListComponents } from './ListComponents';
import { Tooltips } from './Tooltips';
import { Icons } from './Icons';

export function Demo() {
  return (
    <Layout>
      <Tabs>
        <Tab title="Buttons">
          <Buttons />
        </Tab>
        <Tab title="Static elements">
          <ProgressBar />
          <Labels />
        </Tab>
        <Tab title="Form">
          <FormInputs />
          <Form />
        </Tab>
        <Tab title="Tabs">
          <TabsDemo />
        </Tab>
        <Tab title="Pop-ups">
          <Toasts />
          <Modals />
          <Tooltips />
        </Tab>
        <Tab title="Loader">
          <Loader />
        </Tab>
        <Tab title="Feature toggles">
          <FeatureToggles />
        </Tab>
        <Tab title="List components">
          <ListComponents />
        </Tab>
        <Tab title="Icons">
          <Icons />
        </Tab>
      </Tabs>
    </Layout>
  );
}
