import styled from '@emotion/styled';
import { colors } from '~/components/utils/colors';

export const Row = styled.tr`
  td {
    font-family: Poppins;
  }

  td:first-of-type {
    padding-left: 20px;
  }

  &:last-of-type {
    border-bottom: 2px solid ${colors.colorGsFour};
  }
`;

export const Expanded = styled(Row)`
  border-top: 0;

  td {
    border-top: 0 !important;
    font-family: Poppins;
  }
`;

export const NumberColumn = styled.td`
  font-weight: 500;
  font-size: 13px;
  line-height: 19px;

  color: ${colors.colorPriOne};
`;

export const DescriptionColumn = styled.td`
  font-size: 13px;
  font-weight: 400;

  color: ${colors.colorPriOne};
`;

export const Column = styled.td`
  font-weight: 500;
  color: ${colors.colorGsThree};
  font-size: 13px;
  line-height: 19px;
`;

export const ActionsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`;
