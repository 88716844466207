import React, { useMemo } from 'react';
import { useClient } from '~/components/pages/client/context';
import { useWidgetPeriodChart } from '~/model/Client/Progress/hooks/useWidgetPeriodChart';
import { BloodPressureTarget, WidgetId } from '~/model/Client/Progress/progress';
import { colors } from '~/components/utils/colors';
import { DateTime } from 'luxon';
import { Loader } from '~/components/common/Loader';
import { Chart, LoaderContainer } from '../Modal.style';
import { yAnnotationLine } from './Shared';

export interface BloodPressureChartProps {
  periodId: number;
}

export const BloodPressureChart = ({ periodId }: BloodPressureChartProps) => {
  const { clientId } = useClient();
  const { data, isLoading } = useWidgetPeriodChart(clientId, WidgetId.BloodPressue, periodId);

  const categories = useMemo(() => (data?.chart.values ?? []).map((value) => DateTime.fromSeconds(value.date).toFormat('dd/MM')), [data]);
  const seriesData = useMemo(() => {
    if (!data) return [];

    const systolic: (number | null)[] = [];
    const diastolic: (number | null)[] = [];
    let systolicColor = '';
    let diastolicColor = '';

    const { values } = data.chart;

    values.forEach((value) => {
      const systolicValue = value.values?.[0];
      const diastolicValue = value.values?.[1];

      if (systolicValue) {
        systolicColor = systolicValue.colour;
      }

      if (diastolicValue) {
        diastolicColor = diastolicValue.colour;
      }

      systolic.push(systolicValue?.value ?? null);
      diastolic.push(diastolicValue?.value ?? null);
    });

    return [
      { name: 'Systolic BP', data: systolic, color: systolicColor },
      { name: 'Diastolic BP', data: diastolic, color: diastolicColor },
      {
        name: 'Target', data: Array.from({ length: values.length }, () => null),
        color: colors.colorSecBlueThree
      }
    ];
  }, [data]);

  const yAnnotations = useMemo(() => {
    if (!data || !data.chart.target) return [];

    const { unit, target } = data.chart;
    const annotations: YAxisAnnotations[] = [];
    const bpTarget = target as BloodPressureTarget;

    if (bpTarget.diastolic) {
      annotations.push(yAnnotationLine(bpTarget.diastolic, `${bpTarget.diastolic} ${unit}`, colors.colorSecBlueThree));
    }
    if (bpTarget.systolic) {
      annotations.push(yAnnotationLine(bpTarget.systolic, `${bpTarget.systolic} ${unit}`, colors.colorSecBlueThree));
    }

    return annotations;
  }, [data]);

  if (isLoading) return <LoaderContainer><Loader center /></LoaderContainer>;

  return (
    <Chart
      options={{
        chart: {
          id: `widget-${WidgetId.BloodPressue}`,
          height: 325,
          dropShadow: {
            enabled: true,
            top: 2,
            left: 0,
            blur: 4,
            color: colors.colorGsThree,
          },
          toolbar: { show: false },
          animations: { enabled: false }
        },
        xaxis: {
          categories,
          axisTicks: { show: false },
          labels: {
            rotateAlways: true,
            rotate: -90,
            minHeight: 63,
            maxHeight: 63,
          },
        },
        yaxis: {
          axisBorder: { show: false },
          max: !data || !data.chart.values ? 120 : undefined,
          showAlways: true,
          labels: {
            align: 'left',
            minWidth: 80,
            maxWidth: 80,
            formatter: (value: number) => `${value} ${data?.chart.unit}`,
          },
        },
        tooltip: { enabled: false },
        dataLabels: { enabled: false },
        grid: {
          show: true,
          strokeDashArray: 6,
          xaxis: { lines: { show: false } },
          padding: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
          },
        },
        states: {
          hover: {
            filter: { type: 'none' }
          },
          active: {
            filter: { type: 'none' }
          }
        },
        stroke: {
          curve: 'smooth',
          lineCap: 'round',
        },
        annotations: {
          yaxis: yAnnotations,
        }
      }}
      height={325}
      series={seriesData}
      type="line"
    />
  );
};
