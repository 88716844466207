import styled from '@emotion/styled';
import { colors } from '~/components/utils/colors';

export const TableHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const TableWrapper = styled.div`
  padding-top: 2rem;
`;

export interface TableGridWidths {
  $widths: string[];
}

export const TableGridSpacing = styled.div((props: TableGridWidths) => ({
  padding: '0 12px 0 21px',
  display: 'grid',
  gridGap: '16px',
  alignItems: 'center',
  gridTemplateColumns: props.$widths.join(' '),
}));

export const TableHeaderTitle = styled.strong`
  display: flex;
  line-height: 3rem;
  text-transform: uppercase;
  color: ${colors.colorGsThree};
`;

export const TableHeaderButtons = styled.div`
  display: flex;
  place-self: center;

  * + * {
    margin-left: 30px;
  }
`;

export const TableRows = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TableRow = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  & + & {
    border-top: 2px solid ${colors.colorDisabledTwo};
  }
`;

export const TableRowHeader = styled.a<{ $widths: string[] }>(
  (props) => ({
    display: 'grid',
    gridGap: '16px',
    alignItems: 'center',
    gridTemplateColumns: props.$widths.join(' '),
  }),
  `
  align-items: center;
  min-height: 58px;
  padding: 0 12px 0 18px;
  background: ${colors.colorSecBlueFive};
  border-left: 3px solid ${colors.colorDisabledOne};
  color: ${colors.colorGsThree};
  font-size: 14px;
  font-weight: 500;
  transition: none;

  &:hover {
    color: ${colors.colorGsThree};
    transition: none;
  }

  &.active {
    border-left-color: ${colors.colorPriBlueOne};
    color: ${colors.colorPriBlueOne};
  }

  &.expanded {
    border-bottom: 2px solid ${colors.colorDisabledTwo};
  }
`,
);

export const TableRowContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TableRowItem = styled.div`
  font-size: 13px;
  color: ${colors.colorDisabledOne};
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-top: 18px;
  margin-bottom: 18px;
  font-weight: 500;

  & + & {
    border-top: 2px solid ${colors.colorDisabledTwo};
  }
`;

export const TableSubtitle = styled.div`
  color: ${colors.colorDisabledOne};
  font-weight: 500;
`;

export const ChevronWrapper = styled.span`
  color: ${colors.colorDisabledOne};
  margin-right: 6px;
  justify-self: end;
  &.active {
    color: ${colors.colorPriBlueOne};
  }
`;
