import { generatePath } from 'react-router-dom';
import { Color } from '~/components/common/Label';
import { api } from '~/services/api';
import { PaginationData } from '../PaginationData/paginationData';

const URL = {
  LIST: '/api/clients',
  CLIENT: '/api/client/:id',
  CHOICES_LIST: '/api/clients-choice',
  START_JOURNEY: '/api/client/:id/start-journey',
};

export enum QuestionnaireStatus {
  NOT_STARTED = 'not-started',
  STARTED = 'started',
  COMPLETED = 'completed',
}

export interface SubscriptionStatus {
  value: string;
  color: Color;
}

export interface RenewalStatus {
  value: string;
  color: Color;
}

export enum SubscriptionStatusOptions {
  ACTIVE_FREE = 'free',
  ACTIVE_PAID = 'paid',
  OVERDUE = 'overdue',
  NA = 'na',
}

export enum JourneyCode {
  EAT_HEALTHY_TO_GET_FIT = 'Health',
  BE_MORE_ENERGETIC = 'EnergyImprovement',
  IMPROVE_OVERALL_HEALTH = 'HeartHealth',
  WEIGHT_LOSS = 'WeightLoss',
}

export enum JourneyTitle {
  EAT_HEALTHY_TO_GET_FIT = 'Get fit',
  BE_MORE_ENERGETIC = 'Energy',
  IMPROVE_OVERALL_HEALTH = 'Longevity',
  WEIGHT_LOSS = 'Weight',
}

export enum JourneyFullTitle {
  EAT_HEALTHY_TO_GET_FIT = 'Eat Well To Get Fit',
  BE_MORE_ENERGETIC = 'Everyday Energy',
  IMPROVE_OVERALL_HEALTH = 'Optimal Health',
  WEIGHT_LOSS = 'Weight Loss',
}

export enum Sex {
  MALE = 'male',
  FEMALE = 'female',
  other = 'other',
}

export enum ValueDiffColor {
  GREEN = 'green',
  RED = 'red',
}

export type Variant = 'success' | 'danger';

export const getVariantFromColor = (color: ValueDiffColor): Variant => {
  switch (color) {
    default:
    case ValueDiffColor.RED:
      return 'danger';
    case ValueDiffColor.GREEN:
      return 'success';
  }
};

export const getJourneyTitleFromCode = (code: JourneyCode): JourneyTitle => {
  switch (code) {
    case JourneyCode.EAT_HEALTHY_TO_GET_FIT:
      return JourneyTitle.EAT_HEALTHY_TO_GET_FIT;
    case JourneyCode.BE_MORE_ENERGETIC:
      return JourneyTitle.BE_MORE_ENERGETIC;
    case JourneyCode.WEIGHT_LOSS:
      return JourneyTitle.WEIGHT_LOSS;
    default:
    case JourneyCode.IMPROVE_OVERALL_HEALTH:
      return JourneyTitle.IMPROVE_OVERALL_HEALTH;
  }
};

export interface KeyBiomarkerValueDiff {
  value: string;
  color: Color;
}

export interface Client {
  customer_id: string;
  customer_name: string;
  profile_image_name: string;
  welcome_pack_name?: string;
  key_biomarker_name?: string;
  key_biomarker_value?: string;
  key_biomarker_value1_diff?: KeyBiomarkerValueDiff;
  key_biomarker_value2_diff?: KeyBiomarkerValueDiff;
  last_activity?: string;
  goals_completed: number;
  goals_total: number;
  journey_progress: number;
  journey_total: number;
  journey_code: JourneyCode;
  questionnaire_status: QuestionnaireStatus;
  has_uploaded_image: boolean;
  has_comet_chat_account: boolean;
  subscription_status: SubscriptionStatus;
  is_journey_started: boolean;
  are_genetic_test_results_uploaded: boolean;
}

export interface ClientProfile extends Client {
  sex?: Sex;
  email: string;
  city: string;
  country: string;
  last_login_at?: string;
  signed_up_at: string;
  renewal_date_at: string;
  last_log_at: string;
  date_of_birth_at: string;
  renewal_status: RenewalStatus;
  bmi?: Number;
  active_questionnaire: string | null;
  total_purchases: string;
  is_journey_locked: boolean;
}

export interface ClientListResponse {
  clients: Client[];
  total_clients: number;
  paginationData: PaginationData;
}

export interface ClientsChoice {
  id: number;
  fullName: string;
  email: string;
}

export interface ClientFilterParams {
  subscription: string;
  journey: string;
}

export interface ClientSearchAndFilter {
  term: string;
  filters: ClientFilterParams;
}

export const journeyWeekPeriods = [
  { value: 2, title: '2-week', periodTitle: 'period' },
  { value: 4, title: '4-week', periodTitle: 'period' },
  { value: 12, title: '12-week', periodTitle: 'journey' },
];

export const model = {
  client: (id) => api.get<ClientProfile>(generatePath(URL.CLIENT, { id })),

  getList: (currentPage?, clientsPerPage?, term?, filters?: ClientFilterParams) => {
    const params = [
      term ? `term=${term}` : null,
      filters?.journey ? `journey=${filters?.journey}` : null,
      currentPage ? `page=${currentPage}` : null,
      clientsPerPage ? `limit=${clientsPerPage}` : null,
      filters?.subscription ? `subscription=${filters?.subscription}` : null,
    ]
      .filter((param) => param !== null)
      .join('&');

    return api.get<ClientListResponse>(`${URL.LIST}${params ? `?${params}` : ''}`);
  },
  getChoicesList: () => api.get<ClientsChoice[]>(URL.CHOICES_LIST),
  startJourney: (id, weeksCount) => api.post(generatePath(URL.START_JOURNEY, { id }), { weeksCount }),
};
