import { RowsManipulationAction, StateManipulationAction } from './actions';
import { State } from './model';

export const INITIAL_STATE = {
  rows: [],
};

export const expandAll = (state) => ({
  ...state,
  rows: state.rows.map((row) => ({
    ...row,
    expanded: true,
  })),
});

export const expandRow = (state, id) => ({
  ...state,
  rows: state.rows.map((row) => ({
    ...row,
    expanded: row.id === id ? true : row.expanded,
  })),
});

export const collapseAll = (state) => ({
  ...state,
  rows: state.rows.map((row) => ({
    ...row,
    expanded: false,
  })),
});

export const collapseRow = (state, id) => ({
  ...state,
  rows: state.rows.map((row) => ({
    ...row,
    expanded: row.id === id ? false : row.expanded,
  })),
});

export const toggleRow = (state, id) => ({
  ...state,
  rows: state.rows.map((row) => ({
    ...row,
    expanded: row.id === id ? !row.expanded : row.expanded,
  })),
});

export const replaceRows = (state, rows) => ({
  ...state,
  rows,
});

export const reducer = (
  state: State,
  action: RowsManipulationAction | StateManipulationAction,
): State => {
  switch (action.type) {
    case 'EXPAND_ALL':
      return expandAll(state);
    case 'EXPAND':
      return expandRow(state, action.payload!.id);
    case 'COLLAPSE_ALL':
      return collapseAll(state);
    case 'COLLAPSE':
      return collapseRow(state, action.payload!.id);
    case 'TOGGLE':
      return toggleRow(state, action.payload!.id);
    case 'REPLACE_ROWS':
      return replaceRows(state, action.payload!.rows);
    default:
      throw Error(`Unknown action: ${action}`);
  }
};
