import styled from '@emotion/styled';
import { breakpoints } from '~/components/utils/breakpoints/emotionHelpers';
import { colors } from '~/components/utils/colors';
import { Close as CloseIcon } from '../Icons/dist';

export const Overlay = styled.div<{ $openModalCount?: number }>`
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  z-index: ${({ $openModalCount = 0 }) => 5000 + $openModalCount * 10};
`;

export const Wrapper = styled.div<{ $openModalCount?: number }>`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${({ $openModalCount = 0 }) => 5001 + $openModalCount * 10};
  overflow-y: scroll;
`;

interface ModalWrapperProps {
  $width?: string;
}

export const ModalWrapper = styled.div<ModalWrapperProps>`
  position: absolute;
  left: 50%;
  top: 50px;
  width: ${({ $width }) => $width || '600px'};
  transform: translateX(-50%);
  background-color: ${colors.colorWhite};
  padding: 30px;
  border-radius: 12px;

  ${breakpoints.tablet`
    width: 90%;
  `}
`;

export const Close = styled(CloseIcon)`
  position: absolute;
  right: 30px;
  top: 30px;
  color: ${colors.colorDisabledOne};
`;

export interface ModalTitleProps {
  $width?: string;
}

export const Title = styled.h1<ModalTitleProps>`
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: ${colors.colorPriOne};
  margin-bottom: 5px;
  ${({ $width }) => ($width ? `width: ${$width}` : '')}
`;

export const Subtitle = styled.span`
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: ${colors.colorDisabledOne};
`;

export const HeaderWrapper = styled.div`
  padding-bottom: 30px;
`;

interface SeparatorProps {
  $marginBottom?: string;
  $marginTop?: string;
}

export const Separator = styled.div<SeparatorProps>`
  margin-top: ${({ $marginTop }) => $marginTop || '30px'};
  margin-bottom: ${({ $marginBottom }) => $marginBottom || '0'};
  margin-left: 0;
  margin-right: 0;
  height: 2px;
  background: ${colors.colorDisabledTwo};
`;

interface ImageProps {
  $url: string;
  $backgroundSize?: 'cover' | 'contain';
  $backgroundRepeat?: 'repeat' | 'no-repeat';
}

export const Image = styled.div<ImageProps>`
  border-radius: 8px;
  background-image: url('${({ $url }) => $url}');
  background-position: center center;
  width: 100%;
  height: 100%;
  background-size: ${({ $backgroundSize }) => $backgroundSize || 'cover'};
  background-repeat: ${({ $backgroundRepeat }) => $backgroundRepeat || 'repeat'};
`;

export const AlternativeHeading = styled.h2`
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: ${colors.colorPriOne};
  margin-bottom: 15px;
`;

export interface RowProps {
  $gap?: string;
  $maxHeight?: string;
  $marginBottom?: string;
  $justifyContent?: 'space-between';
}

export const Row = styled.div<RowProps>`
  display: flex;
  gap: ${({ $gap }) => $gap || '30px'};
  max-height: ${({ $maxHeight }) => $maxHeight || '100%'};
  margin-bottom: ${({ $marginBottom }) => $marginBottom || '30px'};
  justify-content: ${({ $justifyContent }) => $justifyContent || ''};
`;

interface ColumnProps {
  $width?: string;
  $direction?: 'column' | 'row';
}

export const Column = styled.div<ColumnProps>`
  width: ${({ $width }) => $width || '50%'};
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 19px;
  color: ${colors.colorGsThree};
  margin-bottom: 2px;
  display: flex;
  flex-direction: ${({ $direction }) => $direction || 'column'};
`;

interface ScrollableContentProps {
  $height?: string;
  $fixedHeight?: string;
}

export const ScrollableContent = styled.div<ScrollableContentProps>`
  max-height: ${({ $height }) => $height || '100%'};
  ${({ $fixedHeight }) => ($fixedHeight ? `height: ${$fixedHeight};` : '')}
  overflow-y: auto;
`;

export const UnorderedList = styled.ul`
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;

  li {
    margin-bottom: 5px;
  }
`;

export const OrderedList = styled.ol`
  padding-left: 0;
  margin-bottom: 0;
  margin-left: 13px;

  li {
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const ListItemPrefix = styled.span`
  margin-right: 4px;
`;
