import { api } from '~/services/api';

const URL = {
  SET_PROFILE_IMAGE: '/api/user/profile-image',
  REMOVE_PROFILE_IMAGE: '/api/user/remove-profile-image',
};

export interface SetProfileImageResponse {
  profile_image_url: string;
}

export const model = {
  setProfileImage: (attachment: File) => {
    const formData = new FormData();
    formData.append('profileImageFile', attachment);
    return api.post<null, SetProfileImageResponse>(URL.SET_PROFILE_IMAGE, null, {
      body: formData,
      headers: {},
    });
  },
  removeProfileImage: () => api.post<null, SetProfileImageResponse>(URL.REMOVE_PROFILE_IMAGE),
};
