import styled from '@emotion/styled';
import { colors } from '~/components/utils/colors';

export const ModalContent = styled.div`
  padding: 0 10px;

  & > div {
    &:last-of-type {
      margin-bottom: 0;
    }
  }
`;

export const Section = styled.div`
  margin-bottom: 58px;
`;

export const SectionHeading = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: ${colors.colorPriOne};
  margin-bottom: 30px;
`;

interface SectionContentProps {
  contentType?: 'table';
}

export const SectionContent = styled.div<SectionContentProps>`
  ${({ contentType }) => {
    if (contentType === 'table') {
      return `
        width: calc(100% + 20px);
        margin-left: -10px;

        table {
          thead {
            th {
              &:first-of-type {
                padding-left: 10px;
              }

              &:last-of-type {
                padding-right: 10px;
              }
            }
          }

          tbody {
            tr {
              &.no-border {
                td {
                  border-top: 0;
                }
              }

              &:last-of-type {
                td {
                  border-bottom: 2px solid ${colors.colorGsFour};
                }
              }
            }

            td {
              &:first-of-type {
                padding-left: 10px;
              }

              &:last-of-type {
                padding-right: 10px;
              }
            }
          }
        }
      `;
    }

    return null;
  }};
`;

export const Link = styled.a`
  font-size: 13px;
`;
