import { generatePath } from 'react-router-dom';
import { api } from '~/services/api';

export interface GetScheduleResponse {
  name: string;
  description: string;
  isVisible: boolean;
}

export interface SetScheduleRequest {
  name: string;
  description: string;
}

export interface SetScheduleResponse {
  name: string;
  description: string;
}

const URL = {
  GET: '/api/client/:clientId/schedule',
  UPDATE: '/api/client/:clientId/schedule',
  TOGGLE_VISIBILITY: '/api/client/:clientId/schedule/toggle-is-visible',
};

export const model = {
  get: (clientId: string) => api.get<GetScheduleResponse>(generatePath(URL.GET, { clientId })),
  update: (clientId: string, data: SetScheduleRequest) =>
    api.put<SetScheduleResponse>(generatePath(URL.UPDATE, { clientId }), data),
  toggleVisibility: (clientId: string) =>
    api.put<SetScheduleResponse>(generatePath(URL.TOGGLE_VISIBILITY, { clientId })),
};
