import { generatePath } from 'react-router-dom';
import { Question } from '~/components/pages/client/Sections/Questionnaire/model';
import { TestResultLabel } from '~/components/pages/client/Sections/TestResults/model';
import { api } from '~/services/api';

const URL = {
  GET: '/api/client/:clientId/goal-topic/:id',
  RECOMMENDATIONS_LIST: '/api/client/:clientId/recommendation',
};

export interface ScientificReference {
  name: string;
  url: string;
}

export interface TestResult {
  result: TestResultLabel;
  source: string;
  test: string;
  value: string;
}

export interface Topic {
  id: number;
  name: string;
  description?: string;
  scientificReferences?: ScientificReference[];
  associatedQuestionnaireAnswers: Question[];
  associatedTestResults: TestResult[];
}

export interface TopicChoice {
  id: number;
  choiceName: string;
  title: string;
}

export const model = (clientId) => ({
  get: (id) => api.get<Topic>(generatePath(URL.GET, { clientId, id })),
  getRecommendationsList: (dimensionId) => {
    if (dimensionId === 'undefined') {
      return [] as Array<TopicChoice>;
    }

    return api.get<Array<TopicChoice>>(
      generatePath(`${URL.RECOMMENDATIONS_LIST}?phenotypeDimensionId=${dimensionId}`, { clientId }),
    );
  },
});
