import { generatePath } from 'react-router-dom';
import { api } from '~/services/api';

const URL = {
  PREVIEW_ORDER: '/api/client/:clientId/supplement/preview-order',
  CONFIRM_ORDER: '/api/client/:clientId/supplement/confirm-order',
};

export interface SupplementOrderItemResource {
  supplement: number;
  quantity: number;
}

export interface SupplementOrderRequest {
  items: SupplementOrderItemResource[];
}

export interface SupplementOrderProductResource {
  id: number;
  quantity: number;
  price: number;
  currency: string;
  name: string;
  product_id?: number;
  formatted_price: string;
}

export interface SupplementOrderResponse {
  products: SupplementOrderProductResource[];
  currency: string;
  total_price: number;
  delivery_price: number;
  formatted_total_price: string;
  formatted_delivery_price: string;
  formatted_vat: string;
  client_name: string;
  formatted_coach_commissions: string;
}

export const model = {
  previewOrder: <T>(clientId, data: T) =>
    api.post<T, SupplementOrderResponse[]>(
      generatePath(URL.PREVIEW_ORDER, {
        clientId,
      }),
      data,
    ),

  confirmOrder: <T>(clientId, data: T) =>
    api.post<T, SupplementOrderResponse>(
      generatePath(URL.CONFIRM_ORDER, {
        clientId,
      }),
      data,
    ),
};
