import React, { useState } from 'react';

import { IconButton } from '~/components/common/Button';
import { Edit2, Minus, Plus2 } from '~/components/common/Icons/dist';

import { TestableComponentProps } from '~/model/TestableComponentProps.interface';
import { getVariantFromColor, Label } from '~/components/common/Label';
import { SupplementModal } from './SupplementModal';
import { SupplementRow, style } from './Supplement.style';
import { Supplement as Model, headerColumns, SupplementStatusValue } from '../model';
import { TableRowItem } from '../../ExpandableTable/ExpandableTable.style';
import { useClient } from '../../../context';
import { RowHeaderButton } from '../../Sections.style';

export interface Props extends TestableComponentProps {
  supplement: Model;
  onIncreaseClick: () => void;
  onDecreaseClick: () => void;
  inBasket: number;
}

const label = ({ color, value }) => (
  <Label inline variant={getVariantFromColor(color)} light>
    {value}
  </Label>
);

export const Supplement = ({
  supplement,
  testId,
  onIncreaseClick,
  onDecreaseClick,
  inBasket,
}: Props) => {
  const [showModal, setShowModal] = useState(false);
  const { clientId } = useClient();
  const { id, name, brand, category, servings, formattedPrice, status } = supplement;

  return (
    <TableRowItem>
      <SupplementRow $widths={headerColumns.map(({ width }) => width || '1fr')}>
        <div className={style.supplement}>
          <RowHeaderButton onClick={() => setShowModal(true)}>{name}</RowHeaderButton>
        </div>
        <div className={style.column}>{brand}</div>
        <div className={style.column}>{category}</div>
        <div className={style.column}>{servings}</div>
        <div className={style.column}>{formattedPrice}</div>
        <div className={style.column}>{label(status)}</div>
        <div data-testid="in-basket" className={style.column}>
          {inBasket || '-'}
        </div>
        <div className={style.actions}>
          <IconButton
            shade="light"
            aria-label="View Supplement"
            icon={<Edit2 />}
            onClick={() => setShowModal(true)}
          />
          <IconButton
            shade="light"
            aria-label="Increase amount of supplements in basket"
            icon={<Plus2 />}
            onClick={onIncreaseClick}
            disabled={status.value === SupplementStatusValue.OUT_OF_STOCK}
          />
          <IconButton
            shade="light"
            aria-label="Decrease amount of supplements in basket"
            icon={<Minus />}
            onClick={onDecreaseClick}
            disabled={status.value === SupplementStatusValue.OUT_OF_STOCK}
          />
        </div>
      </SupplementRow>
      {showModal && (
        <SupplementModal
          testId={`supplement-modal-${testId}`}
          onClose={() => setShowModal(false)}
          supplementId={id}
          clientId={clientId}
        >
          <div />
        </SupplementModal>
      )}
    </TableRowItem>
  );
};
