import { useMutation, UseMutationOptions } from 'react-query';
import { model as clientNoteModel } from './testResults';

export const useDeleteClientTestResults = <T>(
  clientId: string,
  customerTestResultId: number,
  mutationOptions?: UseMutationOptions<any, unknown, T>,
) =>
  useMutation(() => clientNoteModel(clientId).delete(customerTestResultId), {
    ...mutationOptions,
  });
