import React from 'react';
import { ConfirmationModal } from '~/components/common/ConfirmationModal';
import { useClient } from '~/components/pages/client/context';
import { getJourneyTitleFromCode } from '~/model/Client';

export interface Props {
  onClose: () => void;
  onSubmit: () => void;
}

export const IncompleteQuestionnaireConfirmationModal = ({ onClose, onSubmit }: Props) => {
  const { client } = useClient();

  return (
    <ConfirmationModal
      testId="start-journey-modal"
      iconVariant="danger"
      title="Missing questionnaire!"
      closeButtonText="No, cancel"
      submitButtonText="Yes, chat!"
      submitButtonVariant="primary"
      description={
        <p className="text-center">
          Your client <strong>{client.customer_name}</strong> needs to complete the onboarding questionnaire to start the <strong>{getJourneyTitleFromCode(client.journey_code)}</strong> Journey.
          <br /><br />
          Would you like to invite <strong>{client.customer_name}</strong> to complete the questionnaire?
        </p>
      }
      onClose={onClose}
      onSubmit={onSubmit}
    />
  );
};
