import React, { useState } from 'react';

import { Label, Props as LabelProps } from '~/components/common/Label';
import { IconButton } from '~/components/common/Button';
import { Edit2, Stop } from '~/components/common/Icons/dist';
import { useChangeMealStatus } from '~/model/Client/Meals/Meal';
import { updateMealOfMealsListQueryData } from '~/model/Client/Meals/Meal/meal';
import { queryClient } from '~/services/api';
import { clientsQueryKey } from '~/model/Client/Meals/useClientMeals';
import { useClient } from '~/components/pages/client/context';

import { TestableComponentProps } from '~/model/TestableComponentProps.interface';
import { MealRow, style } from './Meal.style';
import { MealModal } from './MealModal';
import { Meal as Model, MealStatus, headerColumns } from '../model';
import { TableRowItem } from '../../ExpandableTable/ExpandableTable.style';
import { RowHeaderButton } from '../../Sections.style';

export interface Props extends TestableComponentProps {
  meal: Model;
}

const renderStatus = (status: MealStatus) => {
  const labelProps: LabelProps = {
    light: true,
    inline: true,
  };

  switch (status) {
    case MealStatus.Skipped:
      labelProps.variant = 'danger';
      break;
    case MealStatus.Visible:
      labelProps.variant = 'success';
      break;
    case MealStatus.NotVisible:
      labelProps.variant = 'warning';
      break;
    default:
      labelProps.variant = 'warning';
  }
  return <Label {...labelProps}>{status}</Label>;
};

export const Meal = ({ meal, testId = '' }: Props) => {
  const { clientId } = useClient();
  const { id, meal: mealName, name, score, status } = meal;

  const [showModal, setShowModal] = useState(false);

  const mutation = useChangeMealStatus(clientId, id, status, {
    onError: (_error, _variables, context) =>
      updateMealOfMealsListQueryData(clientId, { ...meal, status: context?.oldStatus }),
    onSettled: () => {
      const queryKey = clientsQueryKey(clientId);
      queryClient.invalidateQueries(queryKey);
    },
  });

  return (
    <TableRowItem>
      <MealRow $widths={headerColumns.map(({ width }) => width || '1fr')}>
        <div className={style.meal}>{mealName}</div>
        <strong className={style.name}>
          <RowHeaderButton onClick={() => setShowModal(true)}>{name}</RowHeaderButton>
        </strong>
        <div>{score}</div>
        <div>{renderStatus(status)}</div>
        <div className={style.actions}>
          <IconButton
            shade="light"
            aria-label="Skip/unskip meal"
            icon={<Stop />}
            onClick={() => mutation.mutate({})}
            disabled={mutation.isLoading}
          />
          <IconButton
            shade="light"
            aria-label="View meal"
            icon={<Edit2 />}
            onClick={() => setShowModal(true)}
          />
        </div>
      </MealRow>
      {showModal && (
        <MealModal
          testId={`meal-modal-${testId}`}
          onClose={() => setShowModal(false)}
          mealId={id}
          clientId={clientId}
        >
          <div />
        </MealModal>
      )}
    </TableRowItem>
  );
};
