import { useMutation, UseMutationOptions } from 'react-query';
import { model } from '../deviceOrder';

export const useConfirmOrder = <T>(
  clientId: string,
  mutationOptions?: UseMutationOptions<any, unknown, T>,
) =>
  useMutation((data: T) => model.confirmOrder(clientId, data), {
    ...mutationOptions,
  });
