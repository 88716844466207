import { UseMutationOptions } from 'react-query';
import { MealStatus } from '~/components/pages/client/Sections/Meals/model';
import { MealStatusChangeMutationContext } from '../meal';
import { useSkipMeal } from './useSkipMeal';
import { useUnskipMeal } from './useUnskipMeal';

export const useChangeMealStatus = <T>(
  clientId: string,
  mealId: number,
  status: MealStatus,
  mutationOptions?: UseMutationOptions<any, unknown, T, MealStatusChangeMutationContext>,
) => {
  switch (status) {
    case MealStatus.Skipped:
      return useUnskipMeal(clientId, mealId, mutationOptions);
    case MealStatus.Visible:
    case MealStatus.NotVisible:
      return useSkipMeal(clientId, mealId, mutationOptions);
    default:
      throw new Error(`Status ${status} cannot be handled.`);
  }
};
