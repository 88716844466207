import { generatePath } from 'react-router-dom';
import { api } from '~/services/api';

const URL = {
  GET_SUPPLEMENT: '/api/client/:clientId/supplement/:supplementId',
};

export const model = {
  getSupplement: <Supplement>(clientId, supplementId) =>
    api.get<Supplement>(generatePath(URL.GET_SUPPLEMENT, { clientId, supplementId })),
};
