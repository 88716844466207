import * as React from 'react';

type Props = React.SVGProps<SVGSVGElement>;

export default ({ stroke, fill }: Props) => (
  <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="40" cy="40" r="38.5" stroke={stroke} strokeWidth="3" />
    <path
      d="M44.176 17L43.152 47.912H35.6L34.512 17H44.176ZM39.568 62.888C37.9467 62.888 36.6027 62.3973 35.536 61.416C34.512 60.392 34 59.1333 34 57.64C34 56.1467 34.512 54.9093 35.536 53.928C36.6027 52.904 37.9467 52.392 39.568 52.392C41.1467 52.392 42.448 52.904 43.472 53.928C44.496 54.9093 45.008 56.1467 45.008 57.64C45.008 59.1333 44.496 60.392 43.472 61.416C42.448 62.3973 41.1467 62.888 39.568 62.888Z"
      fill={fill}
    />
  </svg>
);
