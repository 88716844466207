import styled from '@emotion/styled';
import { colors } from '~/components/utils/colors';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 30px;
  color: ${colors.colorGsThree};
  padding: 0 15px;
`;

export const InfoIconWrapper = styled.div`
  display: flex;
  align-items: center;
  flex: 2;
`;

export const Icon = styled.div`
  margin-right: 17px;
`;

export const Text = styled.div`
  display: flex;
  flex-direction: column;
`;
export const TextTop = styled.span`
  line-height: 18px;
  font-size: 12px;
  color: ${colors.colorGsThree};
`;

export const TextBottom = styled.span`
  font-weight: 600;
  color: ${colors.colorPriOne};
  line-height: 24px;
  font-size: 16px;
  height: 24px;
  overflow: hidden;
`;
