import * as React from 'react';
import { SVGProps } from 'react';
import { Size } from '../types';
interface Props extends SVGProps<SVGSVGElement> {
  size?: Size;
}
const SvgNotes = (props: Props) => {
  const getSize = () => {
    if (props.size) {
      switch (props.size) {
        case 'small':
          return 20;

        case 'medium':
          return 24;

        case 'large':
          return 32;

        default:
          return null;
      }
    }
  };

  return (
    <svg
      width={getSize() || 24}
      height={getSize() || 24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        opacity={0.3}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 3H6a2 2 0 00-2 2v16a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2h-2v.5A1.5 1.5 0 0114.5 5h-5A1.5 1.5 0 018 3.5V3z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 2h1.5a.5.5 0 01.5.5v1a.5.5 0 01-.5.5h-5a.5.5 0 01-.5-.5v-1a.5.5 0 01.5-.5H11a1 1 0 112 0z"
        fill="currentColor"
      />
      <rect opacity={0.3} x={10} y={9} width={7} height={2} rx={1} fill="currentColor" />
      <rect opacity={0.3} x={7} y={9} width={2} height={2} rx={1} fill="currentColor" />
      <rect opacity={0.3} x={7} y={13} width={2} height={2} rx={1} fill="currentColor" />
      <rect opacity={0.3} x={10} y={13} width={7} height={2} rx={1} fill="currentColor" />
      <rect opacity={0.3} x={7} y={17} width={2} height={2} rx={1} fill="currentColor" />
      <rect opacity={0.3} x={10} y={17} width={7} height={2} rx={1} fill="currentColor" />
    </svg>
  );
};

export default SvgNotes;
