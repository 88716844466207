import styled from '@emotion/styled';
import { colors } from '~/components/utils/colors';

export const Remarks = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;

  span {
    display: block;
    font-weight: normal;
    font-size: 11px;
    line-height: 16px;
    color: ${colors.colorGsThree};
  }

  .asterisks {
    span {
      width: 20px;
      text-align: right;
      margin-right: 10px;
    }
  }
`;
