import { CoachProfile, SetProfileImageResponse } from '~/model/Profile';

export interface ProfileImage {
  profile_image_url?: File;
  profile_image_url_remove?: string;
}

export type FormFields = Partial<CoachProfile | ProfileImage>;
export type FormFieldsResponse = [void, SetProfileImageResponse];

export const initialValues = ({
  first_name,
  last_name,
  phone_number,
  address1,
  address2,
  city,
  post_code,
  country,
  profile_image_url,
}: CoachProfile): FormFields => ({
  first_name,
  last_name,
  phone_number,
  address1,
  address2,
  city,
  post_code,
  country,
  profile_image_url,
  profile_image_url_remove: undefined,
});
