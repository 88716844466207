import styled from '@emotion/styled';
import { breakpoints } from '~/components/utils/breakpoints/emotionHelpers';
import { colors } from '~/components/utils/colors';

export const ContentWrapper = styled.div`
  padding: 58px 44px 79px 44px;

  ${breakpoints.tablet`
    padding: 0 32px;
  `}
`;

export const FormWrapper = styled.div`
  padding-top: 73px;

  p {
    font-size: 15px;
    line-height: 22px;
  }
`;

export const NoteParagraph = styled.p`
  color: ${colors.colorWhitelabelGreen};
`;

export const ActionButtonWrapper = styled.div`
  padding-top: 44px;
`;

export const FormInputWrapper = styled.div`
  padding: 24px 0 12px 0;
`;

export const Heading = styled.h1`
  font-weight: 500;
  font-size: 55px;
  line-height: 82px;
  margin: 0;

  ${breakpoints.mobile`
    font-size: 40px;
  `}
`;

export const LinkWrapper = styled.p`
  font-size: 13px;
  line-height: 20px;
  color: ${colors.colorWhiteLabelGreyTwo};
  margin: 0;
`;

export const GetHelpWrapper = styled(LinkWrapper)`
  padding: 30px 30px 0 0;
  text-align: right;
`;

export const RightLinkWrapper = styled(LinkWrapper)`
  text-align: right;
`;

export const Label = styled.label`
  font-size: 16px;
  line-height: 24px;
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  align-items: start;
  gap: 8px;
`;

export const CheckboxLabel = styled.label`
  font-size: 14px;
  line-height: 21px;
  color: ${colors.colorWhiteLabelGreyThree};
  padding: 10px 0 0 0;
`;
