import { api } from '~/services/api';

const URL = {
  GET: '/api/coach/invite-client/email-template',
  SEND: '/api/coach/invite-client/send-email',
  RESET: '/api/coach/invite-client/email-template?default=1',
  SAVE: '/api/coach/invite-client/email-template',
};

export interface SendEmailRequest {
  to: string;
  subject: string;
  content: string;
}

export interface EmailTemplate {
  subject: string;
  content: string;
}

export interface SendEmailResponse {}

export interface InviteClientResponse {
  from: string;
  to: string | null;
  subject: string;
  content: string;
}

export const model = {
  get: () => api.get<InviteClientResponse>(URL.GET),
  send: (data: SendEmailRequest) => api.post<SendEmailResponse>(URL.SEND, data),
  reset: () => api.get<EmailTemplate>(URL.RESET),
  saveEmailTemplate: (data: EmailTemplate) => api.post<EmailTemplate>(URL.SAVE, data),
};
