import React from 'react';
import { Global, css } from '@emotion/react';
import { css as cssCss } from '@emotion/css';
import { colors } from '~/components/utils/colors';

export const style = {
  wrapper: cssCss({
    display: 'flex',
    alignItems: 'center',
  }),
  inputWrapper: cssCss({
    width: '100%',
  }),
  input: cssCss({
    height: 'auto',
  }),
  subnote: cssCss({
    padding: '2px 2px 2px 16px',
  }),
  error: cssCss({
    display: 'block',
    fontSize: '10px',
    lineHeight: '15px',
  }),
  note: cssCss({
    display: 'block',
    fontSize: '11px',
    lineHeight: '16px',
  }),
};

export const RichTextStyleWrapper = () => (
  <Global
    styles={css`
      :root {
        // OME
        --ck-custom-border: ${colors.colorDisabledTwo};
        --ck-color-shadow-drop: 0;
        --ck-color-shadow-inner: 0;
        --ck-inner-shadow: 0;
        --ck-drop-shadow: 0;
        --ck-drop-shadow-active: 0;
        --ck-focus-outer-shadow-geometry: 0;
        --ck-focus-outer-shadow: 0;
        --ck-focus-disabled-outer-shadow: 0;
        --ck-focus-error-outer-shadow: 0;
        --ck-color-base-active: ${colors.colorSecBlueFive};

        /* Overrides the border radius setting in the theme. */
        --ck-border-radius: 6px;

        /* Overrides the default font size in the theme. */
        --ck-font-size-base: 14px;

        /* Helper variables to avoid duplication in the colors. */
        --ck-custom-background: ${colors.colorDisabledTwo};
        --ck-custom-foreground: ${colors.colorSecBlueFive};
        --ck-custom-white: hsl(0, 0%, 100%);

        /* -- Overrides generic colors. ------------------------------------------------------------- */

        --ck-color-base-foreground: var(--ck-custom-background);
        --ck-color-text: ${colors.colorPriOne};

        /* -- Overrides the default .ck-button class colors. ---------------------------------------- */

        --ck-color-button-default-background: var(--ck-custom-background);
        --ck-color-button-default-hover-background: ${colors.colorSecBlueFive};
        --ck-color-button-default-active-background: ${colors.colorSecBlueFive};
        --ck-color-button-default-active-shadow: hsl(270, 2%, 23%);
        --ck-color-button-default-disabled-background: var(--ck-custom-background);

        --ck-color-button-on-background: var(--ck-custom-foreground);
        --ck-color-button-on-hover-background: ${colors.colorSecBlueFive};
        --ck-color-button-on-active-background: ${colors.colorSecBlueFive};
        --ck-color-button-on-active-shadow: hsl(240, 3%, 19%);
        --ck-color-button-on-disabled-background: var(--ck-custom-foreground);

        --ck-color-button-action-background: hsl(168, 76%, 42%);
        --ck-color-button-action-hover-background: ${colors.colorSecBlueFive};
        --ck-color-button-action-active-background: ${colors.colorSecBlueFive};
        --ck-color-button-action-active-shadow: hsl(168, 75%, 34%);
        --ck-color-button-action-disabled-background: hsl(168, 76%, 42%);
        --ck-color-button-action-text: var(--ck-custom-white);

        --ck-color-button-save: hsl(120, 100%, 46%);
        --ck-color-button-cancel: hsl(15, 100%, 56%);

        /* -- Overrides the default .ck-dropdown class colors. -------------------------------------- */

        --ck-color-dropdown-panel-background: var(--ck-custom-background);
        --ck-color-dropdown-panel-border: var(--ck-custom-foreground);

        /* -- Overrides the default .ck-splitbutton class colors. ----------------------------------- */

        --ck-color-split-button-hover-background: var(--ck-color-button-default-hover-background);
        --ck-color-split-button-hover-border: var(--ck-custom-foreground);

        /* -- Overrides the default .ck-input class colors. ----------------------------------------- */

        --ck-color-input-background: var(--ck-custom-background);
        --ck-color-input-border: hsl(257, 3%, 43%);
        --ck-color-input-text: hsl(0, 0%, 98%);
        --ck-color-input-disabled-background: hsl(255, 4%, 21%);
        --ck-color-input-disabled-border: hsl(250, 3%, 38%);
        --ck-color-input-disabled-text: hsl(0, 0%, 78%);

        /* -- Overrides the default .ck-labeled-field-view class colors. ---------------------------- */

        --ck-color-labeled-field-label-background: var(--ck-custom-background);

        /* -- Overrides the default .ck-list class colors. ------------------------------------------ */

        --ck-color-list-background: var(--ck-custom-background);
        --ck-color-list-button-hover-background: var(--ck-color-base-foreground);
        --ck-color-list-button-on-background: var(--ck-color-base-active);
        --ck-color-list-button-on-background-focus: var(--ck-color-base-active-focus);
        --ck-color-list-button-on-text: var(--ck-color-base-background);

        /* -- Overrides the default .ck-balloon-panel class colors. --------------------------------- */

        --ck-color-panel-background: var(--ck-custom-background);
        --ck-color-panel-border: var(--ck-custom-border);

        /* -- Overrides the default .ck-toolbar class colors. --------------------------------------- */

        --ck-color-toolbar-background: var(--ck-custom-background);
        --ck-color-toolbar-border: var(--ck-custom-border);

        /* -- Overrides the default .ck-tooltip class colors. --------------------------------------- */

        --ck-color-tooltip-background: hsl(252, 7%, 14%);
        --ck-color-tooltip-text: hsl(0, 0%, 93%);

        /* -- Overrides the default colors used by the ckeditor5-image package. --------------------- */

        --ck-color-image-caption-background: hsl(0, 0%, 97%);
        --ck-color-image-caption-text: hsl(0, 0%, 20%);

        /* -- Overrides the default colors used by the ckeditor5-widget package. -------------------- */

        --ck-color-widget-blurred-border: hsl(0, 0%, 87%);
        --ck-color-widget-hover-border: hsl(43, 100%, 68%);
        --ck-color-widget-editable-focus-background: var(--ck-custom-white);

        /* -- Overrides the default colors used by the ckeditor5-link package. ---------------------- */

        --ck-color-link-default: hsl(190, 100%, 75%);

        --ck-z-default: 10001;
        --ck-spacing-standard: 0;
        --ck-focus-ring: 1px solid transparent;
      }
    `}
  />
);
