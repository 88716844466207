import { generatePath } from 'react-router-dom';
import { Color } from '~/components/common/Label';
import { api } from '~/services/api';

export enum ActivityType {
  SingleValue = 'SingleValue',
  DoubleValue = 'DoubleValue',
}

export interface ActivityValue {
  value: string;
  color: Color;
}

export interface Activity {
  type: ActivityType;
  title: string;
  firstValue?: ActivityValue;
  secondValue?: ActivityValue;
  firstValueDiff?: ActivityValue;
  secondValueDiff?: ActivityValue;
  firstValueTarget?: string;
  secondValueTarget?: string;
  widgetId: number | null;
}

export interface GetActivitiesListResponse {
  updatedAt: string;
  items: Activity[];
}

const URL = {
  LIST: '/api/client/:clientId/activity',
};

export const model = (clientId) => ({
  getList: () => api.get<GetActivitiesListResponse>(generatePath(URL.LIST, { clientId })),
});
