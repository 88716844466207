import React from 'react';
import { toast } from 'react-toastify';

import { Card, CardBody, CardHeader } from '~/components/common/Card';
import { Button } from '~/components/common/Button';

export const Toasts = () => (
  <Card>
    <CardHeader title="Toasts" />
    <CardBody>
      <Button variant="success" onClick={() => toast.success('Succcess toast')}>
        Success toast
      </Button>
      <Button variant="primary" onClick={() => toast.warning('Warning toast')}>
        Warning toast
      </Button>
      <Button variant="primary" onClick={() => toast.error('Error toast')}>
        Error toast
      </Button>
    </CardBody>
  </Card>
);
