import React from 'react';
import { Loader, Error, Props as ModalProps } from '~/components/common/Modal';
import { useHabit } from '~/model/Client/Habit/hooks';
import { Habit as Component } from './Habit';

interface Props extends Pick<ModalProps, 'testId' | 'onClose'> {
  id: string;
}

export const Habit = ({ id, testId, onClose, ...otherProps }: Props) => {
  const modalProps = {
    testId,
    onClose,
  };

  const { isError, isLoading, data } = useHabit(id);
  if (isLoading) {
    return <Loader {...modalProps} />;
  }

  if (isError || !data) {
    return <Error {...modalProps} />;
  }

  return <Component {...data} {...modalProps} {...otherProps} />;
};
