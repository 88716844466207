import { generatePath } from 'react-router-dom';
import { api } from '~/services/api';

const URL = {
  PREVIEW_ORDER: '/api/client/:clientId/test-kit/preview-order',
  CONFIRM_ORDER: '/api/client/:clientId/test-kit/confirm-order',
};

export interface TestKitOrderItemResource {
  testKit: number;
  quantity: number;
}

export interface TestKitOrderRequest {
  items: TestKitOrderItemResource[];
}

export interface TestKitOrderProductResource {
  id: number;
  quantity: number;
  price: number;
  currency: string;
  name: string;
  product_id?: number;
  formatted_price: string;
}

export interface TestKitOrderResponse {
  products: TestKitOrderProductResource[];
  currency: string;
  total_price: number;
  delivery_price: number;
  formatted_total_price: string;
  formatted_delivery_price: string;
  client_name: string;
  formatted_coach_commissions: string;
  formatted_vat: string;
}

export const model = {
  previewOrder: <T>(clientId, data: T) =>
    api.post<T, TestKitOrderResponse[]>(
      generatePath(URL.PREVIEW_ORDER, {
        clientId,
      }),
      data,
    ),

  confirmOrder: <T>(clientId, data: T) =>
    api.post<T, TestKitOrderResponse>(
      generatePath(URL.CONFIRM_ORDER, {
        clientId,
      }),
      data,
    ),
};
