import * as React from 'react';
import { SVGProps } from 'react';
import { Size } from '../types';
interface Props extends SVGProps<SVGSVGElement> {
  size?: Size;
}
const SvgUsersTwo = (props: Props) => {
  const getSize = () => {
    if (props.size) {
      switch (props.size) {
        case 'small':
          return 20;

        case 'medium':
          return 24;

        case 'large':
          return 32;

        default:
          return null;
      }
    }
  };

  return (
    <svg
      width={getSize() || 20}
      height={getSize() || 20}
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      {...props}
    >
      <path
        opacity={0.3}
        d="M15 11.667a2.5 2.5 0 110-5 2.5 2.5 0 010 5zm-7.5-2.5a3.333 3.333 0 110-6.667 3.333 3.333 0 010 6.667z"
      />
      <path d="M14.668 12.5c2.838.032 5.156 1.467 5.33 4.5.008.122 0 .5-.451.5h-3.214c0-1.876-.62-3.607-1.665-5zM0 16.834c.323-3.977 3.55-6 7.485-6 3.99 0 7.268 1.911 7.512 6 .01.163 0 .667-.626.667H.606c-.209 0-.623-.45-.605-.667z" />
    </svg>
  );
};

export default SvgUsersTwo;
