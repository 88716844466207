import React from 'react';
import styled from '@emotion/styled';
import { capital } from 'case';
import { Card, CardBody, CardHeader } from '~/components/common/Card';
import { Cart, Sex, Wallet } from '~/components/common/Icons/dist';
import { Size } from '~/components/common/Icons/types';

const Container = styled.div`
  display: flex;
  column-gap: 10px;
  margin-bottom: 16px;
`;

const sizes: Size[] = ['small', 'medium', 'large'];

export const Icons = () => (
  <Card>
    <CardHeader title="Icons" />
    <CardBody>
      <h1>Sizes</h1>
      {sizes.map((size) => (
        <Container>
          {capital(size)}
          <Wallet size={size} />
          <Sex size={size} />
          <Cart size={size} />
        </Container>
      ))}
    </CardBody>
  </Card>
);
