import React, { } from 'react';
import {
  FormikInput,
  FormRowInputWrapper,
  FormRow,
} from '~/components/common/Form';
import { FormRowLabel } from './Label';

export const BankDetailsBusiness = () => (
  <>
    <FormRow width="half">
      <>
        <FormRowLabel>Bank name</FormRowLabel>
        <FormRowInputWrapper>
          <FormikInput name="bankName" placeholder="DBS" />
        </FormRowInputWrapper>
      </>
      <>
        <FormRowLabel>Bank address</FormRowLabel>
        <FormRowInputWrapper>
          <FormikInput name="bankAddress" placeholder="12 Marina Boulevard, Singapore 018982" />
        </FormRowInputWrapper>
      </>
    </FormRow>
    <FormRow width="half">
      <>
        <FormRowLabel>BIC number (SWIFT)</FormRowLabel>
        <FormRowInputWrapper>
          <FormikInput name="swift" placeholder="DBSSSGSG" />
        </FormRowInputWrapper>
      </>
      <>
        <FormRowLabel>Branch code</FormRowLabel>
        <FormRowInputWrapper>
          <FormikInput name="branchCode" placeholder="123" />
        </FormRowInputWrapper>
      </>
    </FormRow>
    <FormRow width="half">
      <>
        <FormRowLabel>Bank account name</FormRowLabel>
        <FormRowInputWrapper>
          <FormikInput name="bankAccountName" placeholder="Your Company Limited" />
        </FormRowInputWrapper>
      </>
      <>
        <FormRowLabel>Bank code</FormRowLabel>
        <FormRowInputWrapper>
          <FormikInput name="bankCode" placeholder="7171" />
        </FormRowInputWrapper>
      </>
    </FormRow>
    <FormRow width="half">
      <>
        <FormRowLabel>Bank account number</FormRowLabel>
        <FormRowInputWrapper>
          <FormikInput name="iban" placeholder="1234567890" />
        </FormRowInputWrapper>
      </>
      <>
        <FormRowLabel>Paynow ID</FormRowLabel>
        <FormRowInputWrapper>
          <FormikInput name="paynowId" placeholder="12345678X / 89123456" />
        </FormRowInputWrapper>
      </>
    </FormRow>
  </>
);
