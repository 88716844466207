import clsx from 'clsx';
import React from 'react';
import { FormRowLabel } from '../Label';
import { FormRow } from '../Layout';
import { style } from './Error.style';

interface Props {
  text: string;
  visualSize?: 'medium' | 'large';
}

export const ErrorText = ({ text, visualSize = 'medium' }: Props) => (
  <small className={clsx('text-danger', style.subnote, style.error, visualSize === 'large' ? style.largeNote : '')}>{text}</small>
);

export const FormRowErrorLine = (props: Props) => (
  <FormRow>
    <>
      <FormRowLabel />
      <ErrorText {...props} />
    </>
  </FormRow>
);
