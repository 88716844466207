import React, { useMemo, useState } from 'react';
import { DateTime } from 'luxon';
import clsx from 'clsx';
import { css } from '@emotion/css';
import { Link, generatePath, useNavigate } from 'react-router-dom';

import { ClientProfile, JourneyCode, JourneyFullTitle, QuestionnaireStatus, journeyWeekPeriods, useClientStartJourney } from '~/model/Client';
import { CoachProduct } from '~/model/Product';

import {
  Button,
  ButtonDropdown,
  DropdownSectionItem,
  DropdownSection,
  DropdownItem,
} from '~/components/common/Button';
import { getVariantFromColor, TitledLabel, Variant } from '~/components/common/Label';

import { Envelope16, GpsMarker16, Handshake16, Rocket } from '~/components/common/Icons/dist';
import { Progress } from '~/components/common/Progress';
import { ROUTES } from '~/components/router/Routes';
import { toast } from 'react-toastify';
import { useCreatePayment } from '~/model/Payment';
import { CreatePayment } from '~/model/Payment/payment';
import { ProfileImage } from '~/components/common/ProfileImage';
import { CoachProductSection } from '~/model/Product/coachProduct';
import { useScheduleConsultation } from '~/model/Consultation';
import { CoachProfile } from '~/model/Profile';
import { Partner, getPartner } from '~/components/utils/helpers/partner';
import {
  BadgesWrapper,
  CardTitleWrapper,
  ContactDetails,
  ContactDetailsWrapper,
  FullName,
  JourneyName,
  JourneyWeeks,
  JourneyWrapper,
  Separator,
} from './ClientSummary.style';
import { InfoIcons } from './InfoIcons';
import { OfferServiceModal } from './OfferServiceModal';
import { VerificationWarningModal } from './VerificationWarningModal';
import { StartJourneyConfirmationModal } from './StartJourneyConfirmationModal';
import { IncompleteQuestionnaireConfirmationModal } from './IncompleteQuestionnaireConfirmationModal';

export interface Props {
  coachProductSections: CoachProductSection[];
  client: ClientProfile;
  user: CoachProfile;
  showProfileButton: boolean;
  showChatButton: boolean;
  showScheduleButton: boolean;
  showProductsDropdown: boolean;
}

const actions = (
  { customer_id, customer_name, has_comet_chat_account }: ClientProfile,
  { is_schedule_consultation_enabled, onboarding_status }: CoachProfile,
  coachProductSections: CoachProductSection[],
  showProfileButton: boolean,
  showChatButton: boolean,
  showScheduleButton: boolean,
  showProductsDropdown: boolean,
) => {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [modalCoachProducts, setModalCoachProducts] = useState([] as CoachProduct[]);
  const [showWarningModal, setShowWarningModal] = useState(false);
  const partner = getPartner();
  const isHei = partner === Partner.HEI;

  const paymentMutation = useCreatePayment<CreatePayment>({
    onSuccess: () => {
      toast.success('Payment successfully created');
    },
    onError: () => {
      toast.error('Oops, something went wrong');
    },
  });

  const consultationMutation = useScheduleConsultation(customer_id);

  const handlePaymentSubmit = () => {
    paymentMutation.mutate({
      products: modalCoachProducts.map((product) => product.id),
      client: customer_id,
    });
  };

  const handleConsultationSubmit = () => {
    consultationMutation.mutate(
      {},
      {
        onSuccess: () => {
          toast.success('Consultation booking link has been sent');
        },
        onError: () => {
          toast.error('Oops, something went wrong');
        },
      },
    );
  };

  if (!has_comet_chat_account) {
    return null;
  }

  return (
    <>
      {showScheduleButton && (
        <Button onClick={handleConsultationSubmit} disabled={!is_schedule_consultation_enabled}>
          Schedule consultation
        </Button>
      )}

      {showProductsDropdown && coachProductSections.length > 0 && (
        <ButtonDropdown
          className="ml-10"
          shade="light"
          testId="dropdown-button"
          buttonContent={<>Offer services</>}
          closeOnClick
          disabled={isHei}
        >
          {coachProductSections.map((coachProductSection) => (
            <DropdownSection header={coachProductSection.name} key={coachProductSection.name}>
              {coachProductSection.coachProducts.map((coachProduct) => (
                <DropdownSectionItem
                  key={`payment-product--${coachProduct.id}`}
                  testId={`payment-product--${coachProduct.id}`}
                  onClick={() => {
                    if (onboarding_status === 'COMPLETED' || isHei) {
                      setModalCoachProducts([coachProduct]);
                      setShowConfirmationModal(true);
                    } else {
                      setShowWarningModal(true);
                    }
                  }}
                >
                  <>{coachProduct.name}</>
                </DropdownSectionItem>
              ))}
            </DropdownSection>
          ))}
        </ButtonDropdown>
      )}

      {showChatButton && (
        <Link to={generatePath(ROUTES.CHAT_WITH_USER, { id: customer_id })}>
          <Button
            className="ml-10"
            shade={showProfileButton ? 'light' : 'dark'}
          >
            Chat
          </Button>
        </Link>
      )}

      {showProfileButton && (
        <Link to={generatePath(ROUTES.CLIENT, { id: customer_id })}>
          <Button className="ml-10">Profile</Button>
        </Link>
      )}

      {showConfirmationModal && (
        <OfferServiceModal
          onClose={() => setShowConfirmationModal(false)}
          onSubmit={handlePaymentSubmit}
          customerName={customer_name}
          customerId={customer_id}
          products={modalCoachProducts}
        />
      )}

      {showWarningModal && <VerificationWarningModal onClose={() => setShowWarningModal(false)} />}
    </>
  );
};

const contactDetails = ({ email, city, country, welcome_pack_name }: ClientProfile) => (
  <>
    <ContactDetails>
      <Envelope16 /> {email}
    </ContactDetails>
    <ContactDetails>
      <GpsMarker16 />
      {[city, country].filter((i) => !!i).join(', ')}
    </ContactDetails>
    {welcome_pack_name && welcome_pack_name !== 'Health Coach' && (
      <ContactDetails>
        <Handshake16 /> {welcome_pack_name}
      </ContactDetails>
    )}
  </>
);

const lastLoginLabel = (lastLogin?: string) => {
  const dateTimeLastLogin = lastLogin ? DateTime.fromISO(lastLogin) : DateTime.local();
  const dateTimeDiff = DateTime.local().diff(dateTimeLastLogin, 'day').toObject().days || 0;
  const labelText = lastLogin ? dateTimeLastLogin.toFormat('dd/MM/yyyy') : 'N/A';

  let labelVairant: Variant = 'neutral';

  if (dateTimeDiff < 7) {
    labelVairant = 'success';
  } else if (dateTimeDiff > 7 && dateTimeDiff < 14) {
    labelVairant = 'warning';
  } else if (dateTimeDiff > 14) {
    labelVairant = 'danger';
  }

  return (
    <TitledLabel
      variant={labelVairant}
      title="Last login"
      light
      className={clsx('align-items-center', css({ minWidth: '80px' }))}
    >
      {labelText}
    </TitledLabel>
  );
}

const badges = ({
  date_of_birth_at,
  last_login_at,
  signed_up_at,
  subscription_status,
  renewal_status,
}: ClientProfile) => {
  const blockWidthClassName = css({ minWidth: '80px' });
  return (
    <>
      <TitledLabel
        variant="neutral"
        light
        title="Date of birth"
        className={clsx('align-items-center', blockWidthClassName)}
      >
        {date_of_birth_at ? DateTime.fromISO(date_of_birth_at).toFormat('dd/MM/yyyy') : 'N/A'}
      </TitledLabel>
      <TitledLabel
        variant="neutral"
        light
        title="Signed up"
        className={clsx('align-items-center', blockWidthClassName)}
      >
        {DateTime.fromISO(signed_up_at).toFormat('dd/MM/yyyy')}
      </TitledLabel>
      {lastLoginLabel(last_login_at)}
      <TitledLabel
        variant={subscription_status ? getVariantFromColor(subscription_status.color) : 'neutral'}
        light
        title="Subscription"
        className={clsx('align-items-center', blockWidthClassName)}
      >
        {subscription_status?.value ?? 'N/A'}
      </TitledLabel>
      <TitledLabel
        variant={getVariantFromColor(renewal_status.color)}
        light
        title="Renewal date"
        className={clsx('align-items-center', blockWidthClassName)}
      >
        {renewal_status.value ? DateTime.fromISO(renewal_status.value).toFormat('dd/MM/yyyy') : 'N/A'}
      </TitledLabel>
    </>
  );
};

const progressBar = ({ journey_progress, journey_total, journey_code }: ClientProfile) => {
  const percentage = (100 * journey_progress) / journey_total;
  let journeyName: string;
  let variant;
  switch (journey_code) {
    case JourneyCode.WEIGHT_LOSS:
      journeyName = JourneyFullTitle.WEIGHT_LOSS;
      variant = 'success';
      break;
    case JourneyCode.BE_MORE_ENERGETIC:
      journeyName = JourneyFullTitle.BE_MORE_ENERGETIC;
      variant = 'warning';
      break;
    case JourneyCode.EAT_HEALTHY_TO_GET_FIT:
      journeyName = JourneyFullTitle.EAT_HEALTHY_TO_GET_FIT;
      variant = 'danger';
      break;
    case JourneyCode.IMPROVE_OVERALL_HEALTH:
    default:
      journeyName = JourneyFullTitle.IMPROVE_OVERALL_HEALTH;
      variant = 'primary';
      break;
  }

  return (
    <>
      <JourneyWrapper>
        <JourneyName>{journeyName}</JourneyName>
        <JourneyWeeks>
          Week{' '}
          <strong>
            {journey_progress}/{journey_total}
          </strong>
        </JourneyWeeks>
      </JourneyWrapper>
      <Progress completed={percentage} variant={variant} />
    </>
  );
};

export const ClientSummary = ({
  coachProductSections,
  client,
  user,
  showProfileButton,
  showChatButton,
  showScheduleButton,
  showProductsDropdown,
}: Props) => {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [selectedWeeksCount, setSelectedWeeksCount] = useState(journeyWeekPeriods[2]);
  const { mutateAsync: startJourney, isLoading } = useClientStartJourney(client.customer_id, selectedWeeksCount.value);
  const navigate = useNavigate();

  const onStartJourney = async () => {
    try {
      await startJourney();
      setShowConfirmationModal(false);
    } catch (error) {
      toast.error('Something went wrong, please try again');
    }
  };

  const onChatWithClient = () => {
    setShowConfirmationModal(false);
    navigate(generatePath(ROUTES.CHAT_WITH_USER, { id: client.customer_id }));
  };

  const journeyProgress = () => {
    const {
      is_journey_started,
      is_journey_locked,
      journey_code,
      questionnaire_status,
      are_genetic_test_results_uploaded
    } = client;

    if (is_journey_started && !is_journey_locked) {
      return progressBar(client);
    }

    if (journey_code === null) {
      return null;
    }

    const isQuestionnaireCompleted = questionnaire_status === QuestionnaireStatus.COMPLETED;


    if (!isQuestionnaireCompleted && !are_genetic_test_results_uploaded) {
      return (
        <Button
          icon={<Rocket />}
          iconPosition="right"
          variant="danger"
          onClick={() => setShowConfirmationModal(true)}
        >
          Start Journey
        </Button>
      );
    }

    return (
      <ButtonDropdown
        icon={<Rocket />}
        iconPosition="right"
        variant="success"
        dropdownListSize="small"
        buttonContent="Start Journey"
        closeOnClick
      >
        {journeyWeekPeriods.map((weeks) => (
          <DropdownItem
            key={`${weeks}-weeks-journey`}
            onClick={() => {
              setSelectedWeeksCount(weeks);
              setShowConfirmationModal(true);
            }}
          >
            {weeks.title} {weeks.periodTitle}
          </DropdownItem>
        ))}
      </ButtonDropdown>
    );
  };

  const startJourneyConfirmationModal = useMemo(() => {
    if (!showConfirmationModal) return null;

    const { questionnaire_status, are_genetic_test_results_uploaded } = client;

    if (questionnaire_status === QuestionnaireStatus.COMPLETED || are_genetic_test_results_uploaded) {
      return (
        <StartJourneyConfirmationModal
          onClose={() => setShowConfirmationModal(false)}
          onSubmit={onStartJourney}
          selectedWeeksCount={selectedWeeksCount.value}
          isLoading={isLoading}
        />
      );
    }

    return (
      <IncompleteQuestionnaireConfirmationModal
        onClose={() => setShowConfirmationModal(false)}
        onSubmit={onChatWithClient}
      />
    );
  }, [client, isLoading, showConfirmationModal]);

  return (
    <>
      <div className="card card-custom gutter-b" data-testid="client-summary">
        <div className="card-body">
          <div className="d-flex mb-9">
            <ProfileImage imageUrl={client.profile_image_name} />
            <div className={clsx('flex-grow-1', css({ paddingRight: '12px' }))}>
              <div className="d-flex justify-content-between flex-wrap">
                <CardTitleWrapper>
                  <FullName>{client.customer_name}</FullName>
                  <ContactDetailsWrapper>{contactDetails(client)}</ContactDetailsWrapper>
                </CardTitleWrapper>
                <div className="d-flex my-lg-0 my-3">
                  {actions(client, user, coachProductSections, showProfileButton, showChatButton, showScheduleButton, showProductsDropdown)}
                </div>
              </div>
              <div className="d-flex flex-wrap justify-content-between">
                <div className="d-flex flex-column flex-grow-1">
                  <div className="d-flex mt-4">
                    <BadgesWrapper>{badges(client)}</BadgesWrapper>
                    <div
                      className={clsx('d-flex justify-content-end', { 'flex-column': client.is_journey_started })}
                      style={{ flex: '1' }}
                    >
                      {journeyProgress()}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Separator />

          <InfoIcons />
        </div>
      </div>

      {startJourneyConfirmationModal}
    </>
  );
}
