/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useRef } from 'react';
import { useField } from 'formik';
import clsx from 'clsx';

import KTImageInput from '~/vendor/_metronic/_assets/js/components/image-input';
import { style } from '~/components/common/Form/Photo/Photo.style';
import styled from '@emotion/styled';

const EMPTY_IMAGE_PATH = '/build/admin-fe/media/svg/humans/custom-10.svg';

interface Props {
  name: string;
  emptyImagePath?: string;
  error?: string;
  currentImagePath?: string;
  changeTitle?: string;
  removeTitle?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onRemoveClick?: (event: React.MouseEvent<HTMLElement>) => void;
}

function emptyImage(path?: string) {
  return path || EMPTY_IMAGE_PATH;
}

const ImageWrapperContainer = styled.div`
  .image-input-wrapper {
    background-position: center !important;
  }
`;

export const Photo = ({
  name,
  emptyImagePath,
  currentImagePath,
  changeTitle,
  removeTitle,
  onChange,
  onRemoveClick,
  error,
}: Props) => {
  const imageRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (imageRef.current) {
      // eslint-disable-next-line no-new
      new KTImageInput(imageRef.current.id);
    }
  });

  return (
    <div>
      <div
        className={clsx('image-input image-input-outline', {
          'image-input-empty': !currentImagePath,
        })}
        id={`image-upload--${name}`}
        ref={imageRef}
        style={{ 
          backgroundImage: `url(${emptyImage(emptyImagePath)})`,
          backgroundPosition: 'center' 
        }}
      >
        <ImageWrapperContainer>
          {currentImagePath ? (
            <div
              className="image-input-wrapper"
              style={{ 
                backgroundImage: `url(${currentImagePath})`
              }}
            />
          ) : (
            <div className="image-input-wrapper" />
          )}
        </ImageWrapperContainer>
        <label
          className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
          data-action="change"
          data-toggle="tooltip"
          data-original-title={changeTitle || 'Change avatar'}
          title={changeTitle || 'Change avatar'}
        >
          <i className="fa fa-pen icon-sm text-muted" />
          <input
            type="file"
            accept="image/png, image/jpeg, image/jpg"
            name={name}
            onChange={onChange}
          />

          <input type="hidden" name={`${name}_remove`} />
        </label>

        <span
          className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
          data-action="remove"
          data-toggle="tooltip"
          title={removeTitle || 'Remove avatar'}
          onClick={onRemoveClick}
        >
          <i className="fa fa-times text-muted" />
        </span>
      </div>

      {error && <small className={clsx('text-danger', style.subnote, style.error)}>{error}</small>}
    </div>
  );
};

type FormikPhotoProps = Omit<Props, 'error'>;
export const FormikPhoto = ({ name, ...props }: FormikPhotoProps) => {
  const [field, meta] = useField({ id: name, name, ...props });

  return (
    <Photo error={meta.touched && meta.error ? meta.error : undefined} {...field} {...props} />
  );
};
