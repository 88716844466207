import styled from '@emotion/styled';
import { FormRowLabel as Label } from '~/components/common/Form';

export const ModalFooter = styled.div`
  display: flex;
  padding-right: 30px;
  margin-top: 15px;
  gap: 30px;
  justify-content: flex-end;
`;

export const FormWrapper = styled.div`
  padding-right: 30px;
`;

export const FormRowLabel = styled(Label)`
  flex-basis: 50px;
`;
