import React from 'react';
import { useQueryClient } from 'react-query';
import { useClient } from '~/components/pages/client/context';
import { Loader, Error } from '~/components/common/Modal';
import {
  clientQuestionnaireQueryKey,
  useClientQuestion,
  useClientQuestionAnswer,
} from '~/model/Client/Questionnaire/hooks';
import { model } from '~/model/Client/Questionnaire';
import { AnswersModal as Component, Props as ComponentProps } from './AnswersModal';
import { initialValues, toApiRequest } from './config';
import { fromApiResponse } from './model';

export interface Props extends Omit<ComponentProps, 'onSubmit' | 'initialValues' | 'answer'> {
  questionId: string;
  answerId?: string;
}

export const AnswersModal = ({ questionId, answerId, ...otherProps }: Props) => {
  const queryClient = useQueryClient();
  const { clientId } = useClient();
  const { isLoading, isError, data: apiResponse } = answerId
    ? useClientQuestionAnswer(clientId, answerId)
    : useClientQuestion(clientId, questionId);

  if (isLoading) {
    return <Loader {...otherProps} />;
  }

  if (isError || !apiResponse) {
    return <Error {...otherProps} />;
  }

  const modelValues = fromApiResponse(apiResponse);

  return (
    <Component
      {...otherProps}
      answer={modelValues}
      initialValues={initialValues(modelValues)}
      onSubmit={async (values) => {
        const data = toApiRequest(values);
        if (answerId) {
          await model(clientId).postAnswer(answerId, data);
        } else {
          await model(clientId).postQuestion(questionId, data);
        }
        await queryClient.invalidateQueries(clientQuestionnaireQueryKey(clientId));
      }}
    />
  );
};
