import { useState } from 'react';
import { FormFields } from '~/model/Notes';

export const useSearchAndFilters = ({ term: passedTerm, filter: passedFilter }: FormFields) => {
  const [term, setTerm] = useState(passedTerm);
  const [filter, setFilter] = useState(passedFilter);

  return {
    changeTerm: setTerm,
    changeFilter: setFilter,
    term,
    filter,
  };
};
