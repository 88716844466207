import styled from '@emotion/styled';
import { colors } from '~/components/utils/colors';

export const ErrorPageContainer = styled.div`
  padding: 154px 0;
  color: ${colors.colorPriOne};
`;

export const Heading = styled.div`
  font-weight: 600;
  font-size: 64px;
  line-height: 96px;
  margin-bottom: 30px;
`;

export const Title = styled.div`
  font-weight: 500;
  font-size: 40px;
  line-height: 60px;
  margin-bottom: 30px;
`;

export const ErrorContent = styled.div`
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;

  a {
    display: block;
  }

  p {
    margin-bottom: 30px;
  }
`;
