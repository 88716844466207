import React, { useMemo } from 'react';
import { DateTime } from 'luxon';

import { headerColumns, MealCategory } from '~/components/pages/client/Sections/Meals/model';
import { EmptyState } from '~/components/common/EmptyState';

import { ClientMealsFilterParams, ClientMealsSearchAndFilter } from '~/model/Client/Meals';
import { Meal } from './Meal/Meal';
import { ExpandableTable } from '../ExpandableTable';
import { TableRowContent } from '../ExpandableTable/ExpandableTable.style';
import { SearchAndFilters } from './SearchAndFilters';

export interface Props {
  publishedAt?: string;
  weeks: MealCategory[];
  term: string;
  filters: ClientMealsFilterParams;
  changeTerm: (value) => void;
  changeFilters: (value) => void;
  initialValues: ClientMealsSearchAndFilter;
  isLoading: boolean;
}

export const Meals = ({
  publishedAt,
  weeks,
  term,
  filters,
  changeTerm,
  changeFilters,
  initialValues,
  isLoading,
}: Props) => {
  const filteredOrSearched = useMemo(
    () => term !== '' || filters.meal !== '' || filters.status !== '',
    [term, filters],
  );
  const hasMeals = useMemo(() => weeks && weeks.length > 0, [weeks, weeks.length]);

  if (!hasMeals && !filteredOrSearched) {
    return <EmptyState description="Your client's meal will be visible here" />;
  }

  return (
    <>
      <ExpandableTable
        title="Meal ideas"
        subtitle={
          publishedAt && `Published ${DateTime.fromISO(publishedAt).toFormat('dd/MM/yyyy')}`
        }
        searchAndFilters={
          <SearchAndFilters
            initialValues={initialValues}
            term={term}
            setTerm={changeTerm}
            filters={filters}
            setFilters={changeFilters}
            isLoading={isLoading}
          />
        }
        headers={headerColumns}
        rows={weeks.map(({ number, active, meals }) => ({
          title: number === 0 ? 'Pre-journey week' : `Week ${number}`,
          active,
          expanded: active,
          content:
            meals && meals.length > 0 ? (
              <TableRowContent>
                {meals.map((meal) => (
                  <Meal key={meal.id} meal={meal} />
                ))}
              </TableRowContent>
            ) : undefined,
        }))}
        emptyState={
          <EmptyState title="No meals found" description="Try searching for something else..." />
        }
      />
    </>
  );
};
