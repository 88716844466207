import * as React from 'react';
import { SVGProps } from 'react';
import { Size } from '../types';
interface Props extends SVGProps<SVGSVGElement> {
  size?: Size;
}
const SvgSex = (props: Props) => {
  const getSize = () => {
    if (props.size) {
      switch (props.size) {
        case 'small':
          return 20;

        case 'medium':
          return 24;

        case 'large':
          return 32;

        default:
          return null;
      }
    }
  };

  return (
    <svg
      width={getSize() || 32}
      height={getSize() || 32}
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      {...props}
    >
      <path
        d="M.75 14c0 5.327 4.322 9.75 9.75 9.75 5.328 0 9.75-4.422 9.75-9.75s-4.422-9.75-9.75-9.75C5.173 4.25.75 8.572.75 14zm1.5-.1a8.23 8.23 0 018.25-8.25 8.23 8.23 0 018.25 8.25 8.23 8.23 0 01-8.25 8.25 8.23 8.23 0 01-8.25-8.25z"
        stroke="currentColor"
        strokeWidth={0.1}
      />
      <path
        d="M9.75 14c0 5.327 4.322 9.75 9.75 9.75 5.328 0 9.75-4.422 9.75-9.75s-4.422-9.75-9.75-9.75c-5.327 0-9.75 4.322-9.75 9.75zm1.5-.1a8.23 8.23 0 018.25-8.25 8.23 8.23 0 018.25 8.25 8.23 8.23 0 01-8.25 8.25 8.23 8.23 0 01-8.25-8.25zM9.75 31c0 .428.322.75.75.75s.75-.322.75-.75v-8a.733.733 0 00-.75-.75.733.733 0 00-.75.75v8z"
        stroke="currentColor"
        strokeWidth={0.1}
      />
      <path
        d="M7 28.75h7a.777.777 0 00.496-.213A.733.733 0 0014 27.25H7a.733.733 0 00-.75.75c0 .428.322.75.75.75zM24.265 7.235a.813.813 0 00.535.215.813.813 0 00.535-.215l5.7-5.7a.757.757 0 00.24-.535.757.757 0 00-.24-.535.757.757 0 00-.535-.24.757.757 0 00-.535.24l-5.7 5.7a.757.757 0 00-.24.535c0 .19.082.377.24.535z"
        stroke="currentColor"
        strokeWidth={0.1}
      />
      <path
        d="M24.9 1.75h5.6c.428 0 .75-.322.75-.75a.733.733 0 00-.75-.75h-5.6a.733.733 0 00-.75.75c0 .428.322.75.75.75z"
        stroke="currentColor"
        strokeWidth={0.1}
      />
      <path
        d="M29.75 6.6c0 .428.322.75.75.75s.75-.322.75-.75V1a.734.734 0 00-.75-.75.733.733 0 00-.75.75v5.6z"
        stroke="currentColor"
        strokeWidth={0.1}
      />
    </svg>
  );
};

export default SvgSex;
