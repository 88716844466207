import React, { PropsWithChildren } from 'react';

import { Title, Wrapper } from './EmptyState.style';

interface Props {
  title?: string;
  description?: string;
}

export const defaultTitle = 'This client did not complete their questionnaire!';

export const EmptyState = ({
  title = defaultTitle,
  description,
  children,
}: PropsWithChildren<Props>) => (
  <Wrapper>
    <Title>{title}</Title>
    <h3>{description || children}</h3>
  </Wrapper>
);
