import { generatePath } from 'react-router-dom';
import { api } from '~/services/api';
import { BackendValidationErrorResponse } from '~/model/Error/ErrorTypes';

const URL = {
  LIST: '/api/client/:clientId/goals',
  GET: '/api/client/:clientId/goal/:id',
  ADD: '/api/client/:clientId/goal',
  DELETE: '/api/client/:id/goal/:goalId',
  EDIT: '/api/client/:clientId/goal/:id',
};

export interface ClientGoalsListResponse {
  publishedAt?: string;
  weeks: Array<{
    number: number;
    active: boolean;
    goals?: Array<{
      id: string;
      goal: string;
      topic: string;
      topic_id: number;
      dimension: string;
      completed: number;
      total: number;
      text: string;
      habits?: Array<{
        id: string;
        title: string;
      }>;
    }>;
  }>;
}

export interface GoalResponse {
  dimension_name: string;
  dimension: number;
  recommendation_title: string;
  id: string;
  title: string;
  text: string;
  week: number;
  actions_total_override?: number;
}

export interface EditGoalRequest {
  title: string;
  text: string;
  week: number;
  actions_total_override: number | null;
}

export interface AddGoalRequest {
  dimension: number;
  recommendation?: number;
  title: string;
  text: string;
  actionsTotalOverride: number | null;
  week: number;
  habits?: number[];
}

export interface DeleteGoalRequest {
  id: number;
  goalId: string;
}

export const model = (clientId) => ({
  getList: () => api.get<ClientGoalsListResponse>(generatePath(URL.LIST, { clientId })),
  get: (id) => api.get<GoalResponse>(generatePath(URL.GET, { clientId, id })),
  add: (data: AddGoalRequest) =>
    api.post<AddGoalRequest, void | BackendValidationErrorResponse<AddGoalRequest>>(
      generatePath(URL.ADD, {
        clientId,
      }),
      data,
    ),
  edit: (id, data: EditGoalRequest) =>
    api.post<EditGoalRequest, void | BackendValidationErrorResponse<EditGoalRequest>>(
      generatePath(URL.EDIT, {
        clientId,
        id,
      }),
      data,
    ),
  delete: (id: string, goalId: string) => api.delete(generatePath(URL.DELETE, { id, goalId })),
});
