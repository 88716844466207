import React from 'react';
import { CoachProfile } from '~/model/Profile';
import {
  CoinStack,
  PiggyBank,
  Trend,
  UserNetwork,
  Users,
  UsersStars,
} from '~/components/common/Icons/dist';

interface InfoIconConfig {
  icon: JSX.Element;
  topText: string;
  bottomText: string | number;
}

const makeIcons = (coach: CoachProfile): InfoIconConfig[] => [
  {
    icon: <Users />,
    topText: 'Total clients',
    bottomText: coach.total_clients,
  },
  {
    icon: <UsersStars />,
    topText: 'Active clients',
    bottomText: coach.active_clients,
  },
  {
    icon: <PiggyBank />,
    topText: 'Revenue (total)',
    bottomText: coach.total_revenue || 'N/A',
  },
  {
    icon: <CoinStack />,
    topText: 'Revenue (This month)',
    bottomText: coach.revenue_this_month || 'N/A',
  },
  {
    icon: <Trend />,
    topText: 'Revenue (Average)',
    bottomText: coach.average_revenue || 'N/A',
  },
  {
    icon: <UserNetwork />,
    topText: 'Referral code',
    bottomText: coach.code,
  },
];

const InfoIcon = ({ icon, topText, bottomText }: InfoIconConfig) => (
  <div className="d-flex align-items-center flex-lg-fill mr-5 mb-2">
    <span className="mr-4">{icon}</span>
    <div className="d-flex flex-column text-dark-75">
      <span className="text-gsThree">{topText}</span>
      <span className="font-weight-bolder font-size-h5">
        <span className="text-dark font-weight-bold">{bottomText}</span>
      </span>
    </div>
  </div>
);

interface Props {
  coach: CoachProfile;
}

export const InfoIcons = ({ coach }: Props) => {
  const icons = makeIcons(coach);

  return (
    <div className="d-flex align-items-center flex-wrap mt-8">
      {icons.map((iconConfig) => (
        <InfoIcon key={`${iconConfig.topText}${iconConfig.bottomText}`} {...iconConfig} />
      ))}
    </div>
  );
};
