import React, { useLayoutEffect } from 'react';
import { Loader } from '~/components/common/Loader';
import { TableProvider } from '~/components/common/Table/TableProvider';
import { Card } from '~/components/common/Card';
import { Note } from '~/model/Notes';
import { useNotes } from '~/model/Notes/hooks';
import { useSubheader } from '~/vendor/_metronic/layout';
import { Layout } from '~/components/common/Layout';
import { usePagination } from '~/components/common/Pagination/hooks/usePagination';
import { PaginationProvider } from '~/components/common/Pagination/PaginationProvider';
import { Notes as Component } from './Notes';
import { initialValues } from './SearchAndFilters/config';
import { useSearchAndFilters } from './hooks';
import { useCoachContext } from '../profile';

const makeInitialState = (notes: Note[]) => ({
  rows: notes.map((note) => ({ id: note.id, expanded: false })),
});

export const Notes = () => {
  const { coach } = useCoachContext();
  const values = initialValues();
  const { term, filter, changeTerm, changeFilter } = useSearchAndFilters({
    term: values.term,
    filter: values.filter,
  });
  const pagination = usePagination({
    currentPage: 1,
    itemsPerPage: 10,
  });
  const { isError, isLoading, data } = useNotes(
    coach.id,
    pagination.currentPage,
    pagination.itemsPerPage,
    term,
    filter,
  );

  const subheader = useSubheader();

  useLayoutEffect(() => {
    subheader.setTitle('Notes');
  });

  if (isLoading) {
    return <Loader size="medium" center />;
  }

  if (isError || !data) {
    return <>Error has occured</>;
  }

  return (
    <Layout testId="notes-wrapper">
      <TableProvider initialState={makeInitialState(data.items)}>
        <Card>
          <PaginationProvider pagination={pagination}>
            <Component
              data={data}
              initialValues={values}
              term={term}
              filter={filter}
              onTermChange={changeTerm}
              onFilterChange={changeFilter}
            />
          </PaginationProvider>
        </Card>
      </TableProvider>
    </Layout>
  );
};
