import React from 'react';
import { useParams } from 'react-router-dom';
import { CometChat } from './CometChat';


export const Route = () => {
  const { id } = useParams();

  return (
    <CometChat chatWithUser={id} />
  );
};
