import React, { useContext } from 'react';
import { CoachProfile } from '~/model/Profile';

export interface CoachContextType {
  coachId: string;
  coach: CoachProfile;
  isLoading: boolean;
  isError: boolean;
}

export const CoachContext = React.createContext<CoachContextType>(undefined!);

export const useCoachContext = () => useContext(CoachContext);
