/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { InputHTMLAttributes, useState } from 'react';
import clsx from 'clsx';
import { useField } from 'formik';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import BalloonBlockEditor from '@ckeditor/ckeditor5-build-balloon-block';

import { Label } from '~/components/common/Form/Label';

import { RichTextStyleWrapper, style } from './RichText.style';

interface Props extends InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement> {
  label?: string;
  wrapperClassName?: string;
  error?: string;
  note?: string;
  onTouch?: (event: any) => void;
}

export const RichText = ({
  id,
  label,
  value,
  wrapperClassName,
  error,
  note,
  onChange,
  onTouch,
}: Props) => {
  const [ready, setReady] = useState(false);

  const handleOnChange = (editor) => {
    if (onChange && ready) onChange(editor.getData());
  };

  const renderControl = () => (
    <CKEditor
      id={id}
      config={{
        toolbar: ['bold', 'italic'],
        removePlugins: [
          'Heading',
          'Table',
          'BlockQuote',
          'CKFinderUploadAdapter',
          'CKFinder',
          'EasyImage',
          'Image',
          'ImageCaption',
          'ImageStyle',
          'ImageToolbar',
          'ImageUpload',
          'MediaEmbed',
        ],
      }}
      data={value}
      editor={BalloonBlockEditor}
      onChange={(_event, editor) => handleOnChange(editor)}
      onBlur={(_event) => {
        if (onTouch) onTouch(_event);
      }}
      onReady={(editor) => {
        editor.setData(editor.data.get());
        setReady(true);
      }}
    />
  );

  return (
    <div className={clsx(wrapperClassName, style.wrapper)}>
      {label && <Label>label</Label>}
      <div className={style.inputWrapper}>
        <RichTextStyleWrapper />
        <div className={clsx('form-control', style.input, { 'is-invalid': !!error })}>
          {renderControl()}
        </div>
        {error && (
          <small className={clsx('text-danger', style.subnote, style.error)}>{error}</small>
        )}
        {note && <small className={clsx('text-gsThree', style.subnote, style.note)}>{note}</small>}
      </div>
    </div>
  );
};

interface FormikInputProps extends Omit<Props, 'error'> {
  name: string;
}

export const FormikRichText = ({ name, ...props }: FormikInputProps) => {
  const [field, meta] = useField({ id: name, name, ...props });
  const [touched, setTouched] = useState(false);

  return (
    <RichText
      id={name}
      onTouch={() => setTouched(true)}
      error={(meta.touched || touched) && meta.error ? meta.error : undefined}
      {...field}
      {...props}
    />
  );
};
