/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { ReactElement } from 'react';
import { TestableComponentProps } from '~/model/TestableComponentProps.interface';

export interface Props extends TestableComponentProps {
  id?: string;
  title: string;
  path?: string;
  icon?: React.ReactNode;
  children: React.ReactNode;
}

export interface TabConfig {
  id: string;
  title: string;
  icon: JSX.Element;
  content: JSX.Element;
  enabled: boolean;
}

export interface TabsProps {
  activeTab?: string;
  children: ReactElement<Props>;
}

export const Tab = ({ children, path, id, testId, title, icon }: Props) => <>{children}</>;
