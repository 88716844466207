import React, { useState } from 'react';
import { ClientSummary } from '~/components/common/ClientSummary/container';
import { ClientSelectCard } from './ClientSelectCard';
import { ClientContextProvider } from '../client/context';
import { MarketplaceTabs } from './MarketplaceTabs';

export const Marketplace = () => {
  const [clientId, setClientId] = useState<number | undefined>();

  return (
    <>
      <ClientSelectCard
        onClientSelected={setClientId}
        clientId={clientId}
      />

      {clientId && (
        <ClientContextProvider clientId={`${clientId}`}>
          <ClientSummary
            showChatButton
            showProfileButton
            showScheduleButton={false}
            showProductsDropdown={false}
          />

          <MarketplaceTabs />
        </ClientContextProvider>
      )}
    </>
  );
}
