import React, { Fragment, PropsWithChildren, useState } from 'react';

import { Modal, Props as ModalProps } from '~/components/common/Modal';
import { TestableComponentProps } from '~/model/TestableComponentProps.interface';
import { AlternativeHeading, Column, Row } from '~/components/common/Modal/Modal.style';
import { Phenotype, Reference, ResultRangeSet } from '~/model/Client/TestResults/Phenotype';
import { capitalize } from '@material-ui/core';
import { getVariantFromColor, Label } from '~/components/common/Label';
import { LinkButton } from '~/components/common/Button/Button.style';
import { ColumnWithPadding } from './PhenotypeModal.style';
import { TestKit } from '../../TestKits/model';
// eslint-disable-next-line import/no-cycle
import { TestKitModal } from '../../TestKits/TestKit/TestKitModal/TestKitModal';

export interface Props extends TestableComponentProps, ModalProps {
  phenotype: Phenotype;
}

const buildLabel = ({ color, value }) => (
  <Label inline light variant={getVariantFromColor(color)}>
    {capitalize(value)}
  </Label>
);

const buildRange = (from, to) => {
  if (!from && !to) {
    return 'N/A';
  }

  if (from && !to) {
    return `> ${from}`;
  }

  if (!from && to) {
    return `≤ ${to}`;
  }

  return `> ${from} - ≤ ${to}`;
};

const description = (phenotypeDescription?: string) =>
  phenotypeDescription && (
    <Row $marginBottom="20px">
      <Column $width="100%" data-testid="phenotype-modal-description">
        <AlternativeHeading>Description</AlternativeHeading>
        <Column $width="100%" dangerouslySetInnerHTML={{ __html: phenotypeDescription }} />
      </Column>
    </Row>
  );

const referencesBlock = (references: Reference[]) =>
  references &&
  references.length > 0 && (
    <Row $marginBottom="0">
      <Column $width="100%" data-testid="phenotype-modal-references">
        <AlternativeHeading>References</AlternativeHeading>
        <Column $width="100%">
          <div>
            {references.map((reference, index) => (
              <Fragment key={reference.name}>
                <a target="_blank" href={reference.url} rel="noreferrer">
                  {reference.name}
                </a>
                {index < references.length - 1 && ', '}
              </Fragment>
            ))}
          </div>
        </Column>
      </Column>
    </Row>
  );

const col = (heading: string, testId: string, content?: string) =>
  content && (
    <Column data-testid={`phenotype-modal-${testId}`}>
      <AlternativeHeading>{heading}</AlternativeHeading>
      <Column $width="100%" dangerouslySetInnerHTML={{ __html: content }} />
    </Column>
  );

const rangeRow = (range) => (
  <Row key={`range-${range.id}`} data-testid={`phenotype-modal-range-${range.id}`}>
    <Column $width="200px">{buildLabel(range.name)}</Column>
    <ColumnWithPadding
      $width="150px"
      dangerouslySetInnerHTML={{ __html: buildRange(range.valueFrom, range.valueTo) }}
    />
    <ColumnWithPadding $width="100%" dangerouslySetInnerHTML={{ __html: range.description }} />
  </Row>
);

const rangeSetRow = (rangeSet: ResultRangeSet) => (
  <Column key={`range-set-${rangeSet.name}`} $width="100%">
    <AlternativeHeading>{rangeSet.name}</AlternativeHeading>
    {rangeSet.resultRanges.map((range) => rangeRow(range))}
  </Column>
);

const rangesBlock = (ranges: ResultRangeSet[]) =>
  ranges &&
  ranges.length > 0 && (
    <Row>
      <Column $width="100%" data-testid="phenotype-modal-ranges">
        <AlternativeHeading>Result ranges</AlternativeHeading>
        <Column $width="100%">{ranges.map((rangeSet) => rangeSetRow(rangeSet))}</Column>
      </Column>
    </Row>
  );

const testKitsBlock = (testKits: TestKit[], handleTestKitModal: (id: number) => void) =>
  testKits &&
  testKits.length > 0 && (
    <Column key="range-set-test-kits" $width="100%">
      <AlternativeHeading>Included in test kits</AlternativeHeading>
      <Column $width="100%">
        <div>
          {testKits.map((testKit, index) => (
            <Fragment key={testKit.name}>
              <LinkButton onClick={() => handleTestKitModal(testKit.id)}>{testKit.name}</LinkButton>
              {index < testKits.length - 1 && ', '}
            </Fragment>
          ))}
        </div>
      </Column>
    </Column>
  );

export const PhenotypeModal = ({ phenotype, onClose, testId, width }: PropsWithChildren<Props>) => {
  const [showTestKitModal, setShowTestKitModal] = useState(false);
  const [testKitId, setTestKitId] = useState<number | null>(null);

  const defaultProps = {
    onClose,
    testId,
    width,
  };

  const handleTestKitModal = (id: number) => {
    setTestKitId(id);
    setShowTestKitModal(true);
  };

  if (showTestKitModal && testKitId) {
    return (
      <TestKitModal
        testKitId={testKitId}
        onClose={() => setShowTestKitModal(false)}
        width="772px"
        testId="test-kit-modal"
      />
    );
  }

  return (
    <Modal title={phenotype.name} withHeaderSeparator {...defaultProps}>
      {description(phenotype.description)}
      <Row $marginBottom="20px">
        {col('Category', 'category', phenotype.category)}
        {col('Sample', 'sample', phenotype.sample)}
        {col('Measuring units', 'units', phenotype.measuringUnits)}
        {testKitsBlock(phenotype.testKits, handleTestKitModal)}
      </Row>
      {rangesBlock(phenotype.resultRangeSets)}
      {referencesBlock(phenotype.references)}
    </Modal>
  );
};
