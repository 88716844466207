import { css } from '@emotion/css';
import styled from '@emotion/styled';
import { colors } from '~/components/utils/colors';

export const style = {
  wrapper: css({
    display: 'flex',
    alignItems: 'center',
  }),
  inputWrapper: css({
    width: '100%',
    position: 'relative',
  }),
  input: css({
    padding: '11px 10px 11px 16px',
    marginBottom: '2px',
    height: '40px',
    borderWidth: '1px ',
    borderStyle: 'solid',
    borderRadius: '6px',
  }),
  inputWithIcon: css({
    padding: '11px 10px 11px 46px',
  }),
  icon: css({
    position: 'absolute',
    left: '12px',
    top: '8px',
    bottom: '8px',
  }),
  inputSuffix: css({
    position: 'absolute',
    top: '0',
    padding: '11px 10px 11px 16px',
    pointerEvents: 'none',
    display: 'flex',
    maxWidth: '100%',
  }),
  subnote: css({
    padding: '2px 10px 2px 16px',
  }),
  note: css({
    display: 'block',
    fontSize: '11px',
    lineHeight: '16px',
  }),
  largeNote: css({
    padding: '10px 0 0 25px',
    fontSize: '12px',
    letterSpacing: '-0.2px',
  }),
  checkboxWrapper: css({
    display: 'flex',
    flexDirection: 'column',
  }),
  checkboxLabelInput: css({
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  }),
  checkboxLabel: css({
    fontWeight: 500,
    fontSize: '14px',
  }),
  checkbox: css({
    marginLeft: 0,
    height: '40px',
    padding: '11px 10px 11px 16px',
  }),
  toggle: css({
    all: 'unset',
    margin: '2px',
    width: '40px',
    height: '22px',
    borderRadius: '11px',
    backgroundColor: colors.colorPriBlueTwo,
    position: 'relative',
    cursor: 'pointer',

    '&::after': {
      content: '""',
      width: '18px',
      height: '18px',
      position: 'absolute',
      margin: '2px',
      top: 0,
      bottom: 0,
      backgroundColor: colors.colorWhite,
      borderRadius: '50%',
      transition: 'all 0.2s ease-in-out',
    },

    '&:checked': {
      '&::after': {
        transform: 'translateX(18px)',
        backgroundColor: colors.colorPriBlueOne,
      }
    },
  }),
};

export const InputField = styled.input`
  & + .input-addons {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;

    .suffix-addon {
      .value {
        height: 0;
        visibility: hidden;
        overflow: hidden;
      }

      .units {
        white-space: nowrap;
        background: ${colors.colorWhite};
      }
    }
  }

  &.input-with-icon {
    padding-left: 46px;

    & + .input-addons {
      .suffix-addon {
        padding-left: 46px;
      }
    }
  }

  &:placeholder-shown + .input-addons {
    .suffix-addon {
      display: none;
    }

    .icon-addon {
      color: ${colors.colorDisabledOne};
    }
  }

  &:not(:placeholder-shown) + .input-addons {
    .icon-addon {
      color: ${colors.colorGsThree};
    }
  }

  &.is-large {
    padding: 16px 25px 16px 25px;
    height: 57px;
    font-size: 14px;
    line-height: 21px;
  }
`;

export const TextAreaField = styled.textarea`
  & + .input-addons {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;

    .suffix-addon {
      .value {
        height: 0;
        visibility: hidden;
        overflow: hidden;
      }

      .units {
        white-space: nowrap;
        background: ${colors.colorWhite};
      }
    }
  }

  &.input-with-icon {
    padding-left: 46px;

    & + .input-addons {
      .suffix-addon {
        padding-left: 46px;
      }
    }
  }

  &:placeholder-shown + .input-addons {
    .icon-addon {
      color: ${colors.colorDisabledOne};
    }
  }

  &:not(:placeholder-shown) + .input-addons {
    .icon-addon {
      color: ${colors.colorGsThree};
    }
  }

  &.is-large {
    padding: 16px 25px 16px 25px;
    height: 57px;
    font-size: 14px;
    line-height: 21px;
  }
`;

export const TextArea = styled.textarea<{ $minHeight?: string; $maxHeight?: string }>`
  min-height: ${({ $minHeight }) => $minHeight};
  max-height: ${({ $maxHeight }) => $maxHeight};
`;
