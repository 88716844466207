import React, { useState } from 'react';

import { IconButton } from '~/components/common/Button';
import { Edit2, Minus, Plus2 } from '~/components/common/Icons/dist';

import { TestableComponentProps } from '~/model/TestableComponentProps.interface';
import { getVariantFromColor, Label } from '~/components/common/Label';
import { DeviceRow, style } from './Device.style';
import { Device as Model, headerColumns, DeviceStatusValue } from '../model';
import { TableRowItem } from '../../ExpandableTable/ExpandableTable.style';
import { RowHeaderButton } from '../../Sections.style';
import { DeviceModal } from './DeviceModal';

export interface Props extends TestableComponentProps {
  device: Model;
  onIncreaseClick: () => void;
  onDecreaseClick: () => void;
  inBasket: number;
}

const label = ({ color, value }) => (
  <Label inline variant={getVariantFromColor(color)} light>
    {value}
  </Label>
);

export const Device = ({ device, onIncreaseClick, onDecreaseClick, inBasket }: Props) => {
  const [showModal, setShowModal] = useState(false);
  const { name, brand, size, formattedPrice, status } = device;

  const onClick = () => setShowModal(true);

  return (
    <>
      <TableRowItem>
        <DeviceRow $widths={headerColumns.map(({ width }) => width || '1fr')}>
          <div className={style.device}>
            <RowHeaderButton onClick={onClick}>{name}</RowHeaderButton>
          </div>
          <div className={style.column}>{brand}</div>
          <div className={style.column}>{size}</div>
          <div className={style.column}>{formattedPrice}</div>
          <div className={style.column}>{label(status)}</div>
          <div data-testid="in-basket" className={style.column}>
            {inBasket}
          </div>
          <div className={style.actions}>
            <IconButton
              shade="light"
              aria-label="View Device"
              icon={<Edit2 />}
              onClick={onClick}
            />
            <IconButton
              shade="light"
              aria-label="Increase amount of devices in basket"
              icon={<Plus2 />}
              onClick={onIncreaseClick}
              disabled={status.value === DeviceStatusValue.OUT_OF_STOCK}
            />
            <IconButton
              shade="light"
              aria-label="Decrease amount of devices in basket"
              icon={<Minus />}
              onClick={onDecreaseClick}
              disabled={status.value === DeviceStatusValue.OUT_OF_STOCK}
            />
          </div>
        </DeviceRow>
      </TableRowItem>

      {showModal && (
        <DeviceModal
          deviceId={device.id}
          onClose={() => setShowModal(false)}
        />
      )}
    </>
  );
};
