import React, { PropsWithChildren } from 'react';

import { Error, Loader, Modal, Props as ModalProps } from '~/components/common/Modal';
import { TestableComponentProps } from '~/model/TestableComponentProps.interface';
import { AlternativeHeading, Column, Row } from '~/components/common/Modal/Modal.style';
import { useGetDevice } from '~/model/Client/Devices/Device';
import { useClient } from '~/components/pages/client/context';
import { ApiFile } from '~/model/File/apiFile';
import { Gallery } from '~/components/common/Gallery';
import { DateTime } from 'luxon';

export interface Props extends TestableComponentProps, ModalProps {
  deviceId: number;
}

const row = (heading: string, testId: string, content?: string) =>
  content && (
    <Row>
      <Column $width="100%" data-testid={`device-modal-${testId}`}>
        <AlternativeHeading>{heading}</AlternativeHeading>
        <Column $width="100%" dangerouslySetInnerHTML={{ __html: content }} />
      </Column>
    </Row>
  );

const files = (filesList: ApiFile[]) =>
  filesList &&
  filesList.length > 0 && (
    <Row>
      <Column $width="100%" data-testid="device-modal-files">
        <AlternativeHeading>Supplemental materials</AlternativeHeading>
        <Column $width="100%">
          {filesList.map((file) => (
            <Row key={file.name} $marginBottom="0">
              <a href={file.url} target="_blank" rel="noreferrer">
                {file.name}
              </a>
            </Row>
          ))}
        </Column>
      </Column>
    </Row>
  );

const gallery = (primaryImage: string, additionalImages: ApiFile[]) => {
  const images = [
    { url: primaryImage, name: '' },
    ...additionalImages,
  ];

  return (
    <Row>
      <Column $width="100%" data-testid="device-modal-gallery">
        <Gallery images={images} />
      </Column>
    </Row>
  );
};

export const DeviceModal = ({ deviceId, onClose, testId }: PropsWithChildren<Props>) => {
  const { clientId } = useClient();
  const { data, isLoading, isError } = useGetDevice(clientId, deviceId);

  const defaultProps = {
    onClose,
    testId,
    width: '572px',
  };

  if (isLoading) {
    return <Loader {...defaultProps} />;
  }

  if (isError || !data) {
    return <Error {...defaultProps} />;
  }

  return (
    <Modal
      title={data.name}
      subtitle={data.createdAt && `Added ${DateTime.fromISO(data.createdAt).toFormat('dd/MM/yyyy')}`}
      {...defaultProps}
    >
      {gallery(data.image, data.additionalImages)}
      {row('Description', 'description', data.description)}
      {row('Additional information', 'additional-information', data.additionalInformation)}
      {files(data.supplementaryMaterials)}
    </Modal>
  );
};
