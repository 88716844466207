import styled from "@emotion/styled";
import { colors } from "~/components/utils/colors";
import { StyledDropdownItem } from "./DropdownItem";

export const StyledDropdownSection = styled.div`
padding: 10px 16px;
background-color: ${colors.colorGsOne};
color: ${colors.colorPriOne};
`;

export const StyledDropdownSectionItem = styled(StyledDropdownItem)`
padding-left: 32px;
`;