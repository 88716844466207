import styled from '@emotion/styled';

export const LayoutHeaderButtons = styled.div`
  display: flex;
  place-self: center;

  * + * {
    margin-left: 30px;
  }
`;
