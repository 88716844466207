import { generatePath } from 'react-router-dom';
import { SupplementCategory } from '~/components/pages/client/Sections/Supplements/model';
import { api } from '~/services/api';

const URL = {
  LIST: '/api/client/:clientId/supplement',
};

export const model = {
  getList: (clientId) => api.get<SupplementCategory[]>(generatePath(URL.LIST, { clientId })),
};
