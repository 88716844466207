import { useField } from 'formik';
import React, { InputHTMLAttributes, useRef, useState } from 'react';
import clsx from 'clsx';
import { Input } from '../Input';
import { Upload } from '../../Icons/dist';
import { style } from './FileUpload.style';

export interface Props extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label?: string;
  wrapperClassName?: string;
  error?: string;
  note?: string | React.ReactNode;
}

export const FileUpload = ({ label, wrapperClassName, error, note, onChange, placeholder, ...otherProps }: Props) => {
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const hiddenFileInput = useRef<HTMLInputElement>(null);

  const handleClick = () => hiddenFileInput.current?.click();

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;

    if (files) {
      setSelectedFiles(Array.from(files));
    }

    onChange?.(event);
  };

  return (
    <>
      <input
        type="file"
        ref={hiddenFileInput}
        onChange={handleFileChange}
        hidden
        {...otherProps}
      />
      <div style={{ position: 'relative' }}>
        <Input
          className={clsx(style.uploadField)}
          name={`fileName-${otherProps.name}`}
          label={label}
          value={selectedFiles.map((file) => file.name).join(', ') || ''}
          onClick={handleClick}
          wrapperClassName={wrapperClassName}
          error={error}
          note={note}
          placeholder={placeholder}
          readOnly
        />
        <button
          className={clsx(style.uploadButton)}
          type="button"
          onClick={handleClick}
        >
          <Upload />
        </button>
      </div>
    </>
  );
}

export const FormikFileUpload = ({ name, ...props }: Props) => {
  const [field, meta] = useField({ id: name, name, ...props });

  return (
    <FileUpload
      id={name}
      error={meta.touched ? meta.error : undefined}
      {...field}
      {...props}
    />
  );
};