import React from 'react';
import { useCoachContext } from '~/components/pages/profile/CoachProvider';
import { useCoachSubscriptionPrices } from '~/model/SubscriptionPrice';
import { SettingsForm } from './SettingsForm';

export const Settings = () => {
  const { coach } = useCoachContext();
  const { data: coachSubscriptionPrices } = useCoachSubscriptionPrices(coach.id);

  return (
    <div data-testid="settings-panel-wrapper">
      <SettingsForm coachSubscriptionPrices={coachSubscriptionPrices} />
    </div>
  );
};
