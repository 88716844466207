import React, { PropsWithChildren } from 'react';
import { IntercomProvider as Provider } from 'react-use-intercom';

import { IntercomContext } from './IntercomContext';

export const IntercomProvider = ({ children }: PropsWithChildren<{}>) => (
  <IntercomContext.Provider value={{}}>
    <Provider
      appId={`${process.env.REACT_APP_INTERCOM_APP_ID}`}
      autoBoot
    >
      {children}
    </Provider>
  </IntercomContext.Provider>
);
