/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import { Form, Formik, FormikErrors, FormikHelpers, FormikTouched } from 'formik';
import React from 'react';
import { generatePath, Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FormikCheckbox, FormikDropdown, FormikInput } from '~/components/common/Form';
import { StyledButton } from '~/components/common/Layout/AuthLayout/AuthLayout.style';
import { ROUTES } from '~/components/router/Routes';
import { isBackendValidationError, transformNestedErrors } from '~/model/Error/transformApiErrors';
import { ValidationError } from 'yup';
import { mergeObjects, transformDotNotationObject } from '~/components/utils/helpers/objectHelpers';
import clsx from 'clsx';
import { setToken } from '~/components/common/TokenProvider/utils';
import { useSettingsContext } from '~/components/common/SettingsProvider';
import { useLogin, useRegister } from '~/model/Auth/hooks';
import { model } from '~/model/Auth';
import { Partner, getPartner, getPartnerButtonVairant, getReferralPartner } from '~/components/utils/helpers/partner';
import { helpUrl } from '../../error/config';
import { ActionButtonWrapper, CheckboxLabel, CheckboxWrapper, ContentWrapper, FormInputWrapper, FormWrapper, GetHelpWrapper, Heading, Label, LinkWrapper } from '../auth.style';
import { endUserLicenseAgreementUrl, FormFields, initialValues, privacyPolicyUrl, stepFields, termsAndConditionsUrl, validationSchema } from './config';

type SubmitFn = (() => Promise<void>) & (() => Promise<any>);
type SetTouchedFn = (touched: FormikTouched<FormFields>, shouldValidate?: boolean | undefined) => void;
type SetErrorsFn = (errors: FormikErrors<FormFields>) => void;

export const Register = () => {
  const partnerButtonVariant = getPartnerButtonVairant();
  const partner = getPartner();
  const referralPartner = getReferralPartner();

  const { isLoading, mutateAsync: register } = useRegister();
  const { mutateAsync: login } = useLogin();

  const { step } = useParams();
  const navigate = useNavigate();
  const { countries } = useSettingsContext();

  const currentStep = step ? Number(step) : 0;

  const goToStep = (newStep: number) => {
    navigate(generatePath(ROUTES.REGISTER_STEPPED, { step: newStep }));
  };

  const isEmailTaken = async (email: string, setErrors: SetErrorsFn) => {
    let emailIsTaken = false;

    try {
      await model.register({ email });
    } catch (err) {
      if (isBackendValidationError<FormFields>(err)) {
        const errors = transformNestedErrors(err);

        if (errors.email) {
          setErrors({ email: errors.email });
          emailIsTaken = true;
        }
      }
    }

    return emailIsTaken;
  };

  const handleSubmit = async (values: FormFields, { setErrors }: FormikHelpers<FormFields>) => {
    try {


      await register({
        ...values,
        coachInformation: {
          ...values.coachInformation,
          referralPartner,
        }
      });

      if (values.email && values.plainPassword) {
        const response = await login({ username: values.email, password: values.plainPassword });

        if (response && response.token) {
          setToken(response.token);
        }
      }

      navigate(ROUTES.DEFAULT, { replace: true });
    } catch (err) {
      if (isBackendValidationError<FormFields>(err)) {
        const errors = transformNestedErrors(err);

        if (errors.generic) {
          toast.error(errors.generic);
        }

        const hasStep0Errors = Object.keys(errors).some((key) => stepFields[0].includes(key));
        const hasStep1Errors = Object.keys(errors).some((key) => key.startsWith("coachInformation"));

        if (hasStep0Errors) {
          goToStep(0);
        } else if (errors.coachInformation.referredByReferralPartner) {
          goToStep(2);
        } else if (hasStep1Errors) {
          goToStep(1);
        }

        setErrors(errors);
      }
    }
  };

  const validateStepFields = async (values: FormFields, setErrors: SetErrorsFn, setTouched: SetTouchedFn) => {
    const fields = stepFields[currentStep];
    const fieldsObjects = fields.map((field) => transformDotNotationObject({ [field]: true }));
    const fieldsToTouch = mergeObjects(fieldsObjects);

    setTouched(fieldsToTouch, true);

    let errors = {};

    await Promise.all(fields.map(async (field) => {
      try {
        await validationSchema.validateAt(field, values);
      } catch (err) {
        if (err instanceof ValidationError) {
          errors = { ...errors, ...transformDotNotationObject({ [field]: err.message }) };
        }
      }
    }));

    const isValid = Object.keys(errors).length === 0;

    if (!isValid) {
      setErrors(errors as FormikErrors<FormFields>);
      return isValid;
    }

    if (currentStep === 0 && values.email) {
      const emailIsTaken = await isEmailTaken(values.email, setErrors);

      if (emailIsTaken) {
        return false;
      }
    }

    if (currentStep < stepFields.length - 1 && isValid) {
      goToStep(currentStep + 1);
    }

    return isValid;
  };

  const getTitle = () => {
    switch (currentStep) {
      case 1:
        return <>Contact details</>;
      case 2:
        return <>Referral</>;
      case 3:
        return <>Consent</>;

      default:
        return <>Sign up</>;
    }
  };

  const getSubTitle = () => {
    switch (currentStep) {
      case 1:
        return <>Give us a way to verify your location and get in touch!</>;
      case 2:
        return <>Did a colleague, employer, organisation or a sales representative refer you to the platform?</>;
      case 3:
        if (partner === Partner.HEI) {
          return (
            <>
              This informed consent form is used by Imagene Labs, trading as hei (‘hei’ or ‘us’), to record your consent given for the processing of information and data. If you have any questions, please contact us at hello@healthinside.ai.
              <br /><br />
              Please review the following statements and tick the boxes that you agree to:
            </>
          );
        }

        return <>Please confirm that you understand and accept the terms of using this platform</>;
      default:
        return <>Already have an account? <Link to={ROUTES.LOGIN}>Sign in</Link></>;
    }
  };

  const getButtonText = () => currentStep === stepFields.length - 1 ? "Submit" : "Next";

  const getBasicInformationFields = () => (
    <>
      <FormInputWrapper>
        <Label>Enter your first name</Label>
        <FormikInput
          name="firstName"
          placeholder="First name"
          visualSize="large"
        />
      </FormInputWrapper>
      <FormInputWrapper>
        <Label>Enter your last name</Label>
        <FormikInput
          name="lastName"
          placeholder="Last name"
          visualSize="large"
        />
      </FormInputWrapper>
      <FormInputWrapper>
        <Label>Enter your email address</Label>
        <FormikInput
          name="email"
          placeholder="Email address"
          visualSize="large"
          type="email"
        />
      </FormInputWrapper>
      <FormInputWrapper>
        <Label>Create a password</Label>
        <FormikInput
          name="plainPassword"
          placeholder="Password"
          visualSize="large"
          type="password"
          note="The password must contain at least 8 symbols, one capital letter, one lower case letter, one number and one special character."
        />
      </FormInputWrapper>
      <FormInputWrapper>
        <Label>Repeat the password</Label>
        <FormikInput
          name="passwordConfirmation"
          placeholder="Password"
          visualSize="large"
          type="password"
        />
      </FormInputWrapper>
    </>
  );

  const getContactInformationFields = () => (
    <>
      <FormInputWrapper>
        <Label>Enter your address</Label>
        <FormikInput
          name="coachInformation.address.address1"
          placeholder="Address"
          visualSize="large"
        />
      </FormInputWrapper>
      <FormInputWrapper>
        <Label>Post code</Label>
        <FormikInput
          name="coachInformation.address.postCode"
          placeholder="Post code"
          visualSize="large"
        />
      </FormInputWrapper>
      <FormInputWrapper>
        <Label>City</Label>
        <FormikInput
          name="coachInformation.address.city"
          placeholder="City"
          visualSize="large"
        />
      </FormInputWrapper>
      <FormInputWrapper>
        <Label>Country</Label>
        <FormikDropdown
          name="coachInformation.address.country"
          placeholder="Country"
          visualSize="large"
        >
          {countries?.map((country) => (
            <option key={country.iso} value={country.iso}>
              {country.name}
            </option>
          ))}
        </FormikDropdown>
      </FormInputWrapper>
      <FormInputWrapper>
        <Label>Phone number</Label>
        <FormikInput
          name="coachInformation.address.phoneNumber"
          placeholder="Phone number"
          visualSize="large"
        />
      </FormInputWrapper>
    </>
  );

  const getReferralFields = () => (
    <FormInputWrapper>
      <Label>Enter your Referral code</Label>
      <FormikInput
        name="coachInformation.referredByReferralPartner"
        placeholder="Referral code"
        visualSize="large"
        note={<>If you <b>do not</b> have a Referral code, please leave this field blank.</>}
      />
    </FormInputWrapper>
  );

  const getConsentFields = (errors: FormikErrors<FormFields>, touched: FormikTouched<FormFields>) => {
    if (partner === Partner.HEI) {
      return (
        <>
          <CheckboxWrapper>
            <FormikCheckbox name="agreedWithPrivacyPolicy" />
            <CheckboxLabel
              htmlFor="agreedWithPrivacyPolicy"
              className={clsx(touched.agreedWithPrivacyPolicy && errors.agreedWithPrivacyPolicy ? 'text-danger' : '')}
            >
              I acknowledge that I have read and understood the <a href={privacyPolicyUrl} target="_blank" rel="noreferrer">Privacy Policy</a> and <a href={endUserLicenseAgreementUrl} target="_blank" rel="noreferrer">End User Licence Agreement</a> of the hei platform
            </CheckboxLabel>
          </CheckboxWrapper>
          <CheckboxWrapper>
            <FormikCheckbox name="agreedWithEndUserLicenseAgreement" />
            <CheckboxLabel
              htmlFor="agreedWithEndUserLicenseAgreement"
              className={clsx(touched.agreedWithEndUserLicenseAgreement && errors.agreedWithEndUserLicenseAgreement ? 'text-danger' : '')}
            >
              I acknowledge that I have read and understood the <a href={termsAndConditionsUrl} target="_blank" rel="noreferrer">Terms and Conditions</a> of the hei platform
            </CheckboxLabel>
          </CheckboxWrapper>
          <CheckboxWrapper>
            <FormikCheckbox name="agreedWithPersonalDataCollection" />
            <CheckboxLabel
              htmlFor="agreedWithPersonalDataCollection"
              className={clsx(touched.agreedWithPersonalDataCollection && errors.agreedWithPersonalDataCollection ? 'text-danger' : '')}
            >
              I consent to the collection, use and disclosure of my personal data for the purpose of signing up in the hei platform
            </CheckboxLabel>
          </CheckboxWrapper>
          <CheckboxWrapper>
            <FormikCheckbox name="legalDisclosureHealthAdvice" />
            <CheckboxLabel
              htmlFor="legalDisclosureHealthAdvice"
              className={clsx(touched.legalDisclosureHealthAdvice && errors.legalDisclosureHealthAdvice ? 'text-danger' : '')}
            >
              I confirm that under my local law I am allowed to provide health and wellness advice as well as recommend the purchase of nutritional supplements and biological tests
            </CheckboxLabel>
          </CheckboxWrapper>
        </>
      );
    }

    return (
      <>
        <CheckboxWrapper>
          <FormikCheckbox name="agreedWithPrivacyPolicy" />
          <CheckboxLabel
            htmlFor="agreedWithPrivacyPolicy"
            className={clsx(touched.agreedWithPrivacyPolicy && errors.agreedWithPrivacyPolicy ? 'text-danger' : '')}
          >
            I agree to the <a href={privacyPolicyUrl} target="_blank" rel="noreferrer">Privacy Policy</a> and the <a href={termsAndConditionsUrl} target="_blank" rel="noreferrer">Terms and Conditions</a>
          </CheckboxLabel>
        </CheckboxWrapper>
        <CheckboxWrapper>
          <FormikCheckbox name="agreedWithEndUserLicenseAgreement" />
          <CheckboxLabel
            htmlFor="agreedWithEndUserLicenseAgreement"
            className={clsx(touched.agreedWithEndUserLicenseAgreement && errors.agreedWithEndUserLicenseAgreement ? 'text-danger' : '')}
          >
            I accept the <a href={endUserLicenseAgreementUrl} target="_blank" rel="noreferrer">End User Licence Agreement</a>
          </CheckboxLabel>
        </CheckboxWrapper>
        <CheckboxWrapper>
          <FormikCheckbox name="legalDisclosureHealthAdvice" />
          <CheckboxLabel
            htmlFor="legalDisclosureHealthAdvice"
            className={clsx(touched.legalDisclosureHealthAdvice && errors.legalDisclosureHealthAdvice ? 'text-danger' : '')}
          >
            I confirm that under my local law I am allowed to provide health and wellness advice as well as recommend the purchase of nutritional supplements and biological tests
          </CheckboxLabel>
        </CheckboxWrapper>
      </>
    );
  }

  const getStepFields = (errors: FormikErrors<FormFields>, touched: FormikTouched<FormFields>) => {
    switch (currentStep) {
      case 0:
        return getBasicInformationFields();

      case 1:
        return getContactInformationFields();

      case 2:
        return getReferralFields();

      case 3:
        return getConsentFields(errors, touched);

      default:
        return <></>;
    }
  };

  const handleNext = async (values: FormFields, submitForm: SubmitFn, setErrors: SetErrorsFn, setTouched: SetTouchedFn) => {
    const isValid = await validateStepFields(values, setErrors, setTouched);

    if (isValid && currentStep === stepFields.length - 1) {
      submitForm();
    }
  }

  return (
    <div data-testid="register">
      <GetHelpWrapper>
        Having issues? <a href={helpUrl} target="_blank" rel="noreferrer">Get help</a>
      </GetHelpWrapper>
      <ContentWrapper>
        <Heading>{getTitle()}</Heading>
        <LinkWrapper>{getSubTitle()}</LinkWrapper>
        <FormWrapper>
          <Formik
            initialValues={initialValues()}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
          >
            {({ values, errors, setErrors, touched, setTouched, submitForm }) => (
              <Form>
                {getStepFields(errors, touched)}

                <ActionButtonWrapper>
                  <StyledButton
                    onClick={() => handleNext(values, submitForm, setErrors, setTouched)}
                    type="button"
                    size="extraLarge"
                    isLoading={isLoading}
                    isBlock
                    variant={partnerButtonVariant}
                  >
                    {getButtonText()}
                  </StyledButton>
                </ActionButtonWrapper>
              </Form>
            )}
          </Formik>
        </FormWrapper>
      </ContentWrapper>
    </div>
  );
}
