import { useQuery } from 'react-query';

import { model as subscriptionPrice, SubscriptionPrice } from './subscriptionPrice';

export const coachSubscriptionPricesQueryKey = (id?: string) => `coachSubscriptionPrices--${id}`;

export const useCoachSubscriptionPrices = (id?: string) =>
  useQuery<SubscriptionPrice[]>(coachSubscriptionPricesQueryKey(id), () => subscriptionPrice.getCoachSubscriptionPrices(id), {
    enabled: !!id,
  });
