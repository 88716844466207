import React from 'react';
import { useClient } from '~/components/pages/client/context';
import { useWidgetHistory } from '~/model/Client/Progress/hooks/useWidgetHistory';
import { ExpandableTable, TableRow, TableRowContent } from '~/components/pages/client/Sections/ExpandableTable';
import clsx from 'clsx';
import { css } from '@emotion/css';
import { colors } from '~/components/utils/colors';
import { DateTime } from 'luxon';
import { getVariantFromColor } from '~/components/common/Label';
import { Loader } from '~/components/common/Loader';
import { TargetType, WidgetHistoryDayData, WidgetId } from '~/model/Client/Progress/progress';
import { Header } from '~/components/pages/client/Sections/ExpandableTable/model';
import { DateIntervalLabel } from '~/components/common/DateIntervalLabel';
import { DayDataRow, LoaderContainer } from '../Modal.style';

const headerColumns: Header[] = [
  {
    title: 'Date',
    width: '8fr',
  },
  {
    title: 'Time',
    width: '6fr',
  },
  {
    title: 'HR (bpm)',
    width: '6fr',
  },
  {
    title: 'Result',
    width: '8fr',
  },
  {
    title: 'Target',
    width: '6fr',
  },
  {
    title: '',
    width: '0',
  },
];

export const BloodPressureHistory = () => {
  const { clientId } = useClient();
  const { data, isLoading } = useWidgetHistory(clientId, WidgetId.BloodPressue);

  if (isLoading) return <LoaderContainer><Loader center /></LoaderContainer>;

  const formatValue = (value) => {
    const { systolic, diastolic } = value;

    return `${systolic}/${diastolic}`;
  }

  const tableRow = (dayData: WidgetHistoryDayData[], target: TargetType) => (
    <TableRow key={WidgetId.BloodPressue}>
      {dayData.map(({ time, systolic, diastolic, hr, colour }) => {
        const date = DateTime.fromJSDate(new Date(time));

        return (
          <DayDataRow
            key={time}
            $widths={headerColumns.map(({ width }) => width || '1fr')}
          >
            <div>{date.toFormat('dd/MM/yyyy')}</div>
            <div>{date.toFormat('HH:mm')}</div>
            <div>{hr || 'N/A'}</div>
            <div className={`text-${getVariantFromColor(colour)}`}>{systolic}/{diastolic}</div>
            <div>{formatValue(target)}</div>
          </DayDataRow>
        )
      })}
    </TableRow>
  );

  return (
    <ExpandableTable
      headers={headerColumns}
      withHeader={false}
      activeOnExpand
      rows={(data ?? []).map(({ diff, target, days, weekFrom, weekTo }, weekIndex) => {
        const hasAnyData = days.length > 0;

        return ({
          title: `week-${weekIndex}`,
          showTitle: false,
          titleRowContent: [
            <span className={clsx(css({ gridColumnStart: 1, gridColumnEnd: 4 }))}>
              <DateIntervalLabel from={new Date(weekFrom)} to={new Date(weekTo)} />
            </span>,
            <span className={clsx(css({ gridColumnStart: 4, color: colors.colorDisabledOne }))}>
              {diff}
            </span>,
            <span className={clsx(css({ gridColumnStart: 5, color: colors.colorDisabledOne }))}>
              {formatValue(target)}
            </span>,
          ],
          content: (
            <TableRowContent className={clsx(css({ color: colors.colorGsThree, fontWeight: 500 }))}>
              {hasAnyData ? tableRow(days, target) : (
                <TableRow>
                  <DayDataRow $widths={['1fr']}>
                    <div>Client has not logged any data for this week</div>
                  </DayDataRow>
                </TableRow>
              )}
            </TableRowContent>
          )
        });
      })}
    />
  );
};
