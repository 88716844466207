import { css } from '@emotion/css';

export const style = {
  label: css({
    margin: '10px 30px 10px 0',
    fontWeight: 500,
    fontSize: '14px',
    alignSelf: 'baseline',
    textAlign: 'right',
  }),
};
