import { Color } from '~/components/common/Label';
import { Header } from '~/components/pages/client/Sections/ExpandableTable/model';
import { TestKit } from '../TestKits/model';
import { style } from './TestResults.style';

export interface TestResultsResponse {
  updatedAt: string | null;
  items: TestResultCategory[];
}

export interface TestResultCategory {
  name: string;
  tests: Test[];
  availableResults: number;
}

export interface Test {
  id: number;
  name: string;
  measuringUnits: string;
  testResults: TestResult[];
  testKits: TestKit[];
}

export interface TestResultLabel {
  value: string;
  color: Color;
}

export interface TestResult {
  id: number;
  date: string;
  value: number;
  valueDiff?: TestResultLabel;
  resultRangeValueFrom?: number;
  resultRangeValueTo?: number;
  result: TestResultLabel;
}

export const headerColumns: Header[] = [
  {
    title: 'Biomarker',
    width: '2fr',
  },
  {
    title: 'Test Kit',
    width: '1.75fr',
  },
  {
    title: 'Date',
    width: '1.15fr',
  },
  {
    title: 'Value',
    width: '1.15fr',
  },
  {
    title: 'Result Range',
    width: '1.15fr',
  },
  {
    title: 'Result',
    width: '1.75fr',
  },
  {
    title: 'Action',
    className: style.headers.actions,
    width: '1fr',
  },
];
