import * as React from 'react';
import { SVGProps } from 'react';
import { Size } from '../types';
interface Props extends SVGProps<SVGSVGElement> {
  size?: Size;
}
const SvgWatch = (props: Props) => {
  const getSize = () => {
    if (props.size) {
      switch (props.size) {
        case 'small':
          return 20;

        case 'medium':
          return 24;

        case 'large':
          return 32;

        default:
          return null;
      }
    }
  };

  return (
    <svg
      width={getSize() || 33}
      height={getSize() || 32}
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 33 32"
      {...props}
    >
      <path opacity="0.3" d="M9.00003 17H15V20.5C15 21.3284 14.3285 22 13.5 22H10.5C9.6716 22 9.00003 21.3284 9.00003 20.5V17Z" fill="currentColor" />
      <path opacity="0.3" fillRule="evenodd" clipRule="evenodd" d="M12 17C14.7615 17 17 14.7614 17 12C17 9.23858 14.7615 7 12 7C9.23861 7 7.00003 9.23858 7.00003 12C7.00003 14.7614 9.23861 17 12 17Z" fill="currentColor" />
      <path opacity="0.3" d="M9.00003 3.5C9.00003 2.67157 9.6716 2 10.5 2H13.5C14.3285 2 15 2.67157 15 3.5V7H9.00003V3.5Z" fill="currentColor" />
      <path fillRule="evenodd" clipRule="evenodd" d="M5.00003 12C5.00003 15.866 8.13404 19 12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5C8.13404 5 5.00003 8.13401 5.00003 12ZM17 12C17 14.7614 14.7615 17 12 17C9.23861 17 7.00003 14.7614 7.00003 12C7.00003 9.23858 9.23861 7 12 7C14.7615 7 17 9.23858 17 12Z" fill="currentColor" />
    </svg>
  );
};

export default SvgWatch;
