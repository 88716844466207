import { useQuery } from 'react-query';
import { DeviceCategory } from '~/components/pages/client/Sections/Devices/model';
import { model as clientDeviceModel } from './devices';

export const clientsQueryKey = (clientId) => ['client', clientId, 'devices'];

export const useClientDevices = (clientId) =>
  useQuery<DeviceCategory[]>(clientsQueryKey(clientId), () =>
    clientDeviceModel.getList(clientId),
  );
