import React from 'react';
import { Outlet } from 'react-router-dom';
import { TokenProvider } from '~/components/common/TokenProvider';
import { CometChatProvider } from '~/components/pages/cometChat';
import { CoachProvider } from '~/components/pages/profile';
import { Layout, Content } from '~/vendor/_metronic/layout';
import { FeatureTogglesProvider } from '../../../services/feature-toggle';

export const AuthedWrapper = () => (
  <TokenProvider>
    <FeatureTogglesProvider>
      <CoachProvider>
        <CometChatProvider>
          <Layout>
            <Content>
              <Outlet />
            </Content>
          </Layout>
        </CometChatProvider>
      </CoachProvider>
    </FeatureTogglesProvider>
  </TokenProvider>
);
