import { css } from '@emotion/css';
import { colors } from '~/components/utils/colors';

export const style = {
  uploadButton: css({
    all: 'unset',
    position: 'absolute',
    top: '10px',
    right: '15px',
    cursor: 'pointer',
  }),
  uploadField: css({
    background: `${colors.colorWhite} !important`,
    paddingRight: '45px !important',
  }),
};
