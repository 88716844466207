/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { InputHTMLAttributes, useEffect, useRef } from 'react';
import clsx from 'clsx';
import { useField } from 'formik';

import { Label } from '~/components/common/Form/Label';
import { ErrorText } from '~/components/common/Form/Error';

import { style, TextArea as TextAreaComponent } from './Input.style';

interface Props extends InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement> {
  name: string;
  label?: string;
  wrapperClassName?: string;
  error?: string;
  note?: string;
  rows?: number;
  autoHeight?: boolean;
  minHeight?: string;
  maxHeight?: string;
}

export const TextArea = ({
  label,
  wrapperClassName,
  className,
  error,
  note,
  rows,
  autoHeight,
  minHeight,
  maxHeight,
  ...otherProps
}: Props) => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const handleOnChange = (e: React.FormEvent<HTMLTextAreaElement>) => {
    const target = e.target as HTMLTextAreaElement;
    target.style.height = '0px';
    target.style.height = `${target.scrollHeight + 2}px`;
  };

  useEffect(() => {
    textAreaRef.current!.style.height = `${textAreaRef.current!.scrollHeight + 2}px`;
  }, []);

  return (
    <div className={clsx(wrapperClassName, style.wrapper)}>
      {label && <Label>label</Label>}
      <div className={style.inputWrapper}>
        <TextAreaComponent
          className={clsx(className, 'form-control', style.input, { 'is-invalid': !!error })}
          ref={textAreaRef}
          $minHeight={minHeight}
          $maxHeight={maxHeight}
          rows={rows || 1}
          onChangeCapture={autoHeight ? handleOnChange : undefined}
          {...otherProps}
        />
        {error && <ErrorText text={error} />}
        {note && <small className={clsx('text-gsThree', style.subnote, style.note)}>{note}</small>}
      </div>
    </div>
  );
};

type FormikTextAreaProps = Omit<Props, 'error'>;
export const FormikTextArea = ({ name, ...props }: FormikTextAreaProps) => {
  const [field, meta] = useField({ id: name, name, ...props });

  return (
    <TextArea
      id={name}
      error={meta.touched && meta.error ? meta.error : undefined}
      {...field}
      {...props}
    />
  );
};
