import * as React from 'react';
import { css } from '@emotion/css';
import clsx from 'clsx';
import { style } from './Loader.style';
import { getAlignmentClassName, getWidthClassName, Props } from './LoaderTypes';

export const OmeLoader = ({
  size = 'medium',
  testId,
  wrapperHeight = 'auto',
  fullScreen = false,
  center,
  ...otherProps
}: Props) => (
  <div
    className={clsx(style.wrapper, css({ height: wrapperHeight }), {
      [style.fullScreen]: fullScreen,
    })}
  >
    <svg
      data-testid={testId}
      id="ed4C2zWBfFN1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 566.9 566.9"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      className={clsx(
        css({
          shapeRendering: 'auto',
          display: 'block',
        }),
        getWidthClassName(size),
        getAlignmentClassName(center),
      )}
      preserveAspectRatio="xMidYMid"
      {...otherProps}
    >
      <style>
        {
          '@keyframes ed4C2zWBfFN2_c_o{0%,33.333333%,66.666667%,to{opacity:1}16.666667%,50%,83.333333%{opacity:.1}}'
        }
      </style>
      <defs>
        <linearGradient
          id="loader-svg_svg__ed4C2zWBfFN3-fill"
          x1={-105.599}
          y1={120.715}
          x2={398.405}
          y2={663.488}
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
        >
          <stop id="loader-svg_svg__ed4C2zWBfFN3-fill-0" offset="25%" stopColor="#EC1775" />
          <stop id="loader-svg_svg__ed4C2zWBfFN3-fill-1" offset="29.79%" stopColor="#EB1577" />
          <stop id="loader-svg_svg__ed4C2zWBfFN3-fill-2" offset="75%" stopColor="#E30988" />
        </linearGradient>
        <linearGradient
          id="loader-svg_svg__ed4C2zWBfFN4-fill"
          x1={-104.258}
          y1={119.469}
          x2={399.746}
          y2={662.243}
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
        >
          <stop id="loader-svg_svg__ed4C2zWBfFN4-fill-0" offset="25%" stopColor="#EC1775" />
          <stop id="loader-svg_svg__ed4C2zWBfFN4-fill-1" offset="29.79%" stopColor="#EB1577" />
          <stop id="loader-svg_svg__ed4C2zWBfFN4-fill-2" offset="75%" stopColor="#E30988" />
        </linearGradient>
        <linearGradient
          id="loader-svg_svg__ed4C2zWBfFN5-fill"
          x1={-40.403}
          y1={60.176}
          x2={463.601}
          y2={602.949}
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
        >
          <stop id="loader-svg_svg__ed4C2zWBfFN5-fill-0" offset="25%" stopColor="#EC1775" />
          <stop id="loader-svg_svg__ed4C2zWBfFN5-fill-1" offset="29.79%" stopColor="#EB1577" />
          <stop id="loader-svg_svg__ed4C2zWBfFN5-fill-2" offset="75%" stopColor="#E30988" />
        </linearGradient>
        <linearGradient
          id="loader-svg_svg__ed4C2zWBfFN6-fill"
          x1={-151.456}
          y1={163.296}
          x2={352.548}
          y2={706.069}
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
        >
          <stop id="loader-svg_svg__ed4C2zWBfFN6-fill-0" offset="25%" stopColor="#EC1775" />
          <stop id="loader-svg_svg__ed4C2zWBfFN6-fill-1" offset="29.79%" stopColor="#EB1577" />
          <stop id="loader-svg_svg__ed4C2zWBfFN6-fill-2" offset="75%" stopColor="#E30988" />
        </linearGradient>
        <linearGradient
          id="loader-svg_svg__ed4C2zWBfFN7-fill"
          x1={-43.756}
          y1={63.289}
          x2={460.248}
          y2={606.063}
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
        >
          <stop id="loader-svg_svg__ed4C2zWBfFN7-fill-0" offset="25%" stopColor="#EC1775" />
          <stop id="loader-svg_svg__ed4C2zWBfFN7-fill-1" offset="29.79%" stopColor="#EB1577" />
          <stop id="loader-svg_svg__ed4C2zWBfFN7-fill-2" offset="75%" stopColor="#E30988" />
        </linearGradient>
        <linearGradient
          id="loader-svg_svg__ed4C2zWBfFN8-fill"
          x1={41.058}
          y1={-15.467}
          x2={545.062}
          y2={527.307}
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
        >
          <stop id="loader-svg_svg__ed4C2zWBfFN8-fill-0" offset="25%" stopColor="#EC1775" />
          <stop id="loader-svg_svg__ed4C2zWBfFN8-fill-1" offset="29.79%" stopColor="#EB1577" />
          <stop id="loader-svg_svg__ed4C2zWBfFN8-fill-2" offset="75%" stopColor="#E30988" />
        </linearGradient>
        <linearGradient
          id="loader-svg_svg__ed4C2zWBfFN9-fill"
          x1={53.285}
          y1={-26.82}
          x2={557.288}
          y2={515.953}
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
        >
          <stop id="loader-svg_svg__ed4C2zWBfFN9-fill-0" offset="25%" stopColor="#EC1775" />
          <stop id="loader-svg_svg__ed4C2zWBfFN9-fill-1" offset="29.79%" stopColor="#EB1577" />
          <stop id="loader-svg_svg__ed4C2zWBfFN9-fill-2" offset="75%" stopColor="#E30988" />
        </linearGradient>
        <linearGradient
          id="loader-svg_svg__ed4C2zWBfFN10-fill"
          x1={-90.539}
          y1={106.731}
          x2={413.464}
          y2={649.504}
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
        >
          <stop id="loader-svg_svg__ed4C2zWBfFN10-fill-0" offset="25%" stopColor="#EC1775" />
          <stop id="loader-svg_svg__ed4C2zWBfFN10-fill-1" offset="29.79%" stopColor="#EB1577" />
          <stop id="loader-svg_svg__ed4C2zWBfFN10-fill-2" offset="75%" stopColor="#E30988" />
        </linearGradient>
        <linearGradient
          id="loader-svg_svg__ed4C2zWBfFN11-fill"
          x1={3.567}
          y1={19.346}
          x2={507.571}
          y2={562.12}
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
        >
          <stop id="loader-svg_svg__ed4C2zWBfFN11-fill-0" offset="25%" stopColor="#EC1775" />
          <stop id="loader-svg_svg__ed4C2zWBfFN11-fill-1" offset="29.79%" stopColor="#EB1577" />
          <stop id="loader-svg_svg__ed4C2zWBfFN11-fill-2" offset="75%" stopColor="#E30988" />
        </linearGradient>
        <linearGradient
          id="loader-svg_svg__ed4C2zWBfFN12-fill"
          x1={-115.927}
          y1={130.305}
          x2={388.077}
          y2={673.078}
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
        >
          <stop id="loader-svg_svg__ed4C2zWBfFN12-fill-0" offset="25%" stopColor="#EC1775" />
          <stop id="loader-svg_svg__ed4C2zWBfFN12-fill-1" offset="29.79%" stopColor="#EB1577" />
          <stop id="loader-svg_svg__ed4C2zWBfFN12-fill-2" offset="75%" stopColor="#E30988" />
        </linearGradient>
        <linearGradient
          id="loader-svg_svg__ed4C2zWBfFN13-fill"
          x1={16.649}
          y1={7.199}
          x2={520.653}
          y2={549.972}
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
        >
          <stop id="loader-svg_svg__ed4C2zWBfFN13-fill-0" offset="25%" stopColor="#EC1775" />
          <stop id="loader-svg_svg__ed4C2zWBfFN13-fill-1" offset="29.79%" stopColor="#EB1577" />
          <stop id="loader-svg_svg__ed4C2zWBfFN13-fill-2" offset="75%" stopColor="#E30988" />
        </linearGradient>
        <linearGradient
          id="loader-svg_svg__ed4C2zWBfFN14-fill"
          x1={94.62}
          y1={-65.203}
          x2={598.623}
          y2={477.571}
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
        >
          <stop id="loader-svg_svg__ed4C2zWBfFN14-fill-0" offset="25%" stopColor="#EC1775" />
          <stop id="loader-svg_svg__ed4C2zWBfFN14-fill-1" offset="29.79%" stopColor="#EB1577" />
          <stop id="loader-svg_svg__ed4C2zWBfFN14-fill-2" offset="75%" stopColor="#E30988" />
        </linearGradient>
        <linearGradient
          id="loader-svg_svg__ed4C2zWBfFN15-fill"
          x1={-71.868}
          y1={89.393}
          x2={432.136}
          y2={632.166}
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
        >
          <stop id="loader-svg_svg__ed4C2zWBfFN15-fill-0" offset="25%" stopColor="#EC1775" />
          <stop id="loader-svg_svg__ed4C2zWBfFN15-fill-1" offset="29.79%" stopColor="#EB1577" />
          <stop id="loader-svg_svg__ed4C2zWBfFN15-fill-2" offset="75%" stopColor="#E30988" />
        </linearGradient>
        <linearGradient
          id="loader-svg_svg__ed4C2zWBfFN16-fill"
          x1={37.889}
          y1={-12.524}
          x2={541.893}
          y2={530.249}
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
        >
          <stop id="loader-svg_svg__ed4C2zWBfFN16-fill-0" offset="25%" stopColor="#EC1775" />
          <stop id="loader-svg_svg__ed4C2zWBfFN16-fill-1" offset="29.79%" stopColor="#EB1577" />
          <stop id="loader-svg_svg__ed4C2zWBfFN16-fill-2" offset="75%" stopColor="#E30988" />
        </linearGradient>
        <linearGradient
          id="loader-svg_svg__ed4C2zWBfFN17-fill"
          x1={89.611}
          y1={-60.552}
          x2={593.615}
          y2={482.221}
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
        >
          <stop id="loader-svg_svg__ed4C2zWBfFN17-fill-0" offset="25%" stopColor="#EC1775" />
          <stop id="loader-svg_svg__ed4C2zWBfFN17-fill-1" offset="29.79%" stopColor="#EB1577" />
          <stop id="loader-svg_svg__ed4C2zWBfFN17-fill-2" offset="75%" stopColor="#E30988" />
        </linearGradient>
        <linearGradient
          id="loader-svg_svg__ed4C2zWBfFN18-fill"
          x1={134.529}
          y1={-102.262}
          x2={638.533}
          y2={440.512}
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
        >
          <stop id="loader-svg_svg__ed4C2zWBfFN18-fill-0" offset="25%" stopColor="#EC1775" />
          <stop id="loader-svg_svg__ed4C2zWBfFN18-fill-1" offset="29.79%" stopColor="#EB1577" />
          <stop id="loader-svg_svg__ed4C2zWBfFN18-fill-2" offset="75%" stopColor="#E30988" />
        </linearGradient>
        <linearGradient
          id="loader-svg_svg__ed4C2zWBfFN19-fill"
          x1={-34.126}
          y1={54.347}
          x2={469.878}
          y2={597.12}
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
        >
          <stop id="loader-svg_svg__ed4C2zWBfFN19-fill-0" offset="25%" stopColor="#EC1775" />
          <stop id="loader-svg_svg__ed4C2zWBfFN19-fill-1" offset="29.79%" stopColor="#EB1577" />
          <stop id="loader-svg_svg__ed4C2zWBfFN19-fill-2" offset="75%" stopColor="#E30988" />
        </linearGradient>
        <linearGradient
          id="loader-svg_svg__ed4C2zWBfFN20-fill"
          x1={81.079}
          y1={-52.629}
          x2={585.082}
          y2={490.144}
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
        >
          <stop id="loader-svg_svg__ed4C2zWBfFN20-fill-0" offset="25%" stopColor="#EC1775" />
          <stop id="loader-svg_svg__ed4C2zWBfFN20-fill-1" offset="29.79%" stopColor="#EB1577" />
          <stop id="loader-svg_svg__ed4C2zWBfFN20-fill-2" offset="75%" stopColor="#E30988" />
        </linearGradient>
        <linearGradient
          id="loader-svg_svg__ed4C2zWBfFN21-fill"
          x1={157.482}
          y1={-123.575}
          x2={661.486}
          y2={419.198}
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
        >
          <stop id="loader-svg_svg__ed4C2zWBfFN21-fill-0" offset="25%" stopColor="#EC1775" />
          <stop id="loader-svg_svg__ed4C2zWBfFN21-fill-1" offset="29.79%" stopColor="#EB1577" />
          <stop id="loader-svg_svg__ed4C2zWBfFN21-fill-2" offset="75%" stopColor="#E30988" />
        </linearGradient>
        <linearGradient
          id="loader-svg_svg__ed4C2zWBfFN22-fill"
          x1={-20.974}
          y1={42.135}
          x2={483.029}
          y2={584.908}
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
        >
          <stop id="loader-svg_svg__ed4C2zWBfFN22-fill-0" offset="25%" stopColor="#EC1775" />
          <stop id="loader-svg_svg__ed4C2zWBfFN22-fill-1" offset="29.79%" stopColor="#EB1577" />
          <stop id="loader-svg_svg__ed4C2zWBfFN22-fill-2" offset="75%" stopColor="#E30988" />
        </linearGradient>
        <linearGradient
          id="loader-svg_svg__ed4C2zWBfFN23-fill"
          x1={87.71}
          y1={-58.786}
          x2={591.714}
          y2={483.987}
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
        >
          <stop id="loader-svg_svg__ed4C2zWBfFN23-fill-0" offset="25%" stopColor="#EC1775" />
          <stop id="loader-svg_svg__ed4C2zWBfFN23-fill-1" offset="29.79%" stopColor="#EB1577" />
          <stop id="loader-svg_svg__ed4C2zWBfFN23-fill-2" offset="75%" stopColor="#E30988" />
        </linearGradient>
        <linearGradient
          id="loader-svg_svg__ed4C2zWBfFN24-fill"
          x1={168.136}
          y1={-133.468}
          x2={672.14}
          y2={409.305}
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
        >
          <stop id="loader-svg_svg__ed4C2zWBfFN24-fill-0" offset="25%" stopColor="#EC1775" />
          <stop id="loader-svg_svg__ed4C2zWBfFN24-fill-1" offset="29.79%" stopColor="#EB1577" />
          <stop id="loader-svg_svg__ed4C2zWBfFN24-fill-2" offset="75%" stopColor="#E30988" />
        </linearGradient>
        <linearGradient
          id="loader-svg_svg__ed4C2zWBfFN25-fill"
          x1={205.484}
          y1={-168.149}
          x2={709.488}
          y2={374.625}
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
        >
          <stop id="loader-svg_svg__ed4C2zWBfFN25-fill-0" offset="25%" stopColor="#EC1775" />
          <stop id="loader-svg_svg__ed4C2zWBfFN25-fill-1" offset="29.79%" stopColor="#EB1577" />
          <stop id="loader-svg_svg__ed4C2zWBfFN25-fill-2" offset="75%" stopColor="#E30988" />
        </linearGradient>
        <linearGradient
          id="loader-svg_svg__ed4C2zWBfFN26-fill"
          x1={75.231}
          y1={-47.199}
          x2={579.235}
          y2={495.575}
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
        >
          <stop id="loader-svg_svg__ed4C2zWBfFN26-fill-0" offset="25%" stopColor="#EC1775" />
          <stop id="loader-svg_svg__ed4C2zWBfFN26-fill-1" offset="29.79%" stopColor="#EB1577" />
          <stop id="loader-svg_svg__ed4C2zWBfFN26-fill-2" offset="75%" stopColor="#E30988" />
        </linearGradient>
        <linearGradient
          id="loader-svg_svg__ed4C2zWBfFN27-fill"
          x1={177.056}
          y1={-141.751}
          x2={681.06}
          y2={401.022}
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
        >
          <stop id="loader-svg_svg__ed4C2zWBfFN27-fill-0" offset="25%" stopColor="#EC1775" />
          <stop id="loader-svg_svg__ed4C2zWBfFN27-fill-1" offset="29.79%" stopColor="#EB1577" />
          <stop id="loader-svg_svg__ed4C2zWBfFN27-fill-2" offset="75%" stopColor="#E30988" />
        </linearGradient>
      </defs>
      <g
        style={{
          animation: 'ed4C2zWBfFN2_c_o 3000ms linear infinite normal forwards',
        }}
        stroke="none"
        strokeWidth={1}
      >
        <path
          id="loader-svg_svg__ed4C2zWBfFN3"
          d="M325.6 529.8c-24.9-16.9-59.4-16.8-84.3 0-5.5 3.7-6.9 11.1-3.2 16.6 3.7 5.5 11.1 6.9 16.6 3.2 17-11.5 40.5-11.5 57.5 0 2.1 1.4 4.4 2 6.7 2 3.8 0 7.6-1.8 9.9-5.3 3.7-5.4 2.3-12.8-3.2-16.5z"
          fill="url(#loader-svg_svg__ed4C2zWBfFN3-fill)"
        />
        <path
          id="loader-svg_svg__ed4C2zWBfFN4"
          d="M302.3 478.9c6.5 1.1 12.7-3.3 13.7-9.8 1.1-6.5-3.3-12.7-9.8-13.7-7.5-1.2-15.1-1.9-22.7-1.9-44.5 0-86.7 21.6-112.7 57.8-3.9 5.4-2.6 12.8 2.7 16.7 2.1 1.5 4.6 2.2 7 2.2 3.7 0 7.4-1.7 9.7-5 21.6-30 56.5-47.9 93.3-47.9 6.3.1 12.6.6 18.8 1.6z"
          fill="url(#loader-svg_svg__ed4C2zWBfFN4-fill)"
        />
        <path
          id="loader-svg_svg__ed4C2zWBfFN5"
          d="M357.7 475c-5.6-3.5-12.9-1.9-16.5 3.7-3.5 5.6-1.9 13 3.7 16.5 12.5 7.9 23.2 18 31.9 30.1 2.3 3.2 6 5 9.7 5 2.4 0 4.9-.7 7-2.2 5.4-3.9 6.6-11.3 2.7-16.7-10.4-14.6-23.4-26.8-38.5-36.4z"
          fill="url(#loader-svg_svg__ed4C2zWBfFN5-fill)"
        />
        <path
          id="loader-svg_svg__ed4C2zWBfFN6"
          d="M117.5 400c-4.6-4.8-12.1-5-16.9-.4-12.3 11.6-23.4 24.5-33.3 38.2-3.8 5.4-2.6 12.8 2.8 16.7 2.1 1.5 4.5 2.2 6.9 2.2 3.7 0 7.4-1.7 9.7-5 8.9-12.5 19.1-24.2 30.3-34.8 4.9-4.5 5.1-12.1.5-16.9z"
          fill="url(#loader-svg_svg__ed4C2zWBfFN6-fill)"
        />
        <path
          id="loader-svg_svg__ed4C2zWBfFN7"
          d="M283.5 326.7c-50.8 0-100.3 14.4-142.9 41.7-5.6 3.6-7.2 10.9-3.6 16.5 2.3 3.6 6.1 5.5 10.1 5.5 2.2 0 4.4-.6 6.4-1.9 38.8-24.8 83.8-37.9 130-37.9 10.3 0 20.6.6 30.7 1.9 6.4.8 12.5-3.8 13.4-10.4.8-6.5-3.8-12.5-10.4-13.4-11.1-1.3-22.5-2-33.7-2z"
          fill="url(#loader-svg_svg__ed4C2zWBfFN7-fill)"
        />
        <path
          id="loader-svg_svg__ed4C2zWBfFN8"
          d="M349.4 347c-2 6.3 1.5 13 7.7 15 26.9 8.6 51.9 21.8 74.3 39.1l2.3 1.8c2.2 1.7 4.8 2.6 7.4 2.6 3.5 0 7-1.6 9.4-4.5 4.1-5.2 3.2-12.7-2-16.8l-2.4-1.9c-24.6-19.1-52.1-33.6-81.7-43-6.3-2.1-13 1.4-15 7.7z"
          fill="url(#loader-svg_svg__ed4C2zWBfFN8-fill)"
        />
        <path
          id="loader-svg_svg__ed4C2zWBfFN9"
          d="M486 420.5l-3.3-3.8c-4.3-5-11.9-5.4-16.9-1.1-5 4.4-5.5 11.9-1.1 16.9l3 3.5c4.3 5.1 8.4 10.4 12.3 15.8 2.3 3.3 6 5 9.7 5 2.4 0 4.8-.7 7-2.2 5.4-3.8 6.6-11.3 2.7-16.7-4.1-6-8.6-11.8-13.4-17.4z"
          fill="url(#loader-svg_svg__ed4C2zWBfFN9-fill)"
        />
        <path
          id="loader-svg_svg__ed4C2zWBfFN10"
          d="M327.9 395c-14.5-3.3-29.5-4.9-44.4-4.9-67.7 0-130.7 33.7-168.3 90.1-3.7 5.5-2.2 12.9 3.3 16.6 2 1.4 4.3 2 6.6 2 3.9 0 7.6-1.9 10-5.3 33.2-49.8 88.7-79.5 148.4-79.5 13.2 0 26.4 1.5 39.2 4.3 6.5 1.4 12.8-2.6 14.3-9 1.4-6.5-2.7-12.9-9.1-14.3z"
          fill="url(#loader-svg_svg__ed4C2zWBfFN10-fill)"
        />
        <path
          id="loader-svg_svg__ed4C2zWBfFN11"
          d="M379.2 414.1L377 413c-5.8-3.1-13.1-.8-16.2 5-3.1 5.8-.8 13.1 5 16.2l2 1.1c25.7 13.8 47.8 34 64 58.3 2.3 3.4 6.1 5.3 10 5.3 2.3 0 4.6-.7 6.6-2 5.5-3.7 7-11.1 3.3-16.6-18.3-27.6-43.4-50.5-72.5-66.2z"
          fill="url(#loader-svg_svg__ed4C2zWBfFN11-fill)"
        />
        <path
          id="loader-svg_svg__ed4C2zWBfFN12"
          d="M137.9 324.1c5.8-3.2 7.9-10.4 4.8-16.2-3.2-5.8-10.4-7.9-16.2-4.8-35.1 19.1-66.2 44.2-92.4 74.6-4.3 5-3.7 12.5 1.3 16.9 2.3 1.9 5 2.9 7.8 2.9 3.4 0 6.7-1.4 9.1-4.2 24.1-28.2 53-51.5 85.6-69.2z"
          fill="url(#loader-svg_svg__ed4C2zWBfFN12-fill)"
        />
        <path
          id="loader-svg_svg__ed4C2zWBfFN13"
          d="M391.1 281.3c-70.1-24.2-148.3-23.9-218.2 1-6.2 2.2-9.5 9.1-7.2 15.3 1.7 4.9 6.3 7.9 11.3 7.9 1.3 0 2.7-.2 4-.7 64.8-23.1 137.4-23.4 202.4-.9 6.2 2.1 13-1.1 15.2-7.4 2.1-6.2-1.2-13.1-7.5-15.2z"
          fill="url(#loader-svg_svg__ed4C2zWBfFN13-fill)"
        />
        <path
          id="loader-svg_svg__ed4C2zWBfFN14"
          d="M441.7 303.7c-5.8-3.2-13.1-1.1-16.2 4.7-3.2 5.8-1.1 13.1 4.7 16.2 32.1 17.7 60.6 40.7 84.6 68.6 2.4 2.7 5.7 4.2 9.1 4.2 2.8 0 5.5-.9 7.8-2.9 5-4.3 5.6-11.9 1.3-16.9-26-30-56.7-54.9-91.3-73.9z"
          fill="url(#loader-svg_svg__ed4C2zWBfFN14-fill)"
        />
        <path
          id="loader-svg_svg__ed4C2zWBfFN15"
          d="M185.8 224.3c-1.9-6.3-8.6-9.9-14.9-8-58.7 17.5-113.3 49.2-157.9 91.6-4.8 4.6-5 12.1-.4 16.9 2.3 2.5 5.5 3.7 8.7 3.7 3 0 5.9-1.1 8.2-3.3 41.9-39.9 93.2-69.6 148.3-86.1 6.3-1.9 9.9-8.5 8-14.8z"
          fill="url(#loader-svg_svg__ed4C2zWBfFN15-fill)"
        />
        <path
          id="loader-svg_svg__ed4C2zWBfFN16"
          d="M231.3 227.3c.5 0 1.1 0 1.6-.1 10.7-1.5 21.6-2.5 32.4-3 6.6-.3 11.7-5.9 11.4-12.5-.3-6.6-6-11.6-12.5-11.4-11.5.6-23.1 1.6-34.5 3.2-6.5.9-11.1 6.9-10.2 13.5.8 5.9 5.9 10.3 11.8 10.3z"
          fill="url(#loader-svg_svg__ed4C2zWBfFN16-fill)"
        />
        <path
          id="loader-svg_svg__ed4C2zWBfFN17"
          d="M317.9 225.3c19.5 1.8 38.9 5.2 57.8 10.1 1 .3 2 .4 3 .4 5.3 0 10.2-3.6 11.6-9 1.6-6.4-2.2-12.9-8.6-14.5-20.1-5.2-40.8-8.8-61.6-10.7-6.6-.7-12.4 4.2-13 10.8-.6 6.5 4.2 12.3 10.8 12.9z"
          fill="url(#loader-svg_svg__ed4C2zWBfFN17-fill)"
        />
        <path
          id="loader-svg_svg__ed4C2zWBfFN18"
          d="M436.4 230.8c-6.1-2.6-13.1.3-15.7 6.3-2.6 6.1.3 13.1 6.3 15.7 40.9 17.3 78 41.7 110.3 72.4 2.3 2.2 5.3 3.3 8.2 3.3 3.2 0 6.3-1.2 8.7-3.7 4.6-4.8 4.4-12.3-.4-16.9-34.3-32.7-73.8-58.7-117.4-77.1z"
          fill="url(#loader-svg_svg__ed4C2zWBfFN18-fill)"
        />
        <path
          id="loader-svg_svg__ed4C2zWBfFN19"
          d="M20.6 249.6c2.5 0 5-.8 7.1-2.4 47.2-35 100-59.6 156.9-73 6.4-1.5 10.4-7.9 8.9-14.4-1.5-6.4-7.9-10.4-14.4-8.9C119 165.1 63.2 191 13.4 228c-5.3 3.9-6.4 11.4-2.5 16.7 2.5 3.3 6.1 4.9 9.7 4.9z"
          fill="url(#loader-svg_svg__ed4C2zWBfFN19-fill)"
        />
        <path
          id="loader-svg_svg__ed4C2zWBfFN20"
          d="M323.9 152.6c.4-6.6-4.6-12.3-11.2-12.7-27.6-1.8-55.2-1-82.3 2.1-6.6.8-11.2 6.7-10.5 13.3.7 6.1 5.9 10.6 11.9 10.6.5 0 .9 0 1.4-.1 25.6-3 51.8-3.7 78-2 6.7.3 12.3-4.6 12.7-11.2z"
          fill="url(#loader-svg_svg__ed4C2zWBfFN20-fill)"
        />
        <path
          id="loader-svg_svg__ed4C2zWBfFN21"
          d="M354.4 156.4c-1.2 6.5 3 12.7 9.5 14 63 11.9 123.6 38.5 175.2 76.9 2.1 1.6 4.6 2.4 7.1 2.4 3.6 0 7.3-1.7 9.6-4.8 3.9-5.3 2.8-12.8-2.5-16.7-54.5-40.5-118.5-68.6-185-81.2-6.4-1.3-12.6 2.9-13.9 9.4z"
          fill="url(#loader-svg_svg__ed4C2zWBfFN21-fill)"
        />
        <path
          id="loader-svg_svg__ed4C2zWBfFN22"
          d="M43.5 161.9c1.9 0 3.9-.5 5.7-1.5 15.6-8.5 31.9-16.2 48.3-23 6.1-2.5 9-9.5 6.5-15.6s-9.5-9-15.6-6.5c-17.2 7.1-34.3 15.2-50.7 24.1-5.8 3.2-7.9 10.4-4.8 16.2 2.2 4 6.3 6.3 10.6 6.3z"
          fill="url(#loader-svg_svg__ed4C2zWBfFN22-fill)"
        />
        <path
          id="loader-svg_svg__ed4C2zWBfFN23"
          d="M319.8 78.4c-12-.8-24.2-1.3-36.4-1.3-46.7 0-92.9 6.2-137.5 18.6-6.4 1.8-10.1 8.3-8.3 14.7 1.5 5.3 6.3 8.8 11.5 8.8 1.1 0 2.1-.1 3.2-.4 42.5-11.7 86.7-17.7 131.2-17.7 11.6 0 23.3.4 34.7 1.2 6.5.5 12.3-4.5 12.8-11.1.4-6.6-4.6-12.3-11.2-12.8z"
          fill="url(#loader-svg_svg__ed4C2zWBfFN23-fill)"
        />
        <path
          id="loader-svg_svg__ed4C2zWBfFN24"
          d="M395.7 114c.9.2 1.8.3 2.7.3 5.4 0 10.3-3.7 11.6-9.2 1.5-6.4-2.5-12.9-8.9-14.4-9.4-2.2-19-4.2-28.6-5.8-6.4-1.1-12.7 3.2-13.8 9.7-1.1 6.5 3.2 12.7 9.7 13.8 9.1 1.6 18.3 3.5 27.3 5.6z"
          fill="url(#loader-svg_svg__ed4C2zWBfFN24-fill)"
        />
        <path
          id="loader-svg_svg__ed4C2zWBfFN25"
          d="M435.7 112.4c-2.1 6.2 1.2 13 7.4 15.2 25.6 8.8 50.8 19.9 74.6 32.8 1.8 1 3.8 1.5 5.7 1.5 4.2 0 8.3-2.3 10.5-6.2 3.2-5.8 1-13.1-4.8-16.2-25.1-13.6-51.4-25.2-78.3-34.5-6.2-2.1-13 1.2-15.1 7.4z"
          fill="url(#loader-svg_svg__ed4C2zWBfFN25-fill)"
        />
        <path
          id="loader-svg_svg__ed4C2zWBfFN26"
          d="M125.1 61.7c1.1 0 2.2-.2 3.4-.5 21.2-6.2 43.1-11.1 64.9-14.7 6.5-1.1 10.9-7.2 9.9-13.7-1.1-6.5-7.2-10.9-13.7-9.9-22.8 3.7-45.6 8.9-67.7 15.4-6.3 1.9-10 8.5-8.1 14.8 1.3 5.2 6.1 8.6 11.3 8.6z"
          fill="url(#loader-svg_svg__ed4C2zWBfFN26-fill)"
        />
        <path
          id="loader-svg_svg__ed4C2zWBfFN27"
          d="M248.8 40.3c64.4-4 128.6 3.2 189.7 21 1.1.3 2.2.5 3.4.5 5.2 0 9.9-3.4 11.5-8.6 1.8-6.3-1.8-13-8.1-14.8-63.7-18.6-130.6-26-197.8-21.9-6.6.4-11.6 6.1-11.2 12.7.2 6.4 5.8 11.6 12.5 11.1z"
          fill="url(#loader-svg_svg__ed4C2zWBfFN27-fill)"
        />
      </g>
    </svg>
  </div>
);
