import React, { useEffect, useMemo, useState } from 'react';
import { DateTime } from 'luxon';

import { EmptyState } from '~/components/common/EmptyState';
import { Button } from '~/components/common/Button';
import { Plus1 } from '~/components/common/Icons/dist';
import { Row, Table } from '~/components/common/Table';
import { useTableContext } from '~/components/common/Table/TableProvider';
import { allExpanded, findRow, State } from '~/stateManagement/expandable';
import { DoubleChevron } from '~/components/common/Icons';
import { GetNotesListResponse, Note as model } from '~/model/Notes';
import { Pagination } from '~/components/common/Pagination';
import { usePaginationContext } from '~/components/common/Pagination/PaginationProvider/PaginationContext';
import { Note } from './Note';
import { headerColumns } from './model';
import { AddModal } from './Modal';
import { Layout } from '../../Layout';

export interface Props {
  data: GetNotesListResponse;
}

const updateNotes = (notes: model[], state: State) =>
  notes.map((note) => ({
    ...(findRow(note.id, state) || { id: note.id, expanded: false }),
  }));

const rows = (notes, state): Row[] =>
  notes
    .map((item, index) => {
      const stateRow = findRow(item.id, state!);
      if (!stateRow) {
        return null;
      }

      return {
        content: <Note note={item} index={index + 1} />,
        expanded: false,
        ...stateRow,
      };
    })
    .filter((item) => !!item);

export const Notes = ({ data }: Props) => {
  const { state, expandAll, collapseAll, replaceRows } = useTableContext();
  const [showAddNoteModal, setShowAddNoteModal] = useState(false);
  const { items: notes, updatedAt, paginationData: notesPaginationData } = data;
  const { currentPage, changeCurrentPage, changeItemsPerPage } = usePaginationContext();

  useEffect(() => {
    replaceRows(updateNotes(notes, state!));
    if (currentPage > data.paginationData.last && currentPage > 1) {
      changeCurrentPage(data.paginationData.last);
    }
  }, [notes]);

  const hasNotes = useMemo(() => notes && notes.length > 0, [notes, notes.length]);

  let layoutProps = {};
  if (hasNotes) {
    layoutProps = {
      subtitle: `Updated ${DateTime.fromISO(updatedAt).toFormat('dd/MM/yyyy')}`,
    };
  }

  return (
    <Layout
      title="Your notes"
      headerButtons={
        <>
          {hasNotes && !allExpanded(state!) && (
            <Button
              iconPosition="right"
              shade="light"
              size="large"
              aria-label="Expand all notes"
              icon={<DoubleChevron direction="down" />}
              onClick={expandAll}
            >
              Expand all
            </Button>
          )}
          {hasNotes && allExpanded(state!) && (
            <Button
              iconPosition="right"
              shade="light"
              size="large"
              aria-label="Collapse all notes"
              icon={<DoubleChevron direction="up" />}
              onClick={collapseAll}
            >
              Collapse all
            </Button>
          )}
          <Button
            icon={<Plus1 />}
            iconPosition="right"
            size="large"
            onClick={() => setShowAddNoteModal(true)}
            aria-label="Add new note"
          >
            Add new
          </Button>
        </>
      }
      {...layoutProps}
    >
      <>
        {!hasNotes && (
          <EmptyState
            title="You have no notes for this Client!"
            description="All of your notes about this Client will be visible here"
          />
        )}
        {hasNotes && notesPaginationData && (
          <>
            <Table rows={rows(notes, state)} headers={headerColumns} />
            <Pagination
              paginationData={notesPaginationData}
              onPageChange={changeCurrentPage}
              onItemsPerPageChange={changeItemsPerPage}
            />
          </>
        )}
        {showAddNoteModal && (
          <AddModal title="Add note" onClose={() => setShowAddNoteModal(false)} />
        )}
      </>
    </Layout>
  );
};
