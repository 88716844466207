import React, { useState } from 'react';
import { CometChatConversationListWithMessages } from '~/vendor/CometChatWorkspace/src';

import { useCometChat } from '~/components/pages/cometChat/CometChatProvider';
import { Loader } from '~/components/common/Loader';
import { ClientSummary } from '~/components/common/ClientSummary/container';
import { PoweredByOme } from '~/components/common/Brand';
import { ClientContextProvider } from '../client/context';

interface Props {
  chatWithUser?: string;
}

export function CometChat({ chatWithUser }: Props) {
  const [clientId, setClientId] = useState(chatWithUser);
  const { updateUnreadMessagesCount, isLoading, isError } = useCometChat();

  if (isLoading) {
    return <Loader wrapperHeight="100%" center />;
  }

  if (isError) {
    return <div>Something went wrong while connecting to the chat</div>;
  }

  const onUserChange = (id: number) => {
    setClientId(`${id}`);
  };

  return (
    <>
      {clientId && (
        <ClientContextProvider clientId={clientId} wrapperHeight="305px">
          <ClientSummary showChatButton={false} showProfileButton={!!clientId} />
        </ClientContextProvider>
      )}
      <div className="card card-custom" style={{ height: '600px', marginBottom: '30px' }}>
        <CometChatConversationListWithMessages
          chatWithUser={chatWithUser}
          onMessagesRead={updateUnreadMessagesCount}
          onUserChange={onUserChange}
        />
      </div>
      <PoweredByOme />
    </>
  );
}
