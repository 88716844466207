import React, { useState } from 'react';

import { TestableComponentProps } from '~/model/TestableComponentProps.interface';

import clsx from 'clsx';
import { model, TestReport as Model } from '~/model/Client/TestResults/TestReports';

import { Edit2, Export, Trash } from '~/components/common/Icons/dist';
import { IconButton } from '~/components/common/Button';
import { DateTime } from 'luxon';
import { Label } from '~/components/common/Label';
import { useClient } from '~/components/pages/client/context';
import { toast } from 'react-toastify';
import { style, TestReportRow } from './TestReport.style';
import { headerColumns } from '../config';
import { DeleteConfirmationModal } from '../DeleteConfirmationModal';
import { TableRowItem } from '../../../ExpandableTable/ExpandableTable.style';
import { SendReportConfirmationModal } from '../SendReportConfirmationModal';

export interface Props extends TestableComponentProps {
  testReport: Model;
  reportName: string;
}

export const TestReport = ({ testReport, reportName }: Props) => {
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);
  const [showSendReportConfirmationModal, setShowSendReportConfirmationModal] = useState(false);
  const { clientId } = useClient();
  const { id, date } = testReport;

  return (
    <>
      <TableRowItem>
        <TestReportRow $widths={headerColumns.map(({ width }) => width || '1fr')}>
          <div className={clsx(style.date)}>{DateTime.fromISO(date).toFormat('dd/MM/yyyy')}</div>
          <div>
            <Label inline light variant="neutral">
              N/A
            </Label>
          </div>
          <div className={style.actions}>
            <IconButton
              shade="light"
              aria-label="Show test report"
              icon={<Edit2 />}
              onClick={async () => {
                try {
                  await model(clientId).getTestReport(testReport.id);
                } catch (error) {
                  toast.error('Something went wrong... Please try again.');
                }
              }}
            />
            <IconButton
              shade="light"
              aria-label="Send test report"
              icon={<Export />}
              onClick={() => setShowSendReportConfirmationModal(true)}
            />
            <IconButton
              shade="light"
              aria-label="Delete test report"
              icon={<Trash />}
              onClick={() => setShowDeleteConfirmationModal(true)}
            />
          </div>
        </TestReportRow>
      </TableRowItem>
      {showDeleteConfirmationModal && (
        <DeleteConfirmationModal
          id={id}
          reportName={reportName}
          onClose={() => setShowDeleteConfirmationModal(false)}
        />
      )}
      {showSendReportConfirmationModal && (
        <SendReportConfirmationModal
          id={id}
          reportName={reportName}
          onClose={() => setShowSendReportConfirmationModal(false)}
        />
      )}
    </>
  );
};
