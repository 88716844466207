import { Header } from '~/components/common/Table';

export const headerColumns: Header[] = [
  {
    title: 'Title',
    style: {
      minWidth: '600px',
      paddingRight: 0,
    },
  },
  {
    title: 'Date created',
    style: {
      minWidth: '150px',
    },
  },
  {
    title: 'Date modified',
    style: {
      minWidth: '150px',
    },
  },
  {
    title: 'Action',
    style: {
      minWidth: '50px',
      textAlign: 'right' as const,
    },
  },
];
