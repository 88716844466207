import { useQuery } from 'react-query';
import { TestResultsResponse } from '~/components/pages/client/Sections/TestResults/model';
import { model as clientTestResultsModel } from './testResults';

export const testResultsQueryKey = (clientId) => ['client', clientId, 'test-result'];

export const useClientTestResults = (clientId, term, filters) =>
  useQuery<TestResultsResponse>([testResultsQueryKey(clientId), term, filters], () =>
    clientTestResultsModel(clientId).getList(term, filters),
  );
