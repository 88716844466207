import { css } from '@emotion/css';
import { ErrorMessage, FieldArray, Form, Formik, FormikHelpers, getIn } from 'formik';
import React from 'react';
import dot from 'dot-object';
import { Button } from '~/components/common/Button';
import {
  FormikCheckbox,
  FormikDropdown,
  FormikInput,
  FormRow,
  FormRowInputWrapper,
  FormRowLabel,
  Input,
  FormRowErrorLine,
} from '~/components/common/Form';
import { Modal, Props as ModalProps } from '~/components/common/Modal';
import { Separator } from '~/components/common/Modal/Modal.style';
import { isBackendValidationError, transformErrors } from '~/model/Error/transformApiErrors';
import {
  getYearOptions,
  getMonthOptions,
  getDayOptions,
} from '~/components/pages/client/Sections/config';
import { FormWrapper, ModalFooter } from './AnswersModal.style';
import { Question, QuestionAnswer } from './model';
import { QuestionVariant } from './questionVariants';
import { FormFields } from './config';

export interface Props extends ModalProps {
  initialValues: FormFields;
  onSubmit: (values: FormFields) => void;
  answer: QuestionAnswer;
}

const renderMultipleAnswer = (question: Question) => {
  if (!question.choices || question.choices.length === 0) {
    // eslint-disable-next-line no-console
    console.warn(`Multiple answer on question ${question.id} with no choices`);
  }

  return (
    <FormRow $rowSpacing="15px">
      <>
        <FormRowLabel>Answer:</FormRowLabel>
        <FormRowInputWrapper>
          <div
            className={css({
              display: 'flex',
              flexDirection: 'column',
            })}
          >
            <FieldArray
              name={`${question.id}`}
              render={() =>
                question.choices?.map(({ id: choiceId, text }) => (
                  <FormikCheckbox
                    key={choiceId}
                    name={`${question.id}`}
                    type="checkbox"
                    label={text}
                    value={`${choiceId}`}
                  />
                ))
              }
            />
          </div>
        </FormRowInputWrapper>
      </>
    </FormRow>
  );
};

const renderOpenAnswerByVariant = (question: Question) => {
  switch (question.type) {
    case 'blood_pressure':
      return (
        <>
          <FieldArray
            name={question.id}
            render={({ form }) => (
              <>
                <FormRow width="half" $rowSpacing="15px">
                  <>
                    <FormRowLabel htmlFor={`${question.id}.systolic`}>Systolic:</FormRowLabel>
                    <FormRowInputWrapper>
                      <FormikInput name={`${question.id}.systolic`} />
                    </FormRowInputWrapper>
                  </>
                  <>
                    <FormRowLabel htmlFor={`${question.id}.diastolic`}>Diastolic:</FormRowLabel>
                    <FormRowInputWrapper>
                      <FormikInput name={`${question.id}.diastolic`} />
                    </FormRowInputWrapper>
                  </>
                </FormRow>
                <ErrorMessage
                  name={question.id}
                  render={() => <FormRowErrorLine text={getIn(form.errors, question.id)} />}
                />
                <FormRow $rowSpacing="15px">
                  <>
                    <FormRowLabel htmlFor={`${question.id}.i_dont_know`}>
                      I don&apos;t know:
                    </FormRowLabel>
                    <FormRowInputWrapper>
                      <FormikCheckbox name={`${question.id}.i_dont_know`} />
                    </FormRowInputWrapper>
                  </>
                </FormRow>
              </>
            )}
          />
        </>
      );

    default:
      break;
  }

  switch (question.variant) {
    case QuestionVariant.DateOfBirth:
      return (
        <FieldArray
          name={question.id}
          render={({ form }) => (
            <>
              <FormRow $rowSpacing="15px">
                <>
                  <FormRowLabel htmlFor={`${question.id}`}>Answer:</FormRowLabel>
                  <FormRowInputWrapper>
                    <div style={{ display: 'flex', gap: '10px' }}>
                      <div style={{ width: 'calc(100% / 3)' }}>
                        <FormikDropdown name={`${question.id}.year`} testId="year-dropdown">
                          {getYearOptions().map(({ value, title }) => (
                            <option key={value} value={value}>
                              {title}
                            </option>
                          ))}
                        </FormikDropdown>
                      </div>
                      <div style={{ width: 'calc(100% / 3)' }}>
                        <FormikDropdown name={`${question.id}.month`} testId="month-dropdown">
                          {getMonthOptions().map(({ value, title }) => (
                            <option key={value} value={value}>
                              {title}
                            </option>
                          ))}
                        </FormikDropdown>
                      </div>
                      <div style={{ width: 'calc(100% / 3)' }}>
                        <FormikDropdown name={`${question.id}.day`} testId="day-dropdown">
                          {getDayOptions().map(({ value, title }) => (
                            <option key={value} value={value}>
                              {title}
                            </option>
                          ))}
                        </FormikDropdown>
                      </div>
                    </div>
                  </FormRowInputWrapper>
                </>
              </FormRow>
              <ErrorMessage
                name={question.id}
                render={() => <FormRowErrorLine text={getIn(form.errors, question.id)} />}
              />
            </>
          )}
        />
      );
    case QuestionVariant.WaistCircumference:
    case QuestionVariant.HipCircumference:
      return (
        <FieldArray
          name={question.id}
          render={() => (
            <>
              <FormRow $rowSpacing="15px">
                <>
                  <FormRowLabel htmlFor={`${question.id}.value`}>Answer:</FormRowLabel>
                  <FormRowInputWrapper>
                    <FormikInput name={`${question.id}.value`} />
                  </FormRowInputWrapper>
                </>
              </FormRow>
              <FormRow $rowSpacing="15px">
                <>
                  <FormRowLabel htmlFor={`${question.id}.i_dont_know`}>
                    I don&apos;t know:
                  </FormRowLabel>
                  <FormRowInputWrapper>
                    <FormikCheckbox name={`${question.id}.i_dont_know`} />
                  </FormRowInputWrapper>
                </>
              </FormRow>
            </>
          )}
        />
      );

    default:
      return (
        <FormRow $rowSpacing="15px">
          <>
            <FormRowLabel htmlFor={question.id}>Answer:</FormRowLabel>
            <FormRowInputWrapper>
              <FormikInput name={question.id} />
            </FormRowInputWrapper>
          </>
        </FormRow>
      );
  }
};

const renderAnswerField = (question: Question) => {
  switch (question.type) {
    case 'single_answer':
    case 'single_answer_dropdown':
    case 'food_frequency':
      return (
        <FormRow $rowSpacing="15px">
          <>
            <FormRowLabel htmlFor={question.id}>Answer:</FormRowLabel>
            <FormRowInputWrapper>
              <FormikDropdown name={question.id} testId={`answer-${question.id}`}>
                {question.value === null && (
                  <option value="" disabled>
                    Select an answer
                  </option>
                )}
                {question.id.includes('followup_') && <option value="">-</option>}
                {question.choices?.map(({ id: answerId, text: answerText }) => (
                  <option key={answerId} value={answerId}>
                    {answerText}
                  </option>
                ))}
              </FormikDropdown>
            </FormRowInputWrapper>
          </>
        </FormRow>
      );
    case 'multiple_answers':
    case 'multiple_answers_dropdown':
      return renderMultipleAnswer(question);

    case 'open_ended':
    case 'blood_pressure':
    case 'numeric_value':
    default:
      return renderOpenAnswerByVariant(question);
  }
};

export const AnswersModal = ({
  onClose: handleClose,
  onSubmit,
  initialValues,
  answer,
  ...otherProps
}: Props) => {
  const renderQuestion = () => (
    <>
      <FormRow $rowSpacing="15px">
        <>
          <FormRowLabel htmlFor={`question-${answer.question.id}`}>Question:</FormRowLabel>
          <FormRowInputWrapper>
            <Input name={`question-${answer.question.id}`} value={answer.question.name} disabled />
          </FormRowInputWrapper>
        </>
      </FormRow>

      {renderAnswerField(answer.question)}
    </>
  );

  const renderFollowUpQuestions = () => {
    if (!answer.followUpQuestions || answer.followUpQuestions.length === 0) {
      return null;
    }

    return answer.followUpQuestions.map((followUpQuestion) => (
      <React.Fragment key={followUpQuestion.id}>
        <FormRow $rowSpacing="15px">
          <>
            <FormRowLabel htmlFor={`followup-question-${followUpQuestion.id}`}>
              Follow-up question:
            </FormRowLabel>
            <FormRowInputWrapper>
              <Input
                name={`followup-question-${followUpQuestion.id}`}
                value={followUpQuestion.name}
                disabled
              />
            </FormRowInputWrapper>
          </>
        </FormRow>

        {renderAnswerField(followUpQuestion)}
      </React.Fragment>
    ));
  };

  const handleSubmit = async (
    values: FormFields,
    { setErrors, validateField }: FormikHelpers<FormFields>,
  ) => {
    try {
      await onSubmit(values);
      handleClose();
    } catch (err: unknown) {
      if (isBackendValidationError<FormFields>(err)) {
        const transformedErrors = transformErrors<FormFields>(err);

        // this is necessary because formik does not touch fields that are not in intitialValues before submitting
        // hence the dynamic fields will not display errors
        Object.keys(dot.dot(transformedErrors)).forEach((fieldName) => {
          validateField(fieldName);
        });
        setErrors(transformedErrors);
      }
    }
  };

  return (
    <Modal
      withHeaderSeparator
      hasCloseButton={false}
      title="Edit answer"
      onClose={handleClose}
      {...otherProps}
    >
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {({ isSubmitting }) => (
          <Form>
            <FormWrapper>
              <FormRow $rowSpacing="15px">
                <>
                  <FormRowLabel htmlFor="dimension">Dimension:</FormRowLabel>
                  <FormRowInputWrapper>
                    <FormikInput name="dimension" disabled />
                  </FormRowInputWrapper>
                </>
              </FormRow>

              <FormRow $rowSpacing="15px">
                <>
                  <FormRowLabel htmlFor="category">Category:</FormRowLabel>
                  <FormRowInputWrapper>
                    <FormikInput name="category" disabled />
                  </FormRowInputWrapper>
                </>
              </FormRow>

              {renderQuestion()}
              {renderFollowUpQuestions()}
            </FormWrapper>

            <Separator $marginBottom="30px" />

            <ModalFooter>
              <Button type="button" shade="light" variant="neutral" onClick={handleClose}>
                Cancel
              </Button>
              <Button
                variant="success"
                type="submit"
                disabled={isSubmitting}
                isLoading={isSubmitting}
              >
                Save
              </Button>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
