import { useQuery } from 'react-query';
import { TopicChoice, model as topicModel } from '../topic';

export const topicRecommendationsQueryKey = (clientId: string, dimensionId: string) => [
  'client',
  `${clientId}`,
  'topics',
  `${dimensionId}`,
];

export const useTopicRecommendationsList = (clientId: string, dimensionId: string) =>
  useQuery<Array<TopicChoice>>(topicRecommendationsQueryKey(clientId, dimensionId), () =>
    topicModel(clientId).getRecommendationsList(dimensionId),
  );
