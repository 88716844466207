import React from 'react';
import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { ConfirmationModal } from '~/components/common/ConfirmationModal';
import { useClient } from '~/components/pages/client/context';
import { clientNotesQueryKey } from '~/model/Client/Notes';
import { useDeleteClientNote } from '~/model/Client/Notes/hooks/useDeleteClientNote';
import { notesQueryKey } from '~/model/Notes/hooks';

export interface Props {
  id: number;
  onClose: () => void;
}

export const DeleteConfirmationModal = ({ id, onClose }: Props) => {
  const { clientId } = useClient();
  const queryClient = useQueryClient();
  const deleteNoteMutation = useDeleteClientNote(clientId, id);
  const { isLoading: isDeleteLoading } = deleteNoteMutation;

  return (
    <ConfirmationModal
      testId="delete-note-modal"
      iconVariant="warning"
      title="Are you sure?"
      closeButtonText="No, cancel"
      submitButtonText="Yes, delete!"
      submitButtonVariant="danger"
      description="This will permanentely delete this note?"
      isLoading={isDeleteLoading}
      onClose={onClose}
      onSubmit={() => {
        deleteNoteMutation.mutate(
          {},
          {
            onSuccess: async () => {
              await queryClient.invalidateQueries([clientNotesQueryKey(clientId)]);
              await queryClient.removeQueries([clientNotesQueryKey(clientId)], { inactive: true });
              await queryClient.invalidateQueries(notesQueryKey);
              onClose();
            },
            onError: () => {
              toast.error('Something went wrong... Please try again.');
            },
          },
        );
      }}
    />
  );
};
