import * as React from 'react';
import { SVGProps } from 'react';
import { Size } from '../types';
interface Props extends SVGProps<SVGSVGElement> {
  size?: Size;
}
const SvgPhone16 = (props: Props) => {
  const getSize = () => {
    if (props.size) {
      switch (props.size) {
        case 'small':
          return 20;

        case 'medium':
          return 24;

        case 'large':
          return 32;

        default:
          return null;
      }
    }
  };

  return (
    <svg
      width={getSize() || 16}
      height={getSize() || 16}
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.943 9.428l1.47-1.469a1.333 1.333 0 00.25-1.539l-.084-.166a1.333 1.333 0 01.25-1.54l1.822-1.821a.333.333 0 01.534.086l.736 1.472a2.925 2.925 0 01-.548 3.376l-4 4a3.618 3.618 0 01-3.702.874l-1.61-.537a.333.333 0 01-.13-.552l1.768-1.767a1.333 1.333 0 011.539-.25l.166.083a1.333 1.333 0 001.54-.25z"
      />
    </svg>
  );
};

export default SvgPhone16;
