import { Header } from '~/components/pages/client/Sections/ExpandableTable/model';
import { style } from './Meals.style';

export enum MealStatus {
  Visible = 'Visible',
  Skipped = 'Skipped',
  NotVisible = 'Not visible',
}

export interface Meal {
  id: number;
  name: string;
  meal: string;
  score: number;
  status: MealStatus;
}

export interface MealCategory {
  number: number;
  active: boolean;
  meals?: Meal[];
}

export const headerColumns: Header[] = [
  {
    title: 'Meal',
    width: '1fr',
  },
  {
    title: 'Name',
    width: '6fr',
  },
  {
    title: 'Score',
    width: '0.75fr',
    tooltip:
      'The Score demonstrates a relative fit of the Recipe to the Client’s goals and health status. Higher score is better. Score is reduced due to dislikes, skipping and repetitiveness, etc.',
  },
  {
    title: 'Status',
    width: '1fr',
    tooltip:
      'If a Client skips a “Visible” recipe it receives a “Skipped” status and the next “Not visible” recipe becomes “Visible”',
  },
  {
    title: 'Action',
    className: style.headers.actions,
    width: '1fr',
  },
];
