import React, { useEffect, useState } from 'react';
import { Button } from '~/components/common/Button';
import { Loader } from '~/components/common/Loader';
import { getToken } from '~/components/common/TokenProvider/utils';
import { OnboardingLinkParams, URL } from '~/model/Stripe/OnboardingLink';
import { getBasename } from '~/services/api';
import { ButtonWrapper, OnboardingHeader, OnboardingText, Separator } from './Payout.style';

const getOnboardingUrl = () => `${getBasename()}${URL.STRIPE_ONBOARDING_FLOW}`;

export const NotOnboarded = () => {
  const [queryParams, setQueryParams] = useState<string>();

  useEffect(() => {
    const onboardingLinkParams: OnboardingLinkParams = {
      returnUrl: `${window.location.href}?onboarding=success`,
      ut: `${getToken()}`,
    };

    setQueryParams(new URLSearchParams(onboardingLinkParams).toString());
  }, []);

  if (!queryParams) {
    return <Loader wrapperHeight="100%" center />;
  }

  return (
    <>
      <OnboardingHeader>Let’s set up your billing details</OnboardingHeader>
      <OnboardingText>
        To be able to transfer your earnings to your bank account while complying with the security
        requirements and regulations of your local government, we are partnering with Stripe – the
        world leading provider of payment processing.
      </OnboardingText>
      <OnboardingText>Click Start to set up your payments via Stripe.</OnboardingText>
      <Separator />
      <ButtonWrapper>
        <Button size="large" href={`${getOnboardingUrl()}?${queryParams}`}>
          Start
        </Button>
      </ButtonWrapper>
    </>
  );
};
