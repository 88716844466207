import React from 'react';
import { toast } from 'react-toastify';
import { Loader, Error as ErrorModal } from '~/components/common/Modal';
import { useClient } from '~/components/pages/client/context';
import { useSchedule, model, clientsQueryKey } from '~/model/Client/Supplements/Schedule';
import { queryClient } from '~/services/api';
import { FormFields, initialValues, toApiRequest } from './config';
import { ScheduleModal as Component, Props as ComponentProps } from './ScheduleModal';

export type Props = Omit<
  ComponentProps,
  'onSubmit' | 'initialValues' | 'isVisible' | 'onPublish' | 'onHide'
>;

export const ScheduleModal = ({ onClose, ...otherProps }: Props) => {
  const { clientId } = useClient();
  const { isLoading, isError, data } = useSchedule(clientId);

  const props: Props = {
    onClose,
    withHeaderSeparator: true,
    width: '772px',
    hasCloseButton: false,
    closeOnOuterClick: false,
    ...otherProps,
  };

  if (isLoading) {
    return <Loader {...props} />;
  }

  if (isError || !data) {
    return <ErrorModal {...props} hasCloseButton closeOnOuterClick />;
  }

  const { isVisible } = data;

  return (
    <>
      <Component
        {...props}
        initialValues={initialValues(data)}
        isVisible={isVisible}
        onPublish={async () => {
          if (!isVisible) {
            await model.toggleVisibility(clientId);
            await queryClient.refetchQueries(clientsQueryKey(clientId));
          }

          toast.success('Changes successfully saved');
        }}
        onHide={async () => {
          if (isVisible) {
            await model.toggleVisibility(clientId);
            await queryClient.refetchQueries(clientsQueryKey(clientId));
          }

          toast.success('Changes successfully saved');
        }}
        onSubmit={async (values: FormFields) => {
          const request = toApiRequest(values);
          await model.update(clientId, request);
          await queryClient.refetchQueries(clientsQueryKey(clientId));
        }}
      />
    </>
  );
};
