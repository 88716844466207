import { useState } from 'react';

export interface Props {
  currentPage?: number;
  itemsPerPage?: number;
}

export const usePagination = ({
  currentPage: passedCurrentPage = 1,
  itemsPerPage: passedItemsPerPage = 5,
}: Props) => {
  const [currentPage, setCurrentPage] = useState(passedCurrentPage);
  const [itemsPerPage, setItemsPerPage] = useState(passedItemsPerPage);

  return {
    changeCurrentPage: setCurrentPage,
    changeItemsPerPage: setItemsPerPage,
    currentPage,
    itemsPerPage,
  };
};
