import React, { PropsWithChildren } from 'react';
import { ClientProfile, useClientProfile } from '~/model/Client';
import { Loader } from '~/components/common/Loader';

interface ClientContextType {
  isLoading: boolean;
  isError: boolean;
  clientId: string;
  client: ClientProfile;
}

export const ClientContext = React.createContext<ClientContextType>(undefined!);

interface ClientContextProviderProps {
  clientId: string;
  wrapperHeight?: string;
}

export const ClientContextProvider = ({
  clientId,
  children,
  wrapperHeight = '100%',
}: PropsWithChildren<ClientContextProviderProps>) => {
  const { isLoading, isError, data: client } = useClientProfile(clientId);

  if (isLoading) {
    return <Loader wrapperHeight={wrapperHeight} center />;
  }

  if (isError || !client) {
    return <div>Error has occured</div>;
  }

  return (
    <ClientContext.Provider value={{ clientId, isLoading, isError, client }}>
      {children}
    </ClientContext.Provider>
  );
};

export const useClient = () => React.useContext(ClientContext);
