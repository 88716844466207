import React, { PropsWithChildren } from 'react';
import { TestableComponentProps } from '~/model/TestableComponentProps.interface';
import { PoweredByOme } from '../Brand';

interface Props extends PropsWithChildren<any>, TestableComponentProps { }

export const Layout = ({ testId, children }: Props) => (
  <div data-testid={testId}>
    {children}
    <PoweredByOme />
  </div>
);
