import React from 'react';
import { useClientMealsList } from '~/model/Client/Meals/useClientMeals';
import { Loader } from '~/components/common/Loader';
import { useClient } from '~/components/pages/client/context';

import { Card, CardBody } from '~/components/common/Card';
import { Meals as Component } from './Meals';
import { initialValues } from './SearchAndFilters/config';
import { useSearchAndFilters } from './hooks';

const Error = () => <div>Could not retrieve data</div>;

export const Meals = () => {
  const { clientId } = useClient();
  const values = initialValues();

  const searchAndFilters = useSearchAndFilters({
    term: values.term,
    filters: values.filters,
  });

  const { term, filters } = searchAndFilters;

  const { isError, isLoading, data } = useClientMealsList(clientId, term, filters);

  if (isLoading) {
    return <Loader size="medium" center />;
  }

  if (isError || !data) {
    return <Error />;
  }

  return (
    <Card>
      <CardBody>
        <Component {...data} {...searchAndFilters} initialValues={values} isLoading={isLoading} />
      </CardBody>
    </Card>
  );
};
