import styled from "@emotion/styled";

export const SearchFilterWrapper = styled.div`
  padding: 0 50px;
`;

export const SearchFilterActions = styled.div`
  display: flex;
  gap: 10px;
  justify-content: end;
`;