import React from 'react';
import { useClient } from '~/components/pages/client/context';

import { model } from '~/model/Client/TestResults/testResults';
import { useQueryClient } from 'react-query';
import { DateTime } from 'luxon';
import { testResultsQueryKey } from '~/model/Client/TestResults/useClientTestResults';
import { toast } from 'react-toastify';
import { usePhenotypes } from '~/model/Client/TestResults/Phenotype';
import { Loader } from '~/components/common/Loader';
import { Modal as Component, Props as ComponentProps } from '../Modal';
import { toApiRequest } from '../config';

const Error = () => <div>Could not retrieve biomarkers data</div>;

export interface Props extends Omit<ComponentProps, 'onSubmit' | 'initialValues' | 'title'> {
  phenotypeId?: number;
}

export const AddTestResultsModal = ({ onClose, phenotypeId, ...otherProps }: Props) => {
  const queryClient = useQueryClient();
  const { clientId } = useClient();
  const date = DateTime.now();

  const { data: phenotypes, isLoading, isError } = usePhenotypes();

  if (isLoading) {
    return <Loader size="medium" center />;
  }

  if (isError || !phenotypes) {
    return <Error />;
  }

  return (
    <Component
      onSubmit={async (values) => {
        try {
          const data = await toApiRequest(values);
          await model(clientId).add(data);
          await queryClient.invalidateQueries([testResultsQueryKey(clientId)]);
          toast.success('New test result has been created');
        } catch (err: unknown) {
          console.error(err);
          toast.error('Something went wrong while adding test results, please try again');
        }
        onClose();
      }}
      onClose={onClose}
      testId="add-test-results-modal"
      initialValues={{
        formType: 'value',
        phenotype: phenotypeId || 0,
        testDate: {
          year: date.year,
          month: date.month,
          day: date.day,
        },
        value: 0,
        reportFile: undefined,
        name: '',
      }}
      phenotypes={phenotypes}
      title="Add new test results"
      allowReportUpload
      {...otherProps}
    />
  );
};
