import * as React from 'react';
import { SVGProps } from 'react';
import { Size } from '../types';
interface Props extends SVGProps<SVGSVGElement> {
  size?: Size;
}
const SvgTrend = (props: Props) => {
  const getSize = () => {
    if (props.size) {
      switch (props.size) {
        case 'small':
          return 20;

        case 'medium':
          return 24;

        case 'large':
          return 32;

        default:
          return null;
      }
    }
  };

  return (
    <svg
      width={getSize() || 32}
      height={getSize() || 32}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      {...props}
    >
      <g clipPath="url(#trend_svg__a)" fill="#6D7784">
        <path d="M1 30.8c0 .4.4.667.933.667l29.6.133c.534 0 .934-.267.934-.667s-.4-.666-.934-.666l-29.6-.133c-.533 0-.933.4-.933.666zm13.467-2.667h4.4c.933 0 1.6-.933 1.6-2V9.6c0-.667-.267-1.2-.534-1.6-.266-.267-.666-.4-1.066-.4h-4.4c-.934 0-1.6.933-1.6 2v16.533c0 .667.266 1.2.533 1.6.267.267.667.4 1.067.4zm.133-19.2H19s.267.267.267.667v16.533c0 .4-.267.667-.267.667h-4.4s-.267-.267-.267-.667V9.6c0-.4.134-.667.267-.667zm11.333 19.334h4.4c.934 0 1.6-1.067 1.6-2.534l.134-22.666c0-.8-.267-1.6-.667-2.134-.267-.266-.533-.4-.933-.4h-4.4c-.934 0-1.6 1.067-1.6 2.534l-.134 22.666c0 .8.267 1.6.667 2.134.267.266.667.4.933.4zM26.2 2h4.267c.133.133.266.4.266 1.067L30.6 25.733c0 .667-.267 1.067-.267 1.067h-4.266c-.134-.133-.267-.4-.267-1.067l.133-22.666C25.8 2.4 26.067 2 26.2 2zM8.467 13.2c-.4-.267-.667-.4-1.067-.4H3c-.933 0-1.6.933-1.6 2v11.333c0 .667.267 1.2.533 1.6.267.267.667.4 1.067.4h4.4c.933 0 1.6-.933 1.6-2V14.8c0-.667-.267-1.2-.533-1.6zm-1.2 13.467h-4.4S2.6 26.4 2.6 26V14.667c0-.4.267-.667.267-.667h4.4s.266.267.266.667V26c0 .4-.133.667-.266.667z" />
      </g>
      <defs>
        <clipPath id="trend_svg__a">
          <path fill="#fff" transform="translate(.6)" d="M0 0h32v32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SvgTrend;
