import { FormikErrors } from 'formik';
import dot from 'dot-object';
import { mergeObjects, transformDotNotationObject } from '~/components/utils/helpers/objectHelpers';
import { BackendValidationErrorResponse, ValidationCode } from './ErrorTypes';

export const ERROR_ALREADY_IN_USE = 'Entered code is already in use!';

const errorMessage = (code: ValidationCode, message: string) => {
  switch (code) {
    case ValidationCode.ValueTaken:
      return ERROR_ALREADY_IN_USE;
    case ValidationCode.GenericCode:
    default:
      return message;
  }
};

export const transformErrors = <T>(errors: BackendValidationErrorResponse<T>): FormikErrors<T> =>
  errors.validationErrors.reduce(
    (prevErrors, error) =>
      (dot.str(
        error.field,
        errorMessage(error.code, error.message),
        prevErrors,
      ) as unknown) as FormikErrors<T>,
    {},
  );

export const transformNestedErrors = <T>(errors: BackendValidationErrorResponse<T>) => {
  const fieldErrors = errors.validationErrors.map((error) => transformDotNotationObject({ [error.field]: error.message }));
  const mergedErrors = mergeObjects(fieldErrors);

  return mergedErrors;
}

export const transformErrorsToList = <T>(errors: BackendValidationErrorResponse<T>): string[] =>
  errors.validationErrors.map((error) => error.message);

export const isBackendValidationError = <T>(err: any): err is BackendValidationErrorResponse<T> =>
  !!err.validationErrors;
