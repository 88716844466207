/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo } from "react";
import { Link, generatePath, useNavigate } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import objectPath from "object-path";
import { CometChat } from '@cometchat-pro/chat';
import { useHtmlClassService } from "../../../_core/MetronicLayout";
import { toAbsoluteUrl } from "../../../../_helpers";
import { DropdownTopbarItemToggler } from "../../../../_partials/dropdowns";
import { useCoach } from "~/model/Profile";
import { getBasename } from "~/services/api";
import { ROUTES } from '~/components/router/Routes';
import { Button } from '~/components/common/Button';
import { Image } from '~/components/common/ProfileImage';
import { removeToken } from "~/components/common/TokenProvider/utils";
import { queryClient } from '~/services/api';
import { coachProfileQueryKey } from '~/model/Profile';

export function UserProfileDropdown() {
  const { isLoading, data: user } = useCoach();
  const navigate = useNavigate();

  if (isLoading) {
    return null;
  }
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => ({
    light:
      objectPath.get(uiService.config, "extras.user.dropdown.style") ===
      "light",
  }), [uiService]);

  const logout = async () => {
    try {
      await CometChat.registerTokenForPushNotification('');
      await CometChat.logout();
    } catch (error) {
      console.log("Logout error:", error);
    }
    removeToken();
    queryClient.removeQueries(coachProfileQueryKey());
    navigate(ROUTES.LOGIN, { replace: true });
  }

  return (
    <Dropdown drop="down" alignRight>
      <Dropdown.Toggle
        as={DropdownTopbarItemToggler}
        id="dropdown-toggle-user-profile"
      >
        <div
          className="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"
        >
          <span className="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1">
            Hi,
          </span>{" "}
          <span className="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3">
            {user.first_name} {user.last_name}
          </span>
          <span className="symbol symbol-35 symbol-light-success">
            <span className="symbol-label font-size-h5 font-weight-bold">
              <Image $imageUrl={user.profile_image_url || user.empty_profile_image_url} $size={35} />
            </span>
          </span>
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu className="p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl">
        <>
          {/** ClassName should be 'dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl' */}
          {layoutProps.light && (
            <>
              <div className="d-flex align-items-center p-8 rounded-top">
                <div className="symbol symbol-md bg-light-primary mr-3 flex-shrink-0">
                  <img src={toAbsoluteUrl("/media/users/300_21.jpg")} alt="" />
                </div>
                <div className="text-dark m-0 flex-grow-1 mr-3 font-size-h5">
                  {user.first_name} {user.last_name}
                </div>
              </div>
              <div className="separator separator-solid" />
            </>
          )}

          {!layoutProps.light && (
            <div
              className="d-flex align-items-center justify-content-between flex-wrap p-8 bgi-size-cover bgi-no-repeat rounded-top"
              style={{
                backgroundImage: `url(${toAbsoluteUrl(
                  "/media/misc/bg-1.jpg"
                )})`,
              }}
            >
              <div className="symbol bg-white-o-15 mr-3">
                <span className="symbol-label text-success font-weight-bold font-size-h4">
                  <Image $imageUrl={user.profile_image_url || user.empty_profile_image_url} $size={50} />
                </span>
              </div>
              <div className="text-white m-0 flex-grow-1 mr-3 font-size-h5">
                {user.first_name} {user.last_name}
              </div>
            </div>
          )}
        </>

        <div className="navi navi-spacer-x-0 pt-5">
          <Link to={generatePath(ROUTES.PROFILE)} className="navi-item px-8 cursor-pointer">
            <div className="navi-link">
              <div className="navi-icon mr-2">
                <i className="flaticon2-calendar-3 text-success" />
              </div>
              <div className="navi-text">
                <div className="font-weight-bold cursor-pointer">
                  My Profile
                </div>
                <div className="text-muted">
                  Account settings and more
                </div>
              </div>
            </div>
          </Link>

          <div className="navi-separator mt-3" />

          <div className="navi-footer  px-8 py-5">
            <Button
              shade="light"
              variant="primary"
              size="medium"
              onClick={logout}
            >
              Sign Out
            </Button>
          </div>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
}