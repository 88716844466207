import { generatePath } from 'react-router-dom';
import { api } from '~/services/api';

export interface SubscriptionPrice {
  id: string;
  name: string;
}

const URL = {
  GET_COACH_SUBSCRIPTION_PRICES: '/api/coach/:id/subsciption-price',
};

export const model = {
  getCoachSubscriptionPrices: (id?: string) =>
    api.get<SubscriptionPrice[]>(generatePath(URL.GET_COACH_SUBSCRIPTION_PRICES, { id })),
};
