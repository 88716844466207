import * as React from 'react';
import { SVGProps } from 'react';
import { Size } from '../types';
interface Props extends SVGProps<SVGSVGElement> {
  size?: Size;
}
const SvgDiagnostics = (props: Props) => {
  const getSize = () => {
    if (props.size) {
      switch (props.size) {
        case 'small':
          return 20;

        case 'medium':
          return 24;

        case 'large':
          return 32;

        default:
          return null;
      }
    }
  };

  return (
    <svg
      width={getSize() || 25}
      height={getSize() || 24}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect opacity={0.3} x={2.161} y={3} width={20} height={18} rx={2} fill="currentColor" />
      <path
        d="M10.11 13.316A1 1 0 019.16 14h-4a1 1 0 110-2h3.28l1.771-5.316c.317-.95 1.677-.897 1.92.073l1.844 7.378.79-1.582a1 1 0 01.895-.553h3.5a1 1 0 110 2H16.28l-1.723 3.447c-.416.83-1.64.696-1.865-.204l-1.667-6.669-.914 2.742z"
        fill="currentColor"
      />
      <path
        opacity={0.3}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.161 7a1 1 0 100-2 1 1 0 000 2z"
        fill="#3699FF"
      />
    </svg>
  );
};

export default SvgDiagnostics;
