import { useQuery } from 'react-query';
import { GoalResponse, model as clientGoalsModel } from '../goals';

export const queryKey = (clientId: string, goalId: string) => [
  'client',
  `${clientId}`,
  'goals',
  `${goalId}`,
];

export const useClientGoal = (clientId: string, goalId: string) =>
  useQuery<GoalResponse>(queryKey(clientId, goalId), () => clientGoalsModel(clientId).get(goalId));
