import React, { useReducer, useState } from 'react';
import { DateTime } from 'luxon';
import { toast } from 'react-toastify';

import { EmptyState } from '~/components/common/EmptyState';
import { Button } from '~/components/common/Button';
import { Cart } from '~/components/common/Icons/dist';
import { isBackendValidationError, transformErrorsToList } from '~/model/Error/transformApiErrors';

import { Remarks } from '~/components/common/Remarks';
import {
  anythingInBasket,
  INITIAL_STATE,
  reducer,
  ActionDecrease,
  ActionIncrease,
  ActionReset,
  quantityInBasket,
} from '~/stateManagement/basket';
import { SupplementConfirmationModal } from './Supplement/SupplementConfirmationModal';
import { Schedule } from './Schedule';
import { headerColumns, SupplementCategory as model } from './model';
import { Supplement } from './Supplement';
import { ExpandableTable } from '../ExpandableTable';
import { TableRowContent } from '../ExpandableTable/ExpandableTable.style';

export interface Props {
  supplementCategories: model[];
  updatedAt?: string;
}

export const Supplements = ({ supplementCategories, updatedAt }: Props) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const remarksLines = [
    'All supplements are sold by and the orders are fulfilled by trusted third-parties',
    'Prices shown above include all relevant taxes (VAT/GST)',
  ];

  const handleIncrease = (id: number) => dispatch(ActionIncrease(id));
  const handleDecrease = (id: number) => dispatch(ActionDecrease(id));

  const handleSuccess = () => {
    toast.success('Successfully sent supplements recommendation.');
    dispatch(ActionReset());
    setShowConfirmationModal(false);
  };

  const handleError = (err) => {
    if (isBackendValidationError<model[]>(err)) {
      transformErrorsToList(err).forEach((error) => toast.error(error));
    }
  };

  if (supplementCategories.length === 0) {
    return (
      <EmptyState
        title="There are currently no supplements available!"
        description="Please use Intercom to ask about the restocking time"
      />
    );
  }

  return (
    <>
      <ExpandableTable
        title="Supplements"
        subtitle={updatedAt && `Updated ${DateTime.fromISO(updatedAt).toFormat('dd/MM/yyyy')}`}
        headers={headerColumns}
        appendHeaderButtons={
          <Button
            iconPosition="right"
            size="large"
            variant="primary"
            icon={<Cart />}
            onClick={() => setShowConfirmationModal(true)}
            disabled={!anythingInBasket(state)}
            aria-label="Review basket"
          >
            Review basket
          </Button>
        }
        prependHeaderButtons={<Schedule />}
        rows={supplementCategories.map(({ name, supplements }, index) => ({
          title: name,
          expanded: index === 0,
          content:
            supplements && supplements.length > 0 ? (
              <TableRowContent>
                {supplements.map((supplement) => (
                  <Supplement
                    key={supplement.id}
                    supplement={supplement}
                    onDecreaseClick={() => handleDecrease(supplement.id)}
                    onIncreaseClick={() => handleIncrease(supplement.id)}
                    inBasket={quantityInBasket(state, supplement.id)}
                  />
                ))}
              </TableRowContent>
            ) : undefined,
        }))}
        footer={<Remarks lines={remarksLines} />}
      />

      {showConfirmationModal && (
        <SupplementConfirmationModal
          basket={state}
          onSuccess={handleSuccess}
          onError={handleError}
          onClose={() => setShowConfirmationModal(false)}
        />
      )}
    </>
  );
};
