import * as React from 'react';
import { getPartner, Partner } from '~/components/utils/helpers/partner';
import { Props } from './LoaderTypes';
import { OmeLoader } from './OmeLoader';
import { HeiLoader } from './HeiLoader';

export const Loader = (props: Props) => {
  const partner = getPartner();

  switch (partner) {
    case Partner.HEI:
      return <HeiLoader {...props} />;
    default:
      return <OmeLoader {...props} />;
  }
};
