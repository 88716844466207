import React from 'react';
import { EmptyState } from '~/components/common/EmptyState';
import { Loader } from '~/components/common/Loader';
import { Modal } from '~/components/common/Modal';
import { useClientTestReportsList } from '~/model/Client/TestResults/TestReports';
import { useClient } from '../../../context';
import { TableRowContent } from '../../ExpandableTable/ExpandableTable.style';
import { headerColumns } from './config';
import { ExpandableTable } from './ExpandableTable';
import { TestReport } from './TestReport';

export interface Props {
  onClose: () => void;
}

const Error = () => <div>Could not retrieve data</div>;

export const TestReportsModal = ({ onClose }: Props) => {
  const { clientId } = useClient();
  const { data: testReportsList, isLoading, isError } = useClientTestReportsList(clientId);

  if (isLoading) {
    return <Loader />;
  }

  if (isError || !testReportsList) {
    return <Error />;
  }

  return (
    <Modal
      title="Test reports"
      withHeaderSeparator
      width="772px"
      onClose={onClose}
      closeOnOuterClick={false}
    >
      <ExpandableTable
        headers={headerColumns}
        rows={testReportsList.map(({ name, testReports }) => ({
          title: name,
          content:
            testReports && testReports.length > 0 ? (
              <TableRowContent>
                {testReports.map((testReport) => (
                  <TestReport
                    key={`test-${testReport.id}`}
                    testReport={testReport}
                    reportName={name}
                  />
                ))}
              </TableRowContent>
            ) : undefined,
        }))}
        emptyState={
          <EmptyState title="This Client currently has no test reports available!">
            Test reports will appear here when they are uploaded by the laboratories or when you
            upload them by clicking the <strong>Add new</strong> button in the Test results tab
          </EmptyState>
        }
      />
    </Modal>
  );
};
