import { BillingDetails, BillingType, CompanyDetails } from '~/model/Profile/coach';

export type FormFields = {
  type: number;
  name: string;
  registrationNumber: string;
  vatNumber: string;
  website: string;
  address1: string;
  address2: string;
  city: string;
  country: string;
  postCode: string;
  bankName?: string;
  swift?: string;
  iban?: string;
  bankAccountName?: string;
  bankAddress?: string;
  branchCode?: string;
  bankCode?: string;
  paynowId?: string;
};

export const initialValues = ({
  type,
  companyDetails,
  billingAddress,
  bankDetails,
}: BillingDetails): FormFields => ({
  type,
  name: companyDetails.name,
  registrationNumber: companyDetails.registrationNumber,
  vatNumber: companyDetails.vatNumber,
  website: companyDetails.website,
  address1: billingAddress.address1,
  address2: billingAddress.address2,
  city: billingAddress.city,
  country: billingAddress.country,
  postCode: billingAddress.postCode,
  bankName: bankDetails?.bankName,
  swift: bankDetails?.swift,
  iban: bankDetails?.iban,
  bankAccountName: bankDetails?.bankAccountName,
  bankAddress: bankDetails?.bankAddress,
  branchCode: bankDetails?.branchCode,
  bankCode: bankDetails?.bankCode,
  paynowId: bankDetails?.paynowId,
});

export const toApiRequest = ({
  type,
  name,
  registrationNumber,
  vatNumber,
  website,
  address1,
  address2,
  city,
  country,
  postCode,
  bankName,
  swift,
  iban,
  bankAccountName,
  bankAddress,
  branchCode,
  bankCode,
  paynowId,
}: FormFields): BillingDetails => {
  const companyDetails: CompanyDetails = { name, registrationNumber, vatNumber, website };

  if (type !== BillingType.TYPE_BUSINESS) {
    Object.keys(companyDetails).forEach((key) => {
      companyDetails[key] = null;
    });
  }

  return {
    type: Number(type),
    companyDetails,
    billingAddress: { address1, address2, city, country, postCode },
    bankDetails: { bankName, swift, iban, bankAccountName, bankAddress, branchCode, bankCode, paynowId },
  };
};
