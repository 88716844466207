import React, { useMemo } from 'react';
import { useClient } from '~/components/pages/client/context';
import { useWidgetPeriodChart } from '~/model/Client/Progress/hooks/useWidgetPeriodChart';
import { WidgetId } from '~/model/Client/Progress/progress';
import { colors } from '~/components/utils/colors';
import { DateTime } from 'luxon';
import { Loader } from '~/components/common/Loader';
import { Chart, LoaderContainer } from '../Modal.style';
import { yAnnotationLine } from './Shared';
import { formatTimeMin } from '../History/Shared';

export interface ExerciseChartProps {
  periodId: number;
}

export const ExerciseChart = ({ periodId }: ExerciseChartProps) => {
  const { clientId } = useClient();
  const { data, isLoading } = useWidgetPeriodChart(clientId, WidgetId.Exercise, periodId);

  const categories = useMemo(() => (data?.chart.values ?? []).map((value) => DateTime.fromSeconds(value.date).toFormat('dd/MM')), [data]);
  const seriesData = useMemo(() => {
    if (!data) return [];

    const { values, legend } = data.chart;
    const series: ApexAxisChartSeries = legend.map(({ label }) => ({
      name: label,
      data: [] as (number | null)[],
    }));

    values.forEach(({ values: subValues }) => {
      for (let i = 0; i < series.length; i += 1) {
        const value = subValues?.[i].value ?? null;

        (series[i].data as (number | null)[]).push(value);
      }
    });

    return series;
  }, [data]);
  const barColors = useMemo(() => (data?.chart.values ?? []).map((value) => typeof data?.chart.target === 'number' && (value.values?.[0].value ?? 0) > data?.chart.target ? colors.colorOmeGreen : colors.colorPriBlueOne), [data]);

  const yAnnotations = useMemo(() => {
    if (!data || !data.chart.target) return [];

    const { target } = data.chart;
    const annotations: YAxisAnnotations[] = [];

    if (typeof target === 'number') {
      annotations.push(yAnnotationLine(target, formatTimeMin({ seconds: target }), colors.colorSecBlueThree));
    }

    return annotations;
  }, [data]);

  const maxValue = useMemo(() => {
    if (!data) return 120;

    const { values, target } = data.chart;
    let maxVal = Math.max(...values.map(({ values: subValues }) => Math.max(...(subValues ?? []).map(({ value }) => value))));

    if (typeof target === 'number') {
      maxVal = Math.max(maxVal, target);
    }

    return maxVal * 1.2;
  }, [data]);

  if (isLoading) return <LoaderContainer><Loader center /></LoaderContainer>;

  return (
    <Chart
      options={{
        chart: {
          id: `widget-${WidgetId.Exercise}`,
          height: 325,
          dropShadow: {
            enabled: true,
            top: 2,
            left: 0,
            blur: 4,
            color: '#2fb4d080',
          },
          toolbar: { show: false },
          selection: { enabled: false }
        },
        xaxis: {
          categories,
          axisTicks: { show: false },
          labels: {
            rotateAlways: true,
            rotate: -90,
            minHeight: 63,
            maxHeight: 63,
          },
        },
        yaxis: {
          axisBorder: { show: false },
          max: maxValue,
          min: 0,
          showAlways: true,
          tickAmount: 4,
          labels: {
            align: 'left',
            minWidth: 60,
            maxWidth: 60,
            formatter: (seconds: number) => formatTimeMin({ seconds }),
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: 10,
            borderRadius: 5
          },
        },
        tooltip: { enabled: false },
        dataLabels: { enabled: false },
        colors: [({ dataPointIndex }) => barColors[dataPointIndex]],
        grid: {
          show: true,
          strokeDashArray: 6,
          xaxis: { lines: { show: false } },
          padding: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
          },
        },
        states: {
          hover: {
            filter: { type: 'none' }
          },
          active: {
            filter: { type: 'none' }
          }
        },
        stroke: { show: false },
        annotations: {
          yaxis: yAnnotations,
        }
      }}
      height={325}
      series={seriesData}
      type="bar"
    />
  );
};
