import React from 'react';

import {
  ConfirmationModal as Component,
  Props as ConfirmationModalProps,
  IconVariant,
} from '~/components/common/ConfirmationModal';

export interface Props {
  onClose: () => void,
  onSubmit: () => void,
  isLoading: boolean
}

export const EditTestResultsConfirmationModal = (
  { onClose, onSubmit, isLoading }: Props
) => {
  const confirmationModalProps: ConfirmationModalProps = {
    iconVariant: 'warning' as IconVariant,
    title: 'Are you sure?',
    description: (
      <>
        <p>Changing the test results will regenerate this client&apos;s Journey.</p>
        <p>Any changes you made to the client&apos;s Goals and Meals will be lost!</p>
      </>
    ),
    closeButtonText: 'No, cancel',
    submitButtonText: 'Yes, save!',
    testId: 'confirm-edit-test-results-modal',
    onClose,
    onSubmit,
    isLoading
  };

  return <Component {...confirmationModalProps}/>;
};