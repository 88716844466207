import { css } from '@emotion/css';

export const style = {
  wrapper: css({
    display: 'flex',
    alignItems: 'center',
  }),
  subnote: css({
    padding: '2px 10px 2px 16px',
  }),
  error: css({
    display: 'block',
    fontSize: '10px',
    lineHeight: '15px',
  }),
};
