import { api } from '~/services/api';

const URL = {
  GET: '/api/enabled-features',
};

export type FeatureToggleResponse = string[];

export const model = {
  get: () => api.get<FeatureToggleResponse>(URL.GET),
};
