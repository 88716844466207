import React, { PropsWithChildren } from 'react';
import {
  Layout as StyledLayout,
  LayoutHeaderButtons,
  LayoutSubtitle,
  LayoutContent,
  LayoutSearchAndFilters,
} from './Layout.style';

export interface Props {
  title?: string;
  subtitle?: string;
  headerButtons?: JSX.Element | JSX.Element[];
  searchAndFilters?: JSX.Element | JSX.Element[];
  withHeader?: boolean;
}

export const Layout = ({
  title,
  subtitle,
  headerButtons,
  searchAndFilters,
  children,
  withHeader = true,
}: PropsWithChildren<Props>) => (
  <>
    {withHeader && <StyledLayout>
      <div>
        <h3>{title}</h3>
        {subtitle && <LayoutSubtitle>{subtitle}</LayoutSubtitle>}
      </div>
      {headerButtons && <LayoutHeaderButtons>{headerButtons}</LayoutHeaderButtons>}
    </StyledLayout>}
    {searchAndFilters && <LayoutSearchAndFilters>{searchAndFilters}</LayoutSearchAndFilters>}
    {!withHeader ? children : <LayoutContent>{children}</LayoutContent>}
  </>
);
