import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { IconButton } from '~/components/common/Button';
import { Card, CardBody, CardHeader } from '~/components/common/Card';
import { FormRowInputWrapper, FormikInput, FormikDropdown } from '~/components/common/Form';
import { Close2, Search } from '~/components/common/Icons/dist';
import { Pagination } from '~/components/common/Pagination';
import { usePagination } from '~/components/common/Pagination/hooks/usePagination';
import { SearchFilterWrapper, SearchFilterActions } from '~/components/common/SearchAndFilter';
import {
  mockPaginationData,
  mockPaginationDataByNextPage,
} from '~/model/PaginationData/__mocks__/mockPaginationData';

interface FormFields {
  term?: string;
  journey?: string;
  status?: string;
}

const initialValues: FormFields = {
  term: '',
  journey: '',
  status: '',
};

export const ListComponents = () => {
  const { changeCurrentPage, changeItemsPerPage } = usePagination({
    currentPage: 2,
    itemsPerPage: 5,
  });

  const [paginationData, setPaginationData] = useState(mockPaginationData());

  const handlePageChange = (page: number) => {
    setPaginationData(mockPaginationDataByNextPage(page));
    changeCurrentPage(page);
  };

  return (
    <>
      <Card>
        <CardHeader title="Search/Filters" />
        <CardBody>
          <SearchFilterWrapper>
            <Formik initialValues={initialValues} onSubmit={() => {}}>
              {({ submitForm }) => (
                <Form className="row align-items-center">
                  <div className="row col">
                    <div className="col-12 col-sm-3">
                      <FormRowInputWrapper>
                        <FormikInput
                          name="term"
                          icon={<Search />}
                          placeholder="Search for a client"
                        />
                      </FormRowInputWrapper>
                    </div>
                    <div className="col-12 col-sm-3">
                      <FormRowInputWrapper>
                        <FormikDropdown name="journey" placeholder="Filter Journey">
                          <option value="">All Journeys</option>
                          <option value="Longevity">Longevity</option>
                          <option value="Energy">Energy</option>
                        </FormikDropdown>
                      </FormRowInputWrapper>
                    </div>
                    <div className="col-12 col-sm-3">
                      <FormRowInputWrapper>
                        <FormikDropdown name="status" placeholder="Filter Status">
                          <option value="">All Statuses</option>
                          <option value="not-started">No Started</option>
                          <option value="started">Started</option>
                        </FormikDropdown>
                      </FormRowInputWrapper>
                    </div>
                  </div>
                  <div className="col-auto">
                    <SearchFilterActions>
                      <IconButton
                        icon={<Search />}
                        variant="neutral"
                        type="submit"
                        aria-label="Search"
                        onClick={submitForm}
                      />
                      <IconButton
                        icon={<Close2 />}
                        variant="neutral"
                        type="reset"
                        aria-label="Clear"
                      />
                    </SearchFilterActions>
                  </div>
                </Form>
              )}
            </Formik>
          </SearchFilterWrapper>
        </CardBody>
      </Card>
      <Card>
        <CardHeader title="Pagination with items per page" />
        <CardBody>
          <Pagination
            paginationData={paginationData}
            onPageChange={handlePageChange}
            onItemsPerPageChange={changeItemsPerPage}
          />
        </CardBody>
      </Card>
      <Card>
        <CardHeader title="Pagination without items per page" />
        <CardBody>
          <Pagination paginationData={paginationData} onPageChange={handlePageChange} />
        </CardBody>
      </Card>
    </>
  );
};
