import { useQuery } from 'react-query';
import { Device } from '~/components/pages/client/Sections/Devices/Device/model';
import { model } from '../device';

export const clientDeviceQueryKey = (clientId: string, deviceId: number) => [
  'client',
  clientId,
  'device',
  deviceId,
];

export const useGetDevice = (clientId: string, deviceId: number) =>
  useQuery<Device>(
    clientDeviceQueryKey(clientId, deviceId),
    () => model.getDevice(clientId, deviceId),
    { refetchOnWindowFocus: false },
  );
