import { css } from '@emotion/css';
import styled from '@emotion/styled';
import { colors } from '~/components/utils/colors';

export const style = {
  headers: {
    actions: css({ placeSelf: 'self-end', marginRight: '12px' }),
  },
};

export const RowTitleAdditionalData = styled.span`
  color: ${colors.colorDisabledOne};
`;
