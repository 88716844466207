import { css } from '@emotion/css';
import styled from '@emotion/styled';
import { colors } from '~/components/utils/colors';

export const style = {
  wrapper: css({
    display: 'flex',
    alignItems: 'center',
  }),
  inputWrapper: css({
    width: '100%',
  }),
  select: css({}),
  subnote: css({
    padding: '2px 10px 2px 16px',
  }),
  error: css({
    display: 'block',
    fontSize: '10px',
    lineHeight: '15px',
  }),
  note: css({
    display: 'block',
    fontSize: '11px',
    lineHeight: '16px',
  }),
};

interface StyledDropdownListProps {
  open: boolean;
}

export const StyledDropdownList = styled.div(({ open }: StyledDropdownListProps) => (`
  position: absolute;
  height: auto;
  background-color: ${colors.colorWhite};
  right: 0;
  width: 100%;
  box-shadow: 0px 4px 8px 4px rgba(0, 0, 0, 0.1);
  max-height: 300px;
  overflow: auto;
  border-radius: 6px;
  z-index: 2;
  flex-direction: column;
  display: ${open ? 'flex' : 'none'};

  select {
    width: 100%;
  }
`));

export const StyledSelect = styled.select`
  appearance: none;
  background-image: url('/media/svg/misc/dropdown-down.svg');
  background-repeat: no-repeat;
  background-position: right 12px center !important;
  padding: 11px 10px 11px 16px;
  margin-bottom: 2px;
  height: 40px;
  border-width: 1px;
  border-style: solid;
  border-radius: 6px;

  &:focus {
    background-image: url('/media/svg/misc/dropdown-up.svg');
  }
`;

export const StyledListBox = styled.select`
  border: unset !important;

  option {
    cursor: pointer;
    padding: 10px 12px 10px 16px;
    color: ${colors.colorGsThree};

    &:hover,
    &:focus,
    &:active,
    &:checked {
      background-color: ${colors.colorDisabledTwo};
    }
  }
`;