import { useQuery } from 'react-query';
import { Recipe } from '~/model/Recipe';
import { model } from '../meal';

export const clientMealRecipeQueryKey = (clientId: string, mealId: number) => [
  'client',
  clientId,
  'meal',
  'recipe',
  mealId,
];

export const useGetMealRecipe = (clientId: string, mealId: number) =>
  useQuery<Recipe>(
    clientMealRecipeQueryKey(clientId, mealId),
    () => model.getMealRecipe(clientId, mealId),
    {
      refetchOnWindowFocus: false,
    },
  );
