import styled from '@emotion/styled';
import { colors } from '~/components/utils/colors';
import { Button as BaseButton } from '../Button';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: Poppins;
  margin-top: 25px;
`;

export const PaginationWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  button {
    margin: 0 2px;
  }
`;

export const Button = styled(BaseButton)<{ $active?: boolean }>`
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ $active }) => {
    if ($active) {
      return `
        background-color: ${colors.colorMenuDefault};
        color: ${colors.colorWhite};
        border-color: ${colors.colorMenuDefault};
      `;
    }

    return ``;
  }}
`;
