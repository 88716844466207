import { useQuery } from 'react-query';
import { TestKit } from '~/components/pages/client/Sections/TestKits/TestKit/model';
import { model } from '../testKit';

export const clientTestKitQueryKey = (clientId: string, testKitId: number) => [
  'client',
  clientId,
  'test-kit',
  testKitId,
];

export const useGetTestKit = (clientId: string, testKitId: number) =>
  useQuery<TestKit>(
    clientTestKitQueryKey(clientId, testKitId),
    () => model.getTestKit(clientId, testKitId),
    {
      refetchOnWindowFocus: false,
    },
  );
