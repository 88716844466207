export const actionMessageStyle = () => {

    return {
        padding: "8px 16px",
        marginBottom: "16px",
        textAlign: "center",
        height: "52px"
    }
}

export const actionMessageTxtStyle = () => {

    return {
        fontSize: "13.5px",
        margin: "0",
        lineHeight: "20px",
    }
}

export const messageTimeStampStyle = context => {

	return {
		display: "inline-block",
		fontSize: "11px",
		fontWeight: 500,
		lineHeight: "12px",
		textTransform: "uppercase",
		color: `${context.theme.color.helpText}`,
	};
};
