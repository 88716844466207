import React, { useState } from 'react';

import { IconButton } from '~/components/common/Button';
import { Edit2, Minus, Plus2 } from '~/components/common/Icons/dist';

import { TestableComponentProps } from '~/model/TestableComponentProps.interface';
import { getVariantFromColor, Label } from '~/components/common/Label';
import { TestKitRow, style } from './TestKit.style';
import { TestKit as Model, headerColumns, TestKitStatusValue } from '../model';
import { TableRowItem } from '../../ExpandableTable/ExpandableTable.style';
import { RowHeaderButton } from '../../Sections.style';
import { TestKitModal } from './TestKitModal/TestKitModal';

export interface Props extends TestableComponentProps {
  testKit: Model;
  onIncreaseClick: () => void;
  onDecreaseClick: () => void;
  inBasket: number;
}

const label = ({ color, value }) => (
  <Label inline variant={getVariantFromColor(color)} light>
    {value}
  </Label>
);

export const TestKit = ({ testKit, testId, onIncreaseClick, onDecreaseClick, inBasket }: Props) => {
  const [showModal, setShowModal] = useState(false);
  const { id, name, type, samples, estimatedWaitTime, formattedPrice, status } = testKit;

  return (
    <TableRowItem>
      <TestKitRow $widths={headerColumns.map(({ width }) => width || '1fr')}>
        <div className={style.testKit}>
          <RowHeaderButton onClick={() => setShowModal(true)}>{name}</RowHeaderButton>
        </div>
        <div className={style.column}>{type}</div>
        <div className={style.column}>{samples.join(', ')}</div>
        <div className={style.column}>{estimatedWaitTime}</div>
        <div className={style.column}>{formattedPrice}</div>
        <div className={style.column}>{label(status)}</div>
        <div data-testid="in-basket" className={style.column}>
          {inBasket}
        </div>
        <div className={style.actions}>
          <IconButton
            shade="light"
            aria-label="View Test Kit"
            icon={<Edit2 />}
            onClick={() => setShowModal(true)}
          />
          <IconButton
            shade="light"
            aria-label="Increase amount of test kits in basket"
            icon={<Plus2 />}
            onClick={onIncreaseClick}
            disabled={status.value === TestKitStatusValue.OUT_OF_STOCK}
          />
          <IconButton
            shade="light"
            aria-label="Decrease amount of test kits in basket"
            icon={<Minus />}
            onClick={onDecreaseClick}
            disabled={status.value === TestKitStatusValue.OUT_OF_STOCK}
          />
        </div>
      </TestKitRow>
      {showModal && (
        <TestKitModal
          testId={`test-kit-modal-${testId}`}
          onClose={() => setShowModal(false)}
          testKitId={id}
        />
      )}
    </TableRowItem>
  );
};
