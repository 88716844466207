import { api } from '~/services/api';

const URL = {
  LOGIN: '/api/coach-login',
  RESET_PASSWORD_EMAIL: '/api/coach-send-password-reset-email',
  RESET_PASSWORD: '/api/coach-reset-password',
  REGISTER: '/api/coach-registration',
};

export interface LoginFormData {
  username: string;
  password: string;
}

export interface LoginResponse {
  token: string;
}

export interface ResetPasswordEmailFormData {
  username: string;
  url: string;
}

export interface ResetPasswordFormData {
  password: string;
  token: string;
}

export interface CoachReferralPartner {
  welcomePackSource: string;
}

export interface Address {
  address1?: string;
  postCode?: string;
  city?: string;
  country?: string;
  phoneNumber?: string;
}

export interface CoachInformation {
  referredByReferralPartner?: string;
  referralPartner?: CoachReferralPartner;
  address?: Address;
}

export interface RegisterFormData {
  firstName?: string;
  lastName?: string;
  email?: string;
  plainPassword?: string;
  agreedWithPrivacyPolicy?: boolean;
  agreedWithEndUserLicenseAgreement?: boolean;
  legalDisclosureHealthAdvice?: boolean;
  coachInformation?: CoachInformation;

  agreedWithPersonalDataCollection?: boolean;
}

export const model = {
  login: (data: LoginFormData) => api.post<LoginFormData, LoginResponse>(URL.LOGIN, data),
  sendResetEmail: (data: ResetPasswordEmailFormData) => api.post(URL.RESET_PASSWORD_EMAIL, data),
  resetPassword: ({ password, token }: ResetPasswordFormData) => api.post(`${URL.RESET_PASSWORD}/${token}`, { password }),
  register: (data: RegisterFormData) => api.post(URL.REGISTER, data),
};
