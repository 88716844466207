import { Header } from '../../ExpandableTable/model';
import { style } from '../TestResults.style';

export const headerColumns: Header[] = [
  {
    title: 'Test date',
    width: '3fr',
  },
  {
    title: 'Status',
    width: '1fr',
  },
  {
    title: 'Action',
    className: style.headers.actions,
    width: '1.5fr',
  },
];
