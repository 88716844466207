export const yAnnotationLine = (value: number, text: string, color: string): YAxisAnnotations => ({
  y: value,
  borderColor: color,
  borderWidth: 4,
  strokeDashArray: undefined,
  label: {
    borderColor: color,
    borderRadius: 10,
    position: 'left',
    offsetY: 8,
    offsetX: -4,
    style: {
      color: '#fff',
      background: color
    },
    text
  }
});
