import React, { useMemo } from 'react';
import { useClient } from '~/components/pages/client/context';
import { useWidgetPeriodChart } from '~/model/Client/Progress/hooks/useWidgetPeriodChart';
import { WidgetId } from '~/model/Client/Progress/progress';
import { colors } from '~/components/utils/colors';
import { DateTime, Duration } from 'luxon';
import { Loader } from '~/components/common/Loader';
import { Chart, LoaderContainer } from '../Modal.style';
import { yAnnotationLine } from './Shared';

export interface SleepChartProps {
  periodId: number;
}

export const SleepChart = ({ periodId }: SleepChartProps) => {
  const { clientId } = useClient();
  const { data, isLoading } = useWidgetPeriodChart(clientId, WidgetId.Sleep, periodId);

  const categories = useMemo(() => (data?.chart.values ?? []).map((value) => DateTime.fromSeconds(value.date).toFormat('dd/MM')), [data]);
  const seriesData = useMemo(() => {
    if (!data) return [];

    const { values, legend } = data.chart;
    const series: ApexAxisChartSeries = legend.map(({ label, colour }) => ({
      name: label,
      color: colour,
      data: [] as (number | null)[],
    }));

    values.forEach(({ values: subValues }) => {
      for (let i = 0; i < series.length; i += 1) {
        const value = subValues?.[i].value ?? null;

        (series[i].data as (number | null)[]).push(value);
      }
    });

    return series;
  }, [data]);

  const yAnnotations = useMemo(() => {
    if (!data || !data.chart.target) return [];

    const { target } = data.chart;
    const annotations: YAxisAnnotations[] = [];

    if (typeof target === 'number') {
      const duration = Duration.fromObject({ minutes: target });
      annotations.push(yAnnotationLine(target, duration.toFormat('h\'h\' mm\'min\''), colors.colorSecBlueThree));
    }

    return annotations;
  }, [data]);

  const maxValue = useMemo(() => {
    // maxvalue or target
    if (!data) return 8 * 60; // 8 hours

    const { values, target } = data.chart;
    let maxVal = Math.max(...values.map(({ values: subValues }) => (subValues ?? []).reduce((acc, { value }) => acc + value, 0)));

    if (typeof target === 'number') {
      maxVal = Math.max(maxVal, target);
    }

    return maxVal * 1.2;
  }, [data]);

  const hasData = useMemo(() => (data?.summary?.header.value ?? 0) > 0, [data]);

  if (isLoading) return <LoaderContainer><Loader center /></LoaderContainer>;

  return (
    <Chart
      options={{
        chart: {
          id: `widget-${WidgetId.Sleep}`,
          height: 325,
          dropShadow: {
            enabled: true,
            top: 2,
            left: 0,
            blur: 4,
            color: colors.colorGsThree,
          },
          toolbar: { show: false },
          stacked: true,
          selection: { enabled: false }
        },
        xaxis: {
          categories,
          axisTicks: { show: false },
          labels: {
            rotateAlways: true,
            rotate: -90,
            minHeight: 63,
            maxHeight: 63,
          },
        },
        yaxis: {
          axisBorder: { show: false },
          max: maxValue,
          min: 0,
          labels: {
            align: 'left',
            minWidth: 80,
            maxWidth: 80,
            formatter: (value: number) => {
              if (!hasData) {
                // dummy value, default chart range is 0-2, we multiply by 5
                // to make it look like a normal chart (0-10h)
                return `${value * 5}h`;
              }

              const duration = Duration.fromObject({ minutes: value });
              const hours = duration.as('hours').toFixed(0);
              return `${hours}h`;
            }
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: 10,
            borderRadius: 5,
            borderRadiusApplication: 'around',
            borderRadiusWhenStacked: 'all'
          },
        },
        tooltip: { enabled: false },
        dataLabels: { enabled: false },
        grid: {
          show: true,
          strokeDashArray: 6,
          xaxis: { lines: { show: false } },
          padding: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
          },
        },
        states: {
          hover: {
            filter: { type: 'none' }
          },
          active: {
            filter: { type: 'none' }
          }
        },
        stroke: { show: false },
        annotations: {
          yaxis: yAnnotations,
        },
        legend: {
          onItemClick: { toggleDataSeries: false },
          onItemHover: { highlightDataSeries: false },
          markers: {
            fillColors: [
              ...seriesData.map(({ color }) => `${color}`),
              colors.colorSecBlueThree,
            ],
            shape: 'circle',
          },
          customLegendItems: [
            ...seriesData.map(({ name }) => `${name}`),
            'Target'
          ],
        }
      }}
      height={325}
      series={seriesData}
      type="bar"
    />
  );
};
