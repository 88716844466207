import * as Yup from 'yup';

const ERROR_REQUIRED = 'This field is required';
const ERROR_TYPE_NUMBER = 'The value of this field has to be numeric.';
const ERROR_TYPE_FILE = 'Only .pdf files are allowed.';

export const validationSchema = Yup.object({
    value: Yup.number().typeError(ERROR_TYPE_NUMBER).when('formType', {
        is: 'value',
        then: Yup.number().required(ERROR_REQUIRED),
        otherwise: Yup.number().notRequired(),
    }),
    testDate: Yup.object({
        year: Yup.number().required(ERROR_REQUIRED),
        month: Yup.number().required(ERROR_REQUIRED),
        day: Yup.number().required(ERROR_REQUIRED),
    }),
    phenotype: Yup.number().when('formType', {
        is: 'value',
        then: Yup.number().required(ERROR_REQUIRED),
        otherwise: Yup.number().notRequired(),
    }),
    name: Yup.string().when('formType', {
        is: 'file',
        then: Yup.string().required(ERROR_REQUIRED),
        otherwise: Yup.string().notRequired(),
    }),
    reportFile: Yup.mixed().when('formType', {
        is: 'file',
        then: Yup.mixed()
            .required(ERROR_REQUIRED)
            .test('fileFormat', ERROR_TYPE_FILE, (value) => value && value.type === 'application/pdf'),
        otherwise: Yup.mixed().notRequired(),
    }),
});
