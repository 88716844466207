import * as React from 'react';
import { SVGProps } from 'react';
import { Size } from '../types';
interface Props extends SVGProps<SVGSVGElement> {
  size?: Size;
}
const SvgEdit2 = (props: Props) => {
  const getSize = () => {
    if (props.size) {
      switch (props.size) {
        case 'small':
          return 20;

        case 'medium':
          return 24;

        case 'large':
          return 32;

        default:
          return null;
      }
    }
  };

  return (
    <svg
      width={getSize() || 20}
      height={getSize() || 20}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="currentColor"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.833 2.5a3.333 3.333 0 100 6.667h8.334a3.333 3.333 0 000-6.667H5.833zM7.5 5.833a1.667 1.667 0 11-3.333 0 1.667 1.667 0 013.333 0z"
        fill="currentColor"
      />
      <path
        opacity={0.3}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.833 10.833a3.333 3.333 0 000 6.667h8.334a3.333 3.333 0 000-6.667H5.833zm10 3.334a1.667 1.667 0 11-3.333 0 1.667 1.667 0 013.333 0z"
        fill="currentColor"
      />
    </svg>
  );
};

export default SvgEdit2;
