import * as React from 'react';
import { SVGProps } from 'react';
import { Size } from '../types';
interface Props extends SVGProps<SVGSVGElement> {
  size?: Size;
}
const SvgInfo = (props: Props) => {
  const getSize = () => {
    if (props.size) {
      switch (props.size) {
        case 'small':
          return 20;

        case 'medium':
          return 24;

        case 'large':
          return 32;

        default:
          return null;
      }
    }
  };

  return (
    <svg
      width={getSize() || 8}
      height={getSize() || 8}
      viewBox="0 0 8 8"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 .667a3.335 3.335 0 000 6.666A3.335 3.335 0 004 .667zm-.333 1.666V3h.666v-.667h-.666zm0 1.334v2h.666v-2h-.666zM1.333 4A2.67 2.67 0 004 6.667 2.67 2.67 0 006.667 4 2.67 2.67 0 004 1.333 2.67 2.67 0 001.333 4z"
      />
    </svg>
  );
};

export default SvgInfo;
