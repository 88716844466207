import * as React from 'react';
import { SVGProps } from 'react';
import { Size } from '../types';
interface Props extends SVGProps<SVGSVGElement> {
  size?: Size;
}
const SvgEdit = (props: Props) => {
  const getSize = () => {
    if (props.size) {
      switch (props.size) {
        case 'small':
          return 20;

        case 'medium':
          return 24;

        case 'large':
          return 32;

        default:
          return null;
      }
    }
  };

  return (
    <svg
      width={getSize() || 20}
      height={getSize() || 20}
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      {...props}
    >
      <path d="M17.932 3.993a1.5 1.5 0 01.022 2.1L10.82 13.52a1.5 1.5 0 01-1.05.46l-3 .063.063-3.014a1.5 1.5 0 01.442-1.032l7.294-7.252a1.5 1.5 0 012.118.003l1.244 1.244z" />
      <path
        opacity={0.3}
        d="M10.75 1.667a.833.833 0 010 1.666H5c-.92 0-1.667.746-1.667 1.667v10c0 .92.747 1.667 1.667 1.667h10c.92 0 1.667-.747 1.667-1.667v-4.167a.833.833 0 011.666 0V15A3.333 3.333 0 0115 18.333H5A3.333 3.333 0 011.667 15V5A3.333 3.333 0 015 1.667h5.75z"
      />
    </svg>
  );
};

export default SvgEdit;
