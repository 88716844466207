import React, { useEffect, useState } from 'react';
import { Loader, Modal, Props as ModalProps } from '~/components/common/Modal';
import { Title } from '~/components/common/Modal/Modal.style';
import { TestableComponentProps } from '~/model/TestableComponentProps.interface';
import { Description, Icon, TitleWrapper } from './Habit.style';

export interface Props extends TestableComponentProps, ModalProps {
  title: string;
  description: string;
  icon?: string;
}

export const Habit = ({ title, description, icon, testId, onClose }: Props) => {
  // If there is a provided icon, delay the render until the browser fetches it
  const [isLoading, setIsLoading] = useState(!!icon);

  useEffect(() => {
    if (icon) {
      const img = new Image();
      img.onload = () => {
        setIsLoading(false);
      };
      img.src = icon;
    }
  });

  if (isLoading) {
    return <Loader onClose={onClose} />;
  }

  return (
    <Modal
      title={
        <TitleWrapper>
          {icon && <Icon width={48} src={icon} alt={title} />}
          <Title>{title}</Title>
        </TitleWrapper>
      }
      testId={testId}
      onClose={onClose}
    >
      <Description>{description}</Description>
    </Modal>
  );
};
