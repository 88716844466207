import React from 'react';
import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { ConfirmationModal } from '~/components/common/ConfirmationModal';
import { useClient } from '~/components/pages/client/context';
import { testResultsQueryKey } from '~/model/Client/TestResults/useClientTestResults';
import { useDeleteClientTestResults } from '~/model/Client/TestResults/useDeleteTestResults';

export interface Props {
  testResultsId: number;
  onClose: () => void;
}

export const DeleteTestResultsModal = ({ testResultsId, onClose }: Props) => {
  const { clientId } = useClient();
  const queryClient = useQueryClient();
  const deleteTestResultsMutation = useDeleteClientTestResults(clientId, testResultsId);
  const { isLoading: isDeleteLoading } = deleteTestResultsMutation;

  return (
    <ConfirmationModal
      testId="delete-test-results-modal"
      iconVariant="danger"
      title="Are you sure?"
      closeButtonText="No, cancel"
      submitButtonText="Yes, delete!"
      submitButtonVariant="danger"
      description={
        <>
          <p>Deleting the test results will regenerate this client&apos;s Journey.</p>
          <p>Any changes you made to the client&apos;s Goals and Meals will be lost!</p>
        </>
      }
      isLoading={isDeleteLoading}
      onClose={onClose}
      onSubmit={() => {
        deleteTestResultsMutation.mutate(
          {},
          {
            onSuccess: async () => {
              await queryClient.invalidateQueries([testResultsQueryKey(clientId)]);
              toast.success('Your Test result has been deleted');
              onClose();
            },
            onError: () => {
              toast.error('Something went wrong... Please try again.');
            },
          },
        );
      }}
    />
  );
};
