import React, { DetailedHTMLProps, InputHTMLAttributes, PropsWithChildren } from 'react';
import { TestableComponentProps } from '~/model/TestableComponentProps.interface';
import { DropdownItemValue } from './DropdownItemValue';
import { StyledDropdownItem, StyledDropdownItemCheck } from './DropdownMultiple.style';

interface DropdownItemProps
  extends Omit<DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, 'value'>,
    TestableComponentProps {
  value: DropdownItemValue;
  name: string;
}

export const DropdownMultipleItem = ({
  testId,
  value,
  name,
  ...otherProps
}: PropsWithChildren<DropdownItemProps>) => (
  <StyledDropdownItem data-testid={testId} role="button" tabIndex={0} htmlFor={otherProps.id}>
    <span>{value.title}</span>
    <StyledDropdownItemCheck type="checkbox" name={name} value={value.value} {...otherProps} />
  </StyledDropdownItem>
);
