import { useMutation, UseMutationOptions, useQuery } from 'react-query';
import { TestReportResponse, model as clientTestReportsModel } from './testReports';

export const testReportsQueryKey = (clientId) => ['client', clientId, 'test-reports'];

export const useClientTestReportsList = (clientId) =>
  useQuery<TestReportResponse[]>([testReportsQueryKey(clientId)], () =>
    clientTestReportsModel(clientId).getList(),
  );

export const useDeleteClientTestReport = <T>(
  clientId: string,
  testReportId: number,
  mutationOptions?: UseMutationOptions<any, unknown, T>,
) =>
  useMutation(() => clientTestReportsModel(clientId).delete(testReportId), {
    ...mutationOptions,
  });
