import {
  ClientSearchAndFilter,
  JourneyFullTitle,
  JourneyCode,
  SubscriptionStatusOptions,
} from '~/model/Client';

export const initialValues = (): ClientSearchAndFilter => ({
  term: '',
  filters: {
    subscription: '',
    journey: '',
  },
});

export const getSubscriptionOptions = () => [
  {
    title: 'Active (free)',
    value: SubscriptionStatusOptions.ACTIVE_FREE,
  },
  {
    title: 'Active (paid)',
    value: SubscriptionStatusOptions.ACTIVE_PAID,
  },
  {
    title: 'Overdue',
    value: SubscriptionStatusOptions.OVERDUE,
  },
  {
    title: 'N/A',
    value: SubscriptionStatusOptions.NA,
  },
];

export const getJourneyOptions = () => [
  {
    title: JourneyFullTitle.EAT_HEALTHY_TO_GET_FIT,
    value: JourneyCode.EAT_HEALTHY_TO_GET_FIT,
  },
  {
    title: JourneyFullTitle.WEIGHT_LOSS,
    value: JourneyCode.WEIGHT_LOSS,
  },
  {
    title: JourneyFullTitle.IMPROVE_OVERALL_HEALTH,
    value: JourneyCode.IMPROVE_OVERALL_HEALTH,
  },
  {
    title: JourneyFullTitle.BE_MORE_ENERGETIC,
    value: JourneyCode.BE_MORE_ENERGETIC,
  },
];
