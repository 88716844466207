import { DateTime } from 'luxon';
import React, { useState } from 'react';
import { IconButton } from '~/components/common/Button';
import { Edit, Edit2, Trash } from '~/components/common/Icons/dist';
import { useTableContext } from '~/components/common/Table/TableProvider';
import { Note as model, NoteType } from '~/model/Notes';
import { findRow } from '~/stateManagement/expandable';
import { StrongColumn, Column } from '~/components/common/Table';
import {
  Expanded,
  Row,
  DescriptionColumn,
  ActionsWrapper,
} from '~/components/common/Notes/Note/Note.style';
import { RowHeaderButton } from '../../client/Sections/Sections.style';
import { DeleteConfirmationModal } from '../Modal/DeleteConfirmationModal';
import { EditModal } from '../Modal/EditModal/container';

export interface Props {
  note: model;
  index: number;
}

const expanded = (id, state) => {
  const stateRow = findRow(id, state);
  return stateRow ? stateRow.expanded : false;
};

export const Note = ({ note }: Props) => {
  const { state, toggleRow } = useTableContext();
  const [showEditNoteModal, setShowEditNoteModal] = useState(false);
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);

  return (
    <>
      <Row data-testid="note-main-row">
        <StrongColumn>
          <RowHeaderButton onClick={() => toggleRow!(note.id)}>{note.name}</RowHeaderButton>
        </StrongColumn>
        <Column>{note.type === NoteType.CLIENT ? note.clientName : 'Personal'}</Column>
        <Column>{DateTime.fromISO(note.createdAt).toFormat('dd/MM/yyyy')}</Column>
        <Column>{DateTime.fromISO(note.updatedAt).toFormat('dd/MM/yyyy')}</Column>
        <td>
          <ActionsWrapper>
            <IconButton
              aria-label="Toggle note description"
              shade="light"
              icon={<Edit2 />}
              onClick={() => toggleRow!(note.id)}
            />
            <IconButton
              aria-label="Edit note"
              shade="light"
              icon={<Edit />}
              onClick={() => setShowEditNoteModal(true)}
            />
            <IconButton
              aria-label="Delete note"
              shade="light"
              icon={<Trash />}
              onClick={() => setShowDeleteConfirmationModal(true)}
            />
          </ActionsWrapper>
        </td>
      </Row>
      {expanded(note.id, state) && (
        <Expanded data-testid="note-expanded-row" className="expanded">
          <DescriptionColumn dangerouslySetInnerHTML={{ __html: note.description }} colSpan={5} />
        </Expanded>
      )}
      {showDeleteConfirmationModal && (
        <DeleteConfirmationModal
          noteId={note.id}
          type={note.type}
          clientId={note.clientId}
          onClose={() => setShowDeleteConfirmationModal(false)}
        />
      )}
      {showEditNoteModal && (
        <EditModal title="Edit note" onClose={() => setShowEditNoteModal(false)} note={note} />
      )}
    </>
  );
};
