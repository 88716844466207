// tslint:disable:no-console

// import fetchIntercept from 'fetch-intercept';
import axios from 'axios';
import { getToken, removeToken } from '~/components/common/TokenProvider/utils';
import { getPartner } from '~/components/utils/helpers/partner';
import { BackendErrorResponse } from '~/model/Error/ErrorTypes';

export const getBasename = (): string => process.env.REACT_APP_API_URL as string;

const apiUrl = (url: string) => `${getBasename()}${url}`;

const toError = async (response: Response): Promise<BackendErrorResponse> =>
  (await response.json()) as BackendErrorResponse;

const responseInterceptors: ((response) => void)[] = [];
const interceptResponse = (interceptor: (response) => void) =>
  responseInterceptors.push(interceptor);

interceptResponse((response) => {
  if (response && !response.ok && response?.status === 401) {
    removeToken();
    window.location.href = `${process.env.REACT_APP_LOGIN_URL}`;
    throw new Error('Invalid credentials');
  }
});

const getDefaultHeaders = (): HeadersInit => {
  const token = getToken();
  const appType = getPartner();

  if (token) {
    return {
      Token: token,
      appType,
    };
  }

  return { appType };
};

const axiosInstance = axios.create({
  baseURL: getBasename(),
  headers: getDefaultHeaders(),
});

const handleResponse = async <T>(response) => {
  responseInterceptors.forEach((interceptor) => interceptor.apply(interceptor, [response]));

  if (!response) {
    throw new Error('No response.');
  }

  if (!response.ok) {
    throw await toError(response);
  }

  let responseBody: T | undefined;
  try {
    responseBody = await response.json();
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
  }

  return responseBody as T;
};

export const api = {
  get: async <T>(url: string, params: Partial<RequestInit> = { headers: {} }): Promise<T> => {
    const response = await fetch(apiUrl(url), {
      ...params,
      headers: {
        ...getDefaultHeaders(),
        ...params.headers,
      },
    });

    return handleResponse(response);
  },

  post: async <T, P = any>(
    url: string,
    data?: T,
    params: Partial<RequestInit> = { headers: { 'Content-Type': 'application/json' } },
  ): Promise<P | undefined> => {
    const response = await fetch(apiUrl(url), {
      method: 'POST',
      body: JSON.stringify(data),
      ...params,
      headers: {
        ...getDefaultHeaders(),
        ...params.headers,
      },
    });

    return handleResponse(response);
  },

  put: async <T, P = any>(
    url: string,
    data?: T,
    params: Partial<RequestInit> = { headers: { 'Content-Type': 'application/json' } },
  ): Promise<P | undefined> => {
    const response = await fetch(apiUrl(url), {
      method: 'PUT',
      body: JSON.stringify(data),
      ...params,
      headers: {
        ...getDefaultHeaders(),
        ...params.headers,
      },
    });

    return handleResponse(response);
  },

  delete: async <T>(url: string, params: Partial<RequestInit> = { headers: {} }): Promise<T> => {
    const response = await fetch(apiUrl(url), {
      method: 'DELETE',
      ...params,
      headers: {
        ...getDefaultHeaders(),
        ...params.headers,
      },
    });

    return handleResponse(response);
  },

  download: async (url: string, params: Partial<RequestInit> = { headers: {} }) => {
    await axiosInstance
      .get(url, {
        responseType: 'blob',
        headers: {
          ...getDefaultHeaders(),
          ...params.headers,
        },
      })
      .then((tempResponse) => {
        const file = new Blob([tempResponse.data], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);
        const pdfWindow = window.open();
        if (pdfWindow !== null) pdfWindow.location.href = fileURL;
      });
  },
};
