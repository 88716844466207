import * as React from 'react';
import { SVGProps } from 'react';
import { Size } from '../types';
interface Props extends SVGProps<SVGSVGElement> {
  size?: Size;
}
const SvgClose2 = (props: Props) => {
  const getSize = () => {
    if (props.size) {
      switch (props.size) {
        case 'small':
          return 20;

        case 'medium':
          return 24;

        case 'large':
          return 32;

        default:
          return null;
      }
    }
  };

  return (
    <svg
      width={getSize() || 24}
      height={getSize() || 24}
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        opacity={0.3}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.278 7.793a1 1 0 00-1.414 0l-2.829 2.828-2.828-2.828a1 1 0 00-1.414 1.414l2.828 2.829-2.828 2.828a1 1 0 101.414 1.414l2.829-2.828 2.828 2.828a1 1 0 001.414-1.414l-2.828-2.829 2.828-2.828a1 1 0 000-1.414z"
      />
    </svg>
  );
};

export default SvgClose2;
