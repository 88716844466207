import { css } from '@emotion/css';
import clsx from 'clsx';
import { DateTime } from 'luxon';
import React, { useMemo, useState } from 'react';
import { Button } from '~/components/common/Button';
import { EmptyState } from '~/components/common/EmptyState';
import { File, Plus1 } from '~/components/common/Icons/dist';
import { colors } from '~/components/utils/colors';
import {
  TestKitFilterParams,
  TestKitSearchAndFilters,
} from '~/model/Client/TestResults/testResults';
import { headerColumns, TestResultCategory } from './model';
import { Test } from './Test';
import { ExpandableTable } from '../ExpandableTable';
import { TableRowContent } from '../ExpandableTable/ExpandableTable.style';
import { AddTestResultsModal } from './Modal/AddTestResultsModal';
import { SearchAndFilters } from './SearchAndFilters';
import { TestReportsModal } from './TestReportsModal';

export interface Props {
  updatedAt: string | null;
  testResultCategories: TestResultCategory[];
  term: string;
  filters: TestKitFilterParams;
  changeTerm: (value) => void;
  changeFilters: (value) => void;
  initialValues: TestKitSearchAndFilters;
  isLoading: boolean;
}

export const TestResults = ({
  testResultCategories,
  updatedAt,
  term,
  filters,
  changeTerm,
  changeFilters,
  initialValues,
  isLoading,
}: Props) => {
  const [showAddTestResultsModal, setShowAddTestResultsModal] = useState(false);
  const [showTestReportsModal, setShowTestReportsModal] = useState(false);

  const filteredOrSearched = useMemo(
    () => term !== '' || Object.values(filters).some((value) => value !== ''),
    [term, Object.values(filters).some((value) => value === '')],
  );

  const hasTestKits = useMemo(() => testResultCategories && testResultCategories.length > 0, [
    testResultCategories,
    testResultCategories.length,
  ]);

  if (!hasTestKits && !filteredOrSearched) {
    return (
      <EmptyState
        title="Your Client currently has no test results available!"
        description="Please suggest they purchase a test to personalise their journey"
      />
    );
  }

  return (
    <>
      <ExpandableTable
        title="Test results"
        headers={headerColumns}
        subtitle={updatedAt ? `Updated at ${DateTime.fromISO(updatedAt).toFormat('dd/MM/yyyy')}` : undefined}
        searchAndFilters={
          <SearchAndFilters
            initialValues={initialValues}
            term={term}
            setTerm={changeTerm}
            filters={filters}
            setFilters={changeFilters}
            isLoading={isLoading}
          />
        }
        prependHeaderButtons={
          <Button
            icon={<File />}
            iconPosition="right"
            size="large"
            shade="light"
            aria-label="Open test reports modal"
            onClick={() => setShowTestReportsModal(true)}
          >
            Test reports
          </Button>
        }
        appendHeaderButtons={
          <Button
            icon={<Plus1 />}
            iconPosition="right"
            size="large"
            aria-label="Add new test result"
            onClick={() => setShowAddTestResultsModal(true)}
          >
            Add new
          </Button>
        }
        rows={testResultCategories.map(({ name, tests, availableResults }, index) => ({
          title: name,
          expanded: index === 0,
          titleRowContent: [
            <span
              className={clsx(css({ gridColumnStart: 6 }), {
                [css({ color: colors.colorDisabledOne })]: availableResults === 0,
              })}
            >
              {availableResults} results available
            </span>,
          ],
          content:
            tests && tests.length > 0 ? (
              <TableRowContent>
                {tests.map((test) => (
                  <Test key={`test-${test.id}`} test={test} />
                ))}
              </TableRowContent>
            ) : undefined,
        }))}
        emptyState={
          <EmptyState
            title="No test results found"
            description="Try searching for something else..."
          />
        }
      />
      {showAddTestResultsModal && (
        <AddTestResultsModal onClose={() => setShowAddTestResultsModal(false)} />
      )}
      {showTestReportsModal && <TestReportsModal onClose={() => setShowTestReportsModal(false)} />}
    </>
  );
};
