import { useQuery } from 'react-query';
import { ChoiseResponse, model } from './testResults';

export const categoryChoicesQueryKey = (clientId) => [
  'client',
  clientId,
  'test-result-choice-category',
];
export const testkitChoicesQueryKey = (clientId) => [
  'client',
  clientId,
  'test-result-choice-test-kit',
];
export const resultChoicesQueryKey = (clientId) => [
  'client',
  clientId,
  'test-result-choice-result',
];

export const useCategoryChoises = (clientId) =>
  useQuery<ChoiseResponse[]>(categoryChoicesQueryKey(clientId), model(clientId).categoryChoices);
export const useTestkitChoises = (clientId) =>
  useQuery<ChoiseResponse[]>(testkitChoicesQueryKey(clientId), model(clientId).testkitChoices);
export const useResultChoises = (clientId) =>
  useQuery<ChoiseResponse[]>(resultChoicesQueryKey(clientId), model(clientId).resultChoices);
