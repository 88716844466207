import styled from '@emotion/styled';

export interface StyledRowProps {
  $rowSpacing?: string;
}

export const StyledRow = styled.div<StyledRowProps>`
  display: flex;
  flex-wrap: wrap;

  & + & {
    margin-top: ${({ $rowSpacing }) => $rowSpacing || '30px'};
  }

  @media (min-width: 1200px) {
    flex-wrap: nowrap;
  }
`;

export interface RowInnerProps {
  width?: 'full' | 'half' | 'large' | 'small';
}

export const StyledRowInner = styled.div<RowInnerProps>`
  flex-basis: ${(p) => {
    switch (p.width) {
      case 'full':
      default:
        return '100%';
      case 'half':
        return '50%';
      case 'large':
        return '55%';
      case 'small':
        return '25%';
    }
  }};
  display: flex;
  padding-left: 30px;
  @media (max-width: 1200px) {
    padding-left: 0;
    flex-basis: 100%;
    & + & {
      margin-top: 30px;
    }
  }
`;

export const FormRowInputWrapper = styled.div`
  flex: 1 1 0;
`;

export const FormRowToggleWrapper = styled.div`
  flex: 1 1 0;
  display: flex;
  align-items: center;
`;
