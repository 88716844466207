import React, { useMemo } from 'react';
import { useClient } from '~/components/pages/client/context';
import { useWidgetPeriodChart } from '~/model/Client/Progress/hooks/useWidgetPeriodChart';
import { ChartValueStatus } from '~/model/Client/Progress/progress';
import { colors } from '~/components/utils/colors';
import { DateTime } from 'luxon';
import { Loader } from '~/components/common/Loader';
import { Chart, LoaderContainer } from '../Modal.style';

export interface DefaultChartProps {
  widgetId: number;
  periodId: number;
}

export const DefaultChart = ({ widgetId, periodId }: DefaultChartProps) => {
  const { clientId } = useClient();
  const { data, isLoading } = useWidgetPeriodChart(clientId, widgetId, periodId);

  const categories = useMemo(() => (data?.chart.values ?? []).map((value) => DateTime.fromSeconds(value.date).toFormat('dd/MM')), [data]);
  const seriesData = useMemo(() => (data?.chart.values ?? []).map((value) => value.value), [data]);
  const barColors = useMemo(() => (data?.chart.values ?? []).map((value) => value.status === ChartValueStatus.IsOnTrack ? colors.colorOmeGreen : colors.colorPriBlueOne), [data]);

  if (isLoading) return <LoaderContainer><Loader center /></LoaderContainer>;

  return (
    <Chart
      options={{
        chart: {
          id: `widget-${widgetId}`,
          height: 325,
          dropShadow: {
            enabled: true,
            top: 2,
            left: 0,
            blur: 4,
            color: '#2fb4d080',
          },
          toolbar: { show: false },
        },
        xaxis: {
          categories,
          axisTicks: { show: false },
          labels: {
            rotateAlways: true,
            rotate: -90,
            minHeight: 63,
            maxHeight: 63,
          },
        },
        yaxis: {
          axisBorder: { show: false },
          labels: {
            align: 'left',
            minWidth: 45,
            maxWidth: 45,
            formatter: (value: number) => {
              if (value >= 1000) {
                return `${(value / 1000).toFixed(0)}k`;
              }

              return `${value}`;
            },
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: 10,
            borderRadius: 5,
          },
        },
        tooltip: { enabled: false },
        dataLabels: { enabled: false },
        colors: [({ dataPointIndex }) => barColors[dataPointIndex]],
        grid: {
          show: true,
          strokeDashArray: 6,
          xaxis: { lines: { show: false } },
          padding: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
          },
        },
        states: {
          hover: {
            filter: {
              type: 'none'
            }
          },
          active: {
            filter: {
              type: 'none'
            }
          }

        },
        stroke: { show: false },
      }}
      height={325}
      series={[{ data: seriesData }]}
      type="bar"
    />
  );
};
