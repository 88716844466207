import { generatePath } from 'react-router-dom';
import { api } from '~/services/api';
import { BackendValidationErrorResponse } from '../Error/ErrorTypes';
import { PaginationData } from '../PaginationData';

const URL = {
  LIST: '/api/coach/:id/note',
  ADD: '/api/coach/:id/note',
  DELETE: '/api/coach/:id/note/:noteId',
  EDIT: '/api/coach/:id/note/:noteId',
  GET: '/api/coach/:id/note/:noteId',
};

export enum NoteType {
  CLIENT = 1,
  COACH = 2,
}

export interface Note {
  id: number;
  name: string;
  description: string;
  createdAt: string;
  updatedAt: string;
  type: NoteType;
  clientName?: string;
  clientId?: number;
}

export interface GetNotesListResponse {
  updatedAt: string;
  items: Note[];
  paginationData: PaginationData;
}

export interface NoteRequest {
  name: string;
  description: string;
  type?: NoteType;
  client?: number;
}

export interface NoteFormFilter {
  client?: string;
  type?: string;
}

export interface FormFields {
  term: string;
  filter: NoteFormFilter;
}

export const model = (coachId) => ({
  getList: (currentPage, notesPerPage, term?, filters?: NoteFormFilter) => {
    const client = filters?.client === 'all' ? null : filters?.client;
    const type = filters?.type === 'personal' ? NoteType.COACH : filters?.type;
    const params = [
      currentPage ? `page=${currentPage}` : null,
      notesPerPage ? `limit=${notesPerPage}` : null,
      term ? `term=${term}` : null,
      client ? `client=${client}` : null,
      type ? `type=${type}` : null,
    ]
      .filter((param) => param !== null)
      .join('&');

    return api.get<GetNotesListResponse>(
      `${generatePath(URL.LIST, { id: coachId })}${params && `?${params}`}`,
    );
  },
  get: (noteId) => api.get<Note>(generatePath(URL.GET, { id: coachId, noteId })),
  add: (data: NoteRequest) =>
    api.post<NoteRequest, void | BackendValidationErrorResponse<NoteRequest>>(
      generatePath(URL.ADD, { id: coachId }),
      data,
    ),
  delete: (noteId: number) => api.delete(generatePath(URL.DELETE, { id: coachId, noteId })),
  edit: (noteId: number, data: NoteRequest) =>
    api.put<NoteRequest, void | BackendValidationErrorResponse<NoteRequest>>(
      generatePath(URL.EDIT, { id: coachId, noteId }),
      data,
    ),
});
