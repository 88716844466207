import React from 'react';
import { PaginationContext, PaginationContextType } from './PaginationContext';

export interface Props {
  pagination: PaginationContextType;
  children?: JSX.Element;
}

export const PaginationProvider = ({ pagination, children }: Props) => (
  <PaginationContext.Provider value={pagination}>{children}</PaginationContext.Provider>
);
