import React, { useContext } from 'react';
import { State, StateRow } from '~/stateManagement/expandable';

export interface TableContextType {
  state?: State;
  expandAll: () => void;
  collapseAll: () => void;
  expandRow: (id: string | number) => void;
  collapseRow: (id: string | number) => void;
  toggleRow: (id: string | number) => void;
  replaceRows: (rows: StateRow[]) => void;
}

export const TableContext = React.createContext<TableContextType>(undefined!);

export const useTableContext = () => useContext(TableContext);
