import styled from '@emotion/styled';

export const Layout = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const LayoutContent = styled.div`
  padding-top: 0rem;
`;
