import { Form, Formik, FormikErrors, FormikHelpers } from 'formik';
import React from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FormikInput } from '~/components/common/Form';
import { StyledButton } from '~/components/common/Layout/AuthLayout/AuthLayout.style';
import { ROUTES } from '~/components/router/Routes';
import { getPartnerButtonVairant } from '~/components/utils/helpers/partner';
import { useResetPassword } from '~/model/Auth/hooks';
import { isBackendValidationError, transformErrors } from '~/model/Error/transformApiErrors';
import { helpUrl } from '../../error/config';
import { ActionButtonWrapper, ContentWrapper, FormInputWrapper, FormWrapper, GetHelpWrapper, Heading, Label, LinkWrapper } from '../auth.style';
import { FormFields, initialValues, validationSchema } from './config';

export const PasswordResetConfirm = () => {
  const { isLoading, mutateAsync } = useResetPassword();
  const { token } = useParams();
  const navigate = useNavigate();
  const partnerButtonVariant = getPartnerButtonVairant();

  const handleSubmit = async ({ password }: FormFields, { setErrors }: FormikHelpers<FormFields>) => {
    if (!token) {
      navigate(generatePath(ROUTES.LOGIN));
      return;
    }

    try {
      await mutateAsync({ password, token });

      toast.success('Password reset successfully');

      navigate(ROUTES.LOGIN);
    } catch (err) {
      toast.error('Something went wrong. Password was not changed');

      if (isBackendValidationError<FormFields>(err)) {
        const errors: FormikErrors<FormFields> = transformErrors<FormFields>(err);

        setErrors(errors);

        if (errors.generic) {
          toast.error(errors.generic);
        }
      }
    }
  };

  return (
    <div data-testid="password-change">
      <GetHelpWrapper>
        Having issues? <a href={helpUrl} target="_blank" rel="noreferrer">Get help</a>
      </GetHelpWrapper>
      <ContentWrapper>
        <Heading>New password</Heading>
        <LinkWrapper>Create and repeat your new password here</LinkWrapper>
        <FormWrapper>
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
          >
            {({ submitForm }) => (
              <Form>
                <FormInputWrapper>
                  <Label>Create your password</Label>
                  <FormikInput
                    name="password"
                    placeholder="Password"
                    visualSize="large"
                    type="password"
                  />
                </FormInputWrapper>
                <FormInputWrapper>
                  <Label>Repeat your password</Label>
                  <FormikInput
                    name="password_confirmation"
                    placeholder="Password"
                    visualSize="large"
                    type="password"
                  />
                </FormInputWrapper>
                <ActionButtonWrapper>
                  <StyledButton
                    onClick={isLoading ? undefined : submitForm}
                    type="button"
                    size="extraLarge"
                    isLoading={isLoading}
                    isBlock
                    variant={partnerButtonVariant}
                  >
                    Change password
                  </StyledButton>
                </ActionButtonWrapper>
              </Form>
            )}
          </Formik>
        </FormWrapper>
      </ContentWrapper>
    </div>
  );
}
