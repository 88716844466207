import * as Yup from 'yup';

const ERROR_REQUIRED = 'This field is required';
const ERROR_MAX_LENGTH = 'Only 280 characters allowed.';
const ERROR_LINKEDIN = 'Please enter a valid URL (e.g. https://www.linkedin.com/in/your-profile/)';

export const validationSchema = Yup.object({
  bio: Yup.string()
    .required(ERROR_REQUIRED)
    .max(280, ERROR_MAX_LENGTH)
    .typeError(ERROR_REQUIRED),
  focus_area: Yup.string().max(280, ERROR_MAX_LENGTH).nullable(true),
  languages: Yup.array().min(1, ERROR_REQUIRED),
  linkedin: Yup.string()
    .matches(/^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(in)\/[^/]+\/?$/, ERROR_LINKEDIN)
    .max(280, ERROR_MAX_LENGTH)
    .nullable(true),
  education: Yup.string().max(280, ERROR_MAX_LENGTH).nullable(true),
  qualifications: Yup.string().max(280, ERROR_MAX_LENGTH).nullable(true),
});
