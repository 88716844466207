import React from 'react';
import { capitalize } from '@material-ui/core';
import { getVariantFromColor, Label } from '~/components/common/Label';
import { Row, Table, Column, StrongColumn } from '~/components/common/Table';
import { TestResult } from '~/model/Client/Goals/Topic';
import { headerColumns } from './model';
import { Section, SectionHeading, SectionContent } from '../Topic.style';

interface AssociatedTestResultProps {
  associatedTestResult: TestResult;
}

const AssociatedTestResult = ({ associatedTestResult }: AssociatedTestResultProps) => {
  if (!associatedTestResult) {
    return null;
  }

  return (
    <tr>
      <StrongColumn>{associatedTestResult.test}</StrongColumn>
      <StrongColumn>{associatedTestResult.source}</StrongColumn>
      <Column>{associatedTestResult.value}</Column>
      <Column>
        <Label light variant={getVariantFromColor(associatedTestResult.result.color)}>
          {capitalize(associatedTestResult.result.value)}
        </Label>
      </Column>
    </tr>
  );
};

const rows = (associatedTestResults): Row[] =>
  associatedTestResults.map((item) => ({
    id: item.test,
    expanded: true,
    content: <AssociatedTestResult key={item.test} associatedTestResult={item} />,
  }));

export const AssociatedTestResults = ({ associatedTestResults }) => {
  if (!associatedTestResults || associatedTestResults.length === 0) {
    return null;
  }

  return (
    <Section data-testid="goal-topic-modal-associated-test-results">
      <SectionHeading>Associated test results</SectionHeading>
      <SectionContent contentType="table">
        <Table rows={rows(associatedTestResults)} headers={headerColumns} />
      </SectionContent>
    </Section>
  );
};
