import styled from '@emotion/styled';
import { colors } from '~/components/utils/colors';
import { breakpoints } from '~/components/utils/breakpoints/emotionHelpers';

export const SubmitButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const Separator = styled.div`
  height: 1px;
  margin: 60px 0 30px;
  background: ${colors.colorDisabledTwo};
`;

export const Spacer = styled.div`
  height: 30px;
  ${breakpoints.desktop`
    height: 60px;
  `}
`;
