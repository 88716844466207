import { generatePath } from 'react-router-dom';
import { FoodLogItem } from '~/model/FoodLogItem';
import { api } from '~/services/api';

const URL = {
  GET_FOOD_LOG_ITEM: '/api/client/:clientId/food-log/:foodLogItemId',
};

export const model = {
  getFoodLogItem: (clientId, foodLogItemId) =>
    api.get<FoodLogItem>(generatePath(URL.GET_FOOD_LOG_ITEM, { clientId, foodLogItemId })),
};
