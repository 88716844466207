import React from 'react';
import { Card, CardBody } from '~/components/common/Card';
import { Loader } from '~/components/common/Loader';
import { useClient } from '~/components/pages/client/context';
import { useClientTestResults } from '~/model/Client/TestResults/useClientTestResults';
import { useSearchAndFilters } from './hooks';
import { initialValues } from './SearchAndFilters/config';
import { TestResults as Component } from './TestResults';

const Error = () => <div>Could not retrieve data</div>;

export const TestResults = () => {
  const { clientId } = useClient();
  const values = initialValues();

  const searchAndFilters = useSearchAndFilters({
    term: values.term,
    filters: values.filters,
  });

  const { term, filters } = searchAndFilters;

  const { isError, isLoading, data } = useClientTestResults(clientId, term, filters);
  if (isLoading) {
    return <Loader size="medium" center />;
  }

  if (isError || !data) {
    return <Error />;
  }

  return (
    <Card>
      <CardBody>
        <Component
          testResultCategories={data.items}
          updatedAt={data.updatedAt}
          {...searchAndFilters}
          initialValues={values}
          isLoading={isLoading}
        />
      </CardBody>
    </Card>
  );
};
