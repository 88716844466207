import React from 'react';
import styled from '@emotion/styled';
import { Nav, Tab } from 'react-bootstrap';
import { TabTitle } from './TabTitle';

const CssOverride = styled.div`
  .nav-tabs {
    border-bottom: 0;
  }

  .tab-content {
    background-color: inherit;
    margin-top: 30px;
  }
`;

export const PlainTabs = ({ children }) => (
  <CssOverride>
    <Tab.Container id="tabs" defaultActiveKey={0}>
      <Nav variant="tabs" className="nav-bold nav-tabs-line" as="ul">
        {React.Children.map(children, (child, i) => (
          <TabTitle
            title={child.props.title}
            icon={child.props.icon}
            testId={child.props.testId}
            index={i}
            key={`tab-title-${child.props.title}`}
          />
        ))}
      </Nav>
      <Tab.Content>
        {React.Children.map(children, (child, i) => (
          <Tab.Pane eventKey={i} key={`tab-content-${child.props.title}`} mountOnEnter>
            {child}
          </Tab.Pane>
        ))}
      </Tab.Content>
    </Tab.Container>
  </CssOverride>
);
