import { useQuery } from 'react-query';

import { model as coachProduct, CoachProductSection } from './coachProduct';

export const coachProductsQueryKey = (id?: string) => `coachProducts--${id}`;

export const useCoachProducts = (id?: string) =>
  useQuery<CoachProductSection[]>(coachProductsQueryKey(id), () => coachProduct.getCoachProducts(id), {
    enabled: !!id,
  });
