import React, { Fragment } from 'react';
import { Info } from '../Icons/dist';
import { Tooltip } from '../Tooltip';
import { Header, Row } from './model';
import { applyHeaderClassNames, TableContainer } from './Table.style';

export interface Props {
  headers: Header[];
  rows: Row[];
}

export const Table = ({ rows, headers }: Props) => (
  <TableContainer>
    <table>
      <thead>
        <tr>
          {applyHeaderClassNames(headers).map((header) => (
            <th
              style={header?.style}
              className={header?.className}
              key={header.title}
              colSpan={header.colSpan}
            >
              {header.title}

              {header.tooltip && (
                <Tooltip content={header.tooltip}>
                  <Info className="mb-4 ml-1" />
                </Tooltip>
              )}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>{rows.map((row) => row && <Fragment key={row.id}>{row.content}</Fragment>)}</tbody>
    </table>
  </TableContainer>
);
