import React, { PropsWithChildren, useEffect } from 'react';
import { useCoach } from '~/model/Profile';
import { Loader } from '~/components/common/Loader';

import { Size } from '~/components/common/Loader/LoaderTypes';
import { useIntercom } from 'react-use-intercom';
import { CoachContext } from './CoachContext';

interface Props {
  loaderSize?: Size;
}

export const CoachProvider = ({ children, loaderSize = 'medium' }: PropsWithChildren<Props>) => {
  const { isLoading, isError, data: coach } = useCoach();
  const { update } = useIntercom();

  useEffect(() => {
    if (coach) {
      update({
        name: `${coach.first_name} ${coach.last_name}`,
        userId: coach.id,
        email: coach.email,
        customAttributes: {
          Role: 'Health coach',
          user_id: coach.id,
        },
      });
    }
  }, [coach, update]);

  if (isLoading) {
    return <Loader wrapperHeight="100%" center size={loaderSize} />;
  }

  if (isError || !coach) {
    return <div>Something went wrong while loading coach</div>;
  }

  return (
    <CoachContext.Provider value={{ coachId: coach.id, coach, isLoading, isError }}>
      {children}
    </CoachContext.Provider>
  );
};
