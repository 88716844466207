import React from 'react';
import { Loader } from '~/components/common/Loader';
import { useFeatureToggles } from '~/model/FeatureToggles/hooks';
import { FeatureTogglesContext } from './context';
import { Feature } from './model';

export const FeatureTogglesProvider = ({ children }) => {
  const { isLoading, isError, data: features } = useFeatureToggles();

  if (isLoading) {
    return <Loader wrapperHeight="100%" center />;
  }

  if (isError || !features) {
    return <div>Something went wrong while loading user</div>;
  }

  return (
    <FeatureTogglesContext.Provider value={{ features: features as Feature[], isLoading, isError }}>
      {children}
    </FeatureTogglesContext.Provider>
  );
};
