import { generatePath } from 'react-router-dom';
import { Color } from '~/components/common/Label';
import { api } from '~/services/api';

export interface ColorValue {
  value: string;
  color: Color;
}

export interface Nutrition {
  title: string;
  value: ColorValue;
  target: string;
}

export interface GetNutritionsResponse {
  updatedAt?: string;
  items: Nutrition[];
}

const URL = {
  LIST: '/api/client/:clientId/nutrition',
};

export const model = (clientId) => ({
  getList: () => api.get<GetNutritionsResponse>(generatePath(URL.LIST, { clientId })),
});
