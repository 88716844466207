import React from 'react';
import { useClientGoalsList } from '~/model/Client/Goals/hooks/useClientGoals';
import { useClient } from '~/components/pages/client/context';
import { Loader } from '~/components/common/Loader';
import { Card, CardBody } from '~/components/common/Card';

import { Goals as Component } from './Goals';
import { parseApiResponse } from './model';

const Error = () => <div>Could not retrieve data</div>;

export const Goals = () => {
  const { clientId } = useClient();
  const { isError, isLoading, data } = useClientGoalsList(clientId);
  if (isLoading) {
    return <Loader size="medium" center />;
  }

  if (isError || !data) {
    return <Error />;
  }

  return (
    <Card>
      <CardBody>
        <Component {...parseApiResponse(data)} />
      </CardBody>
    </Card>
  );
};
