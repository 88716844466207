import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getPartner, Partner } from '~/components/utils/helpers/partner';
import { useCoachContext } from '../../CoachProvider';
import { NotOnboarded } from './NotOnboarded';
import { Onboarded } from './Onboarded';
import { OnboardingStarted } from './OnboardingStarted';

const useQueryParams = () => new URLSearchParams(useLocation().search);

export const Payout = () => {
  const queryParams = useQueryParams();
  const { coach } = useCoachContext();
  const location = useLocation()
  const navigate = useNavigate()
  const partner = getPartner();

  useEffect(() => {
    if (queryParams.get('onboarding') === 'success') {
      toast.success(
        'You have completed your onboarding! Note: It might take ' +
        'some time for your account to be fully activated.',
      );
    }

    // delete query params, so we show toast only once
    // history.replace({
    //   pathname: history.location.pathname,
    //   search: '',
    // });
    navigate(location.pathname, { replace: true })
  }, []);

  if (partner === Partner.HEI) {
    return (
      <div data-testid="payout-tab-internal">
        <Onboarded />
      </div>
    );
  }

  return (
    <div data-testid="payout-tab-internal">
      {coach.onboarding_status === 'COMPLETED' && <Onboarded />}
      {coach.onboarding_status === 'STARTED' && <OnboardingStarted />}
      {coach.onboarding_status === 'NOT_STARTED' && <NotOnboarded />}
    </div>
  );
};
