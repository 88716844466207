import { DateTime } from 'luxon';
import { GetClientQuestionAnswerResponse } from '~/model/Client/Questionnaire';
import { QuestionVariant } from './questionVariants';

// Todo: extract this and Goals dimension to some Journey model?
export enum Dimension {
  Nutrition = 'NUTRITION',
  Fitness = 'FITNESS',
  Mind = 'MIND',
  Sleep = 'SLEEP',
  Beauty = 'BEAUTY',
  SexualWellness = 'SEXUAL WELLNESS',
  Longevity = 'LONGEVITY',
  ActionPlan = 'ACTION PLAN',
}

export type QuestionType =
  | 'food_frequency'
  | 'single_answer'
  | 'single_answer_dropdown'
  | 'multiple_answers'
  | 'multiple_answers_dropdown'
  | 'numeric_value'
  | 'open_ended'
  | 'blood_pressure'
  | 'wellness_goals';

export type AnswerValue =
  // open_ended answer
  | string
  // multi_select
  | string[]
  // single_answer
  | number
  // waist/hip circumeference
  | { value: string }
  // blood_pressure or waist/hip circumference
  | { i_dont_know: boolean }
  // blood_pressure
  | { systolic: string; diastolic: string }
  // date of birth
  | { year: string; month: string; day: string };

export interface AnswerChoice {
  id: number;
  text: string;
}

export interface Question {
  id: string;
  type: QuestionType;
  variant?: QuestionVariant;
  name: string;
  choices?: AnswerChoice[];
  value?: AnswerValue;
}

export interface QuestionAnswer {
  dimension?: Dimension;
  category: string;
  question: Question;
  followUpQuestions: Question[];
}

const getValueByVariant = (
  value?: AnswerValue,
  variant?: QuestionVariant,
): AnswerValue | undefined => {
  let result = value;

  switch (variant) {
    case QuestionVariant.DateOfBirth:
      result = {
        year: DateTime.fromISO(value as string).toFormat('yyyy'),
        month: DateTime.fromISO(value as string).toFormat('L'),
        day: DateTime.fromISO(value as string).toFormat('d'),
      };
      break;
    default:
      break;
  }

  return result;
};

export const fromApiResponse = ({
  dimension,
  category,
  question: { id, questionVariantId, name, type, choices, value },
  followup_questions,
}: GetClientQuestionAnswerResponse): QuestionAnswer => ({
  dimension: dimension as Dimension,
  category,
  question: {
    id,
    variant: questionVariantId,
    type: type as QuestionType,
    name,
    choices,
    value: getValueByVariant(value, questionVariantId),
  },
  followUpQuestions: followup_questions.map((followUpQuestion) => ({
    id: followUpQuestion.id,
    type: followUpQuestion.type as QuestionType,
    name: followUpQuestion.name,
    choices: followUpQuestion.choices,
    value: getValueByVariant(followUpQuestion.value),
  })),
});
