import React, { useState } from 'react';
import { useClient } from '~/components/pages/client/context';

import { model } from '~/model/Client/TestResults/testResults';
import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { DateTime } from 'luxon';
import { usePhenotypes } from '~/model/Client/TestResults/Phenotype/usePhenotypes';
import { testResultsQueryKey } from '~/model/Client/TestResults';
import { Modal as Component, Props as ComponentProps } from '../Modal';
import { toApiRequest } from '../config';
import { TestResult } from '../../model';
import { EditTestResultsConfirmationModal } from '../EditTestResultsConfirmationModal';
import { FormFields } from '..';

export interface Props extends Omit<ComponentProps, 'onSubmit' | 'initialValues' | 'title'> {
  testResult: TestResult;
  phenotypeId: number;
}

export const EditTestResultsModal = ({ testResult, phenotypeId, ...otherProps }: Props) => {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [formValues, setFormValues] = useState({} as FormFields);
  const [isLoading, setIsLoading] = useState(false);
  const queryClient = useQueryClient();
  const { clientId } = useClient();

  const { data: phenotypes } = usePhenotypes();
  const date = DateTime.fromISO(testResult.date);

  return (
    <>
      {!showConfirmationModal && (
        <Component
          title="Edit test results"
          onSubmit={(values) => {
            setFormValues(values);
            setShowConfirmationModal(true);
          }}
          testId="edit-test-results-modal"
          initialValues={{
            phenotype: phenotypeId,
            testDate: {
              year: date.year,
              month: date.month,
              day: date.day,
            },
            value: testResult.value,
          }}
          phenotypes={phenotypes}
          {...otherProps}
        />
      )}
      {showConfirmationModal && (
        <EditTestResultsConfirmationModal
          onClose={() => setShowConfirmationModal(false)}
          onSubmit={async () => {
            setIsLoading(true);
            try {
              const data = await toApiRequest(formValues);
              await model(clientId).edit(testResult.id, data);
              await queryClient.invalidateQueries([testResultsQueryKey(clientId)]);
              toast.success('Your Test result has been edited');
            } catch (err: unknown) {
              toast.error('Something went wrong while changing test results, please try again');
            }
            setShowConfirmationModal(false);
            otherProps.onClose();
            setIsLoading(false);
          }}
          isLoading={isLoading}
        />
      )}
    </>
  );
};
