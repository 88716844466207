import styled from '@emotion/styled';
import React, { ReactNode, useState } from 'react';
import { colors } from '~/components/utils/colors';
import { useOuterClick } from '~/hooks';
import { Chevron } from '~/components/common/Icons';
import { ButtonDropdownContext } from './ButtonDropdownContext';
import { Button, Props as ButtonProps } from '../Button';

export type ButtonDropdownListSize = 'small' | 'medium';

export interface ButtonDropdownProps extends ButtonProps {
  buttonContent: ReactNode;
  closeOnClick?: boolean;
  dropdownListSize?: ButtonDropdownListSize;
}

const StyledDropdownList = styled.div`
  position: absolute;
  top: 50px;
  height: auto;
  background-color: ${colors.colorWhite};
  right: 0;
  box-shadow: 0px 4px 8px 4px rgba(0, 0, 0, 0.1);
  max-height: 300px;
  overflow: auto;
  border-radius: 6px;
  z-index: 2;
`;

const StyledDropdownListWithSize = styled(StyledDropdownList) <{ size: ButtonDropdownListSize }>`
  width: ${({ size }) => (size === 'small' ? '250px' : '430px')};
`;

const StyledButtonDropdowWrapper = styled.div`
  position: relative;
`;

export const ButtonDropdown = ({
  buttonContent,
  testId,
  children,
  closeOnClick = false,
  dropdownListSize = 'medium',
  ...buttonProps
}: ButtonDropdownProps) => {
  const [open, setOpen] = useState(false);
  const ref = useOuterClick(() => setOpen(false));

  return (
    <ButtonDropdownContext.Provider value={{ open, toggle: () => setOpen(!open), closeOnClick }}>
      <StyledButtonDropdowWrapper data-testid={testId} ref={ref}>
        <Button
          onClick={() => setOpen(!open)}
          icon={<Chevron direction={open ? 'up' : 'down'} />}
          iconPosition="right"
          {...buttonProps}
        >
          {buttonContent}
        </Button>
        {open && (
          <StyledDropdownListWithSize
            size={dropdownListSize}
            data-testid="list"
          >
            {children}
          </StyledDropdownListWithSize>
        )}
      </StyledButtonDropdowWrapper>
    </ButtonDropdownContext.Provider>
  );
};
