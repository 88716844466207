import styled from '@emotion/styled';
import { colors } from '~/components/utils/colors';

export const FullName = styled.span`
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: ${colors.colorPriOne};
`;

export const ContactDetailsWrapper = styled.div`
  margin-top: 8px;
  display: flex;
`;

export const ContactDetails = styled.div`
  color: ${colors.colorDisabledOne};
  line-height: 20px;
  font-weight: 500;
  font-size: 13px;
  display: flex;
  align-items: center;

  svg {
    margin-right: 6px;
  }

  & + & {
    margin-left: 20px;
  }
`;
export const CardTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const JourneyWrapper = styled.div`
  color: ${colors.colorGsThree};
  margin-bottom: 5px;
  font-size: 13px;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const JourneyName = styled.span`
  font-weight: 500;
`;

export const JourneyWeeks = styled.span`
  font-weight: 400;
  strong {
    font-weight: 500;
  }
`;

export const Separator = styled.div`
  height: 0;
  border-bottom: 2px solid ${colors.colorDisabledTwo};
`;

export const BadgesWrapper = styled.div`
  display: flex;
  align-items: center;
  flex: 2;
  justify-content: space-between;
  margin-right: 70px;
`;
