import React from 'react';
import { useClient } from '~/components/pages/client/context';
import { useWidgetHistory } from '~/model/Client/Progress/hooks/useWidgetHistory';
import { ExpandableTable, TableRow, TableRowContent } from '~/components/pages/client/Sections/ExpandableTable';
import clsx from 'clsx';
import { css } from '@emotion/css';
import { colors } from '~/components/utils/colors';
import { DateTime } from 'luxon';
import { Color, getVariantFromColor } from '~/components/common/Label';
import { Loader } from '~/components/common/Loader';
import { WidgetHistoryDayData, WidgetId } from '~/model/Client/Progress/progress';
import { Header } from '~/components/pages/client/Sections/ExpandableTable/model';
import { DateIntervalLabel } from '~/components/common/DateIntervalLabel';
import { DayDataRow, LoaderContainer } from '../Modal.style';
import { formatTimeHHMM, formatTimeHMin } from './Shared';

const headerColumns: Header[] = [
  {
    title: 'Date',
    width: '5fr',
  },
  {
    title: '',
    width: '0',
  },
  {
    title: 'Awake',
    width: '4fr',
  },
  {
    title: 'Light',
    width: '4fr',
  },
  {
    title: 'Deep',
    width: '4fr',
  },
  {
    title: 'REM',
    width: '4fr',
  },
  {
    title: 'Total',
    width: '6fr',
  },
  {
    title: '',
    width: '0',
  },
];

export const SleepHistory = () => {
  const { clientId } = useClient();
  const { data, isLoading } = useWidgetHistory(clientId, WidgetId.Sleep);

  if (isLoading) return <LoaderContainer><Loader center /></LoaderContainer>;

  const tableRow = (dayData: WidgetHistoryDayData[]) => (
    <TableRow key={WidgetId.Sleep}>
      {dayData.map(({ time, awake, light, deep, rem, total, colour }) => {
        const date = DateTime.fromJSDate(new Date(time));

        return (
          <DayDataRow
            key={time}
            $widths={headerColumns.map(({ width }) => width || '1fr')}
          >
            <div>{date.toFormat('dd/MM/yyyy')}</div>
            <div />
            <div>{formatTimeHHMM(awake)}</div>
            <div>{formatTimeHHMM(light)}</div>
            <div>{formatTimeHHMM(deep)}</div>
            <div>{formatTimeHHMM(rem)}</div>
            <div className={`text-${getVariantFromColor(colour)}`}>{formatTimeHMin({ minutes: total })}</div>
          </DayDataRow>
        )
      })}
    </TableRow>
  );

  return (
    <ExpandableTable
      headers={headerColumns}
      withHeader={false}
      activeOnExpand
      rows={(data ?? []).map(({ days, awake, light, deep, rem, total, colour, weekFrom, weekTo }, weekIndex) => {
        const hasAnyData = days.length > 0;

        return ({
          title: `sleep-week-${weekIndex}`,
          showTitle: false,
          titleRowContent: [
            <span className={clsx(css({ gridColumnStart: 1, gridColumnEnd: 3 }))}>
              <DateIntervalLabel from={new Date(weekFrom)} to={new Date(weekTo)} />
            </span>,
            <span className={clsx(css({ gridColumnStart: 3, color: colors.colorDisabledOne }))}>
              {formatTimeHHMM(awake)}
            </span>,
            <span className={clsx(css({ gridColumnStart: 4, color: colors.colorDisabledOne }))}>
              {formatTimeHHMM(light)}
            </span>,
            <span className={clsx(css({ gridColumnStart: 5, color: colors.colorDisabledOne }))}>
              {formatTimeHHMM(deep)}
            </span>,
            <span className={clsx(css({ gridColumnStart: 6, color: colors.colorDisabledOne }))}>
              {formatTimeHHMM(rem)}
            </span>,
            <span className={clsx(
              { [`text-${getVariantFromColor(colour ?? Color.GREY)}`]: colour },
              css({
                gridColumnStart: 7,
                color: colour ? undefined : colors.colorDisabledOne
              }))}>
              {formatTimeHMin({ minutes: total })}
            </span>,
          ],
          content: (
            <TableRowContent className={clsx(css({ color: colors.colorGsThree, fontWeight: 500 }))}>
              {hasAnyData ? tableRow(days) : (
                <TableRow>
                  <DayDataRow $widths={['1fr']}>
                    <div>Client has not logged any data for this week</div>
                  </DayDataRow>
                </TableRow>
              )}
            </TableRowContent>
          )
        });
      })}
    />
  );
};
