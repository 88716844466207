import { kebab } from 'case';
import clsx from 'clsx';
import { useField } from 'formik';
import React from 'react';
import { Label } from '../Label';
import { ErrorText } from '../Error';
import { style } from './Input.style';
import { FormikCheckboxProps, CheckboxProps } from './Checkbox';

export const Toggle = ({
  label,
  name,
  wrapperClassName,
  className,
  error,
  note,
  checked,
  ...otherProps
}: CheckboxProps) => (
  <div className={clsx(wrapperClassName, style.checkboxWrapper)}>
    <div className={style.checkboxLabelInput}>
      <input
        id={label ? `${name}.${kebab(label)}` : name}
        name={name}
        className={clsx(className, style.toggle)}
        type="checkbox"
        checked={checked}
        {...otherProps}
      />
      {label && (
        <Label htmlFor={`${name}.${kebab(label)}`} className={clsx(style.checkboxLabel)}>
          {label}
        </Label>
      )}
    </div>
    {error?.trim() && <ErrorText text={error} />}
    {note && <small className={clsx('text-gsThree', style.subnote, style.note)}>{note}</small>}
  </div>
);

export const FormikToggle = (props: FormikCheckboxProps) => {
  const [field, meta] = useField(props);

  return (
    <Toggle
      checked={meta.value}
      error={meta.touched && meta.error ? meta.error : undefined}
      {...field}
      {...props}
    />
  );
};
