import React, { PropsWithChildren } from 'react';
import { RowInnerProps, StyledRow, StyledRowInner, StyledRowProps } from './Layout.style';

interface Props extends RowInnerProps, StyledRowProps {}

export const FormRow = ({ children, $rowSpacing, width = 'full' }: PropsWithChildren<Props>) => (
  <StyledRow $rowSpacing={$rowSpacing}>
    {React.Children.map(children, (child) => (
      <StyledRowInner width={width}>{child}</StyledRowInner>
    ))}
  </StyledRow>
);
