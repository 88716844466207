import styled from '@emotion/styled';
import { colors } from '~/components/utils/colors';

export const Layout = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const LayoutSubtitle = styled.div`
  color: ${colors.colorDisabledOne};
  font-weight: 500;
`;

export const LayoutHeaderButtons = styled.div`
  display: flex;
  place-self: center;

  * + * {
    margin-left: 30px;
  }
`;

export const LayoutSearchAndFilters = styled.div`
  padding-top: 2rem;
  padding-left: 0;
  padding-right: 0;
`;

export const LayoutContent = styled.div`
  padding-top: 2rem;
`;
