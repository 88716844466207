import { TestResultRequest } from "~/model/Client/TestResults/testResults";

export interface FormFields {
  formType?: 'value' | 'file';
  value?: number;
  reportFile?: File | null;
  testDate: {
    year: number;
    month: number;
    day: number;
  };
  phenotype: number;
  name?: string;
}

const toBase64File = (file: File) => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = error => reject(error);
});

export const toApiRequest = async ({ value, reportFile: selectedFile, testDate, name, phenotype }: FormFields): Promise<TestResultRequest> => {
  let reportFile;

  if (selectedFile) {
    const base64Data = await toBase64File(selectedFile);

    reportFile = {
      name: selectedFile.name,
      base64Data,
    };

    return ({
      name,
      date: testDate,
      phenotype,
      reportFile,
    });
  }

  return ({
    value,
    testDate,
    phenotype,
  });
};