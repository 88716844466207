import * as React from 'react';
import { SVGProps } from 'react';
import { Size } from '../types';
interface Props extends SVGProps<SVGSVGElement> {
  size?: Size;
}
const SvgPlus1 = (props: Props) => {
  const getSize = () => {
    if (props.size) {
      switch (props.size) {
        case 'small':
          return 20;

        case 'medium':
          return 24;

        case 'large':
          return 32;

        default:
          return null;
      }
    }
  };

  return (
    <svg
      width={getSize() || 20}
      height={getSize() || 20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect x={9.167} y={4.167} width={1.667} height={11.667} rx={0.833} fill="currentColor" />
      <rect
        x={4.167}
        y={10.833}
        width={1.667}
        height={11.667}
        rx={0.833}
        transform="rotate(-90 4.167 10.833)"
        fill="currentColor"
      />
    </svg>
  );
};

export default SvgPlus1;
