import { useMutation, useQuery } from 'react-query';
import {
  ClientListResponse,
  ClientProfile,
  ClientsChoice,
  model as clientsModel,
} from '~/model/Client/clients';
import { queryClient } from '~/services/api';

export const clientsQueryKey = () => `clients`;

export const useClientList = (currentPage, clientsPerPage, term?, filters?) =>
  useQuery<ClientListResponse>(
    [clientsQueryKey(), currentPage, clientsPerPage, term, filters],
    () => clientsModel.getList(currentPage, clientsPerPage, term, filters),
  );

const clientProfileQueryKey = (id) => `client-profile--${id}`;

export const useClientProfile = (id: string) =>
  useQuery<ClientProfile>(clientProfileQueryKey(id), () => clientsModel.client(id));

const clientsChoicesQueryKey = `client-choices`;

export const useClientsChoices = () =>
  useQuery<ClientsChoice[]>(clientsChoicesQueryKey, clientsModel.getChoicesList);

export const useClientStartJourney = (id: string, weeksCount = 12) =>
  useMutation(() => clientsModel.startJourney(id, weeksCount), {
    onSuccess: () => {
      queryClient.invalidateQueries(clientProfileQueryKey(id));
    },
  });