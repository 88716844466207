import { FormFields } from '~/model/Notes/notes';

export const initialValues = (): FormFields => ({
  term: '',
  filter: {
    client: '',
    type: '',
  },
});

export const getClientOptions = () => [
  {
    title: 'All',
    value: 'all',
  },
  {
    title: 'Personal',
    value: 'personal',
  },
];
