import styled from '@emotion/styled';

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
`;

export const Icon = styled.img`
  width: 48px;
`;

export interface DescriptionProps {
  $icon?: boolean;
}

export const Description = styled.div<DescriptionProps>`
  margin-top: ${({ $icon }) => ($icon ? '33px' : '0px')};
  padding: 10px;
  font-size: 13px;
  line-height: 20px;
`;
