import React, { useEffect, useState } from 'react';
import { TabsProps as Props } from './Tab';

export const useActiveTab = ({ activeTab, children }: Props) => {
  const [activeTabKey, setActiveTabKey] = useState<number>(undefined!);
  const childrenArray = React.Children.toArray(children);

  useEffect(() => {
    const activeTabIndex = childrenArray.findIndex(
      (child) => (child as any).props.id === activeTab,
    );
    setActiveTabKey(activeTabIndex > 0 ? activeTabIndex : 0);
  }, [activeTab]);

  return { activeTabKey };
};
