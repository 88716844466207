import React from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { ConfirmationModal } from '~/components/common/ConfirmationModal';
import { ROUTES } from '~/components/router/Routes';

export interface Props {
  onClose: () => void;
}

export const VerificationWarningModal = ({ onClose }: Props) => {
  const navigate = useNavigate();

  const redirectToPayoutDetails = () => {
    navigate(generatePath(ROUTES.PROFILE_TABBED, { tab: 'payout' }));
  };

  return (
    <ConfirmationModal
      testId="verification-warning-modal"
      iconVariant="danger"
      title="Missing payout details!"
      closeButtonText="No, proceed without"
      closeButtonVariant="danger"
      submitButtonText="Yes!"
      submitButtonVariant="primary"
      description={
        <>
          <p className="text-center">
            To earn a commission from selling supplements and receive payments to your bank account
            you need to set up your Payout details first.
          </p>
          <p className="text-center">Do you want to complete your Payout details?</p>
        </>
      }
      onClose={onClose}
      onSubmit={redirectToPayoutDetails}
    />
  );
};
