import { TestableComponentProps } from '~/model/TestableComponentProps.interface';
import { style } from './Loader.style';

export type Size = 'small' | 'medium' | 'large';

export interface Props extends React.SVGProps<SVGSVGElement>, TestableComponentProps {
  size?: Size;
  center?: boolean;
  wrapperHeight?: string;
  fullScreen?: boolean;
}

export const getAlignmentClassName = (center?: boolean) => {
  if (center) {
    return style.loader.center;
  }

  return null;
};

export const getWidthClassName = (size: Size) => {
  switch (size) {
    case 'small':
      return style.loader.small;
    case 'large':
      return style.loader.large;
    case 'medium':
    default:
      return style.loader.medium;
  }
};