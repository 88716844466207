import React from 'react';
import clsx from 'clsx';
import { Nav, Tab } from 'react-bootstrap';
import { CardBody, CardHeader, Card, CardHeaderToolbar } from '~/components/common/Card';
import { TabTitle } from './TabTitle';
import { TabsProps as Props } from './Tab';
import { useActiveTab } from './useActiveTab';
import { StyledWrap } from './Tabs.style';

export const CardTabs = ({ children, activeTab }: Props) => {
  const { activeTabKey } = useActiveTab({ children, activeTab });

  return (
    <StyledWrap>
      <Tab.Container id="tabs" defaultActiveKey={0} activeKey={activeTab && activeTabKey}>
        <Card>
          <CardHeader headerClassName={clsx('card-header-tabs-line')}>
            <CardHeaderToolbar>
              <Nav variant="tabs" className="nav-bold nav-tabs-line" as="ul">
                {React.Children.map(children, (child, i) => (
                  <TabTitle
                    title={child.props.title}
                    path={child.props.path}
                    icon={child.props.icon}
                    testId={child.props.testId}
                    index={i}
                    key={`tab-title-${child.props.title}`}
                  />
                ))}
              </Nav>
            </CardHeaderToolbar>
          </CardHeader>
          <CardBody>
            <Tab.Content>
              {React.Children.map(children, (child, i) => (
                <Tab.Pane eventKey={i} key={`tab-content-${child.props.title}`} mountOnEnter>
                  {child}
                </Tab.Pane>
              ))}
            </Tab.Content>
          </CardBody>
        </Card>
      </Tab.Container>
    </StyledWrap>
  );
};
