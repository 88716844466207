import { css } from '@emotion/css';
import styled from '@emotion/styled';
import { colors } from '~/components/utils/colors';
import { TableGridSpacing } from '~/components/pages/client/Sections/ExpandableTable/ExpandableTable.style';

export const style = {
  date: css({
    color: colors.colorGsThree,
  }),
  actions: css({
    placeSelf: 'self-end',
    display: 'flex',
    flexDirection: 'row',
    gap: '10px',
  }),
};

export const MealRow = styled(TableGridSpacing)`
  color: ${colors.colorGsThree};
`;

export const TestReportRow = styled(TableGridSpacing)`
  color: ${colors.colorGsThree};
  align-items: flex-start;
`;
