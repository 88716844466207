import { css } from '@emotion/css';
import styled from '@emotion/styled';
import { colors } from '~/components/utils/colors';
import {
  TableGridSpacing,
  TableGridWidths,
} from '~/components/pages/client/Sections/ExpandableTable/ExpandableTable.style';

export const style = {
  goal: css({
    fontSize: '14px',
    fontWeight: 500,
    color: colors.colorPriOne,
  }),
  dimension: css({
    textTransform: 'capitalize',
  }),
  actions: css({
    placeSelf: 'self-end',
    display: 'flex',
    gap: '10px',
  }),
  topic: css({
    cursor: 'pointer',
    color: colors.colorPriBlueOne,
  }),
};

export const GoalHeader = styled(TableGridSpacing)``;
export const GoalContent = styled(TableGridSpacing)((props: TableGridWidths) => ({
  color: `${colors.colorGsThree}`,
  paddingTop: '20px',
  // we give the name to the children of the grid to use it in grid-template-area below
  // there is expected to only be one child, that should start at the second grid column and span for
  // the whole width of the grid
  '& > *': {
    gridArea: 'content',
  },
  // The black magic here transforms the provided array of $widths (e.g ['1fr', '1fr', '1fr', '1fr'])
  // into the css grid-template-area: ". content content ." property
  // meaning, it skips the first column, spans the second column up until last column, and leaves the last one it e,pty
  gridTemplateAreas: `"${[...Array(props.$widths.length - 2).fill('content')].join(' ')} ."`,
}));

export const HabitsWrapper = styled.div`
  display: flex;
  margin-top: 20px;

  .habit + .habit {
    margin-left: 10px;
  }
`;
