import * as React from 'react';
import { SVGProps } from 'react';
import { Size } from '../types';
interface Props extends SVGProps<SVGSVGElement> {
  size?: Size;
}
const SvgBullets20 = (props: Props) => {
  const getSize = () => {
    if (props.size) {
      switch (props.size) {
        case 'small':
          return 20;

        case 'medium':
          return 24;

        case 'large':
          return 32;

        default:
          return null;
      }
    }
  };

  return (
    <svg
      width={getSize() || 20}
      height={getSize() || 20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.5 5.417c0-.69.56-1.25 1.25-1.25h7.5a1.25 1.25 0 110 2.5h-7.5c-.69 0-1.25-.56-1.25-1.25zm0 4.166c0-.69.56-1.25 1.25-1.25h7.5a1.25 1.25 0 110 2.5h-7.5c-.69 0-1.25-.56-1.25-1.25zM8.75 12.5a1.25 1.25 0 000 2.5h7.5a1.25 1.25 0 100-2.5h-7.5z"
        fill="currentColor"
      />
      <path
        opacity={0.3}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.583 6.667a1.25 1.25 0 100-2.5 1.25 1.25 0 000 2.5zm0 4.166a1.25 1.25 0 100-2.5 1.25 1.25 0 000 2.5zm1.25 2.917a1.25 1.25 0 11-2.5 0 1.25 1.25 0 012.5 0z"
        fill="currentColor"
      />
    </svg>
  );
};

export default SvgBullets20;
