import React, { useState } from 'react';
import { DateTime } from 'luxon';

import { ExpandableTable } from '~/components/pages/client/Sections/ExpandableTable';
import { Button } from '~/components/common/Button';
import { Plus1 } from '~/components/common/Icons/dist';
import { EmptyState } from '~/components/common/EmptyState';

import { Goal as GoalComponent } from './Goal';
import { headerColumns, Week } from './model';

import { TableRowContent } from '../ExpandableTable/ExpandableTable.style';
import { AddModal } from './Goal/Modal/AddModal';
import { Schedule } from '../Supplements/Schedule';

export interface Props {
  publishedAt?: string;
  weeks: Week[];
}

export const Goals = ({ publishedAt, weeks }: Props) => {
  const [showModal, setShowModal] = useState(false);

  if (weeks.length === 0) {
    return <EmptyState description="The weekly goals of your Client will be visible here" />;
  }

  return (
    <>
      <ExpandableTable
        title="Weekly goals"
        subtitle={
          publishedAt && `Published ${DateTime.fromISO(publishedAt).toFormat('dd/MM/yyyy')}`
        }
        prependHeaderButtons={<Schedule />}
        appendHeaderButtons={
          <>
            <Button
              icon={<Plus1 />}
              iconPosition="right"
              size="large"
              onClick={() => setShowModal(true)}
            >
              Add new
            </Button>
          </>
        }
        headers={headerColumns}
        rows={weeks.map(({ number, active, goals }) => ({
          title: `Week ${number}`,
          active,
          expanded: active,
          content:
            goals && goals.length > 0 ? (
              <TableRowContent>
                {goals && goals.map((goal) => <GoalComponent key={goal.id} goal={goal} />)}
              </TableRowContent>
            ) : undefined,
        }))}
      />
      {showModal && <AddModal width="772px" onClose={() => setShowModal(false)} />}
    </>
  );
};
