import React from 'react';

import { Modal } from '~/components/common/Modal/Modal';
import { Props as ModalProps } from '~/components/common/Modal';
import { Button } from '~/components/common/Button/Button';
import { Alert } from '~/components/common/Icons';
import { colors } from '~/components/utils/colors';
import { TestableComponentProps } from '~/model/TestableComponentProps.interface';

import {
  ConfirmationModalWrapper,
  ConfirmationModalIconContainer,
  ConfirmationModalTitle,
  ConfirmationModalDescription,
  ButtonsWrapper,
} from './ConfirmationModal.style';

export type IconVariant = 'primary' | 'warning' | 'danger';
export type ButtonVariant = 'primary' | 'danger' | 'neutral';

const getVariantColor = (variant) => {
  switch (variant) {
    case 'warning':
      return colors.colorAmberOne;

    case 'danger':
      return colors.colorPriRedOne;

    default:
      return colors.colorPriBlueOne;
  }
};

export interface Props extends TestableComponentProps {
  iconVariant?: IconVariant;
  title: string;
  description?: React.ReactNode | JSX.Element | JSX.Element[];
  closeButtonText: string;
  closeButtonVariant?: ButtonVariant;
  submitButtonText: string;
  submitButtonVariant?: ButtonVariant;
  isLoading?: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

export const ConfirmationModal = ({
  iconVariant = 'primary',
  title,
  description,
  closeButtonText,
  submitButtonText,
  isLoading = false,
  closeButtonVariant = 'neutral',
  submitButtonVariant = 'primary',
  onClose,
  onSubmit,
  testId,
}: Props) => {
  const closeButton = (action) => (
    <Button disabled={isLoading} variant={closeButtonVariant} onClick={action}>
      <strong>{closeButtonText}</strong>
    </Button>
  );
  const submitButton = (action) => (
    <Button
      disabled={isLoading}
      isLoading={isLoading}
      variant={submitButtonVariant}
      onClick={action}
    >
      <strong>{submitButtonText}</strong>
    </Button>
  );

  const defaultModalProps: ModalProps = {
    onClose,
    width: '362px',
    hasCloseButton: false,
    closeOnOuterClick: false,
    testId,
  };

  const alertIconColorProps = {
    stroke: getVariantColor(iconVariant),
    fill: getVariantColor(iconVariant),
  };

  return (
    <Modal {...defaultModalProps}>
      <ConfirmationModalWrapper>
        <ConfirmationModalIconContainer>
          <Alert {...alertIconColorProps} />
        </ConfirmationModalIconContainer>
        <ConfirmationModalTitle>{title}</ConfirmationModalTitle>
        <ConfirmationModalDescription>{description}</ConfirmationModalDescription>
      </ConfirmationModalWrapper>
      <ButtonsWrapper>
        {closeButton(onClose)}
        {submitButton(onSubmit)}
      </ButtonsWrapper>
    </Modal>
  );
};
