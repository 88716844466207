import { css } from '@emotion/css';
import styled from '@emotion/styled';
import { colors } from '~/components/utils/colors';
import { TableGridSpacing } from '../../ExpandableTable/ExpandableTable.style';

export const style = {
  question: css({
    fontSize: '14px',
    fontWeight: 500,
    color: colors.colorPriOne,
  }),
  followUpQuestion: css({
    fontSize: '13px',
    fontWeight: 500,
    color: colors.colorGsThree,
  }),
  answers: css({
    fontSize: '13px',
    color: colors.colorGsThree,
  }),
  actions: css({
    placeSelf: 'self-end',
    display: 'flex',
    gap: '10px',
  }),
};

export const QuestionGridSpacing = styled(TableGridSpacing)`
  padding-bottom: 1rem;

  &:last-child {
    padding-bottom: 0;
  }
`;
