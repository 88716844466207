import { useMutation, UseMutationOptions } from 'react-query';
import { model as clientGoalsModel } from '../goals';

export const useDeleteClientGoal = <T>(
  clientId: string,
  goalId: string,
  mutationOptions?: UseMutationOptions<any, unknown, T>,
) =>
  useMutation(() => clientGoalsModel(clientId).delete(clientId, goalId), {
    ...mutationOptions,
  });
