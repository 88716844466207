import { DateTime } from 'luxon';
import React from 'react';
import { EmptyState } from '~/components/common/EmptyState';
import { ExpandableTable } from '../ExpandableTable';
import { TableRowContent } from '../ExpandableTable/ExpandableTable.style';
import { Week, headerColumns, LogEntry, Averages } from './model';
import { FoodLogItem, round, valueOrNA } from './FoodLogItem';
import { RowTitleAdditionalData } from './FoodLog.style';

export interface Props {
  startDate?: string;
  weeks: Week[];
}

const renderMicronutrientsSummary = ({ kcal, fiber, salt, saturatedFat, sugar }: Averages) => [
  <RowTitleAdditionalData style={{ gridColumnStart: 5 }}>
    {String(valueOrNA(round(kcal, 0), 'kcal'))}
  </RowTitleAdditionalData>,
  <RowTitleAdditionalData>{String(valueOrNA(round(fiber, 1)))}</RowTitleAdditionalData>,
  <RowTitleAdditionalData>{String(valueOrNA(round(saturatedFat, 1)))}</RowTitleAdditionalData>,
  <RowTitleAdditionalData>{String(valueOrNA(round(sugar, 1)))}</RowTitleAdditionalData>,
  <RowTitleAdditionalData>{String(valueOrNA(round(salt, 1)))}</RowTitleAdditionalData>,
];

export const FoodLog = ({ startDate, weeks }: Props) => {
  if (!weeks || weeks.length === 0) {
    return (
      <EmptyState
        title="This Client has not logged any food yet!"
        description="Food logged by your Client will be visible here"
      />
    );
  }

  return (
    <>
      <ExpandableTable
        title="Food log"
        subtitle={startDate && `Start date ${DateTime.fromISO(startDate).toFormat('dd/MM/yyyy')}`}
        headers={headerColumns}
        rows={weeks.map(({ number, active, averages, foodLogs }) => ({
          title: `Week ${number}`,
          active,
          expanded: active,
          titleRowContent: renderMicronutrientsSummary(averages),
          content:
            foodLogs && foodLogs.length > 0 ? (
              <TableRowContent>
                {foodLogs.map(({ date, foodLogItems: entries }) =>
                  entries.map((entry: LogEntry, index) => (
                    <FoodLogItem
                      key={entry.id}
                      date={index === 0 ? date : undefined}
                      entry={entry}
                    />
                  )),
                )}
              </TableRowContent>
            ) : undefined,
        }))}
      />
    </>
  );
};
