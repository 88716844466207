import React, { useEffect, useState } from 'react';
import { useClient } from '~/components/pages/client/context';
import { useWidgetWeeks } from '~/model/Client/Progress/hooks/useWidgetWeeks';
import { Modal as MainModal } from '~/components/common/Modal';
import { Button } from '~/components/common/Button';
import { WidgetId } from '~/model/Client/Progress/progress';
import { ChartContainer, WeeksContainer } from './Modal.style';
import { BloodPressureChart, DefaultChart, SleepChart, ExerciseChart } from './Chart';
import { DefaultHistory, BloodPressureHistory, SleepHistory, ExerciseHistory } from './History';

export interface ModalProps {
  widgetId: number;
  title: string;
  onClose: () => void;
}

export const Modal = ({ widgetId, title, onClose }: ModalProps) => {
  const { clientId } = useClient();
  const { data: widgetWeeksData } = useWidgetWeeks(clientId, widgetId);
  const [periodId, setPeriodId] = useState<number | undefined>();

  useEffect(() => {
    if (widgetWeeksData) {
      setPeriodId(widgetWeeksData[0]?.id);
    }
  }, [widgetWeeksData]);

  const renderChart = () => {
    if (!periodId) return <></>;

    switch (widgetId) {
      case WidgetId.Sleep:
        return <SleepChart periodId={periodId} />;

      case WidgetId.BloodPressue:
        return <BloodPressureChart periodId={periodId} />;

      case WidgetId.Exercise:
        return <ExerciseChart periodId={periodId} />;

      default:
        return <DefaultChart widgetId={widgetId} periodId={periodId} />;
    }
  }

  const renderHistory = () => {
    switch (widgetId) {
      case WidgetId.Sleep:
        return <SleepHistory />;

      case WidgetId.BloodPressue:
        return <BloodPressureHistory />;

      case WidgetId.Exercise:
        return <ExerciseHistory />;

      default:
        return <DefaultHistory widgetId={widgetId} />;
    }
  }

  const modalWidth = () => {
    switch (widgetId) {
      case WidgetId.BloodPressue:
      case WidgetId.Sleep:
      case WidgetId.Exercise:
        return '772px'

      default:
        return '572px'
    }
  }

  return (
    <MainModal
      onClose={onClose}
      title={title}
      width={modalWidth()}
    >
      <ChartContainer>
        {renderChart()}
      </ChartContainer>
      <WeeksContainer>
        {widgetWeeksData?.map((week) => (
          <Button
            key={week.id}
            size="small"
            variant="primary"
            shade={periodId === week.id ? 'dark' : 'light'}
            onClick={() => setPeriodId(week.id)}
          >
            {week.title}
          </Button>
        ))}
      </WeeksContainer>
      {renderHistory()}
    </MainModal>
  );
};
