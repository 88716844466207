import { css } from '@emotion/react';
import { MediaQuery } from './mediaQuery';

export const breakpoints = {
  mobile: (style) => css`
    @media ${MediaQuery.mobile} {
      ${style}
    }
  `,
  tablet: (style) => css`
    @media ${MediaQuery.tablet} {
      ${style}
    }
  `,
  desktop: (style) => css`
    @media ${MediaQuery.desktop} {
      ${style}
    }
  `,
};
