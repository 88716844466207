import * as React from 'react';
import { SVGProps } from 'react';
import { Size } from '../types';
interface Props extends SVGProps<SVGSVGElement> {
  size?: Size;
}
const SvgFile = (props: Props) => {
  const getSize = () => {
    if (props.size) {
      switch (props.size) {
        case 'small':
          return 20;

        case 'medium':
          return 24;

        case 'large':
          return 32;

        default:
          return null;
      }
    }
  };

  return (
    <svg
      width={getSize() || 20}
      height={getSize() || 20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        opacity={0.3}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.048.833h5.64a1.5 1.5 0 01.97.355l3.81 3.224A1.5 1.5 0 0115 5.557v10.346c0 1.492-.017 1.597-1.548 1.597H4.048c-1.531 0-1.548-.105-1.548-1.597V2.43C2.5.938 2.517.833 4.048.833zm1.785 10c0-.46.373-.833.834-.833H12.5a.833.833 0 010 1.667H6.667a.833.833 0 01-.834-.834zm.834 2.5a.833.833 0 100 1.667h2.5a.833.833 0 000-1.667h-2.5z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.714 2.5h6.475a1.5 1.5 0 01.968.355l3.811 3.223a1.5 1.5 0 01.532 1.146v10.345c0 1.493-.017 1.598-1.548 1.598H5.714c-1.53 0-1.548-.105-1.548-1.598V4.097c0-1.492.018-1.597 1.548-1.597zm.12 8.333c0-.46.372-.833.833-.833H12.5a.833.833 0 110 1.667H6.667a.833.833 0 01-.834-.834zm.833 2.5a.833.833 0 000 1.667h2.5a.833.833 0 100-1.667h-2.5z"
        fill="currentColor"
      />
    </svg>
  );
};

export default SvgFile;
