import React, { useState } from 'react';
import { Form, Formik, FormikHelpers } from 'formik';
import { Modal as MainModal, Props as ModalProps } from '~/components/common/Modal';
import { Separator } from '~/components/common/Modal/Modal.style';
import { Button } from '~/components/common/Button';
import { isBackendValidationError, transformErrors } from '~/model/Error/transformApiErrors';
import { TestableComponentProps } from '~/model/TestableComponentProps.interface';
import {
  FormikDropdown,
  FormikInput,
  FormRow,
  FormRowInputWrapper,
} from '~/components/common/Form';
import { FormikRichText } from '~/components/common/Form/RichText';
import { validationSchema } from '~/components/common/Notes/Modal/validation';
import {
  FormWrapper,
  ModalFooter,
  FormRowLabel,
} from '~/components/common/Notes/Modal/Modal.style';
import { useClientsChoices } from '~/model/Client';
import { FormFields } from '~/components/common/Notes/Modal/config';
import { NoteType } from '~/model/Notes';

export interface Props extends ModalProps, TestableComponentProps {
  onSubmit: (values: FormFields) => void;
  title: string;
  initialValues: FormFields;
}

export const Modal = ({
  onClose: handleClose,
  title,
  onSubmit,
  initialValues,
  ...otherProps
}: Props) => {
  const [selectedClient, setSelectedClient] = useState<number | undefined>(initialValues.client);
  const { data: clients } = useClientsChoices();

  const handleSubmit = async (values: FormFields, { setErrors }: FormikHelpers<FormFields>) => {
    try {
      await onSubmit({
        ...values,
        type: selectedClient ? NoteType.CLIENT : NoteType.COACH,
      });
      handleClose();
    } catch (err) {
      if (isBackendValidationError<FormFields>(err)) {
        setErrors(transformErrors<FormFields>(err));
      }
    }
  };

  return (
    <MainModal
      withHeaderSeparator
      hasCloseButton={false}
      title={title}
      onClose={handleClose}
      closeOnOuterClick={false}
      width="772px"
      {...otherProps}
    >
      <Formik
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        initialValues={initialValues}
      >
        {({ isSubmitting, setFieldValue }) => (
          <Form>
            <FormWrapper>
              <FormRow $rowSpacing="15px">
                <>
                  <FormRowLabel takesSpace="required" htmlFor="name">
                    Title:
                  </FormRowLabel>
                  <FormRowInputWrapper>
                    <FormikInput name="name" placeholder="Add you note title here" />
                  </FormRowInputWrapper>
                </>
              </FormRow>
              <FormRow $rowSpacing="15px">
                <>
                  <FormRowLabel takesSpace="required" htmlFor="name">
                    Client:
                  </FormRowLabel>
                  <FormRowInputWrapper>
                    <FormikDropdown
                      name="client"
                      placeholder="Is this note personal or related to a specific Client?"
                      onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                        setFieldValue('client', event.target.value);
                        setSelectedClient(Number(event.target.value));
                      }}
                      value={selectedClient}
                    >
                      <option value="">Personal</option>
                      {clients?.map(({ id, fullName }) => (
                        <option key={id} value={id}>
                          {fullName}
                        </option>
                      ))}
                    </FormikDropdown>
                  </FormRowInputWrapper>
                </>
              </FormRow>
              <FormRow $rowSpacing="15px">
                <>
                  <FormRowLabel takesSpace="required" htmlFor="description">
                    Note:
                  </FormRowLabel>
                  <FormRowInputWrapper>
                    <FormikRichText
                      name="description"
                      placeholder="Add you note here"
                      onChange={(data) => setFieldValue('description', data)}
                    />
                  </FormRowInputWrapper>
                </>
              </FormRow>
            </FormWrapper>

            <Separator $marginBottom="30px" />

            <ModalFooter>
              <Button type="button" shade="light" variant="neutral" onClick={handleClose}>
                Cancel
              </Button>
              <Button
                variant="success"
                type="submit"
                disabled={isSubmitting}
                isLoading={isSubmitting}
              >
                Save
              </Button>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </MainModal>
  );
};
