import React from 'react';
import { capital, lower } from 'case';

import { ClientProfile } from '~/model/Client';

import {
  Checklist,
  Progress,
  Scales,
  Sex,
  Wallet,
} from '~/components/common/Icons/dist';

import { useClient } from '~/components/pages/client/context';
import {
  Icon,
  InfoIconWrapper,
  Text,
  TextBottom,
  TextTop,
  Wrapper,
} from './InfoIcons.style';

interface InfoIconConfig {
  icon: JSX.Element;
  topText?: string;
  bottomText?: string | number;
}

const makeIcons = ({
  sex,
  key_biomarker_value,
  key_biomarker_name,
  bmi,
  goals_total,
  goals_completed,
  total_purchases,
}: ClientProfile): InfoIconConfig[] => [
    {
      icon: <Sex />,
      topText: 'Sex',
      bottomText: sex ? capital(sex) : 'N/A',
    },
    {
      icon: <Scales />,
      topText: 'BMI',
      bottomText: bmi ? bmi.toFixed(1) : 'N/A',
    },
    {
      icon: <Progress />,
      topText: key_biomarker_name,
      bottomText: key_biomarker_value || 'N/A',
    },
    {
      icon: <Checklist />,
      topText: 'Goals achieved',
      bottomText: `${goals_completed}/${goals_total}`,
    },
    {
      icon: <Wallet />,
      topText: 'Total purchases',
      bottomText: total_purchases || 'N/A',
    },
  ];

const InfoIcon = ({ icon, topText, bottomText }: InfoIconConfig) => (
  <InfoIconWrapper>
    <Icon>{icon}</Icon>
    <Text>
      <TextTop>{topText}</TextTop>
      <TextBottom data-testid={`info-${lower(topText!)}`}>{bottomText}</TextBottom>
    </Text>
  </InfoIconWrapper>
);

export const InfoIcons = () => {
  const { client } = useClient();
  const icons = makeIcons(client);

  return (
    <Wrapper>
      {icons.map((iconConfig) => (
        <InfoIcon key={`${iconConfig.topText}${iconConfig.bottomText}`} {...iconConfig} />
      ))}
    </Wrapper>
  );
};
