import React from 'react';
import { Nutrition as model, ColorValue } from '~/model/Client/Nutritions';
import { StrongColumn } from '~/components/common/Table';
import { getVariantFromColor } from '~/components/common/Label';
import { Row, Column } from './Nutrition.style';

export interface Props {
  nutrition: model;
  index: number;
}

const buildValue = (colorValue?: ColorValue) => {
  if (!colorValue) {
    return <span className="text-muted">N/A</span>;
  }

  const { color, value } = colorValue;

  return <span className={`text-${getVariantFromColor(color)}`}>{value}</span>;
};

export const Nutrition = ({ nutrition }: Props) => (
  <Row data-testid="nutrition-main-row">
    <StrongColumn>{nutrition.title}</StrongColumn>
    <Column>{buildValue(nutrition.value)}</Column>
    <Column>{nutrition.target}</Column>
  </Row>
);
