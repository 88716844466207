import * as React from 'react';
import { SVGProps } from 'react';
import { Size } from '../types';

interface Props extends SVGProps<SVGSVGElement> {
  size?: Size;
}

const SvgUpload = (props: Props) => {
  const getSize = () => {
    if (props.size) {
      switch (props.size) {
        case 'small':
          return 20;

        case 'medium':
          return 24;

        case 'large':
          return 32;

        default:
          return null;
      }
    }
  };

  return (
    <svg
      width={getSize() || 20}
      height={getSize() || 20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        opacity="0.3"
        d="M1.66699 10.8333C1.66699 10.4167 2.08366 10 2.50033 10C2.91699 10 3.33366 10.4167 3.33366 10.8333C3.33366 11.25 3.33366 15 3.33366 15C3.33366 15.9205 4.07985 16.6667 5.00033 16.6667H15.0003C15.9208 16.6667 16.667 15.9205 16.667 15V10.8333C16.667 10.3731 17.0401 10 17.5003 10C17.9606 10 18.3337 10.3731 18.3337 10.8333V15C18.3337 16.8409 16.8413 18.3333 15.0003 18.3333H5.00033C3.15938 18.3333 1.66699 16.8409 1.66699 15C1.66699 15 1.66699 11.25 1.66699 10.8333Z"
        fill="#364258"
      />
      <rect
        opacity="0.3"
        x="9.16699"
        y="1.66675"
        width="1.66667"
        height="11.6667"
        rx="0.833333"
        fill="#364258"
      />
      <path
        d="M10.0302 2.8149L6.42259 6.42251C6.09715 6.74795 5.56951 6.74795 5.24408 6.42251C4.91864 6.09707 4.91864 5.56943 5.24408 5.244L9.41074 1.07733C9.72415 0.76393 10.228 0.750679 10.5575 1.04718L14.7241 4.79717C15.0662 5.10506 15.094 5.63197 14.7861 5.97406C14.4782 6.31615 13.9513 6.34388 13.6092 6.036L10.0302 2.8149Z"
        fill="#364258"
      />
    </svg>
  );
};

export default SvgUpload;
