import * as React from 'react';
import { SVGProps } from 'react';
import { Size } from '../types';
interface Props extends SVGProps<SVGSVGElement> {
  size?: Size;
}
const SvgMinus = (props: Props) => {
  const getSize = () => {
    if (props.size) {
      switch (props.size) {
        case 'small':
          return 20;

        case 'medium':
          return 24;

        case 'large':
          return 32;

        default:
          return null;
      }
    }
  };

  return (
    <svg
      width={getSize() || 20}
      height={getSize() || 20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        opacity={0.3}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.666 10a8.333 8.333 0 1016.667 0 8.333 8.333 0 00-16.667 0z"
        fill="currentColor"
      />
      <path
        d="M15 10c0 .46-.373.833-.833.833H5.833a.833.833 0 010-1.666h8.334c.46 0 .833.373.833.833z"
        fill="currentColor"
      />
    </svg>
  );
};

export default SvgMinus;
