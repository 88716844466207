import styled from '@emotion/styled';
import { colors } from '~/components/utils/colors';

export const ConfirmationModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ConfirmationModalIconContainer = styled.div`
  margin-bottom: 30px;
`;

export const ConfirmationModalTitle = styled.h3`
  margin-bottom: 30px;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px;
  color: #4a4b68;
`;

export const ConfirmationModalDescription = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
  color: #4a4b68;
`;

export const ButtonsWrapper = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
`;


export const List = styled.ul`
  list-style-type: none;
  padding-left: 0;
  margin-top: 20px;
  margin-bottom: 0px;
`;

export const ListItem = styled.li<{ $marginBottom?: string; $marginTop?: string }>`
  display: flex;
  justify-content: space-between;
  color: ${colors.colorMenuSection};
  margin-bottom: ${({ $marginBottom }) => $marginBottom || '5px'};
  margin-top: ${({ $marginTop }) => $marginTop || '0px'};
`;

export const ListItemText = styled.span<{ $width?: string }>`
  width: ${({ $width }) => $width || '21%'};
  margin-right: 3%;

  font-weight: 500;
  font-size: 14px;
  line-height: 21px;

  &:last-child {
    text-align: right;
    margin-right: 0;
  }
`;

export const WarningTextLine = styled.span<{ $width?: string }>`
  width: ${({ $width }) => $width || '21%'};
  margin-right: 3%;

  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: ${colors.colorPriRedOne};

  &:last-child {
    text-align: right;
    margin-right: 0;
  }
`;
