import React from 'react';

import { Card, CardBody, CardHeader } from '~/components/common/Card';
import { Button } from '~/components/common/Button';
import { Tooltip } from '~/components/common/Tooltip';

export const Tooltips = () => (
  <Card>
    <CardHeader title="Toasts" />
    <CardBody>
      <Tooltip content="Top tooltip" placement="top">
        <Button>Tooltip top</Button>
      </Tooltip>
      <Tooltip content="Right tooltip" placement="right">
        <Button>Tooltip right</Button>
      </Tooltip>
      <Tooltip content="Bottom tooltip" placement="bottom">
        <Button>Tooltip bottom</Button>
      </Tooltip>
      <Tooltip content="Left tooltip" placement="left">
        <Button>Tooltip left</Button>
      </Tooltip>
    </CardBody>
  </Card>
);
