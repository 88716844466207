import { generatePath } from 'react-router-dom';
import { api } from '~/services/api';
import { MealCategory } from '~/components/pages/client/Sections/Meals/model';

const URL = {
  LIST: '/api/client/:clientId/meals',
};

export enum MealOptions {
  BREAKFAST = 'Breakfast',
  LUNCH = 'Lunch',
  DINNER = 'Dinner',
  SNACK = 'Snack',
}

export enum MealStatusOptions {
  VISIBLE = 'Visible',
  NOT_VISIBLE = 'Not visible',
  SKIPPED = 'Skipped',
}

export interface ClientMealsListResponse {
  publishedAt?: string;
  weeks: MealCategory[];
}

export interface ClientMealsFilterParams {
  meal: string;
  status: string;
}

export interface ClientMealsSearchAndFilter {
  term: string;
  filters: ClientMealsFilterParams;
}

export const model = {
  getList: (clientId, term?, filters?) => {
    const meal = filters?.meal === 'all' ? '' : filters?.meal;
    const status = filters?.status === 'all' ? '' : filters?.status;
    const params = [
      term ? `term=${term}` : null,
      meal ? `meal=${filters?.meal}` : null,
      status ? `status=${filters?.status}` : null,
    ]
      .filter((param) => param !== null)
      .join('&');

    return api.get<ClientMealsListResponse>(
      `${generatePath(URL.LIST, { clientId })}${params && `?${params}`}`,
    );
  },
};
