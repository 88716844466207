import { RefObject, useEffect, useRef } from 'react';

export function useOuterClick(callback): RefObject<HTMLDivElement> {
  const callbackRef = useRef<(e: MouseEvent) => void>();
  const innerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    callbackRef.current = callback;
  });

  useEffect(() => {
    function handleClick(this: Document, e: MouseEvent): void {
      if (
        innerRef.current &&
        callbackRef.current &&
        !innerRef.current.contains(e.target as HTMLElement)
      )
        callbackRef.current(e);
    }

    document.addEventListener('click', handleClick);
    return () => document.removeEventListener('click', handleClick);
  }, []);

  return innerRef;
}
