import { generatePath } from 'react-router-dom';
import { api } from '~/services/api';

export type OnboardingStatus = 'NOT_STARTED' | 'STARTED' | 'COMPLETED';

export enum BillingType {
  TYPE_INDIVIDUAL = 1,
  TYPE_BUSINESS = 2,
}

export interface CoachProfile {
  id: string;
  first_name: string;
  last_name: string;
  bio: string;
  focus_area: string;
  languages: string[];
  linkedin: string;
  education: string;
  qualifications: string;
  sex?: string;
  phone_number: string;
  email: string;
  city: string;
  address1: string;
  address2: string;
  country: string;
  post_code: string;
  last_login_at: string;
  signed_up_at: string;
  profile_image_url: string;
  empty_profile_image_url: string;
  onboarding_status: OnboardingStatus;
  code: string;
  booking_link: string;
  default_subscription_price: string;
  is_schedule_consultation_enabled: boolean;
  total_clients: number;
  active_clients: number;
  start_journey_automatically: boolean;
  average_revenue: string;
  revenue_this_month: string;
  total_revenue: string;
  marketplace_for_customers_enabled: boolean;
}

export interface CompanyDetails {
  name: string;
  registrationNumber: string;
  vatNumber: string;
  website: string;
}

export interface BillingAddress {
  address1: string;
  address2: string;
  city: string;
  country: string;
  postCode: string;
}

export interface BankDetails {
  bankName?: string;
  swift?: string;
  iban?: string;
  bankAccountName?: string;
  bankAddress?: string;
  branchCode?: string;
  bankCode?: string;
  paynowId?: string;
}

export interface BillingDetails {
  type: BillingType;
  companyDetails: CompanyDetails;
  billingAddress: BillingAddress;
  bankDetails?: BankDetails;
}

export interface CoachRequest {
  bio: string;
  focusArea: string;
  languages: string[];
  linkedin: string;
  education: string;
  qualifications: string;
}

const URL = {
  GET_PROFILE: '/api/coach',
  GET_COACH: '/api/coach/:id',
  UPDATE_COACH: '/api/coach/:id',
  UPDATE_COACH_PROFESSIONAL_DETAILS: '/api/coach/:id/professional-details',
  UPDATE_COACH_REFERRAL_CODE: '/api/coach/:id/referral-code',
  UPDATE_COACH_SETTINGS: '/api/coach/:id/settings',
  COACH_BILLING_DETAILS: '/api/coach/:id/billing-details',
};

export const model = {
  getProfile: () => api.get<CoachProfile>(URL.GET_PROFILE),
  getCoachProfile: (id?: string) => api.get<CoachProfile>(generatePath(URL.GET_COACH, { id })),
  updateCoachProfile: <T>(id: string, data: T) =>
    api.post<T>(generatePath(URL.UPDATE_COACH, { id }), data),
  updateCoachProfessionalDetails: <T>(id: string, data: T) =>
    api.post<T>(generatePath(URL.UPDATE_COACH_PROFESSIONAL_DETAILS, { id }), data),
  updateCoachReferralCode: <T>(id: string, data: T) =>
    api.post<T>(generatePath(URL.UPDATE_COACH_REFERRAL_CODE, { id }), data),
  updateCoachSettings: <T>(id: string, data: T) =>
    api.put<T>(generatePath(URL.UPDATE_COACH_SETTINGS, { id }), data),
  getCoachBillingDetails: (id: string) =>
    api.get<BillingDetails>(generatePath(URL.COACH_BILLING_DETAILS, { id })),
  updateCoachBillingDetails: <T>(id: string, data: T) =>
    api.put<T>(generatePath(URL.COACH_BILLING_DETAILS, { id }), data),
};
