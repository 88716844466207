import { css } from '@emotion/css';
import styled from '@emotion/styled';
import clsx from 'clsx';
import { colors } from '~/components/utils/colors';
import { Header } from './model';

export const TableContainer = styled.div`
  display: block;
  width: 100%;
  overflow-x: auto;

  table {
    width: 100%;
    margin-bottom: 1rem;
    background-color: transparent;

    thead {
      font-weight: 600;
      text-transform: uppercase;

      th {
        padding: 10px 20px;
        border-bottom: 2px solid ${colors.colorGsFour};
        padding-top: 10px;
        padding-bottom: 10px;
        vertical-align: middle;
        border-top: 0;
      }
    }

    tbody {
      td {
        border-top: 2px solid ${colors.colorGsFour};
        padding: 10px 20px;
        font-family: Poppins;
        vertical-align: middle;
      }

      tr {
        &.expanded {
          td {
            border-top: 0;
            padding-top: 0;
            padding-bottom: 20px;
          }
        }
      }
    }
  }
`;

export const Column = styled.td`
  font-weight: 500;
  color: ${colors.colorGsThree};
  font-size: 13px;
  line-height: 19px;
`;

export const StrongColumn = styled.td`
  font-weight: 500;
  font-size: 14px;
  font-style: normal;

  color: ${colors.colorPriOne};
`;

export const headerClassName = clsx(
  css({
    color: colors.colorGsThree,
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '18px',
  }),
);

export const applyHeaderClassNames = (headerColumns: Header[]): Header[] =>
  headerColumns.map((header) => ({ ...header, className: headerClassName }));
