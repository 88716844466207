import React, { useState, Fragment } from 'react';

import clsx from 'clsx';
import { IconButton } from '~/components/common/Button';
import { Edit } from '~/components/common/Icons/dist';
import { capitalize } from '@material-ui/core';
import { getVariantFromColor, Label } from '~/components/common/Label';
import { AnswersModal } from './AnswersModal';
import { style, QuestionGridSpacing } from './Question.style';
import { Question as Model, headerColumns, QuestionEvaluation } from '../model';
import { TableRowItem } from '../../ExpandableTable/ExpandableTable.style';

export interface Props {
  question: Model;
}

const buildLabel = (evaluation?: QuestionEvaluation) => {
  if (!evaluation) {
    return (
      <Label inline variant="neutral" light>
        N/A
      </Label>
    );
  }

  const { color, value } = evaluation;

  return (
    <Label inline light variant={getVariantFromColor(color)}>
      {capitalize(value)}
    </Label>
  );
};

export const Question = ({ question: { followup_questions, ...question } }: Props) => {
  const questions = [question, ...followup_questions];
  const [showModal, setShowModal] = useState(false);
  const [userAnswerModalId, setUserAnswerModalId] = useState<string>();

  const renderQuestion = (
    { user_answer_id, question: title, answers, evaluation }: Partial<Model>,
    index: number,
  ) => {
    const isFollowUpQuestion = index > 0;
    return (
      <Fragment key={title}>
        <strong
          className={clsx(
            { [style.question]: !isFollowUpQuestion },
            { [style.followUpQuestion]: isFollowUpQuestion },
          )}
        >
          {title}
        </strong>
        <div className={style.answers}>
          {answers?.length === 0 ? <>N/A</> : <>{answers?.join(', ')}</>}
        </div>
        <div className={style.answers}>{buildLabel(evaluation)}</div>
        <div className={style.actions}>
          {!isFollowUpQuestion && (
            <IconButton
              shade="light"
              aria-label="Edit answers"
              icon={<Edit />}
              onClick={() => {
                setShowModal(true);
                setUserAnswerModalId(user_answer_id);
              }}
            />
          )}
        </div>
      </Fragment>
    );
  };

  return (
    <TableRowItem>
      <QuestionGridSpacing $widths={headerColumns.map(({ width }) => width || '1fr')}>
        {questions.map(renderQuestion)}
      </QuestionGridSpacing>
      {showModal && (
        <AnswersModal
          questionId={`${question.id}`}
          answerId={userAnswerModalId || ''}
          testId="edit-answers-modal"
          width="772px"
          onClose={() => {
            setShowModal(false);
            setUserAnswerModalId(undefined);
          }}
        />
      )}
    </TableRowItem>
  );
};
