import * as Yup from 'yup';
import {
  GetScheduleResponse,
  SetScheduleRequest,
} from '~/model/Client/Supplements/Schedule/schedule';

export interface FormFields {
  title: string;
  note: string;
}

export const initialValues = ({ name, description }: GetScheduleResponse): FormFields => ({
  title: name,
  note: description,
});

export const validationSchema = Yup.object().shape({
  title: Yup.string().required('This field is required'),
  note: Yup.string().required('This field is required'),
});

export const toApiRequest = ({ title, note }: FormFields): SetScheduleRequest => ({
  name: title,
  description: note,
});
