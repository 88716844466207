import { useQuery } from 'react-query';
import { Topic, model } from '..';

export const queryKey = (clientId: string, topicId: number) => [
  'client',
  `${clientId}`,
  'goal-topic',
  `${topicId}`,
];

export const useClientGoalTopic = (clientId: string, topicId: number) =>
  useQuery<Topic>(queryKey(clientId, topicId), () => model(clientId).get(topicId));
