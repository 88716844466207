import React, { useReducer, useState } from 'react';

import { EmptyState } from '~/components/common/EmptyState';
import { Button } from '~/components/common/Button';
import { Cart } from '~/components/common/Icons/dist';

import { Remarks } from '~/components/common/Remarks/Remarks';
import { toast } from 'react-toastify';
import { isBackendValidationError, transformErrorsToList } from '~/model/Error/transformApiErrors';
import {
  anythingInBasket,
  INITIAL_STATE,
  reducer,
  ActionDecrease,
  ActionIncrease,
  quantityInBasket,
  ActionReset,
} from '~/stateManagement/basket';
import { headerColumns, TestKitCategory as model } from './model';
import { TestKit } from './TestKit';
import { ExpandableTable } from '../ExpandableTable';
import { TableRowContent } from '../ExpandableTable/ExpandableTable.style';
import { TestKitConfirmationModal } from './TestKit/TestKitConfirmationModal';

export interface Props {
  testKitsCategories: model[];
}

export const TestKits = ({ testKitsCategories }: Props) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const remarksLines = [
    'All tests are sold by and the orders are fulfilled by trusted third-parties',
    'Prices shown above include all relevant taxes (VAT/GST)',
  ];

  const handleIncrease = (id: number) => dispatch(ActionIncrease(id));
  const handleDecrease = (id: number) => dispatch(ActionDecrease(id));

  const handleSuccess = () => {
    toast.success('Successfully sent test kits recommendation.');
    dispatch(ActionReset());
    setShowConfirmationModal(false);
  };

  const handleError = (err) => {
    if (isBackendValidationError<model[]>(err)) {
      transformErrorsToList(err).forEach((error) => toast.error(error));
    }
  };

  if (testKitsCategories.length === 0) {
    return (
      <EmptyState
        title="There are currently no test kits available!"
        description="Please use Intercom to ask about the restocking time"
      />
    );
  }

  return (
    <>
      <ExpandableTable
        title="Test kits"
        headers={headerColumns}
        appendHeaderButtons={
          <Button
            iconPosition="right"
            size="large"
            variant="primary"
            icon={<Cart />}
            onClick={() => setShowConfirmationModal(true)}
            disabled={!anythingInBasket(state)}
            aria-label="Review basket"
          >
            Review basket
          </Button>
        }
        rows={testKitsCategories.map(({ name, testKits }, index) => ({
          title: name,
          expanded: index === 0,
          content:
            testKits && testKits.length > 0 ? (
              <TableRowContent>
                {testKits.map((testKit) => (
                  <TestKit
                    key={testKit.id}
                    testKit={testKit}
                    onDecreaseClick={() => handleDecrease(testKit.id)}
                    onIncreaseClick={() => handleIncrease(testKit.id)}
                    inBasket={quantityInBasket(state, testKit.id)}
                  />
                ))}
              </TableRowContent>
            ) : undefined,
        }))}
        footer={<Remarks lines={remarksLines} />}
      />
      {showConfirmationModal && (
        <TestKitConfirmationModal
          basket={state}
          onSuccess={handleSuccess}
          onError={handleError}
          onClose={() => setShowConfirmationModal(false)}
        />
      )}
    </>
  );
};
