import styled from '@emotion/styled';
import { breakpoints } from '~/components/utils/breakpoints/emotionHelpers';
import { colors } from '~/components/utils/colors';
import { Button } from '../../Button';

interface PartnerProps {
  partnerColor?: string;
}

export const LogoLink = styled('a')`
  height: 63px;
  display: block;
`;

export const AuthWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  min-width: max(100%, 1440px);
  color: ${colors.colorWhite};

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 458px;
  }

  & > div {
    position: relative;
    z-index: 1;
  }

  .logo {
    max-width: 138px;
  }

  ${breakpoints.tablet`
    min-width: 100%;
  `}
`;

export const PartnerAuthWrapper = styled(AuthWrapper)(({
  partnerColor = colors.colorWhitelabelBlue
}: PartnerProps) => `
  &::before {
    background-color: ${partnerColor};
  }
`);

export const OutletWrapper = styled.div`
  background-color: ${colors.colorWhite};
  min-width: 539px;
  border-radius: 40px;
  margin: 79px 0 80px 0;
  color: ${colors.colorPriOne};
  box-shadow: 0px 4px 35px rgba(0, 0, 0, 0.08);
  width: 539px;
  
  ${breakpoints.tablet`
    min-width: unset;
    margin: 0 16px 80px 16px;
    width: auto;
  `}
  
  ${breakpoints.mobile`
    margin: 0 0 24px 0;
  `}
`;

export const Content = styled.div`
  width: 1440px;
  display: flex;
  flex-direction: row;
  gap: 37.05px;
  justify-content: space-between;
  padding: 0 118px 0 73px;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 458px;
    z-index: -1;
  }
  
  ${breakpoints.tablet`
    width: auto;
    flex-direction: column;
    gap: 16px;
    padding: 0;
  `}
`;

export const PartnerContent = styled(Content)(({
  partnerColor = colors.colorWhitelabelBlue
}: PartnerProps) => `
  &::before {
    background-color: ${partnerColor};
  }
`);

export const IntroWrapper = styled.div`
  margin: 45px 0 80px 0; 
  
  ${breakpoints.tablet`
    margin: 45px 0 0 0;
    padding: 0 32px;
  `}
`;

export const IntroContent = styled.div`
  margin: 41px 0 94px 0;
  
  ${breakpoints.tablet`
    margin: 41px 0;

    & + svg {
      display: none;
    }
  `}
`;

export const Title = styled.div`
  font-weight: 600;
  font-size: 34px;
  line-height: 51px;
`;

export const Description = styled.div`
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
`;

export const StyledButton = styled(Button)`
  box-shadow: 0px 4px 19px rgba(119, 147, 65, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
`;
