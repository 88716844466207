import { CoachProfile } from '~/model/Profile';
import { CoachRequest } from '~/model/Profile/coach';

export type FormFields = {
  bio: string;
  focus_area: string;
  languages: string[];
  linkedin: string;
  education: string;
  qualifications: string;
};

export const initialValues = ({
  bio,
  focus_area,
  languages,
  linkedin,
  education,
  qualifications,
}: CoachProfile): FormFields => ({
  bio,
  focus_area,
  languages,
  linkedin,
  education,
  qualifications,
});

export const toApiRequest = ({
  bio,
  focus_area,
  languages,
  linkedin,
  education,
  qualifications,
}: FormFields): CoachRequest => ({
  bio,
  focusArea: focus_area,
  languages,
  linkedin,
  education,
  qualifications,
});
