import { Color } from '~/components/common/Label';
import { Header } from '~/components/pages/client/Sections/ExpandableTable/model';
import { style } from './Questionnaire.style';

export enum QuestionEvaluationPriority {
  ATTENTION = 'attention',
  MONITOR = 'monitor',
  OPTIOMAL = 'optimal',
}

export interface QuestionEvaluation {
  value: QuestionEvaluationPriority;
  color: Color;
}

export interface Question {
  id: number;
  question: string;
  user_answer_id?: string;
  answers: string[];
  followup_questions: Question[];
  evaluation?: QuestionEvaluation;
}

export interface Category {
  title: string;
  questions: Question[];
}

export const headerColumns: Header[] = [
  {
    title: 'Question',
    width: '5fr',
  },
  {
    title: 'Answer',
    width: '2fr',
  },
  {
    title: 'Evaluation',
    width: '0.75fr',
  },
  {
    title: 'Action',
    className: style.headers.actions,
    width: '1fr',
  },
];
