import { Color } from '~/components/common/Label';
import { Header } from '~/components/pages/client/Sections/ExpandableTable/model';
import { style } from './Devices.style';

export interface DeviceCategory {
  name: string;
  devices: Device[];
}

export enum DeviceStatusValue {
  OUT_OF_STOCK = 'Out of stock',
  IN_STOCK = 'In stock',
}

export interface DeviceStatus {
  color: Color;
  value: DeviceStatusValue;
}

export interface Device {
  id: number;
  name: string;
  brand: string;
  size: string;
  price: string;
  formattedPrice: string;
  status: DeviceStatus;
}

export const headerColumns: Header[] = [
  {
    title: 'Name',
    width: '2.35fr',
  },
  {
    title: 'Brand',
    width: '1.25fr',
  },
  {
    title: 'Size',
    width: '1.75fr',
  },
  {
    title: 'Price',
    width: '1fr',
  },
  {
    title: 'Status',
    width: '1.25fr',
  },
  {
    title: 'In basket',
    width: '1fr',
  },
  {
    title: 'Action',
    className: style.headers.actions,
    width: '1.25fr',
  },
];
