import styled from '@emotion/styled';
import React from 'react';
import { Card, CardBody, CardHeader } from '~/components/common/Card';
import { Progress } from '~/components/common/Progress';
import { colors } from '~/components/utils/colors';

const Separator = styled.div`
  height: 0;
  margin: 30px 0;
  border-bottom: 1px solid ${colors.colorDisabledTwo};
`;

export const ProgressBarDemo = () => (
  <Card>
    <CardHeader title="Progress bars" />
    <CardBody>
      <h3>Variants</h3>
      {['primary', 'warning', 'success', 'danger'].map((variant) => (
        <Progress variant={variant} className="mt-4" completed={50} />
      ))}

      <Separator />

      <h3>Completion</h3>
      {[5, 10, 20, 50, 75, 100].map((completed) => (
        <Progress completed={completed} className="mt-4" />
      ))}
    </CardBody>
  </Card>
);
