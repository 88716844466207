import { kebab } from 'case';
import clsx from 'clsx';
import { useField } from 'formik';
import React, { InputHTMLAttributes } from 'react';
import { Label } from '../Label';
import { ErrorText } from '../Error';
import { style } from './Input.style';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label?: string;
  wrapperClassName?: string;
  labelClassName?: string;
  error?: string;
  checked?: boolean;
  note?: string;
}

export const Radio = ({
  label,
  name,
  wrapperClassName,
  labelClassName,
  className,
  error,
  note,
  ...otherProps
}: Props) => (
  <div className={clsx(wrapperClassName, style.checkboxWrapper)}>
    <div className={style.checkboxLabelInput}>
      <input
        id={label ? `${name}.${kebab(label)}` : name}
        name={name}
        className={clsx(className, style.checkbox)}
        type="radio"
        {...otherProps}
      />
      {label && (
        <Label htmlFor={`${name}.${kebab(label)}`} className={clsx(style.checkboxLabel, labelClassName)}>
          {label}
        </Label>
      )}
    </div>
    {error && <ErrorText text={error} />}
    {note && <small className={clsx('text-gsThree', style.subnote, style.note)}>{note}</small>}
  </div>
);

type FormikRadioProps = Omit<Props, 'error'>;
export const FormikRadio = (props: FormikRadioProps) => {
  const [field, meta] = useField(props);

  return (
    <Radio error={meta.touched && meta.error ? meta.error : undefined} {...field} {...props} />
  );
};
