import React, { useEffect, useState } from 'react';
import { CometChat } from '@cometchat-pro/chat';

import { cometChatSettingsProvider } from '~/services/comet-chat/cometChatSettingsProvider';
import { useFirebaseContext } from '~/components/common/FirebaseProvider';
import { CometChatContext } from './CometChatContext';
import { useCoachContext } from '../../profile/CoachProvider';

export const CometChatProvider = ({ children }) => {
  const [unreadMessagesCount, setUnreadMessagesCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const { token } = useFirebaseContext();
  const { coachId } = useCoachContext();

  const updateUnreadMessagesCount = async () => {
    const messageRequest = new CometChat.MessagesRequestBuilder()
      .setUnread(true)
      .setLimit(1)
      .build();

    const unreadMessages = await messageRequest.fetchPrevious();
    setUnreadMessagesCount(unreadMessages.length);
  };

  const login = async () => {
    setIsLoading(true);

    let loggedinUser;

    try {
      loggedinUser = await CometChat.getLoggedinUser();
    } catch (err) {
      // eslint-disable-next-line no-console
      console.warn('Could not get loggedinUser:', err);
    }

    if (loggedinUser && loggedinUser?.getUid() === coachId) return;

    if (loggedinUser) {
      try {
        await CometChat.registerTokenForPushNotification('');
        await CometChat.logout();
      } catch (err) {
        // eslint-disable-next-line no-console
        console.warn('Could not logout:', err);
      }
    }

    try {
      await CometChat.login(coachId, cometChatSettingsProvider.getAuthKey());
      updateUnreadMessagesCount();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn('Chat login failed with error:', error);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    const initCometChat = async () => {
      const appSetting = new CometChat.AppSettingsBuilder()
        .subscribePresenceForAllUsers()
        .setRegion(cometChatSettingsProvider.getRegion())
        .build();

      try {
        if (!CometChat.isInitialized()) {
          setIsLoading(true);

          await CometChat.init(cometChatSettingsProvider.getAppId(), appSetting);
          CometChat.addMessageListener(
            'cc-messages-listeners',
            new CometChat.MessageListener({
              onTextMessageReceived: updateUnreadMessagesCount,
            }),
          );

          await login();

          setIsLoading(false);
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.warn('Chat initialization failed with error:', err);
        setIsError(true);
        setIsLoading(false);
      }
    };

    initCometChat();
  }, []);

  useEffect(() => {
    login();
  }, [coachId]);

  useEffect(() => {
    if (token && !isLoading) {
      CometChat.registerTokenForPushNotification(token);
    }
  }, [token, isLoading]);

  return (
    <CometChatContext.Provider
      value={{ updateUnreadMessagesCount, unreadMessagesCount, isLoading, isError }}
    >
      {children}
    </CometChatContext.Provider>
  );
};
