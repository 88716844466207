import React, { useMemo, useState } from 'react';
import { DateTime } from 'luxon';

import { EmptyState } from '~/components/common/EmptyState';
import { Row, Table } from '~/components/common/Table';
import { KeyHabit as model } from '~/model/Client/Habit';
import { Button } from '~/components/common/Button';
import { KeyHabit } from './KeyHabit';
import { Layout } from '../../Layout';
import { headerColumns } from './model';
import { ButtonRow } from './KeyHabits.style';

export interface Props {
  keyHabits: model[];
  updatedAt?: string;
}

const rows = (keyHabits, showAll: boolean): Row[] =>
  keyHabits
    .filter((item: model) => showAll || item.isVisible)
    .map((item: model, index) => ({
      id: item.title,
      content: <KeyHabit keyHabit={item} index={index + 1} />,
    }));

export const KeyHabits = ({ keyHabits, updatedAt }: Props) => {
  const [showAll, setShowAll] = useState(false);

  const hasKeyHabits = useMemo(() => keyHabits && keyHabits.length > 0 && updatedAt, [
    keyHabits,
    keyHabits.length,
    updatedAt,
  ]);

  let layoutProps = {};
  if (hasKeyHabits && updatedAt) {
    layoutProps = {
      subtitle: `Updated ${DateTime.fromISO(updatedAt).toFormat('dd/MM/yyyy')}`,
    };
  }

  return (
    <Layout title="Key habits" {...layoutProps}>
      <>
        {!hasKeyHabits && (
          <EmptyState
            title="This Client did not complete their questionnaire!"
            description="Key habits of your client will be visible here"
          />
        )}
        {hasKeyHabits && (
          <>
            <Table rows={rows(keyHabits, showAll)} headers={headerColumns} />
            <ButtonRow className="mt-3">
              <Button onClick={() => setShowAll(!showAll)}>Details</Button>
            </ButtonRow>
          </>
        )}
      </>
    </Layout>
  );
};
