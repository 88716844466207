import React, { Fragment } from 'react';
import { Modal, Props as ModalProps } from '~/components/common/Modal';
import { ScientificReference, Topic as model } from '~/model/Client/Goals/Topic';
import { ModalContent, Section, SectionHeading, SectionContent, Link } from './Topic.style';
import { AssociatedQuestionnaireAnswers } from './AssociatedQuestionnaireAnswers';
import { AssociatedTestResults } from './AssociatedTestResults';

export interface Props extends Pick<ModalProps, 'onClose' | 'testId'> {
  topic: model;
}

const Description = ({ description }: { description?: string }) => {
  if (!description) {
    return null;
  }

  return (
    <Section
      data-testid="goal-topic-modal-description"
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: description }}
    />
  );
};

const ScientificReferences = ({
  scientificReferences,
}: {
  scientificReferences?: ScientificReference[];
}) => {
  if (!scientificReferences || scientificReferences.length === 0) {
    return null;
  }

  return (
    <Section data-testid="goal-topic-modal-scientific-references">
      <SectionHeading>Scientific references</SectionHeading>
      <SectionContent>
        {scientificReferences.map((scientificReference, index) => (
          <Fragment key={scientificReference.name}>
            <Link target="_blank" href={scientificReference.url} rel="noreferrer">
              {scientificReference.name}
            </Link>
            {index < scientificReferences.length - 1 ? ', ' : ''}
          </Fragment>
        ))}
      </SectionContent>
    </Section>
  );
};

export const Topic = ({
  topic: {
    name,
    description,
    scientificReferences,
    associatedQuestionnaireAnswers,
    associatedTestResults,
  },
  ...otherProps
}: Props) => (
  <Modal title={name} {...otherProps}>
    <ModalContent>
      <Description description={description} />
      <AssociatedQuestionnaireAnswers
        associatedQuestionnaireAnswers={associatedQuestionnaireAnswers}
      />
      <AssociatedTestResults associatedTestResults={associatedTestResults} />
      <ScientificReferences scientificReferences={scientificReferences} />
    </ModalContent>
  </Modal>
);
