import styled from '@emotion/styled';

export const Wrapper = styled.div`
  padding: 6rem 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

export const Title = styled.h1`
  padding-bottom: 2rem;
`;
