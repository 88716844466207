import * as Yup from 'yup';

const ERROR_MINIMUM_LENGTH = 'Your code has to be at least 6 characters long';
const ERROR_MAX_LENGTH = 'Your Referral code must not exceed 20 characters';
const ERROR_REQUIRED = 'This field is required';
const ERROR_VALID_URL = 'Please enter a valid URL';

export interface FormFields {
  code: string;
  defaultSubscriptionPrice: string;
  startJourneyAutomatically: boolean;
  marketplaceForCustomersEnabled: boolean;
}

export const validationSchema = Yup.object({
  code: Yup.string()
    .required(ERROR_REQUIRED)
    .min(6, ERROR_MINIMUM_LENGTH)
    .max(20, ERROR_MAX_LENGTH),
  bookingLink: Yup.string()
    .matches(
      /[(http(s)?)://(www.)?a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/,
      ERROR_VALID_URL,
    )
    .nullable(true),
  defaultSubscriptionPrice: Yup.string().required(ERROR_REQUIRED),
});
