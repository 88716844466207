import styled from '@emotion/styled';

export const RowHeaderButton = styled.button`
  border: unset;
  padding: unset;
  background: unset;
  text-align: unset;
  font-weight: unset;
  color: unset;
`;

export const TaxInclLabel = styled.span`
  font-size: 12px;
  font-weight: 500;
  margin-left: 4px;
`;

export const ActionButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;
