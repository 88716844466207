import * as React from 'react';
import { SVGProps } from 'react';
import { Size } from '../types';
interface Props extends SVGProps<SVGSVGElement> {
  size?: Size;
}
const SvgCookingBook = (props: Props) => {
  const getSize = () => {
    if (props.size) {
      switch (props.size) {
        case 'small':
          return 20;

        case 'medium':
          return 24;

        case 'large':
          return 32;

        default:
          return null;
      }
    }
  };

  return (
    <svg
      width={getSize() || 24}
      height={getSize() || 24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        opacity={0.3}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.484 2h-12c-1.747 0-3 1.306-3 3v14c0 1.694 1.253 3 3 3h12c.642 0 1-.373 1-1v-1h-12a1 1 0 110-2h12V3c0-.627-.358-1-1-1z"
        fill="currentColor"
      />
      <mask id="cooking-book_svg__a" maskUnits="userSpaceOnUse" x={4} y={2} width={17} height={20}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.484 2h-12c-1.747 0-3 1.306-3 3v14c0 1.694 1.253 3 3 3h12c.642 0 1-.373 1-1v-1h-12a1 1 0 110-2h12V3c0-.627-.358-1-1-1z"
          fill="currentColor"
        />
      </mask>
      <g mask="url(#cooking-book_svg__a)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.834 11.588c-.776-.21-1.35-.946-1.35-1.823 0-1.04.806-1.883 1.8-1.883.05 0 .101.003.151.007.37-1.1 1.37-1.889 2.549-1.889 1.178 0 2.18.789 2.549 1.889.05-.004.1-.007.15-.007.995 0 1.8.843 1.8 1.883 0 .877-.573 1.614-1.35 1.823v1.941a.46.46 0 01-.45.471h-5.4a.46.46 0 01-.45-.47v-1.942z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};

export default SvgCookingBook;
