import { generatePath } from 'react-router-dom';
import { api } from '~/services/api';
import { Category } from '~/components/pages/client/Sections/Questionnaire/model';
import { BackendValidationErrorResponse } from '~/model/Error/ErrorTypes';

const URL = {
  LIST: '/api/client/:clientId/questionnaire',
  GET_QUESTION: '/api/client/:clientId/questionnaire/:questionId/answer',
  POST_QUESTION: '/api/client/:clientId/questionnaire/:questionId/answer',
  GET_ANSWER: '/api/client/:clientId/questionnaire/answer/:answerId',
  POST_ANSWER: '/api/client/:clientId/questionnaire/answer/:answerId',
};

export interface GetClientQuestionnaireListResponse {
  completedAt?: string;
  categories: Category[];
}

type AnswerValue =
  // open_ended answer
  | string
  // multi_select
  | string[]
  // single_answer
  | number
  // waist/hip circumference answer
  | { value: string }
  // blood_pressure or waist/hip circumference
  | { i_dont_know: boolean }
  // blood_pressure answer
  | { systolic: string; diastolic: string }
  // date of birth
  | { year: string; month: string; day: string };

export interface GetClientQuestionAnswerResponse {
  dimension?: string;
  category: string;

  question: {
    id: string;
    type: string;
    questionVariantId?: number;
    name: string;
    choices?: Array<{
      id: number;
      text: string;
    }>;
    value?: AnswerValue;
  };

  followup_questions: Array<{
    id: string;
    type: string;
    name: string;
    choices?: Array<{
      id: number;
      text: string;
    }>;
    value?: AnswerValue;
  }>;
}

export interface PostEditQuestionAnswerRequest {
  [x: string]: AnswerValue;
}

interface PostEditQuestionAnswerResponse {}

export const model = (clientId: string) => ({
  getList: (includeUnanswered?: boolean) => {
    const params = includeUnanswered ? `?includeUnansweredQuestions=1` : ``;
    return api.get<GetClientQuestionnaireListResponse>(
      generatePath(`${URL.LIST}${params}`, { clientId }),
    );
  },
  getQuestion: (questionId: string) =>
    api.get<GetClientQuestionAnswerResponse>(
      generatePath(URL.GET_QUESTION, { clientId, questionId }),
    ),
  postQuestion: (questionId: string, data: PostEditQuestionAnswerRequest) =>
    api.post<
      PostEditQuestionAnswerResponse,
      void | BackendValidationErrorResponse<PostEditQuestionAnswerResponse>
    >(generatePath(URL.POST_QUESTION, { clientId, questionId }), data),
  getAnswer: (answerId: string) =>
    api.get<GetClientQuestionAnswerResponse>(generatePath(URL.GET_ANSWER, { clientId, answerId })),
  postAnswer: (answerId: string, data: PostEditQuestionAnswerRequest) =>
    api.post<
      PostEditQuestionAnswerResponse,
      void | BackendValidationErrorResponse<PostEditQuestionAnswerResponse>
    >(generatePath(URL.POST_ANSWER, { clientId, answerId }), data),
});
