import styled from '@emotion/styled';
import { FormRowLabel as OriginalFormRowLabel } from '~/components/common/Form';

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 30px;
  padding-right: 30px;
`;

export const FormButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 15px;
  padding-left: 90px;
`;

export const FormFieldsWrapper = styled.div`
  padding-right: 30px;
`;

export const FormRowLabel = styled(OriginalFormRowLabel)`
  flex-basis: 60px;
`;
