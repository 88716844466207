import styled from '@emotion/styled';
import React from 'react';
import { Card, CardBody, CardHeader } from '~/components/common/Card';
import { Label, Variant } from '~/components/common/Label';
import { colors } from '~/components/utils/colors';

const Separator = styled.div`
  height: 1px;
  margin: 30px 0;
  background: ${colors.colorDisabledTwo};
`;

export const LabelDemo = () => (
  <Card>
    <CardHeader title="Labels" />
    <CardBody>
      <h3>Variants</h3>
      {['primary', 'warning', 'info', 'success', 'danger', 'neutral'].map((variant) => (
        <div className="d-flex w-25">
          <Label variant={variant as Variant}>Label {variant}</Label>
        </div>
      ))}

      <Separator />
      <h3>Light variants</h3>
      {['primary', 'warning', 'info', 'success', 'danger', 'neutral'].map((variant) => (
        <div className="d-flex w-50">
          <Label light variant={variant as Variant}>
            Label light {variant}
          </Label>
        </div>
      ))}
    </CardBody>
  </Card>
);
