import { UseMutationOptions } from 'react-query/types/react/types';
import { useMutation } from 'react-query';
import { model as coachProfileModel } from '~/model/Profile/coach';

export const useUpdateCoachProfessionalDetails = <T>(
  id: string,
  mutationOptions?: UseMutationOptions<any, unknown, T>,
) =>
  useMutation((data: T) => coachProfileModel.updateCoachProfessionalDetails<T>(id, data), {
    ...mutationOptions,
  });
