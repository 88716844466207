import { useMutation, UseMutationOptions } from 'react-query';
import { model as clientNoteModel } from '../notes';

export const useDeleteClientNote = <T>(
  clientId: string,
  noteId: number,
  mutationOptions?: UseMutationOptions<any, unknown, T>,
) =>
  useMutation(() => clientNoteModel(clientId).delete(noteId), {
    ...mutationOptions,
  });
