import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import objectPath from "object-path";
import SVG from "react-inlinesvg";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { toAbsoluteUrl } from "../../../_helpers";
import { DoubleChevron } from "~/components/common/Icons/dist";
import { getPartner } from "~/components/utils/helpers/partner";
import { homeUrl } from "~/components/pages/error/config";

export function Brand() {
  const uiService = useHtmlClassService();
  const partner = getPartner();

  const layoutProps = useMemo(() => ({
    brandClasses: uiService.getClasses("brand", true),
    asideSelfMinimizeToggle: objectPath.get(
      uiService.config,
      "aside.self.minimize.toggle"
    ),
    headerLogo: uiService.getSidebarLogo(),
    headerStickyLogo: uiService.getStickyLogo()
  }), [uiService]);

  return (
    <>
      {/* begin::Brand */}
      <div
        className={`brand flex-column-auto ${layoutProps.brandClasses}`}
        id="kt_brand"
      >
        {/* begin::Logo */}
        <a href={homeUrl} className="brand-logo">
          <SVG src={layoutProps.headerLogo} className={partner === 'ome' ? 'logo' : 'partner-logo'} />
        </a>
        {/* end::Logo */}

        {layoutProps.asideSelfMinimizeToggle && (
          <>
            {/* begin::Toggle */}
            <button className="brand-toggle btn btn-sm px-0" id="kt_aside_toggle" type="button">
              <span className="svg-icon svg-icon-xl">
                <DoubleChevron />
              </span>
            </button>
            {/* end::Toolbar */}
          </>
        )}
      </div>
      {/* end::Brand */}
    </>
  );
}
