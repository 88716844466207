import { css } from '@emotion/css';
import styled from '@emotion/styled';
import { colors } from '~/components/utils/colors';

export const style = {
  wrapper: css({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '6px',
    padding: '4px 10px',
  }),
  text: css({
    fontWeight: 500,
    fontSize: '11px',
    lineHeight: '18px',
  }),
};

export const Wrapper = styled.div`
  .bg-light-neutral {
    background-color: ${colors.colorDisabledTwo};
  }

  .text-light-neutral {
    color: ${colors.colorDisabledTwo};
  }

  .bg-neutral {
    background-color: ${colors.colorMenuDefault};
  }

  .text-neutral {
    color: ${colors.colorMenuDefault};
  }
`;
