import { useMutation, UseMutationOptions } from 'react-query';

import { model as paymentModel } from './payment';

export const useCreatePayment = <T>(mutationOptions?: UseMutationOptions<any, unknown, T>) =>
  useMutation((data: T) => paymentModel.createPayment<T>(data), {
    ...mutationOptions,
  });

export const usePreviewPayment = <T>(mutationOptions?: UseMutationOptions<any, unknown, T>) =>
  useMutation((data: T) => paymentModel.previewPayment<T>(data), {
    ...mutationOptions,
  });
