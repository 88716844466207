import * as React from 'react';
import { SVGProps } from 'react';
import { Size } from '../types';
interface Props extends SVGProps<SVGSVGElement> {
  size?: Size;
}
const SvgHalfHeart = (props: Props) => {
  const getSize = () => {
    if (props.size) {
      switch (props.size) {
        case 'small':
          return 20;

        case 'medium':
          return 24;

        case 'large':
          return 32;

        default:
          return null;
      }
    }
  };

  return (
    <svg
      width={getSize() || 24}
      height={getSize() || 25}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        opacity={0.3}
        d="M16.5 5c-1.61 0-3.492 1.825-4.5 3-1.008-1.175-2.89-3-4.5-3C4.651 5 3 7.222 3 10.05c0 4.7 9 9.95 9 9.95s9-5.25 9-9.75C21 7.422 19.349 5 16.5 5z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 8c-1.008-1.175-2.89-3-4.5-3C4.651 5 3 7.222 3 10.05c0 4.7 9 9.95 9 9.95V8z"
        fill="currentColor"
      />
    </svg>
  );
};

export default SvgHalfHeart;
