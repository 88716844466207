import React from 'react';
import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { ConfirmationModal } from '~/components/common/ConfirmationModal';
import { useDeleteClientNote } from '~/model/Client/Notes/hooks/useDeleteClientNote';
import { NoteType } from '~/model/Notes';
import { clientNotesQueryKey } from '~/model/Client/Notes';
import { useDeleteCoachNote, notesQueryKey } from '~/model/Notes/hooks';
import { useCoachContext } from '~/components/pages/profile';

export interface Props {
  noteId: number;
  type: NoteType;
  clientId?: number;
  onClose: () => void;
}

export const DeleteConfirmationModal = ({ noteId, type, clientId, onClose }: Props) => {
  const queryClient = useQueryClient();
  const { coach } = useCoachContext();
  const id = (clientId || coach.id).toString();
  const deleteNoteMutation = clientId
    ? useDeleteClientNote(id, noteId)
    : useDeleteCoachNote(id, noteId);
  const { isLoading: isDeleteLoading } = deleteNoteMutation;

  return (
    <ConfirmationModal
      testId="delete-note-modal"
      iconVariant="warning"
      title="Are you sure?"
      closeButtonText="No, cancel"
      submitButtonText="Yes, delete!"
      submitButtonVariant="danger"
      description="This will permanentely delete this note?"
      isLoading={isDeleteLoading}
      onClose={onClose}
      onSubmit={() => {
        deleteNoteMutation.mutate(
          {},
          {
            onSuccess: async () => {
              await queryClient.invalidateQueries([notesQueryKey]);
              await queryClient.removeQueries([notesQueryKey], { inactive: true });

              if (type === NoteType.CLIENT) {
                await queryClient.invalidateQueries(clientNotesQueryKey(id));
              }
              toast.success('Your Note has been deleted');
              onClose();
            },
            onError: () => {
              toast.error('Something went wrong... Please try again.');
            },
          },
        );
      }}
    />
  );
};
