import { generatePath } from 'react-router-dom';
import { api } from '~/services/api';

const URL = {
  PREVIEW_ORDER: '/api/client/:clientId/device/preview-order',
  CONFIRM_ORDER: '/api/client/:clientId/device/confirm-order',
};

export interface DeviceOrderItemResource {
  device: number;
  quantity: number;
}

export interface DeviceOrderRequest {
  items: DeviceOrderItemResource[];
}

export interface DeviceOrderProductResource {
  id: number;
  quantity: number;
  price: number;
  currency: string;
  name: string;
  product_id?: number;
  formatted_price: string;
}

export interface DeviceOrderResponse {
  products: DeviceOrderProductResource[];
  currency: string;
  total_price: number;
  delivery_price: number;
  formatted_total_price: string;
  formatted_delivery_price: string;
  client_name: string;
  formatted_coach_commissions: string;
  formatted_vat: string;
}

export const model = {
  previewOrder: <T>(clientId, data: T) =>
    api.post<T, DeviceOrderResponse[]>(
      generatePath(URL.PREVIEW_ORDER, {
        clientId,
      }),
      data,
    ),

  confirmOrder: <T>(clientId, data: T) =>
    api.post<T, DeviceOrderResponse>(
      generatePath(URL.CONFIRM_ORDER, {
        clientId,
      }),
      data,
    ),
};
