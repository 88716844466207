import * as React from 'react';
import { SVGProps } from 'react';
import { Size } from '../types';
interface Props extends SVGProps<SVGSVGElement> {
  size?: Size;
}
const SvgScales = (props: Props) => {
  const getSize = () => {
    if (props.size) {
      switch (props.size) {
        case 'small':
          return 20;

        case 'medium':
          return 24;

        case 'large':
          return 32;

        default:
          return null;
      }
    }
  };

  return (
    <svg
      width={getSize() || 20}
      height={getSize() || 21}
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_12907_4081)">
        <path d="M7.58795 16.0711C7.68661 15.9002 7.71335 15.6971 7.66228 15.5066C7.61121 15.316 7.48652 15.1535 7.31564 15.0548C7.14476 14.9561 6.94168 14.9294 6.75109 14.9805C6.5605 15.0315 6.398 15.1562 6.29934 15.3271C6.29934 15.3271 5.33706 16.802 5.59652 18.0324C6.79183 17.6419 7.58795 16.0711 7.58795 16.0711Z" fill="currentColor" />
        <path opacity="0.3" d="M6.9623 10.4705C6.9623 10.4705 3.87716 10.246 2.84403 14.2419C2.84403 14.2419 4.3545 12.8054 6.24917 13.3018L6.9623 10.4705Z" fill="currentColor" />
        <path opacity="0.3" d="M11.4827 13.0805C11.4827 13.0805 13.2206 15.6406 10.2767 18.5332C10.2767 18.5332 10.7663 16.5074 9.3882 15.1143L11.4827 13.0805Z" fill="currentColor" />
        <path opacity="0.3" d="M8.18699 15.3787L6.5475 14.4321C6.51609 14.414 6.49176 14.3857 6.47851 14.3519C6.46525 14.3182 6.46385 14.2809 6.47453 14.2462L6.61526 13.7984C6.62251 13.7755 6.63478 13.7545 6.65117 13.7369C6.66757 13.7194 6.68767 13.7057 6.71002 13.697C6.73237 13.6882 6.75639 13.6845 6.78034 13.6862C6.80429 13.6879 6.82757 13.6949 6.84846 13.7067L8.66648 14.7564C8.68736 14.7683 8.70529 14.7848 8.71894 14.8046C8.73259 14.8244 8.7416 14.847 8.74532 14.8708C8.74903 14.8945 8.74734 14.9188 8.74039 14.9418C8.73343 14.9649 8.72138 14.986 8.70513 15.0038L8.38763 15.3496C8.36278 15.377 8.32916 15.3949 8.29254 15.4002C8.25593 15.4055 8.2186 15.3979 8.18699 15.3787Z" fill="currentColor" />
        <path d="M13.8494 3.08909C12.7862 3.46265 11.7975 4.02123 10.9288 4.73903L14.5569 6.83371C14.7941 5.6555 14.8127 4.48429 14.4956 3.42096C14.4754 3.35647 14.4425 3.29665 14.399 3.24497C14.3554 3.19329 14.302 3.15078 14.2419 3.1199C14.1818 3.08902 14.1161 3.0704 14.0487 3.06511C13.9814 3.05982 13.9136 3.06797 13.8494 3.08909Z" fill="currentColor" />
        <path d="M14.5569 6.8337L10.9333 4.74158C9.72508 5.71976 8.40716 7.20848 7.36307 9.45983C7.36307 9.45983 6.28021 11.9151 6.21296 13.8277C7.2823 13.9729 8.25172 14.5326 8.91215 15.3861C8.91215 15.3861 10.5183 14.6636 12.2714 12.0497C13.2774 10.54 14.1857 8.6769 14.5569 6.8337ZM8.22037 13.5655C8.07214 13.4799 7.95261 13.3523 7.87691 13.1988C7.8012 13.0453 7.77272 12.8728 7.79506 12.7031C7.8174 12.5334 7.88957 12.3741 8.00242 12.2454C8.11528 12.1167 8.26376 12.0244 8.4291 11.9801C8.59443 11.9358 8.76919 11.9415 8.93127 11.9965C9.09335 12.0515 9.23547 12.1534 9.33967 12.2892C9.44387 12.425 9.50546 12.5886 9.51666 12.7594C9.52785 12.9302 9.48815 13.1005 9.40257 13.2487C9.34574 13.3472 9.27009 13.4334 9.17992 13.5026C9.08976 13.5718 8.98685 13.6225 8.87707 13.652C8.7673 13.6814 8.6528 13.6889 8.54012 13.674C8.42745 13.6592 8.31879 13.6223 8.22037 13.5655ZM9.32665 11.6494C9.11601 11.5289 8.94589 11.3486 8.83789 11.1313C8.72989 10.914 8.68888 10.6696 8.72005 10.4289C8.75122 10.1883 8.85318 9.96233 9.01298 9.77973C9.17277 9.59714 9.38322 9.46612 9.6176 9.40332C9.85198 9.34052 10.0997 9.34876 10.3294 9.42699C10.5591 9.50523 10.7604 9.64993 10.9077 9.84275C11.055 10.0356 11.1417 10.2678 11.1568 10.51C11.172 10.7521 11.1148 10.9934 10.9926 11.203C10.8299 11.4821 10.5636 11.6856 10.2515 11.7692C9.9395 11.8528 9.60707 11.8097 9.32665 11.6494ZM10.7871 9.11988C10.5103 8.96008 10.2871 8.72183 10.1457 8.43523C10.0042 8.14862 9.95095 7.82654 9.99251 7.50966C10.0341 7.19278 10.1686 6.89533 10.3792 6.6549C10.5897 6.41447 10.8668 6.24184 11.1755 6.15883C11.4841 6.07582 11.8104 6.08616 12.1131 6.18853C12.4159 6.2909 12.6815 6.48072 12.8764 6.73399C13.0713 6.98726 13.1868 7.29263 13.2082 7.6115C13.2297 7.93038 13.1561 8.24845 12.9968 8.52553C12.8909 8.7098 12.7497 8.87137 12.5812 9.001C12.4128 9.13063 12.2205 9.22577 12.0152 9.28098C11.81 9.33618 11.5958 9.35037 11.3851 9.32272C11.1743 9.29508 10.9711 9.22615 10.7871 9.11988Z" fill="currentColor" />
        <path opacity="0.3" d="M10.7868 9.11988C11.56 9.56628 12.5487 9.30137 12.9951 8.52818C13.4415 7.755 13.1766 6.76633 12.4034 6.31993C11.6302 5.87353 10.6415 6.13845 10.1951 6.91163C9.74874 7.68482 10.0137 8.67348 10.7868 9.11988Z" fill="currentColor" />
        <path opacity="0.3" d="M8.22048 13.5655C8.63441 13.8045 9.16369 13.6627 9.40267 13.2488C9.64166 12.8348 9.49983 12.3055 9.08591 12.0666C8.67198 11.8276 8.14269 11.9694 7.90371 12.3833C7.66473 12.7973 7.80655 13.3265 8.22048 13.5655Z" fill="currentColor" />
        <path opacity="0.3" d="M9.32636 11.6494C9.90966 11.9861 10.6555 11.7863 10.9923 11.203C11.3291 10.6197 11.1292 9.8738 10.5459 9.53702C9.96261 9.20025 9.21674 9.40011 8.87997 9.98341C8.5432 10.5667 8.74305 11.3126 9.32636 11.6494Z" fill="currentColor" />
      </g>
      <defs>
        <clipPath id="clip0_12907_4081">
          <rect width="9.09922" height="17.2401" fill="currentColor" transform="translate(10.2871 0.833313) rotate(30)" />
        </clipPath>
      </defs>
    </svg>

  );
};

export default SvgScales;
