import * as Yup from 'yup';
import { SendEmailRequest } from '~/model/InviteClient';

export interface FormFields {
  from: string;
  subject: string;
  content: string;
  to: string;
}

export const validationSchema = Yup.object().shape({
  subject: Yup.string().required('This field is required'),
  content: Yup.string().required('This field is required'),
  to: Yup.string().email().required('This field is required'),
});

export const toApiRequest = ({ to, subject, content }: FormFields): SendEmailRequest => ({
  to,
  subject,
  content,
});
