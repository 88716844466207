import { css } from '@emotion/css';

export const style = {
  wrapper: css({ display: 'flex', width: '100%' }),
  fullScreen: css({
    justifyContent: 'center',
    alignItems: 'center',
    placeContent: 'center',
    position: 'fixed',
    top: 0,
    background: 'rgb(0,0,0,0.15)',
    left: 0,
    display: 'flex',
    zIndex: 500,
    minHeight: '100%',
    // Loader container should be un-clickable when loader is visible
    pointerEvents: 'unset',
  }),
  loader: {
    small: css({ width: '40px' }),
    medium: css({ width: '75px' }),
    large: css({ width: '110px' }),
    center: css({ margin: 'auto', placeSelf: 'center' }),
  },
};
