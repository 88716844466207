/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
// import React from 'react';
import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/css';
import { Briefcase, Chat, Layers } from '~/components/common/Icons/dist';
import { Tabs, Tab } from '~/components/common/Tabs';
import { colors } from '~/components/utils/colors';
import { Card, CardBody, CardHeader } from '~/components/common/Card';

const Separator = styled.div`
  height: 1px;
  margin: 30px 0;
  border-bottom: 1px solid ${colors.colorDisabledOne};
`;

const generateRandomWidthInPixels = () => {
  const widths = ['calc(50% - 30px)', 'calc(50%)', '100%'];
  return widths[Math.floor(Math.random() * 3)];
};

const generateLoremIpsum = () => {
  const loremIpsum =
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.';
  return loremIpsum.substring(0, Math.floor(Math.random() * loremIpsum.length));
};

export const TabsDemo = () => (
  <>
    <h1>Plain tabs</h1>

    <Tabs>
      <Tab title="Plain tab no icon">
        <>Plain tab no icon content</>
      </Tab>
      <Tab title="Plain tab with icon" icon={<Briefcase />}>
        <>Plain tab 1 content</>
      </Tab>
      <Tab title="Plain tab with icon" icon={<Chat />}>
        <>Plain tab 2 content</>
      </Tab>
    </Tabs>

    <Separator />

    <h1>Card tabs</h1>

    <Tabs variant="card">
      <Tab title="Card tab no icon">
        <>Card tab no icon content</>
      </Tab>
      <Tab title="Card tab 1" icon={<Briefcase />}>
        <>Card tab 1 content</>
      </Tab>
      <Tab title="Card tab 2" icon={<Chat />}>
        <>Card tab 2 content</>
      </Tab>
    </Tabs>

    <Separator />

    <h1>Floating tabs</h1>

    <Tabs variant="floating">
      <Tab title="Floating tabs">
        <Card>
          <CardBody>Floating tab no icon content</CardBody>
        </Card>
      </Tab>
      <Tab title="Card tab 1" icon={<Briefcase />}>
        <Card>
          <CardBody>Floating tab 1 content</CardBody>
        </Card>
      </Tab>
      <Tab title="Card tab 2" icon={<Chat />}>
        <Card>
          <CardBody>Floating tab 2 content</CardBody>
        </Card>
      </Tab>
      <Tab title="Multiple cards" icon={<Layers />}>
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '30px' }}>
          {[...Array.from(Array(5).keys())].map((value) => (
            <Card
              key={value}
              className={css({ width: generateRandomWidthInPixels(), marginBottom: 0 })}
            >
              <CardHeader title={`Floating tab content ${value}`} />
              <CardBody>{generateLoremIpsum()}</CardBody>
            </Card>
          ))}
        </div>
      </Tab>
    </Tabs>
  </>
);
