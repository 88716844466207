import * as React from 'react';
import { SVGProps } from 'react';
import { Size } from '../types';
interface Props extends SVGProps<SVGSVGElement> {
  size?: Size;
}
const SvgAlert = (props: Props) => {
  const getSize = () => {
    if (props.size) {
      switch (props.size) {
        case 'small':
          return 20;

        case 'medium':
          return 24;

        case 'large':
          return 32;

        default:
          return null;
      }
    }
  };

  return (
    <svg
      width={getSize() || 80}
      height={getSize() || 80}
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx={40} cy={40} r={38.5} stroke="#F2C94C" strokeWidth={3} />
      <path
        d="M44.176 17l-1.024 30.912H35.6L34.512 17h9.664zm-4.608 45.888c-1.621 0-2.965-.49-4.032-1.472C34.512 60.392 34 59.133 34 57.64c0-1.493.512-2.73 1.536-3.712 1.067-1.024 2.41-1.536 4.032-1.536 1.579 0 2.88.512 3.904 1.536 1.024.981 1.536 2.219 1.536 3.712 0 1.493-.512 2.752-1.536 3.776-1.024.981-2.325 1.472-3.904 1.472z"
        fill="#FFA11F"
      />
    </svg>
  );
};

export default SvgAlert;
