import qs from 'qs';

export const TOKEN_SESSION_STORAGE_KEY = 'authToken';

export const setToken = (token: string) => {
  localStorage.setItem(TOKEN_SESSION_STORAGE_KEY, token);
};

export const removeToken = () => localStorage.removeItem(TOKEN_SESSION_STORAGE_KEY);

export const getToken = (): string | null => {
  const parsedQuery = qs.parse(window.location.search?.replace('?', ''), { plainObjects: true });
  if (parsedQuery.token) {
    const token = parsedQuery.token as string;
    setToken(token);
    return token;
  }

  const token = localStorage.getItem(TOKEN_SESSION_STORAGE_KEY);
  if (token) {
    return token;
  }

  return null;
};
