import styled from '@emotion/styled';
import { colors } from '~/components/utils/colors';

export const StyledWrap = styled.div`
  .card.card-custom > .card-header.card-header-tabs-line {
    border-bottom: 0;
    .nav .nav-link {
      padding: 30px 18px 30px 9px;
      border-bottom: 3px solid ${colors.colorDisabledOne};
      &:hover,
      &.active {
        border-bottom: 3px solid ${colors.colorPriBlueOne};
      }
    }
  }

  .tab-content {
    background-color: inherit;
  }

  .nav.nav-tabs.nav-tabs-line .nav-link {
    margin: 0 12.5px;
  }
`;
