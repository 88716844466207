import * as React from 'react';
import { SVGProps } from 'react';
import { Size } from '../types';
interface Props extends SVGProps<SVGSVGElement> {
  size?: Size;
}
const SvgWallet = (props: Props) => {
  const getSize = () => {
    if (props.size) {
      switch (props.size) {
        case 'small':
          return 20;

        case 'medium':
          return 24;

        case 'large':
          return 32;

        default:
          return null;
      }
    }
  };

  return (
    <svg
      width={getSize() || 33}
      height={getSize() || 32}
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 33 32"
      {...props}
    >
      <g clipPath="url(#wallet_svg__a)">
        <path d="M7.4 6.5H4.1C2.2 6.5.6 8.1.6 10v17.1c0 1.9 1.6 3.5 3.5 3.5h24.3c1.9 0 3.5-1.6 3.5-3.5V10c0-1.9-1.6-3.5-3.5-3.5h-1.3l-5-5c-.2-.2-.6-.3-.9-.1l-3.3 2.3-2.2-2.2c-.2-.2-.6-.3-.9-.1L7.4 6.5zm23 15.6H23c-.7 0-1.3-.6-1.3-1.3v-4.5c0-.7.6-1.3 1.3-1.3h7.4v7.1zM28.3 8c1.1 0 2.1.9 2.1 2.1v3.6H23c-1.5 0-2.7 1.2-2.7 2.7v4.5c0 1.5 1.2 2.7 2.7 2.7h7.4v3.6c0 1.1-.9 2.1-2.1 2.1H4.1C3 29.3 2 28.4 2 27.2V10c0-1.1.9-2 2.1-2h24.2zm-6.8-5l3.6 3.6h-8.7L21.5 3zM15 3l1.6 1.6-2.8 1.9h-4L15 3z" />
        <path d="M5.3 11.9c-.4 0-.8.4-.8.9v11.8c0 .5.3.9.8.9.4 0 .8-.4.8-.9V12.7c0-.5-.3-.8-.8-.8z" />
        <path
          d="M26.1 16.2c-1.3 0-2.4 1.1-2.4 2.4 0 1.3 1 2.4 2.4 2.4 1.3 0 2.4-1.1 2.4-2.4 0-1.3-1.1-2.4-2.4-2.4zm0 3.9c-.8 0-1.5-.7-1.5-1.5s.7-1.5 1.5-1.5 1.5.7 1.5 1.5-.7 1.5-1.5 1.5z"
          stroke="currentColor"
          strokeWidth={0.5}
          strokeMiterlimit={10}
        />
      </g>
      <defs>
        <clipPath id="wallet_svg__a">
          <path transform="translate(.2)" d="M0 0h32v32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SvgWallet;
