import { Header } from '~/components/common/Table';

export const headerColumns: Header[] = [
  {
    title: 'Test',
    style: {
      width: '300px',
    },
  },
  {
    title: 'Source',
    style: {
      minWidth: '150px',
    },
  },
  {
    title: 'Value',
    style: {
      minWidth: '150px',
    },
  },
  {
    title: 'Result',
    style: {
      minWidth: '100px',
    },
  },
];
