import React, { useContext } from 'react';

export interface PaginationContextType {
  changeCurrentPage: (value: number) => void;
  changeItemsPerPage?: (value: number) => void;
  currentPage: number;
  itemsPerPage: number;
}

export const PaginationContext = React.createContext<PaginationContextType>(undefined!);

export const usePaginationContext = () => useContext(PaginationContext);
