import { css } from '@emotion/css';
import styled from '@emotion/styled';
import { colors } from '~/components/utils/colors';
import { TableGridSpacing } from '~/components/pages/client/Sections/ExpandableTable/ExpandableTable.style';

export const style = {
  meal: css({
    color: colors.colorGsThree,
  }),
  name: css({
    fontSize: '14px',
    fontWeight: 500,
    color: colors.colorPriOne,
  }),
  actions: css({
    placeSelf: 'self-end',
    display: 'flex',
    flexDirection: 'row',
    gap: '10px',
  }),
};

export const MealRow = styled(TableGridSpacing)`
  color: ${colors.colorGsThree};
`;
