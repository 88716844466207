import * as React from 'react';
import { SVGProps } from 'react';
import { Size } from '../types';
interface Props extends SVGProps<SVGSVGElement> {
  size?: Size;
}
const SvgCart2 = (props: Props) => {
  const getSize = () => {
    if (props.size) {
      switch (props.size) {
        case 'small':
          return 20;

        case 'medium':
          return 24;

        case 'large':
          return 32;

        default:
          return null;
      }
    }
  };

  return (
    <svg
      width={getSize() || 24}
      height={getSize() || 24}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        opacity={0.3}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.1608 5.34188L14.6718 3.15193C14.7756 2.70691 15.1724 2.39209 15.6293 2.39209H17.35C17.8931 2.39209 18.3333 2.83231 18.3333 3.37535C18.3333 3.9184 17.8931 4.35862 17.35 4.35862H16.4096L15.1205 9.86973L14.5671 13.1903C14.4753 13.7407 13.9508 14.1096 13.4018 14.0097L4.19615 12.336C3.82154 12.2679 3.51811 11.9929 3.41351 11.6268L1.98202 6.61661C1.7995 5.97779 2.27916 5.34188 2.94354 5.34188H14.1608Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.66669 16.25C6.66669 16.9404 6.10704 17.5 5.41669 17.5C4.72633 17.5 4.16669 16.9404 4.16669 16.25C4.16669 15.5596 4.72633 15 5.41669 15C6.10704 15 6.66669 15.5596 6.66669 16.25ZM14.1667 16.25C14.1667 16.9404 13.607 17.5 12.9167 17.5C12.2263 17.5 11.6667 16.9404 11.6667 16.25C11.6667 15.5596 12.2263 15 12.9167 15C13.607 15 14.1667 15.5596 14.1667 16.25Z"
        fill="currentColor"
      />
    </svg>

  );
};

export default SvgCart2;
