import React from 'react';
import styled from '@emotion/styled';
import { capital } from 'case';
import { Card, CardBody, CardHeader } from '~/components/common/Card';
import {
  Button,
  ButtonDropdown,
  DropdownItem,
  IconButton,
  Size,
  Variant,
} from '~/components/common/Button';
import { Edit, Edit2, Plus1 } from '~/components/common/Icons/dist';
import { colors } from '~/components/utils/colors';

const Separator = styled.div`
  height: 0;
  border-bottom: 1px solid ${colors.colorDisabledTwo};
`;

const Container = styled.div`
  display: flex;
  column-gap: 10px;
  margin-bottom: 16px;
`;

const variants: Variant[] = ['primary', 'success', 'danger', 'neutral', 'ori'];
const sizes: Size[] = ['small', 'medium', 'large'];

export const Buttons = () => {
  const handleClick = () => {
    // click
  };

  return (
    <Card>
      <CardHeader title="Buttons" />
      <CardBody>
        <h1>Variants</h1>
        <Container>
          {variants.map((variant) => (
            <Button variant={variant}>{capital(variant)}</Button>
          ))}
        </Container>

        <Separator />

        <h3>Disabled</h3>
        <Container>
          {variants.map((variant) => (
            <Button disabled variant={variant}>
              {variant}
            </Button>
          ))}
        </Container>

        <h2>Sizes</h2>
        {sizes.map((size) => (
          <Container>
            {variants.map((variant) => (
              <Button size={size} variant={variant}>
                {capital(size)}
              </Button>
            ))}
          </Container>
        ))}

        <Separator />

        <h1>With icons</h1>

        {sizes.map((size) => (
          <>
            <h3>{capital(size)}</h3>
            <Container>
              <Button size={size} isLoading>
                With spinner
              </Button>
              <Button size={size} icon={<Plus1 />}>
                With icon
              </Button>
              <Button
                size={size}
                icon={<Plus1 />}
                iconPosition="right"
                onClick={() => {
                  throw new Error('testing');
                }}
              >
                Right icon
              </Button>
            </Container>
          </>
        ))}

        <h2>Icon Buttons</h2>
        <Container>
          <IconButton icon={<Edit />} />
          <IconButton icon={<Edit2 />} shade="light" />
          <IconButton icon={<Edit />} variant="success" />
          <IconButton icon={<Edit2 />} variant="success" shade="light" />
        </Container>

        <Separator />

        <h2>Dropdown</h2>
        <Container>
          <ButtonDropdown buttonContent={<>Tst</>}>
            <DropdownItem testId="tst" onClick={handleClick}>
              <>Item 1</>
              <>Item 2</>
            </DropdownItem>
          </ButtonDropdown>
        </Container>
      </CardBody>
    </Card>
  );
};
