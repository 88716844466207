import React, { LabelHTMLAttributes } from 'react';
import clsx from 'clsx';
import styled from '@emotion/styled';

import { style } from './Label.style';

export const Label = ({
  children,
  htmlFor,
  className,
  ...otherProps
}: LabelHTMLAttributes<HTMLLabelElement>) => (
  <label htmlFor={htmlFor} className={clsx(style.label, className)} {...otherProps}>
    {children}
  </label>
);

export const FormRowLabel = styled(Label) <LabelHTMLAttributes<HTMLLabelElement>>`
  flex-basis: 180px;
`;
