import { Header } from '~/components/common/Table';

export const headerColumns: Header[] = [
  {
    title: 'Habit',
    style: {
      paddingRight: 0,
    },
  },
  {
    title: 'Goals',
  },
  {
    title: 'Priority',
  },
  {
    title: 'Actions',
    style: {
      textAlign: 'end',
    },
  },
];
