import React, { useContext, useState } from 'react';
import styled from '@emotion/styled';

import { css } from '@emotion/css';
import { Card, CardBody, CardHeader } from '~/components/common/Card';
import { Button } from '~/components/common/Button';
import { Modal } from '~/components/common/Modal';
import { FormRow, FormRowLabel, Input } from '~/components/common/Form';
import { Separator } from '~/components/common/Modal/Modal.style';
import { FormRowInputWrapper } from '~/components/common/Form/Layout/Layout.style';
import {
  ConfirmationModal,
  Props as ConfirmationModalProps,
  IconVariant,
  ButtonVariant,
} from '~/components/common/ConfirmationModal';
import { ModalContext } from '~/components/common/Modal/ModalPortal';

const ModalFooter = styled.div`
  display: flex;
  padding-right: 30px;
  margin-top: 15px;
  gap: 30px;
  justify-content: flex-end;
`;

const contentModal = ({ handleClose }) => (
  <Modal onClose={() => handleClose()} title="Modal title" subtitle="Completed 12/05/02">
    <div>
      <h1>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</h1>
      <p>
        Quisque egestas mattis est, at scelerisque mauris condimentum non. Vivamus imperdiet
        scelerisque viverra. Nulla sed mattis libero. Integer non elit id dui semper vulputate eu a
        mauris. In ac mauris sed massa fringilla lobortis et vel ligula. Aenean hendrerit arcu
        turpis, id lacinia purus tincidunt quis. Morbi a fringilla ante. Orci varius natoque
        penatibus et magnis dis parturient montes, nascetur ridiculus mus. Praesent ornare
        consectetur mollis. Cras sagittis nisi sed dignissim egestas. Donec ut tellus magna. Quisque
        pulvinar orci tortor, eget venenatis massa mattis pharetra. Duis cursus, urna sed posuere
        tempus, turpis eros rhoncus elit, vel molestie risus augue sed augue.
      </p>
      <p>
        Nulla tempus odio sapien, ac iaculis arcu volutpat id. Sed at interdum tellus. Nulla
        efficitur augue non elit tempor, eu pharetra est vestibulum. Nullam malesuada fringilla
        dolor vel ultricies. Pellentesque tincidunt vestibulum orci id sagittis. Nulla facilisi.
        Maecenas porta purus ac rhoncus varius. Praesent eget tincidunt nibh. Maecenas at risus non
        justo lacinia tristique et sit amet augue. Quisque velit nisi, ultrices ac fringilla a,
        pretium eu felis. Quisque fringilla enim nec ligula blandit varius. Fusce mattis a nisl vel
        facilisis. Curabitur ultricies interdum tellus, et suscipit justo. Proin eu odio justo.
        Suspendisse id lectus vehicula,congue est at, commodo odio.
      </p>
      <p>
        Pellentesque a libero est. Suspendisse suscipit justo vitae erat posuere tempor.
        Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis
        egestas. Proin sagittis ipsum vulputate bibendum viverra. Etiam mollis vel sem vel
        consectetur. Integer massa mi, aliquet accumsan felis in, pulvinar consequat elit. Proin a
        neque at leo cursus pulvinar. Praesent id dapibus sapien, sed volutpat purus. Quisque
        commodo dolor sed massa euismod egestas. Donec nec nunc eu metus elementum tristique in id
        neque. Mauris efficitur vitae leo porta cursus. Morbi vel ipsum id dolor fringilla
        tincidunt. Pellentesque eu odio tortor. Etiam fringilla non tellus vel sagittis. Ut in nisl
        mauris. Ut feugiat velit sit amet velit congue posuere. Nam vehicula dignissim facilisis.
        Donec sed egestas justo. Proin feugiat congue massa eget accumsan. In quis tristique lorem,
        non maximus dolor. Nulla in purus magna. Donec consectetur nulla at sapien volutpat placerat
        non ut lectus. Vivamus et turpis eu nisi eleifend rhoncus sit amet sed justo. Duis nec
        lectus quis ligula tempus consequat. Praesent dapibus euismod sagittis. Vivamus rhoncus
        facilisis ex. Duis eleifend vehicula nibh, a tincidunt velit auctor sed. Etiam hendrerit
        mauris sit amet ante pretium tempor. Sed auctor ex vel nunc aliquam mollis. Etiam mattis
        libero eget dictum porttitor.
      </p>
    </div>
  </Modal>
);

const formModal = ({ handleClose }) => (
  <Modal
    hasCloseButton={false}
    onClose={() => handleClose()}
    title="Modal title"
    withHeaderSeparator
  >
    <FormRow $rowSpacing="15px">
      <>
        <FormRowLabel htmlFor="dimension">Dimension:</FormRowLabel>
        <FormRowInputWrapper>
          <Input name="dimension" value="this is a disabled input" disabled />
        </FormRowInputWrapper>
      </>
    </FormRow>
    <FormRow $rowSpacing="15px">
      <>
        <FormRowLabel htmlFor="topic">Topic:</FormRowLabel>
        <FormRowInputWrapper>
          <Input name="topic" value="this is an enabled input" />
        </FormRowInputWrapper>
      </>
    </FormRow>

    <Separator $marginBottom="30px" />

    <ModalFooter>
      <Button type="button" shade="light" variant="neutral" onClick={handleClose}>
        Cancel
      </Button>
      <Button variant="success" type="submit">
        Save
      </Button>
    </ModalFooter>
  </Modal>
);

const noHeaderModal = ({ handleClose }) => (
  <Modal onClose={() => handleClose()}>
    <div>
      <h1>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</h1>
      <p>
        Quisque egestas mattis est, at scelerisque mauris condimentum non. Vivamus imperdiet
        scelerisque viverra. Nulla sed mattis libero. Integer non elit id dui semper vulputate eu a
        mauris. In ac mauris sed massa fringilla lobortis et vel ligula. Aenean hendrerit arcu
        turpis, id lacinia purus tincidunt quis. Morbi a fringilla ante. Orci varius natoque
        penatibus et magnis dis parturient montes, nascetur ridiculus mus. Praesent ornare
        consectetur mollis. Cras sagittis nisi sed dignissim egestas. Donec ut tellus magna. Quisque
        pulvinar orci tortor, eget venenatis massa mattis pharetra. Duis cursus, urna sed posuere
        tempus, turpis eros rhoncus elit, vel molestie risus augue sed augue.
      </p>
      <p>
        Nulla tempus odio sapien, ac iaculis arcu volutpat id. Sed at interdum tellus. Nulla
        efficitur augue non elit tempor, eu pharetra est vestibulum. Nullam malesuada fringilla
        dolor vel ultricies. Pellentesque tincidunt vestibulum orci id sagittis. Nulla facilisi.
        Maecenas porta purus ac rhoncus varius. Praesent eget tincidunt nibh. Maecenas at risus non
        justo lacinia tristique et sit amet augue. Quisque velit nisi, ultrices ac fringilla a,
        pretium eu felis. Quisque fringilla enim nec ligula blandit varius. Fusce mattis a nisl vel
        facilisis. Curabitur ultricies interdum tellus, et suscipit justo. Proin eu odio justo.
        Suspendisse id lectus vehicula,congue est at, commodo odio.
      </p>
      <p>
        Pellentesque a libero est. Suspendisse suscipit justo vitae erat posuere tempor.
        Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis
        egestas. Proin sagittis ipsum vulputate bibendum viverra. Etiam mollis vel sem vel
        consectetur. Integer massa mi, aliquet accumsan felis in, pulvinar consequat elit. Proin a
        neque at leo cursus pulvinar. Praesent id dapibus sapien, sed volutpat purus. Quisque
        commodo dolor sed massa euismod egestas. Donec nec nunc eu metus elementum tristique in id
        neque. Mauris efficitur vitae leo porta cursus. Morbi vel ipsum id dolor fringilla
        tincidunt. Pellentesque eu odio tortor. Etiam fringilla non tellus vel sagittis. Ut in nisl
        mauris. Ut feugiat velit sit amet velit congue posuere. Nam vehicula dignissim facilisis.
        Donec sed egestas justo. Proin feugiat congue massa eget accumsan. In quis tristique lorem,
        non maximus dolor. Nulla in purus magna. Donec consectetur nulla at sapien volutpat placerat
        non ut lectus. Vivamus et turpis eu nisi eleifend rhoncus sit amet sed justo. Duis nec
        lectus quis ligula tempus consequat. Praesent dapibus euismod sagittis. Vivamus rhoncus
        facilisis ex. Duis eleifend vehicula nibh, a tincidunt velit auctor sed. Etiam hendrerit
        mauris sit amet ante pretium tempor. Sed auctor ex vel nunc aliquam mollis. Etiam mattis
        libero eget dictum porttitor.
      </p>
    </div>
  </Modal>
);

const confirmationModal = ({ handleClose }) => {
  const confirmationModalProps: ConfirmationModalProps = {
    iconVariant: 'warning' as IconVariant,
    title: 'Are you sure?',
    description: (
      <>
        <p>This will permanentely delete your client!</p>
        <p style={{ color: 'red' }}>Do you reall want to do this?</p>
      </>
    ),
    closeButtonText: 'No, cancel!',
    submitButtonText: 'Yes, delete',
    submitButtonVariant: 'danger' as ButtonVariant,
    onClose: () => {
      handleClose();
    },
    onSubmit: () => {
      handleClose();
    },
  };

  return <ConfirmationModal {...confirmationModalProps} />;
};

const NestedModals = ({ handleClose }) => {
  const [showInnerModal, setShowInnerModal] = useState(false);
  const { openModalCount } = useContext(ModalContext);

  return (
    <Modal
      closeOnOuterClick={false}
      onClose={() => handleClose()}
      title={`Currently opened modals: ${openModalCount}`}
    >
      <Button
        onClick={() => {
          setShowInnerModal(true);
        }}
      >
        Another one
      </Button>
      {showInnerModal && <NestedModals handleClose={() => setShowInnerModal(false)} />}
    </Modal>
  );
};

export const Modals = () => {
  const [showContentModal, setShowContentModal] = useState(false);
  const [showFormModal, setShowFormModal] = useState(false);
  const [showNoHeaderModal, setShowNoHeaderModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showNestedModals, setShowNestedModals] = useState(false);

  return (
    <>
      <Card>
        <CardHeader title="Modals" />
        <CardBody>
          <div className={css({ display: 'flex', flexDirection: 'row', gap: '10px' })}>
            <Button variant="success" onClick={() => setShowContentModal(true)}>
              Content modal
            </Button>
            <Button variant="neutral" shade="light" onClick={() => setShowFormModal(true)}>
              Form modal
            </Button>
            <Button shade="light" onClick={() => setShowNoHeaderModal(true)}>
              No Header modal
            </Button>
            <Button variant="neutral" shade="light" onClick={() => setShowNestedModals(true)}>
              Nested modals
            </Button>
          </div>
          {showContentModal && contentModal({ handleClose: () => setShowContentModal(false) })}
          {showFormModal && formModal({ handleClose: () => setShowFormModal(false) })}
          {showNoHeaderModal && noHeaderModal({ handleClose: () => setShowNoHeaderModal(false) })}
          {showNestedModals && <NestedModals handleClose={() => setShowNestedModals(false)} />}
        </CardBody>
      </Card>

      <Card>
        <CardHeader title="Modals" />
        <CardBody>
          <Button shade="light" onClick={() => setShowConfirmationModal(true)}>
            Confirmation modal
          </Button>
        </CardBody>
        {showConfirmationModal &&
          confirmationModal({ handleClose: () => setShowConfirmationModal(false) })}
      </Card>
    </>
  );
};
