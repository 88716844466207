import React, { useState } from 'react';
import { useClient } from '~/components/pages/client/context';
import { useSchedule } from '~/model/Client/Supplements/Schedule';
import { ScheduleButton } from './ScheduleButton';
import { ScheduleModal } from './ScheduleModal';

export const Schedule = () => {
  const { clientId } = useClient();
  const { isLoading, isError, data } = useSchedule(clientId);
  const [showModal, setShowModal] = useState(false);

  const isButtonDisabled = isLoading || isError || !data;
  return (
    <>
      <ScheduleButton disabled={isButtonDisabled} onClick={() => setShowModal(true)} />
      {showModal && <ScheduleModal onClose={() => setShowModal(false)} />}
    </>
  );
};
