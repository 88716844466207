export function removeCSSClass(ele, cls) {
    const reg = new RegExp("(\\s|^)" + cls + "(\\s|$)");
    ele.className = ele.className.replace(reg, " ");
}

export function addCSSClass(ele, cls) {
    ele.classList.add(cls);
}

// TODO set proper process.env.PUBLIC_URL from webpack config
export const toAbsoluteUrl = pathname => pathname;
