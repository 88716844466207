import React from 'react';
import { Form, Formik } from 'formik';
import { css } from '@emotion/css';
import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import {
  Separator,
  SubmitButtonWrapper,
} from '~/components/pages/profile/Details/Layout/Form.style';

import {
  FormikDropdown,
  FormikInput,
  FormikToggle,
  FormRow,
  FormRowInputWrapper,
  FormRowLabel,
  FormRowToggleWrapper,
} from '~/components/common/Form';
import { Button } from '~/components/common/Button';
import { coachProfileQueryKey } from '~/model/Profile';
import { model } from '~/model/Profile/coach';
import { isBackendValidationError, transformErrors } from '~/model/Error/transformApiErrors';
import { useCoachContext } from '~/components/pages/profile/CoachProvider';
import { queryKey as inviteClientQueryKey } from '~/model/InviteClient';
import { CardHeaderTitle } from '~/components/common/Card';
import { SubscriptionPrice } from '~/model/SubscriptionPrice';
import { Tooltip } from '~/components/common/Tooltip';
import { Info } from '~/components/common/Icons/dist';
import { getPartner, getPartnerName, Partner } from '~/components/utils/helpers/partner';
import { FormFields, validationSchema } from './validation';

export interface Props {
  coachSubscriptionPrices?: SubscriptionPrice[];
}

export const SettingsForm = ({ coachSubscriptionPrices }: Props) => {
  const partnerName = getPartnerName();
  const { coach } = useCoachContext();
  const queryClient = useQueryClient();
  const partner = getPartner();
  const isHei = partner === Partner.HEI;

  return (
    <Formik
      initialValues={{
        code: coach.code,
        bookingLink: coach.booking_link,
        defaultSubscriptionPrice: coach.default_subscription_price,
        startJourneyAutomatically: coach.start_journey_automatically,
        marketplaceForCustomersEnabled: coach.marketplace_for_customers_enabled,
      }}
      validationSchema={validationSchema}
      onSubmit={async (values: FormFields, { setSubmitting, setErrors }) => {
        try {
          const { startJourneyAutomatically, marketplaceForCustomersEnabled, ...rest } = values;
          await model.updateCoachSettings(coach.id, {
            ...rest,
            startJourneyAutomatically: startJourneyAutomatically ? 1 : 0,
            marketplaceForCustomersEnabled: marketplaceForCustomersEnabled ? 1 : 0,
          });
          await queryClient.refetchQueries(coachProfileQueryKey());
          queryClient.refetchQueries(inviteClientQueryKey());
          toast.success('Settings have been successfully updated');
        } catch (err) {
          if (isBackendValidationError<FormFields>(err)) {
            setErrors(transformErrors<FormFields>(err));
          } else {
            toast.error(
              'Something went wrong. Please try again or contact support about this issue.',
            );
          }
        }

        setSubmitting(false);
      }}
    >
      {({ isSubmitting }) => (
        <Form>
          <div className={css({ padding: '30px 30px 0 0' })}>
            <FormRow width="large">
              <>
                <FormRowLabel takesSpace="larger" />
                <CardHeaderTitle>Referral code</CardHeaderTitle>
              </>
            </FormRow>
            <FormRow width="large">
              <>
                <FormRowLabel takesSpace="larger" htmlFor="code">
                  Your referral code
                </FormRowLabel>
                <FormRowInputWrapper>
                  <FormikInput
                    name="code"
                    note={`Your clients should use this code when registering in ${partnerName}`}
                  />
                </FormRowInputWrapper>
              </>
            </FormRow>
            <FormRow width="large">
              <>
                <FormRowLabel takesSpace="larger" />
                <CardHeaderTitle>Booking and calendar</CardHeaderTitle>
              </>
            </FormRow>
            <FormRow width="large">
              <>
                <FormRowLabel takesSpace="larger" htmlFor="bookingLink">
                  Booking link
                </FormRowLabel>
                <FormRowInputWrapper>
                  <FormikInput
                    name="bookingLink"
                    note="Please copy your calendar (Calendly, etc.) booking link here"
                  />
                </FormRowInputWrapper>
              </>
            </FormRow>
            <FormRow width="large">
              <>
                <FormRowLabel takesSpace="larger" />
                <CardHeaderTitle>Service prices</CardHeaderTitle>
              </>
            </FormRow>
            <FormRow width="large">
              <>
                <FormRowLabel takesSpace="larger" htmlFor="defaultSubscriptionPrice">
                  Default subscription
                  <Tooltip content="This is the monthly subscription price for your NEW clients, which can be changed at any time after the Payout details are completed.">
                    <Info className="mb-6" />
                  </Tooltip>
                </FormRowLabel>
                <FormRowInputWrapper>
                  <FormikDropdown
                    name="defaultSubscriptionPrice"
                    note={
                      <>
                        Your <strong>NEW</strong> clients will be charged this amount every month
                      </>
                    }
                    disabled={!isHei && coach.onboarding_status !== 'COMPLETED'}
                    testId="defaultSubscriptionPrice"
                  >
                    {coachSubscriptionPrices?.map((price) => (
                      <option value={price.id} key={price.id}>
                        {price.name}
                      </option>
                    ))}
                  </FormikDropdown>
                </FormRowInputWrapper>
              </>
            </FormRow>
            <FormRow width="large">
              <>
                <FormRowLabel takesSpace="larger" htmlFor="startJourneyAutomatically">
                  Start Journeys automatically
                  <Tooltip content="When this is turned on the Journey for your new Clients will start as soon as they complete the questionnaire">
                    <Info className="mb-6" />
                  </Tooltip>
                </FormRowLabel>
                <FormRowToggleWrapper>
                  <FormikToggle name="startJourneyAutomatically" />
                </FormRowToggleWrapper>
              </>
            </FormRow>
            <FormRow width="large">
              <>
                <FormRowLabel takesSpace="larger" htmlFor="marketplaceForCustomersEnabled">
                  Allow unsupervised purchases
                  <Tooltip size="large" content={
                    <>
                      Having this on will allow your clients to browse the test, supplement and device marketplace and make the purchases <strong>without</strong> your explicit recommendation. You will be notified about every purchase and <strong>still earn</strong> your standard commission!
                    </>
                  }>
                    <Info className="mb-6" />
                  </Tooltip>
                </FormRowLabel>
                <FormRowToggleWrapper>
                  <FormikToggle name="marketplaceForCustomersEnabled" />
                </FormRowToggleWrapper>
              </>
            </FormRow>

            <Separator />
            <SubmitButtonWrapper>
              <Button
                variant="success"
                type="submit"
                disabled={isSubmitting}
                isLoading={isSubmitting}
              >
                Save
              </Button>
            </SubmitButtonWrapper>
          </div>
        </Form>
      )}
    </Formik>
  );
};
