import React, { PropsWithChildren } from 'react';
import { DateTime } from 'luxon';
import { capital } from 'case';

import { Error, Loader, Modal, Props as ModalProps } from '~/components/common/Modal';
import { useGetMealRecipe } from '~/model/Client/Meals/Meal';
import { TestableComponentProps } from '~/model/TestableComponentProps.interface';
import {
  AlternativeHeading,
  Column,
  OrderedList,
  Image,
  Row,
  UnorderedList,
  ListItemPrefix,
  RowProps,
} from '~/components/common/Modal/Modal.style';
import { Recipe } from '~/model/Recipe';
import { Label } from '~/components/common/Label';
import { NutritionsPerServing } from '../../../NutritionsPerServing';

export interface Props extends TestableComponentProps, ModalProps {
  mealId: number;
  clientId: string;
}

const image = (recipeImage?: string) =>
  recipeImage && (
    <Column>
      <Image data-testid="meal-modal-image" $url={recipeImage} />
    </Column>
  );

const labels = ({ servings, skill, preparation_time, cuisine }: Partial<Recipe>) => {
  const allLabels = [servings, skill, preparation_time, cuisine];
  const rowProps: RowProps = allLabels.every((label) => !!label)
    ? { $justifyContent: 'space-between', $gap: '0px' }
    : {};

  return (
    allLabels.some((label) => !!label) && (
      <Row {...rowProps}>
        {servings && (
          <Column $width="auto">
            <Label light>Servings - {servings}</Label>
          </Column>
        )}
        {skill && (
          <Column $width="auto">
            <Label light variant="success">
              Skill - {capital(skill)}
            </Label>
          </Column>
        )}
        {preparation_time && (
          <Column $width="auto">
            <Label light variant="warning">
              Preparation time - {preparation_time} min
            </Label>
          </Column>
        )}
        {cuisine && (
          <Column $width="auto">
            <Label light>Cuisine - {capital(cuisine)}</Label>
          </Column>
        )}
      </Row>
    )
  );
};

const ingredients = (recipeIngredients: string[]) =>
  recipeIngredients &&
  recipeIngredients.length > 0 && (
    <Row>
      <Column $width="100%">
        <AlternativeHeading data-testid="meal-modal-ingredients-heading">
          Ingredients
        </AlternativeHeading>
        <UnorderedList>
          {recipeIngredients.map((recipeIngredient, index) => (
            <li key={recipeIngredient} data-testid={`meal-modal-ingredients-item-${index}`}>
              <ListItemPrefix>-</ListItemPrefix>
              {recipeIngredient}
            </li>
          ))}
        </UnorderedList>
      </Column>
    </Row>
  );

const instructions = (cookingInstructions: string[]) =>
  cookingInstructions &&
  cookingInstructions.length > 0 && (
    <Row $marginBottom="0">
      <Column $width="100%">
        <AlternativeHeading data-testid="meal-modal-instructions-heading">
          Cooking instructions
        </AlternativeHeading>
        <OrderedList>
          {cookingInstructions.map((row, index) => (
            <li key={row} data-testid={`meal-modal-instructions-item-${index}`}>
              {row}
            </li>
          ))}
        </OrderedList>
      </Column>
    </Row>
  );

export const MealModal = ({ clientId, mealId, onClose, testId }: PropsWithChildren<Props>) => {
  const { data, isLoading, isError } = useGetMealRecipe(clientId, mealId);

  const defaultProps = {
    onClose,
    testId,
  };

  if (isLoading) {
    return <Loader {...defaultProps} />;
  }

  if (isError || !data) {
    return <Error {...defaultProps} />;
  }

  const {
    name,
    created_at,
    image: recipeImage,
    nutrition_per_serving,
    ingredients: recipeIngredients,
    cooking_instructions,
  } = data;

  return (
    <Modal
      title={name}
      subtitle={`Created ${DateTime.fromISO(created_at).toFormat('dd/MM/yyyy')}`}
      {...defaultProps}
    >
      <Row $maxHeight="230px" $marginBottom="20px">
        {image(recipeImage)}
        <NutritionsPerServing nutritionsPerServing={nutrition_per_serving} testId="meal-modal" />
      </Row>
      {labels(data)}
      {ingredients(recipeIngredients)}
      {instructions(cooking_instructions)}
    </Modal>
  );
};
