import React from 'react';
import { Loader, Error, Props as ModalProps } from '~/components/common/Modal';
import { useClientGoalTopic } from '~/model/Client/Goals/Topic/hooks';
import { useClient } from '~/components/pages/client/context';
import { Topic as Component } from './Topic';

interface Props extends Pick<ModalProps, 'onClose' | 'testId'> {
  topicId: number;
}

export const Topic = ({ topicId, testId, onClose }: Props) => {
  const modalProps = {
    testId,
    onClose,
    width: '772px',
  };

  const { clientId } = useClient();
  const { isError, isLoading, data } = useClientGoalTopic(clientId, topicId);

  if (isLoading) {
    return <Loader {...modalProps} />;
  }

  if (isError || !data) {
    return <Error {...modalProps} />;
  }

  return <Component topic={data} {...modalProps} />;
};
