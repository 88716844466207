import { style } from '~/components/pages/client/Sections/Goals/Goals.style';
import { Header } from '~/components/pages/client/Sections/ExpandableTable/model';
import { ClientGoalsListResponse } from '~/model/Client/Goals';

export const parseApiResponse = ({ weeks, publishedAt }: ClientGoalsListResponse) => ({
  publishedAt,
  weeks: weeks as Week[],
});

export interface Habit {
  id: string;
  title: string;
}

export enum Dimension {
  Nutrition = 'NUTRITION',
  Fitness = 'FITNESS',
  Mind = 'MIND',
  Sleep = 'SLEEP',
  Beauty = 'BEAUTY',
  SexualWellness = 'SEXUAL WELLNESS',
  Longevity = 'LONGEVITY',
  ActionPlan = 'ACTION PLAN',
}

export interface Goal {
  id: string;
  goal: string;
  topic: string;
  topic_id: number;
  dimension: Dimension;
  completed: number;
  total: number;
  text: string;
  habits?: Habit[];
}

export interface Week {
  number: number;
  active: boolean;
  goals?: Goal[];
}

export const headerColumns: Header[] = [
  {
    title: 'Goal',
    width: '5fr',
  },
  {
    title: 'Topic',
    width: '3fr',
  },
  {
    title: 'Dimension',
    width: '1fr',
  },
  {
    title: 'Progress',
    width: '1fr',
  },
  {
    title: 'Action',
    className: style.headers.actions,
    width: '1fr',
  },
];
