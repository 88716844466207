import React, { PropsWithChildren, useCallback } from 'react';
import { useOuterClick, useEscClick } from '~/hooks';
import { TestableComponentProps } from '~/model/TestableComponentProps.interface';
import { Loader as MainLoader } from '~/components/common/Loader';
import { ModalContext, ModalPortal } from './ModalPortal';
import {
  Overlay,
  Wrapper,
  ModalWrapper,
  Close,
  HeaderWrapper,
  Title,
  Subtitle,
  Separator,
} from './Modal.style';

export interface Props extends TestableComponentProps {
  hasCloseButton?: boolean;
  closeOnOuterClick?: boolean;
  onClose: () => void;
  title?: string | JSX.Element;
  subtitle?: string;
  withHeaderSeparator?: boolean;
  width?: string;
}

const useCombinedRefs = (...refs) =>
  useCallback(
    (element) =>
      refs.forEach((ref) => {
        if (!ref) {
          return;
        }
        if (typeof ref === 'function') {
          // eslint-disable-next-line consistent-return
          return ref(element);
        }
        // eslint-disable-next-line no-param-reassign
        (ref as any).current = element;
      }),
    refs,
  );

const closeButton = ({ onClose, hasCloseButton }: Partial<Props>) => {
  if (!hasCloseButton) {
    return null;
  }

  return (
    <Close
      onClick={onClose}
      tabIndex={-1}
      onKeyDown={onClose}
      role="button"
      aria-label="Close modal"
    />
  );
};

const header = ({ title, subtitle, withHeaderSeparator, hasCloseButton }: Partial<Props>) => {
  if (!title) {
    return null;
  }

  return (
    <HeaderWrapper data-testid="modal-header">
      <div>
        <Title $width={hasCloseButton ? '95%' : '100%'}>{title}</Title>
      </div>
      {subtitle && <Subtitle>{subtitle}</Subtitle>}
      {withHeaderSeparator && <Separator data-testid="modal-header-separator" />}
    </HeaderWrapper>
  );
};

export const Modal = ({
  children,
  hasCloseButton = true,
  closeOnOuterClick = true,
  onClose,
  title,
  subtitle,
  withHeaderSeparator = false,
  testId,
  width,
}: PropsWithChildren<Props>) => {
  const escClickRef = useEscClick(() => onClose());
  const outerClickRef = useOuterClick(() => onClose());

  const { openModalCount } = React.useContext(ModalContext);

  return (
    <ModalPortal>
      <Overlay $openModalCount={openModalCount} />
      <Wrapper $openModalCount={openModalCount} data-testid={testId}>
        <ModalWrapper
          $width={width}
          ref={closeOnOuterClick ? useCombinedRefs(outerClickRef, escClickRef) : null}
        >
          {closeButton({ onClose, hasCloseButton })}
          {header({ title, subtitle, withHeaderSeparator, hasCloseButton })}
          <div>{children}</div>
        </ModalWrapper>
      </Wrapper>
    </ModalPortal>
  );
};

export const Loader = (props: Props) => (
  <Modal {...props}>
    <MainLoader center />
  </Modal>
);
export const Error = (props: Props) => (
  <Modal {...props}>
    <>Error has occured</>
  </Modal>
);
