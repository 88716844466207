import { useQuery } from 'react-query';
import { FoodLogItem } from '~/model/FoodLogItem';
import { model } from '../foodLogItem';

export const queryKey = (clientId: string, foodLogItemId: string) => [
  'client',
  clientId,
  'foodLog',
  foodLogItemId,
];

export const useGetFoodLogItem = (clientId: string, foodLogItemId: string) =>
  useQuery<FoodLogItem>(queryKey(clientId, foodLogItemId), () =>
    model.getFoodLogItem(clientId, foodLogItemId),
  );
