import React from 'react';
import { Feature } from './model';

export interface FeatureContextType {
  features: Feature[];
  isLoading?: boolean;
  isError?: boolean;
}

export const FeatureTogglesContext = React.createContext<FeatureContextType>({
  features: [],
  isLoading: true,
  isError: false,
});
