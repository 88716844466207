import { css } from '@emotion/css';

export const style = {
  error: css({
    display: 'block',
    fontSize: '10px',
    lineHeight: '15px',
  }),
  subnote: css({
    padding: '2px 10px 2px 16px',
  }),
  largeNote: css({
    padding: '10px 0 0 25px',
    fontSize: '12px',
    letterSpacing: '-0.2px',
  }),
};
