import * as React from 'react';
import { SVGProps } from 'react';
import { Size } from '../types';
interface Props extends SVGProps<SVGSVGElement> {
  size?: Size;
}
const SvgCart = (props: Props) => {
  const getSize = () => {
    if (props.size) {
      switch (props.size) {
        case 'small':
          return 20;

        case 'medium':
          return 24;

        case 'large':
          return 32;

        default:
          return null;
      }
    }
  };

  return (
    <svg
      width={getSize() || 24}
      height={getSize() || 24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        opacity={0.3}
        d="M12 4.562L7.768 9.64a1 1 0 11-1.536-1.28l5-6a1 1 0 011.536 0l5 6a1 1 0 11-1.536 1.28L12 4.562z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.28 9a1 1 0 00-1 1v8a3 3 0 003 3h13.44a3 3 0 003-3v-8a1 1 0 00-1-1H3.28zM12 12a1 1 0 00-1 1v4a1 1 0 102 0v-4a1 1 0 00-1-1zm-5.742 1.361a1 1 0 111.932-.518l1.035 3.864a1 1 0 11-1.932.518L6.258 13.36zm10.777-1.225a1 1 0 00-1.224.707l-1.036 3.864a1 1 0 101.932.518l1.036-3.864a1 1 0 00-.707-1.225z"
        fill="currentColor"
      />
    </svg>
  );
};

export default SvgCart;
