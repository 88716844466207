import React, { useEffect, useState } from 'react';
import { Button } from '~/components/common/Button';
import { Loader } from '~/components/common/Loader';
import { getToken } from '~/components/common/TokenProvider/utils';
import { OnboardingLinkParams, URL } from '~/model/Stripe/OnboardingLink';
import { getBasename } from '~/services/api';
import { ButtonWrapper, OnboardingHeader, OnboardingText, Separator } from './Payout.style';

const getOnboardingUrl = () => `${getBasename()}${URL.STRIPE_ONBOARDING_FLOW}`;

export const OnboardingStarted = () => {
  const [queryParams, setQueryParams] = useState<string>();

  useEffect(() => {
    const onboardingLinkParams: OnboardingLinkParams = {
      returnUrl: `${window.location.href}`,
      ut: `${getToken()}`,
    };

    setQueryParams(new URLSearchParams(onboardingLinkParams).toString());
  }, []);

  if (!queryParams) {
    return <Loader wrapperHeight="100%" center />;
  }

  return (
    <>
      <OnboardingHeader>Great, you are almost there!</OnboardingHeader>
      <OnboardingText>
        We are just waiting for Stripe to complete your account verification process.
      </OnboardingText>
      <OnboardingText>
        We will let you know as soon as the process is completed or you can click Check status to
        view the verification progress or provide any additional information if needed.
      </OnboardingText>
      <Separator />
      <ButtonWrapper>
        <Button size="large" href={`${getOnboardingUrl()}?${queryParams}`}>
          Check status
        </Button>
      </ButtonWrapper>
    </>
  );
};
