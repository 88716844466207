import { generatePath } from 'react-router-dom';
import { api } from '~/services/api';

const URL = {
  SCHEDULE_CONSULTATION: '/api/client/:clientId/consultation/schedule',
};

export const model = {
  scheduleConsultation: (clientId) =>
    api.post(generatePath(URL.SCHEDULE_CONSULTATION, { clientId })),
};
