import React, { useState } from 'react';
import { Formik } from 'formik';
import { toast } from 'react-toastify';

import { Separator } from '~/components/common/Modal/Modal.style';
import { Modal, Props as ModalProps } from '~/components/common/Modal/Modal';
import {
  FormikInput,
  FormikRichText,
  FormRow,
  FormRowInputWrapper,
  FormRowLabel,
} from '~/components/common/Form';
import { Button } from '~/components/common/Button';
import { FormFields, validationSchema } from './config';
import { ButtonsWrapper } from './ScheduleModal.style';

export interface Props extends ModalProps {
  initialValues: FormFields;
  onSubmit: (values: FormFields) => void;
  onPublish: () => void;
  onHide: () => void;
  isVisible: boolean;
}

export const ScheduleModal = ({
  onClose: handleClose,
  onSubmit,
  initialValues,
  onPublish,
  onHide,
  isVisible,
  ...otherProps
}) => {
  const [changed, setChanged] = useState(false);

  const handlePublishClick = (submitForm) => {
    if (changed) {
      submitForm();
    }

    onPublish();
  };

  return (
    <>
      <Modal title="Edit schedule" {...otherProps} onClose={handleClose}>
        <Formik
          initialValues={initialValues}
          onSubmit={async (values: FormFields, { setSubmitting }) => {
            try {
              await onSubmit(values);
              toast.success('You have successfully updated the schedule');
              handleClose();
            } catch (err: unknown) {
              toast.error(
                'Something went wrong. Please try again or contact support about this issue.',
              );
              setSubmitting(false);
            }
          }}
          validationSchema={validationSchema}
        >
          {({ submitForm, isSubmitting, setFieldValue }) => (
            <>
              <div>
                <FormRow $rowSpacing="15px">
                  <>
                    <FormRowLabel htmlFor="title">Title:</FormRowLabel>
                    <FormRowInputWrapper>
                      <FormikInput name="title" />
                    </FormRowInputWrapper>
                  </>
                </FormRow>
                <FormRow $rowSpacing="15px">
                  <>
                    <FormRowLabel htmlFor="note">Note:</FormRowLabel>
                    <FormRowInputWrapper>
                      <FormikRichText
                        name="note"
                        onChange={(data) => {
                          setChanged(true);
                          setFieldValue('note', data);
                        }}
                      />
                    </FormRowInputWrapper>
                  </>
                </FormRow>
              </div>
              <Separator $marginBottom="30px" />
              <ButtonsWrapper>
                <Button variant="danger" onClick={onHide} disabled={!isVisible || changed}>
                  Hide
                </Button>
                <Button
                  variant="primary"
                  onClick={() => handlePublishClick(submitForm)}
                  disabled={isVisible && !changed}
                >
                  Publish
                </Button>
                <Button variant="neutral" onClick={handleClose}>
                  Cancel
                </Button>
                <Button
                  onClick={submitForm}
                  variant="success"
                  isLoading={isSubmitting}
                  disabled={isSubmitting}
                >
                  Save
                </Button>
              </ButtonsWrapper>
            </>
          )}
        </Formik>
      </Modal>
    </>
  );
};
