import { Header } from '~/components/common/Table';

export const headerColumns: Header[] = [
  {
    title: 'Question',
    style: {
      width: '450px',
    },
  },
  {
    title: 'Answer',
    style: {
      minWidth: '200px',
    },
  },
  {
    title: 'Evaluation',
    style: {
      minWidth: '100px',
    },
  },
];
