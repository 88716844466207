import React, { PropsWithChildren } from 'react';
import { Navigate } from 'react-router-dom';
import { ROUTES } from '~/components/router';

import { TokenContext } from './TokenContext';
import { getToken } from './utils';

export const TokenProvider = ({ children }: PropsWithChildren<{}>) => {
  const token = getToken();

  if (!token) {
    return <Navigate to={ROUTES.LOGIN} replace />;
  }

  return (
    <TokenContext.Provider value={{ token }}>
      {children}
    </TokenContext.Provider>
  );
};
