import { useQuery } from 'react-query';
import {
  GetClientQuestionnaireListResponse,
  model as clientQuestionnaireModel,
} from '../questionnaire';

export const queryKey = (clientId: string) => ['client', `${clientId}`, 'questionnaire'];

export const useClientQuestionnaireList = (clientId: string, includeUnanswered?: boolean) =>
  useQuery<GetClientQuestionnaireListResponse>(queryKey(clientId), () =>
    clientQuestionnaireModel(clientId).getList(includeUnanswered),
  );
