import { generatePath } from 'react-router-dom';
import { api } from '~/services/api';

const URL = {
  SHOW: '/api/habit/:id',
  RECOMMENDATIONS_LIST: '/api/recommendation-habit',
  KEY_HABITS: '/api/client/:id/habit',
};

export interface Habit {
  title: string;
  description: string;
  icon?: string;
}

export interface HabitRecommendation {
  id: number;
  title: string;
}

export interface KeyHabit {
  id: number;
  title: string;
  goalCount: number;
  priority: number;
  isVisible: boolean;
}

export interface GetKeyHabitsResponse {
  updatedAt?: string;
  items: KeyHabit[];
}

export const model = {
  show: (id: string) => api.get<Habit>(generatePath(URL.SHOW, { id })),
  recommendationsList: () => api.get<HabitRecommendation[]>(URL.RECOMMENDATIONS_LIST),
  getKeyHabits: (id: string) => api.get<GetKeyHabitsResponse>(generatePath(URL.KEY_HABITS, { id })),
};
