import styled from '@emotion/styled';
import { colors } from '~/components/utils/colors';

export const FeaturedImageWrapper = styled.div`
  width: 260px;
  height: 230px;
  border-radius: 1rem;
  overflow: hidden;

  & img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

export const ImagesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;
`;

export const ImageWrapper = styled.div`
  aspect-ratio: 1;
  overflow: hidden;
  cursor: pointer;
  border-radius: 1rem;
  border: 1px solid ${colors.colorDisabledOne};

  &.featured {
    border-color: ${colors.colorPriBlueFour};
  }

  & img {
    width: 80px;
    height: 80px;
    object-fit: cover;
  }
`;
