import { ClientMealsSearchAndFilter, MealOptions, MealStatusOptions } from '~/model/Client/Meals';

export const initialValues = (): ClientMealsSearchAndFilter => ({
  term: '',
  filters: {
    meal: '',
    status: '',
  },
});

export const getMealOptions = () => [
  {
    title: MealOptions.BREAKFAST,
    value: MealOptions.BREAKFAST,
  },
  {
    title: MealOptions.LUNCH,
    value: MealOptions.LUNCH,
  },
  {
    title: MealOptions.DINNER,
    value: MealOptions.DINNER,
  },
  {
    title: MealOptions.SNACK,
    value: MealOptions.SNACK,
  },
];

export const getMealStatusOptions = () => [
  {
    title: MealStatusOptions.VISIBLE,
    value: MealStatusOptions.VISIBLE,
  },
  {
    title: MealStatusOptions.NOT_VISIBLE,
    value: MealStatusOptions.NOT_VISIBLE,
  },
  {
    title: MealStatusOptions.SKIPPED,
    value: MealStatusOptions.SKIPPED,
  },
];
