import { css } from '@emotion/css';
import styled from '@emotion/styled';
import { colors } from '~/components/utils/colors';
import { TableGridSpacing } from '~/components/pages/client/Sections/ExpandableTable/ExpandableTable.style';

export const style = {
  device: css({
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '21px',
    color: colors.colorPriOne,
  }),
  column: css({
    fontSize: '13px',
    lineHeight: '19px',
    fontWeight: 500,
    color: colors.colorGsThree,
  }),
  actions: css({
    placeSelf: 'self-end',
    display: 'flex',
    flexDirection: 'row',
    gap: '10px',
    alignSelf: 'auto',
  }),
};

export const DeviceRow = styled(TableGridSpacing)`
  color: ${colors.colorGsThree};
`;
