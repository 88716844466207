import React from 'react';
import { Activities } from './Activities';
import { KeyHabits } from './KeyHabits/container';
import { Notes } from './Notes';
import { Nutritions } from './Nutritions';

export const Overview = () => (
  <>
    <Notes />
    <div className="row">
      <div className="col-12 col-lg-6">
        <KeyHabits />
        <Nutritions />
      </div>
      <div className="col-12 col-lg-6">
        <Activities />
      </div>
    </div>
  </>
);
