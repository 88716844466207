import React from 'react';
import { Card, CardBody, CardHeader } from '~/components/common/Card';
import { Checkbox, Input, Photo, RichText } from '~/components/common/Form';

export const FormInputs = () => (
  <Card>
    <CardHeader title="Form Elements" />
    <CardBody>
      <Input name="basic" placeholder="Basic text field" />
      <Input name="label" label="Label of the field" placeholder="Basic text field" />
      <Input name="disabled" placeholder="Disabled text field with placeholder" readOnly disabled />
      <Checkbox name="i_dont_know" type="checkbox" label="this is a checkbox" />
      <Input
        name="with-note"
        readOnly
        disabled
        note="This is a note"
        value="Disabled text field with Value"
      />
      <Input name="error" label="label" note="this is a note" error="This is an error" />
      <RichText
        name="Rich Text"
        note="CKEditor note"
        error="CKEditor error"
        value="<h1>Inner html</h1>"
      />
      <Photo name="test" error="This is bad" />
    </CardBody>
  </Card>
);
