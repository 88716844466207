import * as React from 'react';
import { SVGProps } from 'react';
import { Size } from '../types';
interface Props extends SVGProps<SVGSVGElement> {
  size?: Size;
}
const SvgStop = (props: Props) => {
  const getSize = () => {
    if (props.size) {
      switch (props.size) {
        case 'small':
          return 20;

        case 'medium':
          return 24;

        case 'large':
          return 32;

        default:
          return null;
      }
    }
  };

  return (
    <svg
      width={getSize() || 18}
      height={getSize() || 18}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx={9} cy={9} r={8} stroke="currentColor" strokeWidth={2} />
      <path
        opacity={0.3}
        stroke="currentColor"
        strokeWidth={2}
        strokeLinecap="round"
        d="M4.113 4.443l10.23 9.444"
      />
    </svg>
  );
};

export default SvgStop;
