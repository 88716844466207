import { generatePath } from 'react-router-dom';
import { DeviceCategory } from '~/components/pages/client/Sections/Devices/model';
import { api } from '~/services/api';

const URL = {
  LIST: '/api/client/:clientId/device',
};

export const model = {
  getList: (clientId: number) => api.get<DeviceCategory[]>(generatePath(URL.LIST, { clientId })),
};
