import React from 'react';
import { Formik } from 'formik';
import { toast } from 'react-toastify';

import { Modal, Props as ModalProps } from '~/components/common/Modal/Modal';
import { Button } from '~/components/common/Button/Button';
import { Separator } from '~/components/common/Modal/Modal.style';
import { FormikInput } from '~/components/common/Form';
import { FormRowInputWrapper } from '~/components/common/Form/Layout/Layout.style';

import { isBackendValidationError, transformErrors } from '~/model/Error/transformApiErrors';
import { model, queryKey } from '~/model/InviteClient';
import { queryClient } from '~/services/api';

import {
  ButtonsWrapper,
  FormButtonsWrapper,
  FormFieldsWrapper,
  FormRowLabel,
} from './InviteClientModal.style';
import { FormFields, validationSchema } from './config';
import { Confirm } from './Confirm';
import { FormRow } from './Layout';
import { FormikRichText } from './RichText';

export interface Props extends ModalProps {
  initialValues: FormFields;
  onSubmit: (values: FormFields) => void;
}

export const InviteClientModal = ({
  onClose: handleClose,
  onSubmit,
  initialValues,
  ...otherProps
}) => {
  const [showConfirmationModal, setShowConfirmationModal] = React.useState(false);

  return (
    <>
      <Modal title="Invite a new client" {...otherProps} onClose={handleClose}>
        <Formik
          initialValues={initialValues}
          onSubmit={() => setShowConfirmationModal(true)}
          validationSchema={validationSchema}
        >
          {({ submitForm, isSubmitting, setSubmitting, setFieldValue, values, setErrors }) => (
            <>
              <FormFieldsWrapper>
                <FormRow $rowSpacing="15px">
                  <>
                    <FormRowLabel htmlFor="from">From:</FormRowLabel>
                    <FormRowInputWrapper>
                      <FormikInput name="from" disabled />
                    </FormRowInputWrapper>
                  </>
                </FormRow>
                <FormRow $rowSpacing="15px">
                  <>
                    <FormRowLabel htmlFor="to">To:</FormRowLabel>
                    <FormRowInputWrapper>
                      <FormikInput name="to" placeholder="Enter the client’s email here" />
                    </FormRowInputWrapper>
                  </>
                </FormRow>
                <FormRow $rowSpacing="15px">
                  <>
                    <FormRowLabel htmlFor="subject">Subject:</FormRowLabel>
                    <FormRowInputWrapper>
                      <FormikInput name="subject" placeholder="Enter the email subject here" />
                    </FormRowInputWrapper>
                  </>
                </FormRow>
                <FormRow $rowSpacing="15px">
                  <>
                    <FormRowLabel htmlFor="content">Text:</FormRowLabel>
                    <FormRowInputWrapper>
                      <FormikRichText
                        name="content"
                        note="If you edit and save a new version of the message, it will NOT be automatically updated when you change your Referral code or name. Make sure your name and Referral code are correct before sending!"
                        onChange={(data) => setFieldValue('content', data)}
                      />
                    </FormRowInputWrapper>
                  </>
                </FormRow>
                <FormRow $rowSpacing="15px">
                  <FormButtonsWrapper>
                    <Button
                      variant="neutral"
                      size="small"
                      onClick={async () => {
                        try {
                          const { subject, content } = await model.reset();
                          setFieldValue('subject', subject);
                          setFieldValue('content', content);
                          toast.success('The default message text has been restored!');
                        } catch (err: unknown) {
                          toast.error('Something went wrong, please try again!');
                        }
                      }}
                    >
                      Restore default message
                    </Button>
                    <Button
                      variant="success"
                      size="small"
                      onClick={async () => {
                        try {
                          await model.saveEmailTemplate({
                            subject: values.subject,
                            content: values.content,
                          });
                          await queryClient.refetchQueries(queryKey());
                          toast.success('Your new message text has been saved!');
                        } catch (err: unknown) {
                          toast.error('Something went wrong, please try again!');
                        }
                      }}
                    >
                      Save
                    </Button>
                  </FormButtonsWrapper>
                </FormRow>
              </FormFieldsWrapper>
              <Separator $marginBottom="30px" />
              <ButtonsWrapper>
                <Button variant="neutral" onClick={handleClose}>
                  Cancel
                </Button>
                <Button onClick={submitForm} isLoading={isSubmitting} disabled={isSubmitting}>
                  Send
                </Button>
              </ButtonsWrapper>

              {showConfirmationModal && (
                <Confirm
                  email={values.to}
                  onSubmit={async () => {
                    setShowConfirmationModal(false);
                    try {
                      await onSubmit(values);
                      toast.success('Your invitation has been sent!');
                      handleClose();
                    } catch (err: unknown) {
                      toast.error('Something went wrong while sending an email, please try again');
                      if (isBackendValidationError<FormFields>(err)) {
                        const transformedErrors = transformErrors<FormFields>(err);
                        setErrors(transformedErrors);
                        setSubmitting(false);
                      }
                    }
                  }}
                  onClose={() => {
                    setSubmitting(false);
                    setShowConfirmationModal(false);
                  }}
                />
              )}
            </>
          )}
        </Formik>
      </Modal>
    </>
  );
};
