import { generatePath } from 'react-router-dom';
import { Color } from '~/components/common/Label';
import { api } from '~/services/api';

export interface WidgetWeek {
  id: number;
  title: string;
}

export enum ChartType {
  Target = 'Target',
}

export enum ChartValueStatus {
  IsOnTrack = 'IsOnTrack',
  IsOffTrack = 'IsOffTrack',
  Neutral = 'Neutral',
}

export enum ChartColumnType {
  Day = 'Day',
  Week = 'Week',
  Month = 'Month',
}

export enum WidgetId {
  ActiveCalories = 3,
  Steps = 4,
  Sleep = 5,
  BloodPressue = 9,
  Exercise = 17,
}

export interface ChartLegend {
  label: string;
  colour: string;
}

export interface ChartValueColor {
  colour: string;
  value: number;
}

export interface ChartValue {
  date: number;
  formattedDate: string;
  value: number;
  values?: ChartValueColor[];
  status: ChartValueStatus;
}

export enum ChartUnit {
  Kcal = 'Kcal',
}

export type TargetType = number | BloodPressureTarget;

export interface BloodPressureTarget {
  systolic: number | null;
  diastolic: number | null;
  systolicLower: number | null;
  diastolicLower: number | null;
}

export interface Chart {
  target: TargetType;
  unit: ChartUnit;
  legend: ChartLegend[];
  values: ChartValue[];
  columnType: ChartColumnType;
}

export interface ChartSummaryHeader {
  value: number;
  // has other properties too, but none are used in the codebase
}

export interface ChartSummary {
  header: ChartSummaryHeader;
  unit: ChartUnit;
  values: ChartValue[];
}

export interface WidgetChartResponse {
  chartType: ChartType;
  chart: Chart;
  summary?: ChartSummary;
}

export interface WidgetHistoryDayData {
  time: string;
  value: number;
  status: string;
  colour: Color;
  systolic?: number;
  diastolic?: number;
  hr?: number;

  hrMin?: number;
  hrMax?: number;
  hrAvg?: number;
  duration?: number;
  calories?: number;
  steps?: number;

  deep?: number;
  rem?: number;
  light?: number;
  awake?: number;
  total?: number;
}

export interface WidgetHistoryWeek {
  diff: string;
  target: TargetType;
  days: WidgetHistoryDayData[];

  calories?: number;
  colour?: Color;
  duration?: number;
  steps?: number;
  status?: string;

  deep?: number;
  rem?: number;
  light?: number;
  awake?: number;
  total?: number;

  weekFrom: string;
  weekTo: string;
}

const URL = {
  WIDGET_WEEKS: '/api/client/:clientId/widgets/:widgetId',
  WIDGET_PERIOD_CHART: '/api/client/:clientId/widgets/:widgetId/:periodId',
  WIDGET_HISTORY: '/api/client/:clientId/widgets/:widgetId/12-week',
};

export const model = (clientId) => ({
  getWidgetWeeks: (widgetId) => api.get<WidgetWeek[]>(generatePath(URL.WIDGET_WEEKS, { clientId, widgetId })),
  getWidgetPeriodChart: (widgetId, periodId) => api.get<WidgetChartResponse>(generatePath(URL.WIDGET_PERIOD_CHART, { clientId, widgetId, periodId })),
  getWidgetHistory: (widgetId) => api.get<WidgetHistoryWeek[]>(generatePath(URL.WIDGET_HISTORY, { clientId, widgetId })),
});
