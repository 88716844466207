/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { InputHTMLAttributes, useEffect, useState } from 'react';
import clsx from 'clsx';
import { useField } from 'formik';

import { TestableComponentProps } from '~/model/TestableComponentProps.interface';
import { useOuterClick } from '~/hooks';
import { style } from './Dropdown.style';
import {
  DisplayText,
  StyledButtonDropdowWrapper,
  StyledDropdownButton,
  StyledDropdownList,
} from './DropdownMultiple.style';
import { DropdownMultipleItem } from './DropdownMultipleItem';
import { DropdownItemValue } from './DropdownItemValue';

export interface DropdownMultipleProps
  extends InputHTMLAttributes<HTMLSelectElement>,
    TestableComponentProps {
  name: string;
  label?: string;
  error?: string;
  note?: JSX.Element | string;
  closeOnClick?: boolean;
  value?: string[];
  options?: DropdownItemValue[];
}

export const DropdownMultiple = ({
  className,
  error,
  note,
  testId,
  value,
  options,
  name,
  ...otherProps
}: DropdownMultipleProps) => {
  const [open, setOpen] = useState(false);
  const [displayValue, setDisplayValue] = useState('');
  const ref = useOuterClick(() => setOpen(false));

  useEffect(() => {
    const displayVal = options
      ?.filter(({ value: optionValue }) => value?.includes(optionValue))
      .map((val) => val.title)
      .join(' | ');
    setDisplayValue(displayVal || '');
  }, [value]);

  return (
    <div className={clsx(style.wrapper)}>
      <div className={style.inputWrapper}>
        <StyledButtonDropdowWrapper data-testid={testId} ref={ref}>
          <StyledDropdownButton
            id={name}
            type="button"
            onClick={() => setOpen(!open)}
            className={clsx('form-control text-left', [{ open }], className, {
              'is-invalid': !!error,
            })}
            data-testid={`${testId}-button`}
          >
            <DisplayText data-testid={`${testId}-text`}>
              {displayValue || otherProps.placeholder}
            </DisplayText>
          </StyledDropdownButton>
          {open && (
            <StyledDropdownList data-testid={`${testId}-list`}>
              {options &&
                options.map((option) => (
                  <DropdownMultipleItem key={option.value} name={name} value={option} />
                ))}
            </StyledDropdownList>
          )}
        </StyledButtonDropdowWrapper>
        {error && (
          <small className={clsx('text-danger', style.subnote, style.error)}>{error}</small>
        )}
        {note && <small className={clsx('text-gsThree', style.subnote, style.note)}>{note}</small>}
      </div>
    </div>
  );
};

type FormikDropdownProps = Omit<DropdownMultipleProps, 'error'>;
export const FormikDropdownMultiple = ({ name, ...props }: FormikDropdownProps) => {
  const [field, meta] = useField({ id: name, name, ...props });

  return (
    <DropdownMultiple
      id={name}
      error={meta.touched && meta.error ? meta.error : undefined}
      {...field}
      {...props}
    />
  );
};
