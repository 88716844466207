import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import objectPath from "object-path";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_helpers";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { User } from "~/components/common/Icons/dist";
import { homeUrl } from "~/components/pages/error/config";

export function HeaderMobile() {
  const uiService = useHtmlClassService();

  const layoutProps = useMemo(() => ({
    headerLogo: uiService.getMobileLogo(),
    asideDisplay: objectPath.get(uiService.config, "aside.self.display"),
    headerMenuSelfDisplay:
      objectPath.get(uiService.config, "header.menu.self.display") === true,
    headerMobileCssClasses: uiService.getClasses("header_mobile", true),
    headerMobileAttributes: uiService.getAttributes("header_mobile")
  }), [uiService]);

  return (
    <>
      <div
        id="kt_header_mobile"
        className={`header-mobile align-items-center ${layoutProps.headerMobileCssClasses}`}
        {...layoutProps.headerMobileAttributes}
      >
        <a href={homeUrl}>
          <SVG
            src={layoutProps.headerLogo}
            className="logo"
            height="40px"
          />
        </a>

        <div className="d-flex align-items-center">
          {layoutProps.asideDisplay && (
            <>
              <button className="btn p-0 burger-icon burger-icon-left" id="kt_aside_mobile_toggle" type="button">
                <span />
              </button>
            </>
          )}

          {layoutProps.headerMenuSelfDisplay && (
            <>
              <button className="btn p-0 burger-icon ml-4" id="kt_header_mobile_toggle" type="button" >
                <span />
              </button>
            </>
          )}

          <button
            type="button"
            className="btn btn-hover-text-primary p-0 ml-2"
            id="kt_header_mobile_topbar_toggle"
          >
            <span className="svg-icon svg-icon-xl">
              <User />
            </span>
          </button>
        </div>
      </div>
    </>
  );
}
