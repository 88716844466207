import { Header } from '~/components/common/Table';

export const headerColumns: Header[] = [
  {
    title: 'Health marker',
    style: {
      paddingRight: 0,
    },
  },
  {
    title: 'This week',
  },
  {
    title: 'Target',
  },
  {
    title: 'Actions',
    style: {
      textAlign: 'end',
    }
  },
];
