import React, { PropsWithChildren } from 'react';
import { TestableComponentProps } from '~/model/TestableComponentProps.interface';
import { DropdownItemProps } from './DropdownItem'
import { useButtonDropdown } from './ButtonDropdownContext';
import { StyledDropdownSection, StyledDropdownSectionItem } from './DropdownSection.style';

export interface DropdownSectionProps extends TestableComponentProps {
  header: JSX.Element | string;
}

export const DropdownSection = ({
  children,
  testId,
  header,
}: PropsWithChildren<DropdownSectionProps>) => (
    <section data-testid={testId}>
      <StyledDropdownSection>
        {header}
      </StyledDropdownSection>
      {children}
    </section>
  );

  export const DropdownSectionItem = ({
    children,
    testId,
    onClick,
  }: PropsWithChildren<DropdownItemProps>) => {
    const { toggle, closeOnClick } = useButtonDropdown();
  
    const handleClick = (e) => {
      onClick(e);
  
      if (closeOnClick) {
        toggle();
      }
    };
  
    return (
      <StyledDropdownSectionItem
        data-testid={testId}
        onClick={handleClick}
        onKeyDown={handleClick}
        role="button"
        tabIndex={0}
      >
        {children}
      </StyledDropdownSectionItem>
    );
  };
  