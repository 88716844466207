import * as React from 'react';
import { SVGProps } from 'react';
import { Size } from '../types';
interface Props extends SVGProps<SVGSVGElement> {
  size?: Size;
}
const SvgProgress = (props: Props) => {
  const getSize = () => {
    if (props.size) {
      switch (props.size) {
        case 'small':
          return 20;

        case 'medium':
          return 24;

        case 'large':
          return 32;

        default:
          return null;
      }
    }
  };

  return (
    <svg
      width={getSize() || 33}
      height={getSize() || 32}
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 33 32"
      {...props}
    >
      <g
        clipPath="url(#progress_svg__a)"
        stroke="currentColor"
        strokeWidth={0.5}
        strokeMiterlimit={10}
      >
        <path d="M31.1 1.6c-.4-.5-1-.7-1.7-.7C28.1.9 27 2 27 3.3c0 .6.2 1.2.7 1.7.4.5 1 .7 1.7.7 1.3 0 2.4-1.1 2.4-2.4 0-.6-.2-1.2-.7-1.7zm-1.6 3.2c-.4 0-.8-.2-1.1-.4-.3-.3-.4-.7-.4-1.1 0-.8.7-1.5 1.5-1.5.4 0 .8.2 1.1.4.3.3.4.7.4 1.1-.1.8-.7 1.5-1.5 1.5zm-25.8 9c-1.3 0-2.4 1.1-2.4 2.4 0 1.3 1 2.4 2.4 2.4 1.3 0 2.4-1.1 2.4-2.4-.1-1.3-1.1-2.4-2.4-2.4zm-.1 3.9c-.8 0-1.5-.7-1.5-1.5s.7-1.5 1.5-1.5 1.5.7 1.5 1.5-.6 1.5-1.5 1.5zm9-12.8c-1.3 0-2.4 1.1-2.4 2.4 0 1.3 1 2.4 2.4 2.4 1.3 0 2.4-1.1 2.4-2.4 0-1.3-1.1-2.4-2.4-2.4zm0 3.9c-.8 0-1.5-.7-1.5-1.5s.7-1.5 1.5-1.5 1.5.7 1.5 1.5-.7 1.5-1.5 1.5zm6.5 2.5c-1.3 0-2.4 1.1-2.4 2.4 0 1.3 1 2.4 2.4 2.4 1.3 0 2.4-1.1 2.4-2.4-.1-1.3-1.1-2.4-2.4-2.4zm-.1 3.9c-.8 0-1.5-.7-1.5-1.5s.7-1.5 1.5-1.5 1.5.7 1.5 1.5-.6 1.5-1.5 1.5z" />
        <path d="M27.815 4.35l-7.697 7.718.637.635 7.698-7.717-.638-.636zM14.326 8.312l-.636.637 3.748 3.747.636-.636-3.748-3.748zm-3.412.014L4.69 14.548l.637.636 6.222-6.222-.636-.636zM26.4 9.9v21.5h5.4V9.9h-5.4zm.9 20.7V10.8h3.6v19.8h-3.6zM18.1 19v12.5h5.4V19h-5.4zm4.5 11.6H19V19.9h3.6v10.7zM9.7 18.3v13.1h5.4V18.3H9.7zm4.5 12.3h-3.7V19.2h3.6v11.4h.1zm-12.9-9v9.9h5.4v-9.9H1.3zm4.5 9H2.2v-8.2h3.6v8.2z" />
      </g>
      <defs>
        <clipPath id="progress_svg__a">
          <path transform="translate(.6)" d="M0 0h32v32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SvgProgress;
