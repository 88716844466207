import React, { PropsWithChildren } from 'react';
import { PlainTabs } from './PlainTabs';
import { CardTabs } from './CardTabs';
import { FloatingTabs } from './FloatingTabs';

type Variant = 'plain' | 'card' | 'floating';

interface Props extends PropsWithChildren<any> {
  variant?: Variant;
  activeTab?: string;
}

export const Tabs = ({ variant, activeTab, children }: Props) => {
  const visibleChildren = children.filter(Boolean);

  switch (variant) {
    case 'card':
      return <CardTabs activeTab={activeTab}>{visibleChildren}</CardTabs>;
    case 'floating':
      return <FloatingTabs activeTab={activeTab}>{visibleChildren}</FloatingTabs>;
    case 'plain':
    default:
      return <PlainTabs>{visibleChildren}</PlainTabs>;
  }
};
