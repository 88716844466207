import * as React from 'react';
import { SVGProps } from 'react';
import { Size } from '../types';
interface Props extends SVGProps<SVGSVGElement> {
  size?: Size;
}
const SvgCogwheel = (props: Props) => {
  const getSize = () => {
    if (props.size) {
      switch (props.size) {
        case 'small':
          return 20;

        case 'medium':
          return 24;

        case 'large':
          return 32;

        default:
          return null;
      }
    }
  };

  return (
    <svg
      width={getSize() || 24}
      height={getSize() || 24}
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        opacity={0.3}
        d="M18.622 9.75h.128a2.25 2.25 0 010 4.5h-.064a.488.488 0 00-.447.295.488.488 0 00.089.54l.044.044a2.25 2.25 0 11-3.182 3.184l-.04-.04a.49.49 0 00-.545-.094.487.487 0 00-.295.444v.127a2.25 2.25 0 01-4.5 0 .524.524 0 00-.363-.514.485.485 0 00-.532.092l-.044.044a2.25 2.25 0 11-3.184-3.182l.04-.04a.49.49 0 00.094-.545.487.487 0 00-.443-.295H5.25a2.25 2.25 0 010-4.5.524.524 0 00.514-.363.485.485 0 00-.092-.532l-.045-.044A2.25 2.25 0 118.81 5.687l.04.04a.488.488 0 00.537.097l.108-.023a.486.486 0 00.255-.423V5.25a2.25 2.25 0 014.5 0v.065c0 .194.117.37.303.449.182.08.395.042.532-.092l.044-.045a2.25 2.25 0 113.184 3.183l-.04.04a.488.488 0 00-.097.537l.023.108a.486.486 0 00.423.255z"
      />
      <path fillRule="evenodd" clipRule="evenodd" d="M12 15a3 3 0 100-6 3 3 0 000 6z" />
    </svg>
  );
};

export default SvgCogwheel;
