import React from 'react';
import { StyledDropdown } from './Dropdown.style';

const selectOptions = [5, 10, 15].map((number) => ({ label: `${number}`, value: number }));

export interface DropdownProps {
  onChange: (value: number) => void;
  itemsPerPage: number;
}

export const Dropdown = ({ onChange, itemsPerPage }: DropdownProps) => {
  const handleChange = (e) => {
    e.preventDefault();
    onChange(+e.target.value);
  };

  return (
    <StyledDropdown
      onChange={handleChange}
      value={itemsPerPage}
      aria-label="Select amount of items per page to show"
    >
      {selectOptions.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </StyledDropdown>
  );
};
