import React, { useContext } from 'react';
import { Country } from '~/model/Country';

export interface SettingsContextType {
  countries: Country[];
  isLoading: boolean;
  isError: boolean;
}

export const SettingsContext = React.createContext<SettingsContextType>(undefined!);

export const useSettingsContext = () => useContext(SettingsContext);
