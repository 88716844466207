import { HttpStatusCode } from '~/types/HttpStatusCode';

export enum ErrorCode {
  ValidationFailed = 'ValidationFailed',
  Exception = 'Exception',
  AppleHealthDisabled = 'AppleHealthDisabled',
  PaymentFailed = 'PaymentFailed',
  OutdatedAppVersion = 'OutdatedAppVersion',
  UserDidNotAgreedWithTerms = 'UserDidNotAgreedWithTerms',
  NoActiveSubscriptions = 'NoActiveSubscriptions',
  NoWelcomePack = 'NoWelcomePack',
  GoogleFitDisabled = 'GoogleFitDisabled',
  GoogleFitInvalidEmail = 'GoogleFitInvalidEmail',
  NotFound = 'NotFound',
}

export enum ValidationCode {
  GenericCode = 'GenericCode',
  ValueBlank = 'ValueBlank',
  ValueWrongFormat = 'ValueWrongFormat',
  ValueTaken = 'ValueTaken',
  ValueNotLessThan = 'ValueNotLessThan',
  ValueNotMoreThan = 'ValueNotMoreThan',
  ValueOutOfRange = 'ValueOutOfRange',
  UsernameAndPasswordDoNotMatch = 'UsernameAndPasswordDoNotMatch',
  MissingNumberOrSymbol = 'MissingNumberOrSymbol',
  TooManyNumbersOrSymbols = 'TooManyNumbersOrSymbols',
  MissingLowercase = 'MissingLowercase',
  TooManyLowercaseLetters = 'TooManyLowercaseLetters',
  MissingSpecialCharacter = 'MissingSpecialCharacter',
  TooManySpecialCharacters = 'TooManySpecialCharacters',
  NotFound = 'NotFound',
}

export interface ValidationError {
  field: string;
  code: ValidationCode;
  message: string;
}

export interface BackendErrorResponse {
  code: HttpStatusCode;
  errorCode: ErrorCode;
  message: string;
}

export interface BackendFormError {
  errors: ValidationCode[];
}

export interface BackendFormErrors<T extends Record<string, any>> {
  // when T = { name: string }, the type results to { children: { name: { errors: [] }} }
  children: Record<keyof T, BackendFormError>;
}

export interface BackendValidationErrorResponse<T extends Record<string, any>>
  extends BackendErrorResponse {
  errors: BackendFormErrors<T>;
  validationErrors: ValidationError[];
}
