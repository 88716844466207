import React, { useState } from 'react';
import { Activity as Model, ActivityValue } from '~/model/Client/Activities';
import { StrongColumn } from '~/components/common/Table';
import { getVariantFromColor } from '~/components/common/Label';
import { IconButton } from '~/components/common/Button';
import { Edit2 } from '~/components/common/Icons/dist';
import { ActionButtonWrapper } from '../../../Sections.style';
import { Modal as ActivityWidgetModal } from '../../../Activities/Activity/Modal';
import { Row, Column } from '../../../Activities/Activity/Activity.style';

export interface Props {
  activity: Model;
  index: number;
}

const buildValue = (activityValue?: ActivityValue, activityValueDiff?: ActivityValue) => {
  if (!activityValue) {
    return null;
  }

  const { color, value } = activityValue;

  return (
    <>
      <span className={`text-${getVariantFromColor(color)}`}>{value}</span>
      {activityValueDiff && (
        <small>
          &nbsp;(
          <span className={`text-${getVariantFromColor(activityValueDiff.color)}`}>
            {activityValueDiff.value}
          </span>
          )
        </small>
      )}
    </>
  );
};

const buildValues = ({ firstValue, firstValueDiff, secondValue, secondValueDiff }: Model) => {
  if (firstValue || firstValue === 0) {
    return (
      <>
        {buildValue(firstValue, firstValueDiff)}
        {secondValue && (
          <>{buildValue({ ...secondValue, value: `/${secondValue.value}` }, secondValueDiff)}</>
        )}
      </>
    );
  }

  return 'N/A';
};

const buildTargetValue = ({ firstValueTarget, secondValueTarget }: Model) => {
  if (!firstValueTarget && !secondValueTarget) {
    return 'N/A';
  }

  return [firstValueTarget, secondValueTarget].filter((value) => !!value).join('/');
};

export const Activity = ({ activity }: Props) => {
  const [activityWidgetId, setActivityWidgetId] = useState<number | null>(null);

  return (
    <>
      <Row data-testid="activity-main-row">
        <StrongColumn>{activity.title}</StrongColumn>
        <Column>{buildValues(activity)}</Column>
        <Column>{buildTargetValue(activity)}</Column>
        <td>
          <ActionButtonWrapper>
            <IconButton
              aria-label="Show"
              shade="light"
              icon={<Edit2 />}
              disabled={!activity.widgetId}
              onClick={() => setActivityWidgetId(activity.widgetId)}
            />
          </ActionButtonWrapper>
        </td>
      </Row>
      {activityWidgetId && (
        <ActivityWidgetModal
          widgetId={activityWidgetId}
          title={activity.title}
          onClose={() => setActivityWidgetId(null)}
        />
      )}
    </>
  );
}
