import React, { PropsWithChildren } from 'react';
import styled from '@emotion/styled';
import { TestableComponentProps } from '~/model/TestableComponentProps.interface';
import { colors } from '~/components/utils/colors';
import { useButtonDropdown } from './ButtonDropdownContext';

export interface DropdownItemProps extends TestableComponentProps {
  onClick: (event: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>) => void;
}

export const StyledDropdownItem = styled.div`
  padding: 10px 16px;
  color: ${colors.colorGsThree};

  &:first-of-type {
    border-radius: 6px 6px 0 0;
  }
  &:last-of-type {
    border-radius: 0 0 6px 6px;
  }

  &:hover {
    background-color: ${colors.colorDisabledTwo};
  }
`;

export const DropdownItem = ({
  children,
  testId,
  onClick,
}: PropsWithChildren<DropdownItemProps>) => {
  const { toggle, closeOnClick } = useButtonDropdown();

  const handleClick = (e) => {
    onClick(e);

    if (closeOnClick) {
      toggle();
    }
  };

  return (
    <StyledDropdownItem
      data-testid={testId}
      onClick={handleClick}
      onKeyDown={handleClick}
      role="button"
      tabIndex={0}
    >
      {children}
    </StyledDropdownItem>
  );
};
