/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation, NavLink, generatePath } from "react-router-dom";
import { checkIsActive } from "../../../../_helpers";

import { ROUTES } from '~/components/router';
import { UsersTwo, Layers, Chat, Notes, Cart2 } from "~/components/common/Icons/dist";
import { useCometChat } from "~/components/pages/cometChat/CometChatProvider";

export function AsideMenuList({ layoutProps }) {
    const location = useLocation();
    const { unreadMessagesCount } = useCometChat();
    const getMenuItemActive = (url, hasSubmenu = false) => checkIsActive(location, url)
        ? ` ${!hasSubmenu &&
        "menu-item-active"} menu-item-open menu-item-not-hightlighted`
        : "";

    return (
        <>
            {/* begin::Menu Nav */}
            <ul className={`menu-nav ${layoutProps.ulClasses}`}>
                {/* begin::1 Level */}
                <li
                    className={`menu-item ${getMenuItemActive(ROUTES.CLIENTS, false)}`}
                    aria-haspopup="true"
                >
                    <NavLink className="menu-link" to={ROUTES.CLIENTS}>
                        <span className="svg-icon menu-icon">
                            <UsersTwo />
                        </span>
                        <span className="menu-text">Clients</span>
                    </NavLink>
                </li>
                {/* end::1 Level */}

                {/* begin::1 Level */}
                <li
                    className={`menu-item ${getMenuItemActive(ROUTES.NOTES, false)}`}
                    aria-haspopup="true"
                >
                    <NavLink className="menu-link" to={ROUTES.NOTES}>
                        <span className="svg-icon menu-icon">
                            <Notes />
                        </span>
                        <span className="menu-text">Notes</span>
                    </NavLink>
                </li>
                {/* end::1 Level */}

                <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                        generatePath(ROUTES.CHAT_HOME),
                        true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                >
                    <NavLink className="menu-link" to={ROUTES.CHAT_HOME}>
                        <span className="svg-icon menu-icon">
                            <Chat />
                        </span>
                        <span className="menu-text">Chat</span>
                        {unreadMessagesCount > 0 && <span className="menu-label">
                            <span className="label label-danger label-inline">new</span>
                        </span>}
                    </NavLink>
                </li>

                <li
                    className={`menu-item ${getMenuItemActive(ROUTES.MARKETPLACE, false)}`}
                    aria-haspopup="true"
                >
                    <NavLink className="menu-link" to={ROUTES.MARKETPLACE}>
                        <span className="svg-icon menu-icon">
                            <Cart2 />
                        </span>
                        <span className="menu-text">Marketplace</span>
                    </NavLink>
                </li>
            </ul>

            {/* end::Menu Nav */}
        </>
    );
}
