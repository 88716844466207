import * as React from 'react';
import { SVGProps } from 'react';
import { Size } from '../types';
interface Props extends SVGProps<SVGSVGElement> {
  size?: Size;
}
const SvgGpsMarker16 = (props: Props) => {
  const getSize = () => {
    if (props.size) {
      switch (props.size) {
        case 'small':
          return 20;

        case 'medium':
          return 24;

        case 'large':
          return 32;

        default:
          return null;
      }
    }
  };

  return (
    <svg
      width={getSize() || 16}
      height={getSize() || 16}
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.373 13.165C5.92 11.833 3.333 9.158 3.333 7c0-3 2-5 5-5s5 2.5 5 5c0 1.816-2.637 4.686-4.08 6.122-.522.518-1.34.54-1.88.043zM10 6.333a1.667 1.667 0 11-3.333 0 1.667 1.667 0 013.333 0z"
      />
    </svg>
  );
};

export default SvgGpsMarker16;
