import React, { useLayoutEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useSubheader } from '~/vendor/_metronic/layout';
import { Layout } from '~/components/common/Layout';
import { ClientContextProvider } from '~/components/pages/client/context';
import { ClientSummary } from '~/components/common/ClientSummary/container';
import { Route as Sections } from './Sections/route';


export const ClientProfile = () => {
  const subheader = useSubheader();
  useLayoutEffect(() => {
    subheader.setTitle('Profile');
  });

  return (
    <Layout testId="client-profile-screen">
      <ClientSummary showProfileButton={false} />
      <Sections />
    </Layout>
  );
};

export const ClientProfileRoute = () => {
  const { id } = useParams();

  return (
    <ClientContextProvider clientId={id!}>
      <ClientProfile />
    </ClientContextProvider>
  );
}