import { useQuery } from 'react-query';
import {
  GetClientQuestionAnswerResponse,
  model as clientQuestionnaireModel,
} from '../questionnaire';

export const queryKey = (clientId: string, answerId: string) => [
  'client',
  `${clientId}`,
  'questionnaire',
  'answer',
  `${answerId}`,
];

export const useClientQuestionAnswer = (clientId: string, answerId: string) =>
  useQuery<GetClientQuestionAnswerResponse>(queryKey(clientId, answerId), () =>
    clientQuestionnaireModel(clientId).getAnswer(answerId),
  );
