import React, { PropsWithChildren } from 'react';

import { Loader } from '~/components/common/Loader';
import { useCountries } from '~/model/Country';

import { SettingsContext } from './SettingsContext';

export const SettingsProvider = ({ children }: PropsWithChildren<{}>) => {
  const { isLoading, isError, data: countries } = useCountries();

  if (isLoading) {
    return <Loader wrapperHeight="100%" center />;
  }

  if (isError || !countries) {
    return <div>Something went wrong while loading coach</div>;
  }

  return (
    <SettingsContext.Provider value={{ countries, isLoading, isError }}>
      {children}
    </SettingsContext.Provider>
  );
};
