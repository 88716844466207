import React, { useState } from 'react';
import { DateTime } from 'luxon';

import clsx from 'clsx';
import { IconButton } from '~/components/common/Button';
import { Edit2 } from '~/components/common/Icons/dist';

import { FoodLogItemModal } from './FoodLogItemModal';
import { FoodLogTableRowItem, Row, style } from './FoodLogItem.style';
import { headerColumns, LogEntry } from '../model';
import { RowHeaderButton } from '../../Sections.style';

export interface Props {
  date?: string;
  entry: LogEntry;
}

export const valueOrNA = (value?: string | number, postfix: string = 'g'): string =>
  value ? `${value}${postfix}` : 'N/A';

export const round = (value?: string | number, decimals: number = 1) =>
  value ? (+value).toFixed(decimals).replace(new RegExp(`[.,]0{0,${decimals}}$`), '') : undefined;

const dayOfWeek = (date: string) => DateTime.fromISO(date).weekdayShort;
const time = (date: string) => DateTime.fromISO(date).toFormat('HH:mm');

export const FoodLogItem = ({
  date,
  entry: { id, meal, amount, kcal, salt, fiber, sugar, saturated_fat },
}: Props) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <FoodLogTableRowItem className={clsx({ group: Boolean(date) })}>
      <Row $widths={headerColumns.map(({ width }) => width || '1fr')}>
        <span>{date && dayOfWeek(date)}</span>
        <span>{date && time(date)}</span>
        <strong className={style.meal}>
          <RowHeaderButton onClick={() => setShowModal(true)}>
            {meal}
          </RowHeaderButton>
        </strong>
        <span>{amount || 'N/A'}</span>
        <span>{valueOrNA(round(kcal, 0), 'kcal')}</span>
        <span>{valueOrNA(round(fiber))}</span>
        <span>{valueOrNA(round(saturated_fat, 1))}</span>
        <span>{valueOrNA(round(sugar, 1))}</span>
        <span>{valueOrNA(round(salt, 1))}</span>
        <div className={style.actions}>
          <IconButton
            shade="light"
            aria-label="Show meal"
            icon={<Edit2 />}
            onClick={() => setShowModal(true)}
          />
        </div>
      </Row>
      {showModal && (
        <FoodLogItemModal
          testId="food-log-item-modal"
          onClose={() => setShowModal(false)}
          foodLogItemId={id}
        >
          <div />
        </FoodLogItemModal>
      )}
    </FoodLogTableRowItem>
  );
};
