import React, { useContext } from 'react';

interface ButtonDropdownContextType {
  open: boolean;
  toggle: () => void;
  closeOnClick?: boolean;
}

export const ButtonDropdownContext = React.createContext<ButtonDropdownContextType>({
  open: false,
  toggle: () => {},
  closeOnClick: false,
});

export const useButtonDropdown = () => useContext(ButtonDropdownContext);
