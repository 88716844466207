import { useQuery } from 'react-query';
import { GetNotesListResponse } from '~/model/Notes/notes';
import { model as clientNoteModel } from '../notes';

export const clientNotesQueryKey = (clientId) => ['client', clientId, 'note'];

export const useClientNotes = (clientId, currentPage, notesPerPage) =>
  useQuery<GetNotesListResponse>([clientNotesQueryKey(clientId), currentPage, notesPerPage], () =>
    clientNoteModel(clientId).getList(currentPage, notesPerPage),
  );
