import React from 'react';
import { generatePath, useParams } from 'react-router-dom';
import { Tab, TabConfig, Tabs } from '~/components/common/Tabs';
import { Bucket, Diagnostics, FourBlocks, Watch } from '~/components/common/Icons/dist';
import { ROUTES } from '~/components/router';
import { Supplements } from '../../client/Sections/Supplements';
import { TestKits } from '../../client/Sections/TestKits';
import { Devices } from '../../client/Sections/Devices';

export const MarketplaceTabs = () => {
  const { tab: activeTab } = useParams();

  const TABS: TabConfig[] = [
    {
      id: 'services',
      title: 'Services',
      icon: <FourBlocks />,
      content: <></>,
      enabled: false,
    },
    {
      id: 'supplements',
      title: 'Supplements',
      icon: <Bucket />,
      content: <Supplements />,
      enabled: true,
    },
    {
      id: 'test-kits',
      title: 'Test Kits',
      icon: <Diagnostics />,
      content: <TestKits />,
      enabled: true,
    },
    {
      id: 'devices',
      title: 'Devices',
      icon: <Watch />,
      content: <Devices />,
      enabled: true,
    },
  ];

  return (
    <Tabs variant="floating" activeTab={activeTab}>
      {TABS.filter((t) => t.enabled).map((tab) => (
        <Tab
          key={tab.id}
          id={tab.id}
          testId={`${tab.id}-tab`}
          path={generatePath(ROUTES.MARKETPLACE_TABBED, { tab: tab.id })}
          icon={tab.icon}
          title={tab.title}
        >
          {tab.content}
        </Tab>
      ))}
    </Tabs>
  );
}
