import { useContext } from 'react';
import { FeatureTogglesContext } from './context';
import { Feature } from './model';

export const useFeatureToggle = (id: Feature) => {
  const { features, isLoading, isError } = useContext(FeatureTogglesContext);

  return {
    isLoading,
    isError,
    isFeatureEnabled: () => Boolean(features.find((f) => f === id)),
  };
};
