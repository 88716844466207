import React, { useEffect } from 'react';
import {
  ConfirmationModal,
  Props as ConfirmationModalProps,
} from '~/components/common/ConfirmationModal';
import { usePreviewPayment } from '~/model/Payment';
import { CoachProduct } from '~/model/Product';
import { Loader } from '../../Loader';
import { ModalContentWrapper, ServicesList, TotalPriceWrapper } from './OfferServiceModal.style';

export interface Props extends ConfirmationModalProps {
  products: CoachProduct[];
  customerName: string;
  onSubmit: () => void;
  onClose: () => void;
}

const Error = () => <div>Could not retrieve data</div>;

const description = (customerName, products, paymentPreview) => (
  <ModalContentWrapper>
    <p>This will recommend {customerName} to purchase the following services:</p>
    <ServicesList>
      {products.map((product) => (
        <li key={product.id}>{product.name}</li>
      ))}
    </ServicesList>
    <div>
      {paymentPreview?.formatted_vat && (
        <TotalPriceWrapper>
          VAT/GST: {paymentPreview?.formatted_vat}
        </TotalPriceWrapper>
      )}
      <TotalPriceWrapper><strong>Total price: {paymentPreview.formatted_total_price}</strong></TotalPriceWrapper>
      {paymentPreview.formatted_coach_commissions && (
        <TotalPriceWrapper>
          Your commissions: {paymentPreview.formatted_coach_commissions}
        </TotalPriceWrapper>
      )}
    </div>
  </ModalContentWrapper>
);

export const OfferServiceModal = ({
  onClose: handleClose,
  customerName,
  customerId,
  products,
  onSubmit,
}) => {
  const paymentPreviewMutation = usePreviewPayment();

  useEffect(() => {
    paymentPreviewMutation.mutate({
      products: products.map((product) => product.id),
      client: customerId,
    });
  }, []);

  const { isLoading, isError, data } = paymentPreviewMutation;

  const confirmationModalProps: Props = {
    title: 'Are you sure?',
    closeButtonText: 'No, cancel',
    submitButtonText: 'Yes, send!',
    isLoading,
    onClose: () => {
      handleClose();
    },
    onSubmit: () => {
      onSubmit();
      handleClose();
    },
    customerName,
    products,
  };

  if (isLoading) {
    return (
      <ConfirmationModal {...confirmationModalProps} description={<Loader size="small" center />} />
    );
  }

  if (isError || !data) {
    return <ConfirmationModal {...confirmationModalProps} description={<Error />} />;
  }

  return (
    <ConfirmationModal
      {...confirmationModalProps}
      description={description(customerName, products, data)}
    />
  );
};
