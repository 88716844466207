import * as React from 'react';
import styled from '@emotion/styled';

interface ChevronProps extends React.SVGProps<SVGSVGElement> {
  direction: 'left' | 'right' | 'up' | 'down';
}

const DEG_BY_DIR = {
  left: '-45deg',
  right: '45deg',
  up: '180deg',
  down: '0deg',
};

const StyledWrapper = styled.div<{ $dir: 'left' | 'right' | 'up' | 'down' }>`
  ${({ $dir }) => `transform: rotate(${DEG_BY_DIR[$dir]})`}
`;

function SvgArrowDown({ direction, ...otherProps }: ChevronProps) {
  return (
    <StyledWrapper $dir={direction}>
      <svg
        width={16}
        height={16}
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
        {...otherProps}
      >
        <path d="M4.338 5.816a.667.667 0 10-.943.943l4 4a.667.667 0 00.922.02l4.011-4.02a.667.667 0 00-.9-.984l-3.541 3.59-3.549-3.549z" />
      </svg>
    </StyledWrapper>
  );
}

export default SvgArrowDown;
