import { Form, Formik, FormikProps } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';
import { css } from '@emotion/css';
import { useQueryClient } from 'react-query';
import { Button } from '~/components/common/Button';
import { CardHeaderTitle } from '~/components/common/Card';
import {
  FormikDropdown,
  FormikInput,
  FormRowInputWrapper,
  FormRow,
} from '~/components/common/Form';
import { FormikRadio } from '~/components/common/Form/Input/Radio';
import { Loader } from '~/components/common/Loader';
import { BillingDetails, BillingType } from '~/model/Profile/coach';
import { URL, LoginLinkParams } from '~/model/Stripe/OnboardingLink';
import { getBasename } from '~/services/api';
import { useSettingsContext } from '~/components/common/SettingsProvider';
import { toast } from 'react-toastify';
import { getToken } from '~/components/common/TokenProvider/utils';
import { Tooltip } from '~/components/common/Tooltip';
import { Info } from '~/components/common/Icons/dist';
import { Partner, getPartner } from '~/components/utils/helpers/partner';
import { useCoachContext } from '../../CoachProvider';
import {
  ButtonWrapper,
  LabelText,
  OnboardingHeader,
  OnboardingText,
  Separator,
} from './Payout.style';
import { FormFields, initialValues, toApiRequest } from './config';
import {
  coachBillingDetailsQueryKey,
  useCoachBillingDetails,
  useUpdateCoachBillingDetails,
} from './hooks';
import { FormRowLabel } from './Label';
import { BankDetailsIndividual } from './BankDetailsIndividual';
import { BankDetailsBusiness } from './BankDetailsBusiness';

const getLoginUrl = () => `${getBasename()}${URL.STRIPE_LOGIN_LINK}`;

const getCountryListDropdown = (countries) => (
  <FormikDropdown name="country" placeholder="Select a country">
    <option value="">Select a country</option>
    {countries.map(({ name, iso }) => (
      <option key={iso} value={iso}>
        {name}
      </option>
    ))}
  </FormikDropdown>
);

export const Onboarded = () => {
  const [queryParams, setQueryParams] = useState<string>();
  const { coach } = useCoachContext();
  const { countries } = useSettingsContext();
  const queryClient = useQueryClient();
  const { data: coachBillingDetails, isError } = useCoachBillingDetails(coach.id);
  const mutation = useUpdateCoachBillingDetails(coach.id, {
    onSuccess: () => {
      queryClient.invalidateQueries(coachBillingDetailsQueryKey(coach.id));
    },
  });
  const partner = getPartner();
  const isHei = useMemo(() => partner === Partner.HEI, [partner]);

  if (!coach.code) {
    return (
      <>
        <OnboardingText>There has been a problem with setting up your account</OnboardingText>
      </>
    );
  }

  useEffect(() => {
    const loginLinkQueryParams: LoginLinkParams = {
      redirectUrl: `${window.location.href}`,
      ut: `${getToken()}`,
    };

    setQueryParams(new URLSearchParams(loginLinkQueryParams).toString());
  }, []);

  if (!queryParams || !coachBillingDetails) {
    return <Loader wrapperHeight="100%" center />;
  }

  if (isError) {
    return <>Error has occured</>;
  }

  return (
    <>
      <Formik
        initialValues={initialValues(coachBillingDetails)}
        onSubmit={(values, actions) => {
          const data = toApiRequest(values);
          mutation.mutate(data, {
            onSettled: async () => {
              queryClient.setQueryData(coachBillingDetailsQueryKey(coach.id), (oldData) => ({
                ...(oldData as BillingDetails),
                ...data,
              }));
              actions.setSubmitting(false);
            },
            onError: () => {
              toast.error('Something went wrong... Please try again.');
            },
          });
        }}
      >
        {({ isSubmitting, values }: FormikProps<FormFields>) => (
          <Form>
            <OnboardingHeader>Your payments are all set up!</OnboardingHeader>
            {!isHei && (
              <OnboardingText>
                You can keep track of your financial details by clicking the Stripe dashboard button
                at the bottom.
              </OnboardingText>
            )}
            <OnboardingText>
              Please provide us with some information to let us know if you are a VAT payer:
            </OnboardingText>
            <div>
              <FormRowInputWrapper>
                <LabelText>
                  Will you be providing your services as an individual or a business?
                </LabelText>
                <FormikRadio
                  name="type"
                  value={`${BillingType.TYPE_INDIVIDUAL}`}
                  label="Individual"
                  checked={`${values.type}` === `${BillingType.TYPE_INDIVIDUAL}`}
                />
                <FormikRadio
                  name="type"
                  value={`${BillingType.TYPE_BUSINESS}`}
                  label="Business"
                  checked={`${values.type}` === `${BillingType.TYPE_BUSINESS}`}
                />
              </FormRowInputWrapper>
            </div>
            {values.type && (
              <>
                <OnboardingText className={css({ marginTop: '60px' })}>
                  Please provide us with some additional information for invoicing purposes:
                </OnboardingText>
                <div className={css({ padding: '30px 30px 0 0' })}>
                  {`${values.type}` === `${BillingType.TYPE_BUSINESS}` && (
                    <div className={css({ marginBottom: '60px' })}>
                      <FormRow>
                        <>
                          <FormRowLabel />
                          <CardHeaderTitle>Company details</CardHeaderTitle>
                        </>
                      </FormRow>
                      <FormRow>
                        <>
                          <FormRowLabel>Company name</FormRowLabel>
                          <FormRowInputWrapper>
                            <FormikInput name="name" placeholder="Your Company Limited" />
                          </FormRowInputWrapper>
                        </>
                        <>
                          <FormRowLabel>{isHei ? 'GST/' : ''}VAT number</FormRowLabel>
                          <FormRowInputWrapper>
                            <FormikInput name="vatNumber" placeholder="GB 000000000" />
                          </FormRowInputWrapper>
                        </>
                      </FormRow>
                      <FormRow>
                        <>
                          <FormRowLabel>Registration number</FormRowLabel>
                          <FormRowInputWrapper>
                            <FormikInput name="registrationNumber" placeholder="00000000" />
                          </FormRowInputWrapper>
                        </>
                        <>
                          <FormRowLabel>Website</FormRowLabel>
                          <FormRowInputWrapper>
                            <FormikInput name="website" placeholder="www.yourcoach.co.uk" />
                          </FormRowInputWrapper>
                        </>
                      </FormRow>
                    </div>
                  )}
                  <div className={css({ marginBottom: isHei ? '60px' : 0 })}>
                    <FormRow>
                      <>
                        <FormRowLabel />
                        <CardHeaderTitle>Billing address</CardHeaderTitle>
                      </>
                    </FormRow>
                    <FormRow>
                      <>
                        <FormRowLabel>Address line 1</FormRowLabel>
                        <FormRowInputWrapper>
                          <FormikInput name="address1" placeholder="1 Victoria Street" />
                        </FormRowInputWrapper>
                      </>
                      <>
                        <FormRowLabel>Address line 2</FormRowLabel>
                        <FormRowInputWrapper>
                          <FormikInput name="address2" placeholder="Block 2" />
                        </FormRowInputWrapper>
                      </>
                    </FormRow>
                    <FormRow>
                      <>
                        <FormRowLabel>City</FormRowLabel>
                        <FormRowInputWrapper>
                          <FormikInput name="city" placeholder="London" />
                        </FormRowInputWrapper>
                      </>
                      <>
                        <FormRowLabel>Post code</FormRowLabel>
                        <FormRowInputWrapper>
                          <FormikInput name="postCode" placeholder="NW3 9IE" />
                        </FormRowInputWrapper>
                      </>
                    </FormRow>
                    <FormRow width="half">
                      <>
                        <FormRowLabel>Country</FormRowLabel>
                        <FormRowInputWrapper>{getCountryListDropdown(countries)}</FormRowInputWrapper>
                      </>
                    </FormRow>
                  </div>
                  {isHei && (
                    <div>
                      <FormRow>
                        <>
                          <FormRowLabel />
                          <CardHeaderTitle>
                            <div className="d-flex">
                              Bank details
                              <Tooltip content="We will use these details to transfer your earnings to your bank account every month.">
                                <Info />
                              </Tooltip>
                            </div>
                          </CardHeaderTitle>
                        </>
                      </FormRow>
                      {`${values.type}` === `${BillingType.TYPE_BUSINESS}` ? <BankDetailsBusiness /> : <BankDetailsIndividual />}
                    </div>
                  )}
                </div>
                <Separator />
                <ButtonWrapper>
                  <Button
                    variant="success"
                    type="submit"
                    disabled={isSubmitting}
                    isLoading={isSubmitting}
                  >
                    Save
                  </Button>
                  {!isHei && (
                    <Button size="large" href={`${getLoginUrl()}?${queryParams}`} target="_blank">
                      Stripe dashboard
                    </Button>
                  )}
                </ButtonWrapper>
              </>
            )}
            {!values.type && !isHei && (
              <>
                <Separator />
                <ButtonWrapper>
                  <Button size="large" href={`${getLoginUrl()}?${queryParams}`} target="_blank">
                    Stripe dashboard
                  </Button>
                </ButtonWrapper>
              </>
            )}
          </Form>
        )}
      </Formik>
    </>
  );
};
