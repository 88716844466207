import React, { useState } from 'react';

import { TestableComponentProps } from '~/model/TestableComponentProps.interface';

import clsx from 'clsx';
import { LinkButton } from '~/components/common/Button/Button.style';
import { TestResult } from '../TestResult';

import { headerColumns, Test as Model } from '../model';
import { TableRowItem } from '../../ExpandableTable/ExpandableTable.style';
import { style, TestResultRow } from '../TestResult/TestResult.style';
import { TestKitModal } from '../../TestKits/TestKit/TestKitModal/TestKitModal';
import { PhenotypeModal } from '../PhenotypeModal/container';
import { RowHeaderButton } from '../../Sections.style';

export interface Props extends TestableComponentProps {
  test: Model;
}

export const Test = ({ test }: Props) => {
  const [showPhenotypeModal, setShowPhenotypeModal] = useState(false);
  const [testKitId, setTestKitId] = useState<null | number>(null);
  const { testResults, measuringUnits, id } = test;

  const disabledColumnClass = { [style.disabled]: testResults.length === 0 };
  const columnClass = clsx(style.column, disabledColumnClass);

  return (
    <>
      <TableRowItem>
        <TestResultRow $widths={headerColumns.map(({ width }) => width || '1fr')}>
          <div className={clsx(style.name, disabledColumnClass)}>
            <RowHeaderButton onClick={() => setShowPhenotypeModal(true)}>
              {test.name}
            </RowHeaderButton>
          </div>
          <div className={columnClass}>
            {test.testKits.length === 0 && <>N/A</>}
            {test.testKits.length !== 0 &&
              test.testKits.map((testKit, index) => (
                <div key={testKit.id}>
                  <LinkButton
                    onClick={() => {
                      setTestKitId(testKit.id);
                    }}
                  >
                    {testKit.name}
                    {index + 1 !== test.testKits.length && <>, </>}
                  </LinkButton>
                </div>
              ))}
          </div>
          <div className={style.list}>
            {testResults.length > 0 &&
              testResults.map((testResult) => (
                <TestResult
                  key={`test-result-${testResult.id}`}
                  phenotypeId={id}
                  measuringUnits={measuringUnits}
                  testResult={testResult}
                  setShowPhenotypeModal={setShowPhenotypeModal}
                />
              ))}
            {testResults.length === 0 && (
              <TestResult
                phenotypeId={id}
                measuringUnits={measuringUnits}
                setShowPhenotypeModal={setShowPhenotypeModal}
              />
            )}
          </div>
        </TestResultRow>
      </TableRowItem>

      {testKitId && <TestKitModal onClose={() => setTestKitId(null)} testKitId={testKitId} />}
      {showPhenotypeModal && (
        <PhenotypeModal
          testId="phenotype-modal"
          width="772px"
          phenotypeId={id}
          onClose={() => setShowPhenotypeModal(false)}
        />
      )}
    </>
  );
};
