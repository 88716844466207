import { generatePath } from 'react-router-dom';
import { TestResultsResponse } from '~/components/pages/client/Sections/TestResults/model';
import { BackendValidationErrorResponse } from '~/model/Error/ErrorTypes';
import { api } from '~/services/api';

export interface ReportFile {
  name: string;
  base64Data: string;
}

export interface TestResultRequestDate {
  year: number;
  month: number;
  day: number;
}

export interface TestResultRequest {
  value?: number;
  reportFile?: ReportFile;
  testDate?: TestResultRequestDate;
  date?: TestResultRequestDate;
  phenotype: number;
  name?: string;
}

export interface ChoiseResponse {
  id: string | number;
  name: string;
}

export interface TestKitFilterParams {
  category: string;
  testKit: string;
  result: string;
}

export interface TestKitSearchAndFilters {
  term: string;
  filters: TestKitFilterParams;
}

const URL = {
  LIST: '/api/client/:clientId/test-result',
  ADD: '/api/client/:clientId/test-result',
  ADD_REPORT: '/api/client/:clientId/test-report',
  DELETE: '/api/client/:clientId/test-result/:customerTestResultId',
  EDIT: '/api/client/:clientId/test-result/:customerTestResultId',
  CHOICE_CATEGORY: '/api/client/:clientId/test-result-choice-category',
  CHOICE_TESTKIT: '/api/client/:clientId/test-result-choice-test-kit',
  CHOICE_RESULT: '/api/client/:clientId/test-result-choice-result',
};

export const model = (clientId) => ({
  getList: (term?, filters?) => {
    const category = filters?.category === 'all' ? '' : filters?.category;
    const testKit = filters?.testKit === 'all' ? '' : filters?.testKit;
    const result = filters?.result === 'all' ? '' : filters?.result;
    const params = [
      term ? `term=${term}` : null,
      category ? `category=${filters?.category}` : null,
      testKit ? `testKit=${filters?.testKit}` : null,
      result ? `result=${filters?.result}` : null,
    ]
      .filter((param) => param !== null)
      .join('&');

    return api.get<TestResultsResponse>(
      `${generatePath(URL.LIST, { clientId })}${params && `?${params}`}`,
    );
  },
  add: (data: TestResultRequest) =>
    api.post<TestResultRequest, void | BackendValidationErrorResponse<TestResultRequest>>(
      generatePath(data.reportFile ? URL.ADD_REPORT : URL.ADD, { clientId }),
      data,
    ),
  edit: (customerTestResultId, data: TestResultRequest) =>
    api.put<TestResultRequest, void | BackendValidationErrorResponse<TestResultRequest>>(
      generatePath(URL.EDIT, {
        clientId,
        customerTestResultId,
      }),
      data,
    ),
  delete: (customerTestResultId: number) =>
    api.delete(generatePath(URL.DELETE, { clientId, customerTestResultId })),
  categoryChoices: () => api.get<ChoiseResponse[]>(generatePath(URL.CHOICE_CATEGORY, { clientId })),
  testkitChoices: () => api.get<ChoiseResponse[]>(generatePath(URL.CHOICE_TESTKIT, { clientId })),
  resultChoices: () => api.get<ChoiseResponse[]>(generatePath(URL.CHOICE_RESULT, { clientId })),
});
