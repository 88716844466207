import { BasketState } from './reducer';

export const anythingInBasket = ({ items }: BasketState) =>
  items.length > 0 && items.some((item) => item.quantity > 0);

export const quantityInBasket = ({ items }: BasketState, id: number) => {
  const found = items.find((item) => item.id === id);
  if (found) {
    return found.quantity;
  }

  return 0;
};
