// import {all} from "redux-saga/effects";
import { combineReducers } from 'redux';
import { mockUser } from './__mocks__/mockUser';

export const rootReducer = combineReducers({
  auth: (state = { user: mockUser, authToken: '123' }) => state,
});

export function* rootSaga() {
  // yield all([auth.saga()]);
}
