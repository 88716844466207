import React from 'react';
import { model } from '~/model/Notes';
import { queryClient } from '~/services/api';
import { notesQueryKey } from '~/model/Notes/hooks';
import { toApiRequest } from '~/components/common/Notes/Modal/config';
import { clientNotesQueryKey } from '~/model/Client/Notes';
import { useCoachContext } from '~/components/pages/profile';
import { Modal as Component, Props as ComponentProps } from '../Modal';

export const AddModal = ({
  ...otherProps
}: Omit<ComponentProps, 'onSubmit' | 'initialValues' | 'clients' | 'title'>) => {
  const { coach } = useCoachContext();

  return (
    <Component
      onSubmit={async (values) => {
        const data = toApiRequest(values);
        await model(coach.id).add(data);
        await queryClient.invalidateQueries([notesQueryKey]);
        await queryClient.removeQueries([notesQueryKey], { inactive: true });

        if (values.client) {
          await queryClient.invalidateQueries([clientNotesQueryKey(values.client)]);
        }
      }}
      title="Add note"
      testId="add-note-modal"
      initialValues={{
        name: '',
        description: '',
      }}
      {...otherProps}
    />
  );
};
