import { useQuery } from 'react-query';
import { TestKitCategory } from '~/components/pages/client/Sections/TestKits/model';
import { model as clientTestKitModel } from './testKits';

export const clientsQueryKey = (clientId) => ['client', clientId, 'test-kits'];

export const useClientTestKits = (clientId) =>
  useQuery<TestKitCategory[]>(clientsQueryKey(clientId), () =>
    clientTestKitModel.getList(clientId),
  );
