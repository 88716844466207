import styled from '@emotion/styled';
import ApexChart from 'react-apexcharts';
import { colors } from '~/components/utils/colors';
import { hexToRgba } from '~/components/utils/colors/hexToRgba';
import { TableGridSpacing } from '../../../ExpandableTable';

export const WeeksContainer = styled.div`
  padding: 15px 15px 60px 55px;
  display: flex;
  justify-content: space-around;
`;

export const Chart = styled(ApexChart)`
  svg {
    overflow: visible;
  }

  .apexcharts-yaxis, .apexcharts-yaxis-texts-g {
    transform: none;
  }

  .apexcharts-yaxis-label {
    transform: translateX(-20px);
  }

  .apexcharts-xaxis text,
  .apexcharts-yaxis text {
    font-family: 'Poppins';
    font-size: 12px;
    font-weight: 600;
  }

  .apexcharts-xaxis-texts-g text {
    fill: ${colors.colorGsTwo};
  }

  .apexcharts-yaxis-texts-g text {
    fill: ${colors.colorGsThree};
  }

  .apexcharts-grid-borders {
    line[stroke-dasharray="0"] {
      visibility: hidden;
    }
  }

  .apexcharts-xaxis {
    line {
      visibility: hidden;
    }
  }

  .apexcharts-yaxis-annotations {
    rect {
      filter: drop-shadow(0px 2px 6px ${hexToRgba(colors.colorSecBlueThree, 0.4)});
    }
  }

  .apexcharts-legend {
    gap: 16px;

    .apexcharts-legend-marker {
      margin-right: 8px;
      overflow: hidden;

      .apexcharts-legend-marker {
        display: block;
      }
    }

    .apexcharts-legend-series[seriesname="Target"] {
      .apexcharts-legend-marker {
        width: 12px !important;
        height: 4px !important;
      }
    }
  }

  .apexcharts-marker {
    display: none;
  }
`;

export const DayDataRow = styled(TableGridSpacing)`
  padding: 10px 12px 10px 21px;
`;

export const ChartContainer = styled.div`
  width: 100%;
  height: 325px;
`;

export const LoaderContainer = styled.div`
  width: 100%;
  height: 325px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
