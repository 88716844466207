import { DateTime } from 'luxon';
import React from 'react';
import { getDaySuffix } from '~/components/utils/helpers/dateHelpers';

export type DateIntervalLabelProps = {
  from: Date;
  to: Date;
}

export const DateIntervalLabel = ({ from, to }: DateIntervalLabelProps) => {
  const formatDate = () => {
    const dateFrom = DateTime.fromJSDate(from);
    const dateTo = DateTime.fromJSDate(to);
    const differentYear = dateFrom.year !== dateTo.year;
    const isDifferentMonth = dateFrom.month !== dateTo.month;
    const dateFromDaySuffix = getDaySuffix(dateFrom.day);
    const dateToDaySuffix = getDaySuffix(dateTo.day);

    if (differentYear) {
      return (
        <>{dateFrom.day}<sup>{dateFromDaySuffix}</sup> {dateFrom.toFormat('MMM yyyy')} - {dateTo.day}<sup>{dateToDaySuffix}</sup> {dateTo.toFormat('MMM yyyy')}</>
      );
    }

    if (!isDifferentMonth) {
      return (
        <>{dateFrom.day}<sup>{dateFromDaySuffix}</sup> - {dateTo.day}<sup>{dateToDaySuffix}</sup> {dateTo.toFormat('MMM')}</>
      );
    }

    return (
      <>{dateFrom.day}<sup>{dateFromDaySuffix}</sup> {dateFrom.toFormat('MMM')} - {dateTo.day}<sup>{dateToDaySuffix}</sup> {dateTo.toFormat('MMM')}</>
    );
  }

  return formatDate();
};
