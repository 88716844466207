import styled from '@emotion/styled';
import { colors } from '~/components/utils/colors';

export const StyledDropdownContainer = styled.div`
  display: flex;
  align-items: center;
  color: ${colors.colorMenuDefault};
`;

export const Content = styled.div`
  margin: 0 10px;
  p {
    margin: 0;
    font-size: 12px;
    color: ${colors.colorMenuDefault};
  }
`;
