import { useQuery } from 'react-query';
import { GetKeyHabitsResponse, Habit, HabitRecommendation, model } from './habit';

export const queryKey = (id: string) => `habit--${id}`;
export const recommendationsQueryKey = `habit-recommendations`;
export const clientKeyHabitsQueryKey = (clientId) => ['client', clientId, 'habit'];

export const useHabit = (id: string) => useQuery<Habit>(queryKey(id), () => model.show(id));
export const useHabitRecommendationsList = () =>
  useQuery<Array<HabitRecommendation>>(recommendationsQueryKey, () => model.recommendationsList());

export const useKeyHabits = (id: string) =>
  useQuery<GetKeyHabitsResponse>(clientKeyHabitsQueryKey(id), () => model.getKeyHabits(id));
