import React from 'react';
import { css } from '@emotion/css';
import { colors } from '~/components/utils/colors';
import {
  ConfirmationModal,
  Props as ConfirmationModalProps,
} from '~/components/common/ConfirmationModal';
import { getPartnerName } from '~/components/utils/helpers/partner';

export const Confirm = ({ email, onClose, onSubmit }) => {
  const partnerName = getPartnerName();

  const modalProps: ConfirmationModalProps = {
    title: 'Are you sure?',
    description: (
      <>
        <p>
          This will send an email to <strong>{email}</strong> inviting them to download and register
          in the ({partnerName}) app.
        </p>
        <p className={css({ color: colors.colorPriRedOne })}>
          Please make sure that you have incuded your Referral code in the email!
        </p>
      </>
    ),
    closeButtonText: 'No, cancel',
    submitButtonText: 'Yes, send!',
    onClose,
    onSubmit,
  };

  return <ConfirmationModal {...modalProps} />;
};
