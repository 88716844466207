import React, { useEffect, useState } from 'react';
import { Form, Formik, FormikHelpers } from 'formik';

import { Modal as MainModal, Props as ModalProps } from '~/components/common/Modal';
import { Separator } from '~/components/common/Modal/Modal.style';
import { Button } from '~/components/common/Button';
import { isBackendValidationError, transformErrors } from '~/model/Error/transformApiErrors';
import { TestableComponentProps } from '~/model/TestableComponentProps.interface';
import {
  FormikDropdown,
  FormikInput,
  FormikRichText,
  FormRow,
  FormRowInputWrapper,
  FormRowLabel,
  FormikDropdownMultiple,
} from '~/components/common/Form';

import { useDimensionsList } from '~/model/Client/Goals/Dimensions/hooks';
import { useTopicRecommendationsList } from '~/model/Client/Goals/Topic/hooks';
import { useHabitRecommendationsList } from '~/model/Client/Habit/hooks';
import { capitalizeFirstLetter } from '~/components/utils/string';
import { FormFields, getActionsOptions, getWeekOptions } from './config';
import { GOAL_TITLE_MAX_LENGTH, validationAddSchema, validationSchema } from './validation';
import { FormWrapper, ModalFooter } from './Modal.style';

export interface Props extends ModalProps, TestableComponentProps {
  onSubmit: (values: FormFields) => void;
  clientId: string;
  initialValues: FormFields;
}

export const Modal = ({
  onClose: handleClose,
  onSubmit,
  clientId,
  initialValues,
  ...otherProps
}: Props) => {
  const [selectedDimension, setSelectedDimension] = useState<number | undefined>(undefined);
  const [selectedTopic, setSelectedTopic] = useState<number | undefined>(undefined);
  const { data: dimensionsList } = useDimensionsList();
  const { data: topicChoicesList } = useTopicRecommendationsList(
    clientId,
    String(selectedDimension),
  );
  const { data: habitRecommendationList } = useHabitRecommendationsList();

  useEffect(() => {
    if (dimensionsList && initialValues.dimension) {
      setSelectedDimension(initialValues.dimension);
    }
  }, [selectedDimension, dimensionsList, selectedTopic, topicChoicesList]);

  const handleSubmit = async (values: FormFields, { setErrors }: FormikHelpers<FormFields>) => {
    try {
      if (initialValues.dimension && initialValues.topic) {
        await onSubmit(values);
      } else if (selectedDimension && selectedTopic) {
        await onSubmit({
          ...values,
          dimension: selectedDimension,
          recommendation: selectedTopic,
        });
      }
      handleClose();
    } catch (err) {
      if (isBackendValidationError<FormFields>(err)) {
        setErrors(transformErrors<FormFields>(err));
      }
    }
  };

  return (
    <MainModal
      withHeaderSeparator
      hasCloseButton={false}
      onClose={handleClose}
      closeOnOuterClick={false}
      {...otherProps}
    >
      <Formik
        validationSchema={initialValues.dimension ? validationSchema : validationAddSchema}
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, setFieldValue }) => (
          <Form>
            <FormWrapper>
              <FormRow $rowSpacing="15px">
                <>
                  <FormRowLabel htmlFor="dimension">Dimension:</FormRowLabel>
                  <FormRowInputWrapper>
                    <FormikDropdown
                      name="dimension"
                      disabled={!!initialValues.dimension}
                      value={selectedDimension}
                      onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                        setFieldValue('dimension', event.target.value);
                        setSelectedDimension(parseInt(event.target.value, 10));
                        setSelectedTopic(undefined);
                      }}
                      placeholder="Select a dimension of the goal"
                    >
                      <option value="">Select a dimension of the goal</option>
                      {dimensionsList?.map((dimension) => (
                        <option value={dimension.id} key={dimension.id}>
                          {capitalizeFirstLetter(dimension.name)}
                        </option>
                      ))}
                    </FormikDropdown>
                  </FormRowInputWrapper>
                </>
              </FormRow>
              <FormRow $rowSpacing="15px">
                <>
                  <FormRowLabel htmlFor="recommendation">Topic:</FormRowLabel>
                  <FormRowInputWrapper>
                    {!!initialValues.dimension && (
                      <FormikInput
                        name="recommendation"
                        value={initialValues.topic}
                        disabled={!!initialValues.dimension}
                      />
                    )}
                    {!initialValues.dimension && (
                      <FormikDropdown
                        name="recommendation"
                        value={selectedTopic}
                        onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                          setFieldValue('recommendation', event.target.value);
                          setSelectedTopic(parseInt(event.target.value, 10));
                        }}
                        placeholder="Select a topic that fits this goal the best"
                      >
                        <option value="">Select a topic that fits this goal the best</option>
                        {topicChoicesList?.map((topic) => (
                          <option value={topic.id} key={topic.id}>
                            {topic.title}
                          </option>
                        ))}
                      </FormikDropdown>
                    )}
                  </FormRowInputWrapper>
                </>
              </FormRow>
              {!initialValues.dimension && (
                <FormRow $rowSpacing="15px">
                  <>
                    <FormRowLabel htmlFor="habits">Habits:</FormRowLabel>
                    <FormRowInputWrapper>
                      <FormikDropdownMultiple
                        name="habits"
                        placeholder="Select the Habits that the this goal will help develop"
                        options={habitRecommendationList?.map((habit) => ({
                          title: habit.title,
                          value: `${habit.id}`,
                        }))}
                      />
                    </FormRowInputWrapper>
                  </>
                </FormRow>
              )}
              <FormRow $rowSpacing="15px">
                <>
                  <FormRowLabel htmlFor="goal">Goal:</FormRowLabel>
                  <FormRowInputWrapper>
                    <FormikInput
                      name="goal"
                      maxLength={GOAL_TITLE_MAX_LENGTH}
                      placeholder="Add a short and clear goal title here"
                      note={`Try to make it short and simple (${GOAL_TITLE_MAX_LENGTH} characters maximum)`}
                    />
                  </FormRowInputWrapper>
                </>
              </FormRow>
              <FormRow $rowSpacing="15px">
                <>
                  <FormRowLabel htmlFor="description">Description:</FormRowLabel>
                  <FormRowInputWrapper>
                    <FormikRichText
                      name="description"
                      onChange={(values) => setFieldValue('description', values)}
                    />
                  </FormRowInputWrapper>
                </>
              </FormRow>
              <FormRow $rowSpacing="15px">
                <>
                  <FormRowLabel htmlFor="actions">Actions required:</FormRowLabel>
                  <FormRowInputWrapper>
                    <FormikDropdown
                      name="actions"
                      placeholder="How many actions are required to achieve this goal?"
                    >
                      <option value="">Default</option>
                      {getActionsOptions().map(({ value, title }) => (
                        <option key={value} value={value}>
                          {title}
                        </option>
                      ))}
                    </FormikDropdown>
                  </FormRowInputWrapper>
                </>
              </FormRow>
              <FormRow $rowSpacing="15px">
                <>
                  <FormRowLabel htmlFor="week">Week number:</FormRowLabel>
                  <FormRowInputWrapper>
                    <FormikDropdown
                      name="week"
                      placeholder="Which week do you want to show the goal in?"
                    >
                      {getWeekOptions().map(({ value, title }) => (
                        <option key={value} value={value}>
                          {title}
                        </option>
                      ))}
                    </FormikDropdown>
                  </FormRowInputWrapper>
                </>
              </FormRow>
            </FormWrapper>

            <Separator $marginBottom="30px" />

            <ModalFooter>
              <Button type="button" shade="light" variant="neutral" onClick={handleClose}>
                Cancel
              </Button>
              <Button
                variant="success"
                type="submit"
                disabled={isSubmitting}
                isLoading={isSubmitting}
              >
                Save
              </Button>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </MainModal>
  );
};
