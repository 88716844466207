import clsx from 'clsx';
import React, { ReactNode } from 'react';

import { Props as ButtonProps } from '../Button';
import { StyledIconButton } from '../Button.style';

export interface IconButtonProps extends Omit<ButtonProps, 'children'> {
  icon: ReactNode;
}

export const IconButton = ({
  icon,
  shade = 'dark',
  variant = 'primary',
  testId,
  className,
  ...otherProps
}: IconButtonProps) => (
  <StyledIconButton
    data-testid={testId}
    className={clsx(`btn btn-${variant}`, shade ? `btn-${shade}-${variant}` : '', className)}
    {...otherProps}
  >
    <span data-testid="icon">{icon}</span>
  </StyledIconButton>
);
