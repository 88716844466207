import * as React from 'react';
import { SVGProps } from 'react';
import { Size } from '../types';
interface Props extends SVGProps<SVGSVGElement> {
  size?: Size;
}
const SvgHandshake16 = (props: Props) => {
  const getSize = () => {
    if (props.size) {
      switch (props.size) {
        case 'small':
          return 20;

        case 'medium':
          return 24;

        case 'large':
          return 32;

        default:
          return null;
      }
    }
  };

  return (
    <svg
      width={getSize() || 16}
      height={getSize() || 16}
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      {...props}
    >
      <path
        d="M.2 8.2c.067.067.133.133.2.133L2.4 9h.133a.367.367 0 00.334-.2l1.6-4.6h.066c.4.133.867.133 1.267-.067l.2-.066c.133-.067.4-.067.6 0l3.667 1.266c-.067.134-.134.334-.267.4-.133.134-.4.134-.667 0L7.667 5.2H7.4c-.067 0-.067.067-.067.2 0 0-.266.867-.933 1.267-.467.266-1 .266-1.667 0-.2-.067-.333 0-.4.2-.066.2 0 .333.2.4.4.133.734.2 1.067.2.4 0 .733-.067 1.067-.267.666-.333 1-.933 1.133-1.333l1.333.466c.534.2.934.134 1.267-.066.533-.334.533-1.067.533-1.134a.366.366 0 00-.2-.333L6.8 3.467c-.4-.134-.8-.134-1.2.066l-.2.067c-.267.133-.533.133-.8.067l-.267-.134h-.066l-2-.666c-.2-.067-.334 0-.4.2L.2 8c-.067 0-.067.133 0 .2zm2.133-4.667l1.4.467-1.466 4.333-1.4-.466 1.466-4.334z"
        stroke="currentColor"
        strokeWidth={0.2}
        strokeMiterlimit={10}
      />
      <path
        d="M3.466 11.067a.866.866 0 00.8.533c0 .133 0 .267.067.4.133.2.267.4.533.467.067.066.2.066.334.066.133 0 .266 0 .4-.066l.2-.067c0 .067.066.2.066.267.134.2.267.4.534.466.066.067.2.067.333.067s.267 0 .4-.067l3.667-1.8c.8-.4 1.6-.933 2.266-1.533l.8-.733 1.867-.934c.133-.066.2-.266.133-.4L13.6 3.067c-.067-.067-.134-.134-.2-.134h-.267l-1.933.934a.32.32 0 00-.134.4l2.2 4.466-.666.6c-.6.6-1.334 1.067-2.067 1.4l-3.667 1.8c-.066.067-.133.067-.2 0-.066 0-.133-.066-.2-.133-.066-.067-.066-.133 0-.2 0-.067.067-.133.134-.2l2.066-1a.32.32 0 00.134-.4c-.067-.133-.267-.2-.4-.133l-2.067 1-1 .4c-.067.066-.133.066-.2 0-.067 0-.133-.067-.2-.134-.067-.066-.067-.133 0-.2 0-.066.067-.133.133-.2L8 9.933a.32.32 0 00.133-.4.32.32 0 00-.4-.133L4.466 11a.32.32 0 01-.4-.133C4 10.733 4 10.667 4 10.6s.066-.133.133-.2L5.4 9.733l1.666-.8c.134-.066.2-.266.134-.4a.32.32 0 00-.4-.133L4.2 9.667a.32.32 0 01-.4-.134.32.32 0 01.133-.4l1.4-.666a.32.32 0 00.133-.4.32.32 0 00-.4-.134l-1.4.667c-.466.2-.666.8-.4 1.267.067.133.134.266.267.333-.2.2-.2.6-.067.867zm8.334-6.8l1.333-.667 2 4.067-1.333.666-2-4.066z"
        stroke="currentColor"
        strokeWidth={0.2}
        strokeMiterlimit={10}
      />
    </svg>
  );
};

export default SvgHandshake16;
