import { StateRow } from './model';

type RowsManipulationActionKind = 'EXPAND_ALL' | 'EXPAND' | 'COLLAPSE_ALL' | 'COLLAPSE' | 'TOGGLE';

export const ActionExpand = (id: string | number): RowsManipulationAction => ({
  type: 'EXPAND',
  payload: { id },
});
export const ActionExpandAll = (): RowsManipulationAction => ({ type: 'EXPAND_ALL' });
export const ActionCollapseAll = (): RowsManipulationAction => ({ type: 'COLLAPSE_ALL' });
export const ActionCollapse = (id: string | number): RowsManipulationAction => ({
  type: 'COLLAPSE',
  payload: { id },
});
export const ActionToggle = (id: string | number): RowsManipulationAction => ({
  type: 'TOGGLE',
  payload: { id },
});
export const ActionReplaceRows = (rows: StateRow[]): StateManipulationAction => ({
  type: 'REPLACE_ROWS',
  payload: { rows },
});

export type RowsManipulationAction = {
  type: RowsManipulationActionKind;
  payload?: { id: string | number };
};
export type StateManipulationAction = { type: 'REPLACE_ROWS'; payload?: { rows: StateRow[] } };
