import React, { useEffect, useMemo, useState } from 'react';
import { Form, Formik, FormikHelpers } from 'formik';

import { Modal as MainModal, Props as ModalProps } from '~/components/common/Modal';
import { Separator } from '~/components/common/Modal/Modal.style';
import { Button } from '~/components/common/Button';
import { isBackendValidationError, transformErrors } from '~/model/Error/transformApiErrors';
import { TestableComponentProps } from '~/model/TestableComponentProps.interface';
import { FormikDropdown, FormikInput, FormRow, FormRowInputWrapper } from '~/components/common/Form';

import { Phenotype } from '~/model/Client/TestResults/Phenotype';
import { FormikRadio } from '~/components/common/Form/Input/Radio';
import { FormikFileUpload } from '~/components/common/Form/FileUpload';
import { FormFields } from './config';
import { getDayOptions, getMonthOptions, getYearOptions } from '../../config';
import { validationSchema } from './validation';
import { FormWrapper, ModalFooter, FormRowLabel, formTypeLabelStyle } from './Modal.style';

export interface Props extends ModalProps, TestableComponentProps {
  onSubmit: (values: FormFields) => void;
  title: string;
  initialValues: FormFields;
  phenotypes?: Phenotype[];
  allowReportUpload?: boolean;
}

export const Modal = ({
  onClose: handleClose,
  title,
  onSubmit,
  initialValues,
  phenotypes,
  allowReportUpload = false,
  ...otherProps
}: Props) => {
  const [formType, setFormType] = useState<'value' | 'file'>('value');
  const [selectedPhenotype, setSelectedPhenotype] = useState<Phenotype | null>(null);

  const isFileForm = useMemo(() => formType === 'file', [formType]);

  useEffect(() => {
    if (initialValues.phenotype === 0 && !selectedPhenotype && phenotypes) {
      setSelectedPhenotype(phenotypes[0]);
    } else if (!selectedPhenotype && phenotypes) {
      const newPhenotype = phenotypes.find((phenotype) => phenotype.id === initialValues.phenotype);
      if (newPhenotype) {
        setSelectedPhenotype(newPhenotype);
      }
    }
  }, [phenotypes, selectedPhenotype, initialValues]);

  const handlePhenotypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newPhenotype = phenotypes?.find((phenotype) => phenotype.id === parseInt(event.target.value, 10));
    if (newPhenotype) {
      setSelectedPhenotype(newPhenotype);
    }
  };

  const handleSubmit = async (values: FormFields, { setErrors }: FormikHelpers<FormFields>) => {
    try {
      await onSubmit({ ...values, phenotype: selectedPhenotype?.id || initialValues.phenotype });
    } catch (err) {
      if (isBackendValidationError<FormFields>(err)) {
        setErrors(transformErrors<FormFields>(err));
      }
    }
  };

  return (
    <MainModal
      withHeaderSeparator
      hasCloseButton={false}
      title={title}
      onClose={handleClose}
      closeOnOuterClick={false}
      {...otherProps}
    >
      <Formik
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        initialValues={initialValues}
      >
        {({ isSubmitting, setFieldValue }) => (
          <Form encType="multipart/form-data">
            <FormWrapper>
              {allowReportUpload && (
                <div style={{ marginBottom: '60px' }}>
                  <FormRowLabel takesSpace="required">
                    What type of test results would you like to add?
                  </FormRowLabel>
                  <FormikRadio
                    name="formType"
                    value="value"
                    label="Enter individual biomarker result"
                    onChange={() => {
                      setFormType('value');
                      setFieldValue('formType', 'value');
                    }}
                    checked={!isFileForm}
                    labelClassName={formTypeLabelStyle}
                  />
                  <FormikRadio
                    name="formType"
                    value="file"
                    label="Upload a test report file"
                    onChange={() => {
                      setFormType('file');
                      setFieldValue('formType', 'file');
                    }}
                    checked={isFileForm}
                    labelClassName={formTypeLabelStyle}
                  />
                </div>
              )}
              <FormRow $rowSpacing="15px">
                {isFileForm ? (
                  <>
                    <FormRowLabel takesSpace="required" htmlFor="phenotype">
                      Report name:
                    </FormRowLabel>
                    <FormRowInputWrapper>
                      <FormikInput
                        name="name"
                        placeholder="Choose a test name or create a new one"
                      />
                    </FormRowInputWrapper>
                  </>
                ) : (
                  <>
                    <FormRowLabel takesSpace="required" htmlFor="phenotype">
                      Biomarker:
                    </FormRowLabel>
                    <FormRowInputWrapper>
                      <FormikDropdown
                        name="phenotype"
                        placeholder="Choose a biomarker"
                        value={selectedPhenotype?.id}
                        onChange={handlePhenotypeChange}
                        disabled={initialValues.phenotype !== 0}
                      >
                        {phenotypes?.map(({ name, id }) => (
                          <option value={id} key={id}>{name}</option>
                        ))}
                      </FormikDropdown>
                    </FormRowInputWrapper>
                  </>
                )}
              </FormRow>
              <FormRow $rowSpacing="15px">
                <>
                  <FormRowLabel takesSpace="required" htmlFor="testDate">
                    Test date:
                  </FormRowLabel>
                  <FormRowInputWrapper>
                    <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gap: '10px' }}>
                      <div>
                        <FormikDropdown name="testDate.day" testId="day-dropdown">
                          {getDayOptions().map(({ value, title: optionTitle }) => (
                            <option key={value} value={value}>
                              {optionTitle}
                            </option>
                          ))}
                        </FormikDropdown>
                      </div>
                      <div>
                        <FormikDropdown name="testDate.month" testId="month-dropdown">
                          {getMonthOptions().map(({ value, title: optionTitle }) => (
                            <option key={value} value={value}>
                              {optionTitle}
                            </option>
                          ))}
                        </FormikDropdown>
                      </div>
                      <div>
                        <FormikDropdown name="testDate.year" testId="year-dropdown">
                          {getYearOptions().map(({ value, title: optionTitle }) => (
                            <option key={value} value={value}>
                              {optionTitle}
                            </option>
                          ))}
                        </FormikDropdown>
                      </div>
                    </div>
                  </FormRowInputWrapper>
                </>
              </FormRow>
              <FormRow $rowSpacing="15px">
                {isFileForm ? (
                  <>
                    <FormRowLabel takesSpace="required" htmlFor="value">
                      Add report:
                    </FormRowLabel>
                    <FormRowInputWrapper>
                      <div style={{ display: 'grid', gridTemplateColumns: '2.1fr 1fr', gap: '10px' }}>
                        <div>
                          <FormikFileUpload
                            name="file"
                            placeholder="Select a PDF file to upload"
                            accept=".pdf"
                            onChange={(event) => {
                              const file = event.target.files?.[0];

                              if (file) {
                                setFieldValue('reportFile', file);
                              }
                            }}
                          />
                        </div>
                      </div>
                    </FormRowInputWrapper>
                  </>
                ) : (
                  <>
                    <FormRowLabel takesSpace="required" htmlFor="value">
                      Value:
                    </FormRowLabel>
                    <FormRowInputWrapper>
                      <div style={{ display: 'grid', gridTemplateColumns: '2.1fr 1fr', gap: '10px' }}>
                        <div>
                          <FormikInput
                            name="value"
                            placeholder={`0 ${selectedPhenotype?.measuringUnits || ''}`}
                            type="number"
                            suffix={selectedPhenotype?.measuringUnits || ''}
                          />
                        </div>
                      </div>
                    </FormRowInputWrapper>
                  </>
                )}
              </FormRow>
            </FormWrapper>

            <Separator $marginBottom="30px" />

            <ModalFooter>
              <Button type="button" shade="light" variant="neutral" onClick={handleClose}>
                Cancel
              </Button>
              <Button
                variant="success"
                type="submit"
                disabled={isSubmitting}
                isLoading={isSubmitting}
              >
                Save
              </Button>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </MainModal>
  );
};
