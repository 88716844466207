import * as React from 'react';
import styled from '@emotion/styled';

interface Props extends React.SVGProps<SVGSVGElement> {
  direction: 'left' | 'right' | 'up' | 'down';
}

const DEG_BY_DIR = {
  left: '90deg',
  right: '-90deg',
  up: '180deg',
  down: '0deg',
};

const StyledWrapper = styled.div<{ $dir: 'left' | 'right' | 'up' | 'down' }>`
  ${({ $dir }) => `transform: rotate(${DEG_BY_DIR[$dir]})`}
`;

function SvgArrowDown({ direction, ...otherProps }: Props) {
  return (
    <StyledWrapper $dir={direction}>
      <svg
        width={20}
        height={20}
        viewBox="0 0 20 20"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
        {...otherProps}
      >
        <path
          d="M5.42259 9.77016C5.09715 9.44472 4.56951 9.44472 4.24408 9.77016C3.91864 10.0956 3.91864 10.6232 4.24408 10.9487L9.24408 15.9487C9.55956 16.2642 10.0675 16.2752 10.3964 15.9737L15.4104 10.9476C15.7496 10.6366 15.7725 10.1094 15.4615 9.77016C15.1506 9.43089 14.6234 9.40797 14.2841 9.71897L9.8584 14.206L5.42259 9.77016Z"
          fill="currentColor"
        />
        <path
          d="M5.42259 4.77022C5.09715 4.44478 4.56951 4.44478 4.24408 4.77022C3.91864 5.09566 3.91864 5.62329 4.24408 5.94873L9.24408 10.9487C9.55956 11.2642 10.0675 11.2753 10.3964 10.9738L15.4104 5.94762C15.7496 5.63662 15.7725 5.10948 15.4615 4.77022C15.1506 4.43095 14.6234 4.40803 14.2841 4.71903L9.8584 9.20603L5.42259 4.77022Z"
          fill="currentColor"
        />
      </svg>
    </StyledWrapper>
  );
}

export default SvgArrowDown;
