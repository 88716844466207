import { DateTime } from 'luxon';
import React, { PropsWithChildren } from 'react';

import { Error, Loader, Modal, Props as ModalProps } from '~/components/common/Modal';
import { Column, Image, Row } from '~/components/common/Modal/Modal.style';
import { useGetFoodLogItem } from '~/model/Client/FoodLog/FoodLogItem';
import { TestableComponentProps } from '~/model/TestableComponentProps.interface';
import { NutritionsPerServing } from '~/components/pages/client/Sections/NutritionsPerServing';
import { useClient } from '~/components/pages/client/context';

export interface Props extends TestableComponentProps, ModalProps {
  foodLogItemId: string;
}

const image = (foodLogItemImage?: string) =>
  foodLogItemImage && (
    <Column>
      <Image data-testid="food-log-item-modal-image" $url={foodLogItemImage} />
    </Column>
  );

export const FoodLogItemModal = ({ onClose, testId, foodLogItemId }: PropsWithChildren<Props>) => {
  const { clientId } = useClient();
  const { data, isLoading, isError } = useGetFoodLogItem(clientId, foodLogItemId);

  const defaultProps = {
    onClose,
    testId,
  };

  if (isLoading) {
    return <Loader {...defaultProps} />;
  }

  if (isError || !data) {
    return <Error {...defaultProps} />;
  }

  const { name, logged_at, nutrition_per_serving, image: foodLogItemImage } = data;

  return (
    <Modal
      title={name}
      subtitle={`Logged at ${DateTime.fromISO(logged_at).toFormat('HH:mm, dd/MM/yyyy')}`}
      {...defaultProps}
    >
      <Row $maxHeight="230px" $marginBottom="20px">
        {image(foodLogItemImage)}
        <NutritionsPerServing
          nutritionsPerServing={nutrition_per_serving}
          testId="food-log-item-modal"
        />
      </Row>
    </Modal>
  );
};
