import React from 'react';
import { useQueryClient } from 'react-query';
import { useClient } from '~/components/pages/client/context';

import { model, clientNotesQueryKey } from '~/model/Client/Notes';
import { Note } from '~/model/Notes';
import { toApiRequest } from '~/components/common/Notes/Modal/config';
import { notesQueryKey } from '~/model/Notes/hooks';
import { Modal as Component, Props as ComponentProps } from '../Modal';

interface Props extends Omit<ComponentProps, 'onSubmit' | 'initialValues'> {
  note: Note;
}

export const EditModal = ({ note, ...otherProps }: Props) => {
  const queryClient = useQueryClient();
  const { clientId } = useClient();

  const { name, description } = note;

  return (
    <Component
      onSubmit={async (values) => {
        const data = toApiRequest(values);
        await model(clientId).edit(note.id, data);
        await queryClient.invalidateQueries([clientNotesQueryKey(clientId)]);
        await queryClient.removeQueries([clientNotesQueryKey(clientId)], { inactive: true });
        await queryClient.invalidateQueries(notesQueryKey);
      }}
      testId="edit-note-modal"
      initialValues={{ name, description }}
      {...otherProps}
    />
  );
};
