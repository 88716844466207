import * as React from 'react';
import { SVGProps } from 'react';
import { Size } from '../types';
interface Props extends SVGProps<SVGSVGElement> {
  size?: Size;
}
const SvgChat20 = (props: Props) => {
  const getSize = () => {
    if (props.size) {
      switch (props.size) {
        case 'small':
          return 20;

        case 'medium':
          return 24;

        case 'large':
          return 32;

        default:
          return null;
      }
    }
  };

  return (
    <svg
      width={getSize() || 20}
      height={getSize() || 20}
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.667 4.5a3 3 0 013-3h10.667a3 3 0 013 3V11a3 3 0 01-3 3h-.262l-1.274 2.307a1 1 0 01-1.709.069L10.514 14H4.667a3 3 0 01-3-3V4.5zM4.834 4h7.5a.833.833 0 010 1.667h-7.5a.833.833 0 010-1.667zm0 3.333H9A.833.833 0 119 9H4.834a.833.833 0 110-1.667z"
      />
      <path
        opacity={0.3}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.817 7.32h4.286c.473 0 .857.385.857.86s-.384.86-.857.86H4.817a.859.859 0 01-.857-.86c0-.475.384-.86.857-.86z"
      />
    </svg>
  );
};

export default SvgChat20;
