import * as React from 'react';
import { SVGProps } from 'react';
import { Size } from '../types';
interface Props extends SVGProps<SVGSVGElement> {
  size?: Size;
}
const SvgDoubleChevron = (props: Props) => {
  const getSize = () => {
    if (props.size) {
      switch (props.size) {
        case 'small':
          return 20;

        case 'medium':
          return 24;

        case 'large':
          return 32;

        default:
          return null;
      }
    }
  };

  return (
    <svg
      width={getSize() || 24}
      height={getSize() || 24}
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        d="M12.707 6.707a1 1 0 00-1.414-1.414l-6 6a1 1 0 00-.03 1.383l5.5 6a1 1 0 001.474-1.352L7.384 12.03l5.323-5.323z"
        fill="#000"
      />
      <path
        opacity={0.3}
        d="M19.707 6.707a1 1 0 00-1.414-1.414l-6 6a1 1 0 00-.03 1.383l5.5 6a1 1 0 001.474-1.352l-4.853-5.294 5.323-5.323z"
        fill="#000"
      />
    </svg>
  );
};

export default SvgDoubleChevron;
