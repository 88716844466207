import * as React from 'react';
import { SVGProps } from 'react';
import { Size } from '../types';
interface Props extends SVGProps<SVGSVGElement> {
  size?: Size;
}
const SvgSendMail = (props: Props) => {
  const getSize = () => {
    if (props.size) {
      switch (props.size) {
        case 'small':
          return 20;

        case 'medium':
          return 24;

        case 'large':
          return 32;

        default:
          return null;
      }
    }
  };

  return (
    <svg
      width={getSize() || 20}
      height={getSize() || 20}
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      {...props}
    >
      <path
        opacity={0.3}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.5 5a.833.833 0 000 1.667h1.667a.833.833 0 000-1.667H2.5zM0 10c0-.46.373-.833.833-.833h3.334a.833.833 0 010 1.666H.833A.833.833 0 010 10zm2.5 4.167c0-.46.373-.834.833-.834h.834a.833.833 0 010 1.667h-.834a.833.833 0 01-.833-.833z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.666 5a2 2 0 00-2 2v6a2 2 0 002 2H18a2 2 0 002-2V7a2 2 0 00-2-2H8.666zm9.605 1.93a.526.526 0 00-.7-.203l-4.238 2.205-4.237-2.205a.526.526 0 00-.7.203.492.492 0 00.21.677l4.482 2.333a.53.53 0 00.49 0l4.483-2.333a.492.492 0 00.21-.677z"
      />
    </svg>
  );
};

export default SvgSendMail;
