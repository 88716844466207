import { DateTime } from "luxon";

export const getYearOptions = () => {
  const yearsToShow = 80;
  const endYear = new Date().getFullYear();
  return [...Array.from(Array(yearsToShow).keys())].map((i) => ({
    title: endYear - i,
    value: endYear - i,
  }));
};

export const getMonthOptions = () =>
  [...Array.from(Array(12).keys())].map((i) => ({
    title: DateTime.fromObject({ month: i + 1 }).toFormat('LLL'),
    value: i + 1,
  }));

export const getDayOptions = () =>
  [...Array.from(Array(31).keys())].map((i) => ({
    title: i + 1,
    value: i + 1,
  }));
