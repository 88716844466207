import React, { PropsWithChildren, useReducer } from 'react';
import {
  ActionCollapse,
  ActionCollapseAll,
  ActionExpand,
  ActionExpandAll,
  ActionReplaceRows,
  ActionToggle,
  INITIAL_STATE,
  reducer,
  State,
  StateRow,
} from '~/stateManagement/expandable';
import { TableContext } from './TableContext';

interface Props {
  initialState?: State;
}

export const TableProvider = ({
  initialState = INITIAL_STATE,
  children,
}: PropsWithChildren<Props>) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const expandAll = () => dispatch(ActionExpandAll());
  const collapseAll = () => dispatch(ActionCollapseAll());

  const toggleRow = (id) => dispatch(ActionToggle(id));
  const collapseRow = (id) => dispatch(ActionCollapse(id));
  const expandRow = (id) => dispatch(ActionExpand(id));
  const replaceRows = (rows: StateRow[]) => dispatch(ActionReplaceRows(rows));

  return (
    <TableContext.Provider
      value={{ state, expandAll, collapseAll, toggleRow, expandRow, collapseRow, replaceRows }}
    >
      {children}
    </TableContext.Provider>
  );
};
