import * as Yup from 'yup';
import { Partner, getPartner, getPartnerDefaultCountry } from '~/components/utils/helpers/partner';
import { RegisterFormData } from '~/model/Auth';

const ERROR_REQUIRED = 'This field is required';
const ERROR_EMAIL = 'Invalid email address';

export interface FormFields extends RegisterFormData {
  generic?: string;
  passwordConfirmation?: string;
}

export const initialValues = (): FormFields => {
  const defaultCountry = getPartnerDefaultCountry();
  const partner = getPartner();

  return {
    firstName: '',
    lastName: '',
    email: '',
    plainPassword: '',
    agreedWithPrivacyPolicy: false,
    agreedWithEndUserLicenseAgreement: false,
    legalDisclosureHealthAdvice: false,
    agreedWithPersonalDataCollection: partner !== Partner.HEI,
    coachInformation: {
      referredByReferralPartner: '',
      address: {
        address1: '',
        postCode: '',
        city: '',
        country: defaultCountry,
        phoneNumber: '',
      }
    }
  };
};


export const validationSchema = Yup.object({
  firstName: Yup.string().required(ERROR_REQUIRED),
  lastName: Yup.string().required(ERROR_REQUIRED),
  email: Yup.string().email(ERROR_EMAIL).required(ERROR_REQUIRED),
  plainPassword: Yup.string()
    .required(ERROR_REQUIRED)
    .min(8, 'The password must contain at least 8 symbols')
    .max(255, 'The password must contain at most 255 symbols')
    .matches(/^(?=.*[A-Z])/, 'The password must contain at least one capital letter')
    .matches(/^(?=.*[a-z])/, 'The password must contain at least one lower case letter')
    .matches(/^(?=.*\d)/, 'The password must contain at least one number')
    .matches(/^(?=.*[!@#$%^&*,.?(){}[\]])/, 'The password must contain at least one special character'),
  passwordConfirmation: Yup.string().oneOf([Yup.ref('plainPassword'), null], 'Passwords must match').required(ERROR_REQUIRED),
  agreedWithPrivacyPolicy: Yup.boolean().oneOf([true], ' '),
  agreedWithEndUserLicenseAgreement: Yup.boolean().oneOf([true], ' '),
  legalDisclosureHealthAdvice: Yup.boolean().oneOf([true], ' '),
  agreedWithPersonalDataCollection: Yup.boolean().oneOf([true], ' '),
  coachInformation: Yup.object({
    referredByReferralPartner: Yup.string(),
    address: Yup.object({
      address1: Yup.string().required(ERROR_REQUIRED),
      postCode: Yup.string().required(ERROR_REQUIRED),
      city: Yup.string().required(ERROR_REQUIRED),
      country: Yup.string().required(ERROR_REQUIRED),
      phoneNumber: Yup.string()
        .required(ERROR_REQUIRED)
        .matches(/^[0-9\s\\+]+$/, 'Please enter a valid phone number')
        .matches(/^\+/, 'Please include your area code (e.g.: +44…)'),
    }),
  }),
});

export const stepFields = [
  ['firstName', 'lastName', 'email', 'plainPassword', 'passwordConfirmation'],
  ['coachInformation.address.address1', 'coachInformation.address.postCode', 'coachInformation.address.city', 'coachInformation.address.country', 'coachInformation.address.phoneNumber'],
  ['coachInformation.referredByReferralPartner'],
  ['agreedWithPrivacyPolicy', 'agreedWithEndUserLicenseAgreement', 'agreedWithPersonalDataCollection', 'legalDisclosureHealthAdvice'],
];

export const privacyPolicyUrl = process.env.REACT_APP_PRIVACY_POLICY_URL ?? 'https://ome.health/privacy-policy';
export const termsAndConditionsUrl = process.env.REACT_APP_TERMS_AND_CONDITIONS_URL ?? 'https://ome.health/terms-and-conditions';
export const endUserLicenseAgreementUrl = process.env.REACT_APP_USER_AGREEMENT_URL ?? 'https://ome.health/end-user-license-agreement';
