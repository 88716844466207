import * as React from 'react';
import { SVGProps } from 'react';
import { Size } from '../types';
interface Props extends SVGProps<SVGSVGElement> {
  size?: Size;
}
const SvgEnvelope16 = (props: Props) => {
  const getSize = () => {
    if (props.size) {
      switch (props.size) {
        case 'small':
          return 20;

        case 'medium':
          return 24;

        case 'large':
          return 32;

        default:
          return null;
      }
    }
  };

  return (
    <svg
      width={getSize() || 16}
      height={getSize() || 16}
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 4a2 2 0 00-2 2v4.667a2 2 0 002 2h8a2 2 0 002-2V6a2 2 0 00-2-2H4zm8.774 1.43a.5.5 0 00-.678-.204L8 7.432 3.904 5.226a.5.5 0 10-.474.88L7.763 8.44a.5.5 0 00.474 0l4.333-2.333a.5.5 0 00.204-.677z"
      />
    </svg>
  );
};

export default SvgEnvelope16;
