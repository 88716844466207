import { useQuery } from 'react-query';
import { Supplement } from '~/components/pages/client/Sections/Supplements/Supplement/model';
import { model } from '../supplement';

export const clientSupplementQueryKey = (clientId: string, supplementId: number) => [
  'client',
  clientId,
  'supplement',
  supplementId,
];

export const useGetSupplement = (clientId: string, supplementId: number) =>
  useQuery<Supplement>(
    clientSupplementQueryKey(clientId, supplementId),
    () => model.getSupplement(clientId, supplementId),
    {
      refetchOnWindowFocus: false,
    },
  );
