import React from 'react';
import { Button } from '~/components/common/Button';
import { SendMail } from '~/components/common/Icons/dist';
import { useCoachContext } from '~/components/pages/profile/CoachProvider';

export interface Props {
  onClick: () => void;
}

export const InviteClientButton = ({ onClick: handleClick }: Props) => {
  const { coach } = useCoachContext();

  const disabled = !coach.code;

  return (
    <Button
      disabled={disabled}
      iconPosition="right"
      icon={<SendMail />}
      testId="invite-new-button"
      onClick={handleClick}
    >
      Invite new
    </Button>
  );
};
