/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { InputHTMLAttributes, useState } from 'react';
import clsx from 'clsx';
import { useField } from 'formik';

import { TestableComponentProps } from '~/model/TestableComponentProps.interface';
import { useOuterClick } from '~/hooks';
import { style, StyledListBox, StyledDropdownList } from './Dropdown.style';
import { DisplayText, StyledButtonDropdowWrapper, StyledDropdownButton } from './DropdownMultiple.style';
import { VisualSize } from '../Input';

export interface Props extends InputHTMLAttributes<HTMLSelectElement>, TestableComponentProps {
  name: string;
  label?: string;
  error?: string;
  note?: JSX.Element | string;
  visualSize?: VisualSize;
}

export const Dropdown = ({ className, error, note, testId, name, children, onChange, disabled, visualSize = 'medium', ...otherProps }: Props) => {
  const [open, setOpen] = useState(false);
  const [displayValue, setDisplayValue] = useState('');
  const refWrapper = useOuterClick(() => setOpen(false));

  const getValidChildren = () => React.Children.toArray(children).filter(React.isValidElement);

  const interceptOnChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setOpen(false);

    try {
      const selected = (event.target[event.target.selectedIndex] as HTMLOptionElement).text;
      setDisplayValue(selected);
    } catch (_error) {
      setDisplayValue('');
    }

    if (onChange) {
      onChange(event);
    }
  }

  const adjustDisplayValue = (element: HTMLSelectElement) => {
    if (element && element.selectedOptions.length > 0) {
      setDisplayValue(element.selectedOptions[0].text);
    }
  }

  return (
    <div className={clsx(style.wrapper)}>
      <div className={style.inputWrapper}>
        <StyledButtonDropdowWrapper data-testid={testId} ref={refWrapper}>
          <StyledDropdownButton
            id={name}
            type="button"
            onClick={() => disabled ? null : setOpen(!open)}
            className={clsx(
              'form-control text-left',
              [{ open }],
              className,
              {
                'is-invalid': !!error,
              },
              { 'is-large': visualSize === 'large' }
            )}
            data-testid={`${testId}-button`}
            disabled={disabled}
          >
            <DisplayText data-testid={`${testId}-text`} className={clsx({ 'is-large': visualSize === 'large' })}>
              {displayValue || otherProps.placeholder}
            </DisplayText>
          </StyledDropdownButton>
          <StyledDropdownList data-testid={`${testId}-list`} open={open}>
            <StyledListBox
              ref={adjustDisplayValue}
              name={name}
              data-testid={`${testId}-list-box`}
              size={Math.min(10, Math.max(getValidChildren().length, 2))}
              onChange={interceptOnChange}
              disabled={disabled}
              {...otherProps}
            >
              {children}
            </StyledListBox>
          </StyledDropdownList>
        </StyledButtonDropdowWrapper>
        {error && <small className={clsx('text-danger', style.subnote, style.error)}>{error}</small>}
        {note && <small className={clsx('text-gsThree', style.subnote, style.note)}>{note}</small>}
      </div>
    </div>
  );
};

type FormikDropdownProps = Omit<Props, 'error'>;
export const FormikDropdown = ({ name, ...props }: FormikDropdownProps) => {
  const [field, meta] = useField({ id: name, name, ...props });

  return (
    <Dropdown
      id={name}
      error={meta.touched && meta.error ? meta.error : undefined}
      {...field}
      {...props}
    />
  );
};
