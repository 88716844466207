import * as React from 'react';
import { SVGProps } from 'react';
import { Size } from '../types';
interface Props extends SVGProps<SVGSVGElement> {
  size?: Size;
}
const SvgKnifeFork = (props: Props) => {
  const getSize = () => {
    if (props.size) {
      switch (props.size) {
        case 'small':
          return 20;

        case 'medium':
          return 24;

        case 'large':
          return 32;

        default:
          return null;
      }
    }
  };

  return (
    <svg
      width={getSize() || 24}
      height={getSize() || 24}
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.645 3h-1v4.5a3.5 3.5 0 107 0V3h-1l-.45 4.502a.553.553 0 01-1.1 0L9.645 3h-1l-.45 4.502a.552.552 0 01-1.1 0L6.645 3z"
      />
      <rect opacity={0.3} x={7.726} y={13} width={2.838} height={9} rx={1.419} />
      <rect opacity={0.3} x={16.736} y={15} width={2.818} height={7} rx={1.409} />
      <path fillRule="evenodd" clipRule="evenodd" d="M19.645 3v10h-4V7a4 4 0 014-4z" />
    </svg>
  );
};

export default SvgKnifeFork;
