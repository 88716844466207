import React, { useState } from 'react';
import { css } from '@emotion/css';
import { Form, Formik, FormikProps } from 'formik';
import { useQueryClient } from 'react-query';

import {
  FormikDropdown,
  FormikInput,
  FormRow,
  FormRowLabel,
  FormRowInputWrapper,
  FormikPhoto,
} from '~/components/common/Form';
import { Button } from '~/components/common/Button';
import { useCoachContext } from '~/components/pages/profile/CoachProvider';
import { CoachProfile, coachProfileQueryKey } from '~/model/Profile';
import { useSettingsContext } from '~/components/common/SettingsProvider';
import { FormFields, FormFieldsResponse, initialValues } from './config';
import { validationSchema } from './validation';
import { useUpdateCoachProfile } from './hooks';

import { Separator, Spacer, SubmitButtonWrapper } from '../Layout/Form.style';

const getCountryListDropdown = (countries) => (
  <FormikDropdown name="country">
    <option>Select a country</option>
    {countries.map(({ name, iso }) => (
      <option key={iso} value={iso}>
        {name}
      </option>
    ))}
  </FormikDropdown>
);

export const PersonalDetails = () => {
  const { coach } = useCoachContext();
  const { countries } = useSettingsContext();

  const queryClient = useQueryClient();
  const [coachProfileImageUrl, setCoachProfileImageUrl] = useState(coach.profile_image_url);
  const mutation = useUpdateCoachProfile<FormFields>(coach.id, {
    onSuccess: () => {
      queryClient.refetchQueries(coachProfileQueryKey());
    },
  });

  return (
    <div data-testid="personal-details-tab-internal">
      <Formik
        initialValues={initialValues(coach)}
        validationSchema={validationSchema(countries)}
        onSubmit={(values: Partial<FormFields>, actions) => {
          mutation.mutate(values, {
            onSettled: async (data) => {
              const [_personalDetailsResponse, profileImageResponse] = data as FormFieldsResponse;

              let profileImageUrl: string = coach.profile_image_url;
              if (profileImageResponse) {
                profileImageUrl = coach.empty_profile_image_url;
                if (profileImageResponse.profile_image_url) {
                  profileImageUrl = profileImageResponse.profile_image_url;
                }
              }

              queryClient.setQueryData(coachProfileQueryKey(), (oldData) => ({
                ...(oldData as CoachProfile),
                ...values,
                profile_image_url: profileImageUrl,
              }));
              actions.setSubmitting(false);
            },
          });
        }}
      >
        {({ isSubmitting, setFieldValue }: FormikProps<FormFields>) => (
          <Form>
            <div className={css({ padding: '30px 30px 0 0' })}>
              <FormRow>
                <>
                  <FormRowLabel>Picture</FormRowLabel>
                  <FormRowInputWrapper>
                    <FormikPhoto
                      name="profile_image_url"
                      emptyImagePath={coach.empty_profile_image_url}
                      currentImagePath={coachProfileImageUrl}
                      onRemoveClick={() => {
                        setCoachProfileImageUrl(coach.empty_profile_image_url);
                        setFieldValue('profile_image_url_remove', 1);
                      }}
                      onChange={(event) => {
                        setFieldValue('profile_image_url_remove', 0);
                        if (event.currentTarget.files && event.currentTarget.files[0]) {
                          setFieldValue('profile_image_url', event.currentTarget.files[0]);
                        }
                      }}
                    />
                  </FormRowInputWrapper>
                </>
              </FormRow>
              <FormRow width="half">
                <>
                  <FormRowLabel>First name</FormRowLabel>
                  <FormRowInputWrapper>
                    <FormikInput name="first_name" />
                  </FormRowInputWrapper>
                </>
              </FormRow>
              <FormRow width="half">
                <>
                  <FormRowLabel>Last name</FormRowLabel>
                  <FormRowInputWrapper>
                    <FormikInput name="last_name" />
                  </FormRowInputWrapper>
                </>
              </FormRow>
              <FormRow>
                <>
                  <FormRowLabel>Phone</FormRowLabel>
                  <FormRowInputWrapper>
                    <FormikInput name="phone_number" />
                  </FormRowInputWrapper>
                </>
                <>
                  <FormRowLabel>Email</FormRowLabel>
                  <FormRowInputWrapper>
                    <FormikInput name="email" disabled value={coach.email} />
                  </FormRowInputWrapper>
                </>
              </FormRow>
              <Spacer />
              <FormRow>
                <>
                  <FormRowLabel>Address Line 1</FormRowLabel>
                  <FormRowInputWrapper>
                    <FormikInput name="address1" />
                  </FormRowInputWrapper>
                </>
                <>
                  <FormRowLabel>Address Line 2</FormRowLabel>
                  <FormRowInputWrapper>
                    <FormikInput name="address2" />
                  </FormRowInputWrapper>
                </>
              </FormRow>
              <FormRow>
                <>
                  <FormRowLabel>City</FormRowLabel>
                  <FormRowInputWrapper>
                    <FormikInput name="city" />
                  </FormRowInputWrapper>
                </>
                <>
                  <FormRowLabel>Post Code</FormRowLabel>
                  <FormRowInputWrapper>
                    <FormikInput name="post_code" />
                  </FormRowInputWrapper>
                </>
              </FormRow>
              <FormRow width="half">
                <>
                  <FormRowLabel>Country</FormRowLabel>
                  <FormRowInputWrapper>{getCountryListDropdown(countries)}</FormRowInputWrapper>
                </>
              </FormRow>

              <Separator />
              <SubmitButtonWrapper>
                <Button
                  variant="success"
                  type="submit"
                  disabled={isSubmitting}
                  isLoading={isSubmitting}
                >
                  Save
                </Button>
              </SubmitButtonWrapper>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
