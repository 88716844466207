import { useQuery } from 'react-query';
import { SupplementCategory } from '~/components/pages/client/Sections/Supplements/model';
import { model as clientSupplementModel } from './supplements';

export const clientsQueryKey = (clientId) => ['client', clientId, 'supplements'];

export const useClientSupplements = (clientId) =>
  useQuery<SupplementCategory[]>(clientsQueryKey(clientId), () =>
    clientSupplementModel.getList(clientId),
  );
