import { useMutation, UseMutationOptions } from 'react-query';
import { model as consultationModel } from './consultation';

export const useScheduleConsultation = <T>(
  clientId: string,
  mutationOptions?: UseMutationOptions<any, unknown, T>,
) =>
  useMutation(() => consultationModel.scheduleConsultation(clientId), {
    ...mutationOptions,
  });
