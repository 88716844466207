import React from 'react';
import { ConfirmationModal } from '~/components/common/ConfirmationModal';
import { useClient } from '~/components/pages/client/context';
import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { useDeleteClientGoal } from '~/model/Client/Goals/hooks/useDeleteClientGoal';
import { clientGoalsQueryKey } from '~/model/Client/Goals/hooks';

export interface Props {
  goalId: string;
  onClose: () => void;
}

export const DeleteModal = ({ goalId, onClose }: Props) => {
  const queryClient = useQueryClient();
  const { clientId } = useClient();
  const deleteClientGoalMutation = useDeleteClientGoal(clientId, goalId);

  return (
    <ConfirmationModal
      testId="delete-goal-modal"
      iconVariant="danger"
      title="Are you sure?"
      closeButtonText="No, cancel"
      submitButtonText="Yes, delete!"
      submitButtonVariant="danger"
      description={<p>This will permanentely delete this goal?</p>}
      onClose={onClose}
      onSubmit={() => {
        deleteClientGoalMutation.mutate(
          {},
          {
            onSuccess: async () => {
              await queryClient.invalidateQueries(clientGoalsQueryKey(clientId));
              toast.success('The Goal has been deleted');
              onClose();
            },
            onError: () => {
              onClose();
              toast.error('Something went wrong... Please try again.');
            },
          },
        );
      }}
    />
  );
};
